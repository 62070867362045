import { KatDropdown } from '@amzn/katal-react';
import React, { useEffect, useState } from 'react';
import { localization } from 'src/helpers';
import $style from './case-status-drop-down.module.scss';

interface CaseStatusDropdownProps {
    caseId?: string;
    caseStatusList?: { name: string, value: string }[];
    caseStatus?: string;
    setCaseStatus: Function;
}

const defaults: CaseStatusDropdownProps = {
    caseStatusList: [],
    setCaseStatus: () => { }
}


export default function CaseStatusDropdown(props: CaseStatusDropdownProps = defaults) {
    const [localNewCaseStatus, setLocalNewCaseStatus] = useState(props.caseStatus ?? '');

    const { translate } = localization();

    function onCaseStatusChange(event) {
        setLocalNewCaseStatus(event.detail.value);
        props.setCaseStatus(event.detail.value);
    }

    useEffect(() => {
        if (!localNewCaseStatus && props.caseStatus) {
            setLocalNewCaseStatus(props.caseStatus)
        }
    }, [props.caseStatus])

    return (
        <div className={$style.styledDiv}>
            <KatDropdown
                onChange={onCaseStatusChange}
                value={localNewCaseStatus}
                options={props.caseStatusList}
                label={translate('Paragon_TAM_CM_Case_Status')} />
        </div>
    )
}