import { WidgetFunctions } from "@amzn/paragon-context/lib/ParagonWidgetDataContext/models/WidgetFunctions";
import { SCEDataRequest, SchemaCaseElementsDataResponse } from "src/widgets/IssueSummaryWidget/model/schemaElementsData";

export function getSchemaCaseData(caseId: string | undefined, requests: [SCEDataRequest], callingSystem: string, widgetMethods: WidgetFunctions) {
    const params = {
        caseId: caseId,
        schemaCaseElementsDataRequests: requests,
        callingSystem: callingSystem,
    };

    return widgetMethods.proxyPost<SchemaCaseElementsDataResponse>("getSchemaElementsData", params);
}
