import React, {useEffect, useState} from 'react';
import {localization} from 'src/helpers';

import {useParagonContext, useParagonContextConsumerEl} from '@amzn/paragon-ui-react';

import Modal from 'src/components/Modal/Modal';
import BigButtonLink from 'src/components/BigButtonLink/BigButtonLink';


import {ReviewPtrModalProps} from 'src/widgets/IssueSummaryWidget/components/widgets/ReplyCase/model/replyCaseSchemaElements'

import $style from './review-ptr-modal.module.scss';
import {logClickStreamData} from "src/common/utils";
import {metricNames} from "src/widgets/IssueSummaryWidget/components/widgets/ReplyCase/model/clickStreamMetric";
import {paragonAnalyticsContext} from "@amzn/paragon-context/lib/ParagonAnalyticsContext";

const defaults: ReviewPtrModalProps = {
    caseId: '',
    showPtrModal: false,
    userLogin: '',
    isTardisSCV: false,
}

export default function ReviewPtrModal(props: ReviewPtrModalProps = defaults) {
    const [ptrModalVisible, setPtrModalVisible] = useState(props.showPtrModal);

    const tadisContactSOPUrl: string = '/hz/codex/?id=GTD88GPQSBRPG54C&layer=Published/US&locale=en-US';

    const { translate } = localization();

    const PTR_BACK_COUNT_LIMIT = 2;

    const el = useParagonContextConsumerEl();
    const { methods: clickStreamFunctions } = useParagonContext(el, paragonAnalyticsContext);


    useEffect(() => {
        showWidget();
    }, []);

    function shouldShowPtrModal() {
        return ptrModalVisible;
    }

    function tardisBackCounter() {
        return getBackCount();
    }

    function showModal() {
        setPtrModalVisible(true);
    }

    function hideModal() {
        setPtrModalVisible(false);
    }

    function showWidget() {
        if (getBackCount() >= PTR_BACK_COUNT_LIMIT) {
            props.transferCaseWithTranslatedReply?.();
        } else {
            showModal();
        }
    }

    function hideWidget() {
        hideModal();
        updateBackCount();
        if (getBackCount() > PTR_BACK_COUNT_LIMIT) {
            props.transferCaseWithTranslatedReply?.();
        } else {
            props.showComposer?.();
        }
    }

    function submitWidget() {
        hideModal();
        logClickStreamData(clickStreamFunctions, 'click', metricNames.reviewView.submitPtrModal);
        //analyticsMethods.reportEvent(event);
        props.transferCaseWithTranslatedReply?.();
    }

    function updateBackCount() {
        const storageKey = (props.caseId as string).concat('backCount');
        let curCount = 0;
        if (sessionStorage.getItem(storageKey)) {
            curCount = parseInt(sessionStorage.getItem(storageKey) as string);
        }
        sessionStorage.setItem(storageKey, (curCount + 1).toString());
    }

    function getBackCount() {
        const storageKey = (props.caseId as string).concat('backCount');
        const backCount = sessionStorage.getItem(storageKey);

        if (backCount) {
            return parseInt(backCount);
        }

        return 0;
    }

    function getBottomText2() {
        return props.isTardisSCV ?
            translate('Paragon_TAM_CM_SCV_Ptr_Select_Option_Send_Description_2') :
            translate('Paragon_TAM_CM_Ptr_Select_Option_Send_Description_2')
    }

    const bodyElement = () => <div className={$style.ptrModalContent}>
        <div>
            { translate('Paragon_TAM_CM_Ptr_Select_Option_Description') }
        </div>
        <div className={$style.bigButtonsContainer}>
            <BigButtonLink className={$style.bigButtonLink} topText={translate('Paragon_TAM_CM_Ptr_Select_Option_Back_Heading')}
                                 bottomText1={translate('Paragon_TAM_CM_Ptr_Select_Option_Back_Description_1')}
                                 bottomText2={translate('Paragon_TAM_CM_Ptr_Select_Option_Back_Description_2')}
                                 hrefLink={tadisContactSOPUrl}
                                 linkifiedText={translate('Paragon_TAM_CM_Ptr_Contact_SOP')}
                                 buttonClicked={hideWidget}/>
            <BigButtonLink className={$style.bigButtonLink} topText={translate('Paragon_TAM_CM_Ptr_Select_Option_Send_Heading')}
                                 bottomText1={translate('Paragon_TAM_CM_Ptr_Select_Option_Send_Description_1')}
                                 bottomText2={getBottomText2()}
                                 hrefLink={tadisContactSOPUrl}
                                 linkifiedText={translate('Paragon_TAM_CM_Ptr_Contact_SOP')}
                                 data-csm-meta-go-back-counter={tardisBackCounter()}
                                 data-csm-meta-action="transfer"
                                 data-csm-name="tardis-ptr-modal"
                                 data-csm-meta-userId={props.userLogin}
                                 buttonClicked={() => submitWidget()}/>
        </div>
    </div>

    return (
        <div>
            {shouldShowPtrModal() &&
            <Modal className={$style.modalContainer} visible={!!ptrModalVisible}
                            onDismiss={hideWidget}
                            header={() => <p>{translate('Paragon_TAM_CM_Ptr_Select_Option')}</p>}
                            body={bodyElement}/>
            }
        </div>
    )
}