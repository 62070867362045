import {KatInput, KatLabel, KatCard} from '@amzn/katal-react';
import React, {useContext, useEffect, useState} from 'react';
import {localization} from 'src/helpers';
import {ReplyHandlerContext} from "src/widgets/IssueSummaryWidget/components/widgets/ReplyCase/providers/replyHandler.context";


interface IssueSummaryFollowupWorkProps {
    caseId?: string
}

import $style from './issue-summary-followup-work.module.scss';

export default function IssueSummaryFollowupWork(props: IssueSummaryFollowupWorkProps) {
    const [followupWork, setFollowupWork] = useState('');

    const { translate } = localization();

    const replySubmitHandlerContext = useContext(ReplyHandlerContext);

    useEffect(() => {
        replySubmitHandlerContext.setIssueSummaryFollowupWorFormData({
            caseId: props.caseId || '',
            followupWork: followupWork
        });
    }, [followupWork]);

    return (
        <KatCard className={$style.styledKatCard} data-testid="katal-card" no-body-padding>
            <div className={$style.styledDiv}>
                <KatLabel data-testid="katal-label" text={translate('Paragon_TAM_CM_Additional_Instructions_PMA')} />
            </div>
            <KatInput
                data-testid="katal-input"
                id="issue-summary-follow-up-work"
                onInput={(event) => {setFollowupWork(event.currentTarget.value)}}
                type="text"
                placeholder={translate('Paragon_TAM_CM_FollowupWorkPlaceholder')}
                value={followupWork}
            />
        </KatCard>
    )
}