import {KatDropdown, KatLabel} from '@amzn/katal-react';
import React, {useState} from 'react';
import {localization} from 'src/helpers';
import {ReasonCategory} from 'src/widgets/IssueSummaryWidget/components/widgets/ReplyCase/model/replyCaseSchemaElements';
import classnames from "classnames";

import $style from './reason-category-dropdown.module.scss';

interface Result {
    name: string;
    value?: string;
}

interface ReasonCategoryDropdownProps {
    reasonCategoryList?: ReasonCategory[];
    caseResolutionCategoryId?: string;
    caseResolutionReasonId?: string;
    updateCaseResolutionReasonId: (reasonId: string) => void;
    updateCaseResolutionCategoryId: (categoryId: string) => void;
}

export default function ReasonCategoryDropdown(props: ReasonCategoryDropdownProps) {
    const [reasonCategoryList] = useState(props.reasonCategoryList);
    const [caseResolutionReasonId, setCaseResolutionReasonId] = useState(props.caseResolutionReasonId);
    const [caseResolutionCategoryId, setCaseResolutionCategoryId] = useState(props.caseResolutionCategoryId);

    const { translate } = localization();

    function convertedReasonCategoryList() {
        const resultList: Result[] = []
        if (reasonCategoryList) {
            for (let i = 0; i < reasonCategoryList.length; i++) {
                const reasonCategory = reasonCategoryList[i]
                resultList.push({name: reasonCategory.category, value: reasonCategory.id ? reasonCategory.id.toString() : undefined})
            }
        }
        return resultList
    }

    function reasonMap() { // categoryId -> list of Reasons
        const resultMap: Record<string, Result[]> = {};
        if (reasonCategoryList) {
            for (let i = 0; i < reasonCategoryList.length; i++) {
                const reasonCategory = reasonCategoryList[i]
                if (reasonCategory && reasonCategory.reasons) {
                    resultMap[reasonCategory.id] = reasonCategory.reasons.map(reason => ({name: reason.reason, value: reason.id ? reason.id.toString() : undefined}))
                }
            }
        }
        return resultMap
    }

    function setSelectedCategoryId(event) {
        setSelectedReasonId({ detail: {} });
        setCaseResolutionCategoryId(event.detail.value);
        props.updateCaseResolutionCategoryId(event.detail.value);
    }

    function setSelectedReasonId(event) {
        setCaseResolutionReasonId(event.detail.value);
        props.updateCaseResolutionReasonId(event.detail.value);
    }

    const resolutionReasonDropdownElement = <section className="kat-col-xs-6 reason-dropdown-katal">
        <div className={$style.reasonCategoryHeader}>
            <KatLabel data-testid="katal-label-reason" text={translate('Paragon_TAM_CM_Reason_Code')} />
        </div>
        <KatDropdown
            data-testid="katal-dropdown-reason"
            id="resolutionReasonFormDropdown"
            onChange={setSelectedReasonId}
            options={JSON.stringify(reasonMap()[caseResolutionCategoryId as string])}
            state={caseResolutionReasonId ? undefined : 'error'}
            value={caseResolutionReasonId}/>
    </section>

    return (
        <div>
            <div className="kat-row p-0">
                <section className={classnames("kat-col-xs-6", $style.reasonCategoryContainer)}>
                    <div className={$style.reasonCategoryHeader}>
                        <KatLabel data-testid="katal-label-category" text={translate('Paragon_TAM_CM_Reason_Category')} />
                    </div>
                    <KatDropdown
                        data-testid="katal-dropdown-category"
                        id="resolutionCategoryFormDropdown"
                        onChange={setSelectedCategoryId}
                        options={JSON.stringify(convertedReasonCategoryList())}
                        state={caseResolutionCategoryId ? undefined : 'error'}
                        value={caseResolutionCategoryId}/>
                </section>
                {caseResolutionCategoryId && reasonMap()[caseResolutionCategoryId] && resolutionReasonDropdownElement}
            </div>
        </div>
    )
}