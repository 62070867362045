// bad or offensive words
/* We have to use a workaround for \b since Javascript does not support non-latin unicode characters for word boundaries.
 * Instead, we have to use lookarounds: https://shiba1014.medium.com/regex-word-boundaries-with-unicode-207794f6e7ed
 * Only compatible with Chrome/Firefox/Edge https://caniuse.com/js-regexp-lookbehind.
*/
export const pottyWordPattern = new RegExp(
    /\b[\w-]*(ph|f)u(ck?|k|qu?)(e?(t|d|rs?|ing))?[\w-]*(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)[\w-]*sh(i|y)t[\w-]*(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)b[iy]t?ch(es)?(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)beyatch(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)coc?k([ -]?suck(e?[dr]?|ing))?(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)[ck]lit+(or[iu]s)?(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)cunt\w*(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)prick(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)hummer(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)bastard(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)twat(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)tubesteak(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)(?!assess)ass(hol|wip|fac)?[esd]*(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)castrat\w*(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)nutsac?k(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)an(al|us)(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)butt(hole)?(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)tit+(ie|y)?s?(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)pecker(wood)?s?(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)boobs?(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)zooph(e|i)l(e|i)a?c?(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)sodom(y|ize)(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)(m[ou]th(a|er))?fuk(er|a)?s?(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)shiz.*(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)ji[sz]{1,2}(([uei]m)?ing)?(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)ejaculat(ed?|ion|ing)(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)blow-?job(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)pee-?pee(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)fellat[eio]+(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)s(l|m)ut+y?s?(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)hump(s|ing?)?(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)whore?(d|ing|s)?(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)felch(ed|ing)?(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)[\w-]*su(c?k+(ers?|ed|ing?|y|s\w*)?|x+)(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)cum+(ing|ed)?(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)blow[ -?](ing?|job|hard|s)s?(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)piggery(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)coitus(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)furtling(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)horny(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)cunnilingus(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)dumbass(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)fuct(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)pederast(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)incontinence(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)bimbo(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)prostitute(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)piss(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)crap(?=[\s,.:;"']|$)/.source +

    // racially offensive

    /|(?<=[\s,.:;"']|^)gooks?(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)kikes?(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)nat?zis?(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)jewboy(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)ar(i|y)ans?(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)hit{1,2}ler(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)nig{1,2}(er|a(s|z)?)(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)d[iy]ke\w*(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)kkk(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)queer(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)orgy(?=[\s,.:;"']|$)/.source +

    // body parts

    /|(?<=[\s,.:;"']|^)hooters?(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)(p|w)ussy?(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)pen(i|u)s(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)vagia?nal?(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)bone(r|d)(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)tush[iey]*(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)fec(al|es)(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)test(e|i)s(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)rect(um|al)(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)ca(h|j)ones(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)urin(e|ated?)(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)testicle?s?(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)mast(u|e)rbat(e|ion)(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)snatch(?=[\s,.:;"']|$)/.source +

    // German words

    /|(?<=[\s,.:;"']|^)Schei[M\-_s]+dreck(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)Schei[M\-_s]+e(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)(be)?schissen?(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)plagi(ier(en)?|at)(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)schwul[er]*(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)(ab)?wichse[nr]+(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)M[M-vo]pse(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)(be)?pissen(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)Arier(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)arisch(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)Abschlag(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)Rabatt(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)erotisch(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)herumfingern(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)betatschen(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)Huren(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)Luder(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)Kacke(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)geil(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)scharf(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)Hure(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)Betrug(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)Schwindel(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)Kitzler(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)Fotze(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)vulva(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)Schlampe(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)Schwanz(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)Muschi(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)Rute(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)Arschloch(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)Popo(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)Arsch(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)Saft(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)urinieren(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)Arschficker(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)kastrieren(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)bumsen(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)flachlegen(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)ablutschen(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)abkauen(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)Koitus(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)Titten(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)Göring(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)Görings(?=[\s,.:;"']|$)/.source +

    // Spanish words

    /|(?<=[\s,.:;"']|^)ching[ador]+(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)maric[ao(sn)]+(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)cul(er)?o(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)puta(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)joder(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)joto(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)jodido(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)mamey(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)mayate(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)verga(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)macanema(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)pinche(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)cabron(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)mierda(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)desmadre(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)cagar(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)cagada(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)carajo(?=[\s,.:;"']|$)/.source +
    /|(?<=[\s,.:;"']|^)chingate(?=[\s,.:;"']|$)/.source, 'giu')