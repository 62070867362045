import React, {useCallback, useContext, useEffect, useState} from 'react';
import {KatStatusindicator, KatLabel, KatTextarea} from '@amzn/katal-react';
import {FieldError, useFormContext} from 'react-hook-form';

import {useParagonContext, useParagonContextConsumerEl} from "@amzn/paragon-ui-react";
import {paragonCoreDataContext} from "@amzn/paragon-context/lib/ParagonCoreDataContext";
import {paragonReplyContext} from "@amzn/paragon-context/lib/ParagonReplyContext";
import {paragonUserContext} from "@amzn/paragon-context/lib/ParagonUserContext";
import {paragonPageContext} from "@amzn/paragon-context/lib/ParagonPageContext";

import {Blurb, ParagonEvents} from 'src/widgets/IssueSummaryWidget/components/widgets/ReplyCase/model/replyCaseSchemaElements';

import $style from './email-body-component.module.scss';
import {ReplyHandlerContext} from "src/widgets/IssueSummaryWidget/components/widgets/ReplyCase/providers/replyHandler.context";

export enum ContentType {
    BODY_CONTENT = 'emailContent',
    SIGNATURE_CONTENT = 'signatureContent',
    PREVIOUS_EMAIL_CONTENT = 'previousEmailCorrespondenceContent'
}

export type Inputs = {
    emailContent: string,
    signatureContent: string,
    previousEmailCorrespondenceContent: string
}

interface ValidationOutput {
    failedRule: string
}

interface EmailBodyComponentProps {
    className?: string;
    caseId?: string;
    header?: string;
    placeholderText?: string
    content?: string;
    contentIds?: string[];
    rules?: object;
    type?: string;
    tenantId?: number;
    focus?: boolean;
    isValid?: (validationOutput: ValidationOutput) => void;
    updateContent? : (content) => void;
    requestFocus?: () => void;
    name: ContentType;
}

const defaults: EmailBodyComponentProps = {
    focus: false,
    name: ContentType.BODY_CONTENT
}

export default function EmailBodyComponent(props: EmailBodyComponentProps = defaults) {
    const [emailContent, setEmailContent] = useState(props.content || '');
    const [frictionBlurbs, setFrictionBlurbs] = useState<Blurb[]>([]);

    const { register, setValue, formState: { errors }, trigger } = useFormContext<Inputs>();

    const el = useParagonContextConsumerEl();
    const { value: coreData } = useParagonContext(el, paragonCoreDataContext);
    const { value: userDetails } = useParagonContext(el, paragonUserContext);
    const { value: pageData } = useParagonContext(el, paragonPageContext);
    const { value: replyData, on: replyEventOn } = useParagonContext(el, paragonReplyContext);

    const replyHandlerContext = useContext(ReplyHandlerContext);

    //TODO: Find out how to use the react ref to focus on text area
    //const textareaRef = useRef<KatTextarea.Element>(null);

    const loadFrictionBlurbs = useCallback(() => {
        const storageKey = 'bodyFrictionBlurbs'.concat(props.caseId as string)
        const value = localStorage.getItem(storageKey);
        if (value) {
            setFrictionBlurbs(JSON.parse(value));
        }
    }, [props.caseId, setFrictionBlurbs]);

    const addToBlurbFrictionMetadata = useCallback((data) => {
        const blurbData = data.blurbData;
        const originalBlurbContent = blurbData ? blurbData.blurbText : data.blurbContent
        const greyedValue = data.propertyValue;

        const workflowData = replyData.workflowData;

        const blurbMetadata: Blurb = {
            originalContent: originalBlurbContent,
            metadata: {
                PmTenantId: props.tenantId ? props.tenantId : -1,
                PmCaseId: props.caseId as string,
                PmBlurbId: data.blurbName ? data.blurbName : data.blurbId,
                PmBlurbLocale: data.language ? data.language : data.locale,
                PmWorkflowId: workflowData?.workflowId,
                PmWorkflowStepName: workflowData?.currentStepName,
                PmDiagRunId: workflowData?.diagRunId,
                PmGreyed: greyedValue
            }
        }

        const newFrictionBlurbs = [ ...frictionBlurbs, blurbMetadata ];

        setFrictionBlurbs(newFrictionBlurbs)
        const storageKey = 'bodyFrictionBlurbs'.concat(props.caseId as string)
        localStorage.setItem(storageKey, JSON.stringify(newFrictionBlurbs))
    }, [frictionBlurbs]);

    const handleAttachBlurb = useCallback((data) => {
        const spacing = !(emailContent) ? '' : '\n\n'
        const blurb = data.blurbContent;
        const pasteText = spacing + blurb;

        setEmailContent(emailContent + pasteText);

        addToBlurbFrictionMetadata(data);
    }, [emailContent]);

    useEffect(() => {
        const unsubAttachBlurb = replyEventOn(ParagonEvents.ATTACH_BLURB, payload => {
            handleAttachBlurb(payload);
        });

        const unsubAttachBlurbFriction = replyEventOn(ParagonEvents.ATTACH_BLURB_FRICTION, payload => {
            handleAttachBlurb(payload);
        });

        return () => {
            unsubAttachBlurb();
            unsubAttachBlurbFriction();
        }
    }, [handleAttachBlurb]);

    useEffect(() => {
        trigger();
    }, [trigger]);

    useEffect(() => {
        if (props.content !== emailContent) {
            setEmailContent(props.content || '');
        }
    }, [props.content]);

    useEffect(() => {
        props.updateContent?.(emailContent);
        setValue(props.name, emailContent);
        replyHandlerContext.setEmailBodyComponentFormData({
            caseId: props.caseId as string,
            emailContent: emailContent,
            blurbFrictionData: {
                frictionBlurbs: frictionBlurbs
            }
        });

        trigger();
    }, [emailContent, frictionBlurbs]);

    useEffect(() => {
        if (props.type === 'body') {
            loadFrictionBlurbs();
        }
    }, [loadFrictionBlurbs, props.type]);

    useEffect(() => {
        handleValidation(errors[props.name]);
    }, [errors[props.name], handleValidation]);

    function handleValidation(error: FieldError | undefined) {
        const validationOutput = {
            failedRule: error?.type || '',
            failedRuleMessage: error?.message
        }
        props.isValid?.(validationOutput);
    }

    const headerElement = <span>
    <KatLabel className={$style.headerKatLabel} data-testid="katal-label" text={props.header} />
  </span>

    const errorMessageElement = <div className={$style.statusIndicatorDiv}>
        <KatStatusindicator className={$style.styledKatStatusIndicator} data-testid="katal-status-indicator" label={errors[props.name]?.message as string} variant="error" />
    </div>

    return (
        <div className={`textarea-container ${props.className}`}>
            <div>
                {props.header && headerElement}
                <KatTextarea
                    data-testid="katal-textarea"
                    {...register(props.name, {...props.rules, onChange: (event) => setEmailContent(event.currentTarget.value)})}
                    kat-spellcheck={true}
                    value={emailContent}
                    className={$style.styledKatTextarea}
                    placeholder={props.placeholderText}
                />
            </div>
            {errors[props.name] && errorMessageElement}
        </div>
    )
}