import React, { useEffect, useState } from 'react';
import { KatAlert, KatBox, KatLink } from '@amzn/katal-react';
import { paragonCoreDataContext } from "@amzn/paragon-context/lib/ParagonCoreDataContext";
import { paragonWidgetDataContext } from '@amzn/paragon-context/lib/ParagonWidgetDataContext';
import { paragonAnalyticsContext } from "@amzn/paragon-context/lib/ParagonAnalyticsContext";
import {
    PageSection,
    useParagonContextConsumerEl,
    useParagonContext
} from "@amzn/paragon-ui-react";
import $style from "./action-plan-widget.module.scss";
import { SOPDetails } from './components/sopListDetails/SOPDetails';
import { ActivityList } from './components/ActionsListDetails/ActivityList';
import { SelectionFooter } from './components/common/SelectionFooter';
import { FinalBlurb } from './components/common/FinalBlurb';
import { HeaderComponent } from './components/common/HeaderComponent';
import { getActivityStep } from './service/actionPlan';
import { GetNextSOPStepResponseData } from './model/ActionPlanTypes';
import { getParagonDomain, logClickStreamData } from 'src/common/utils';
import { logger } from 'src/logger';

export const ActionPlanWidget = ({ }) => {
    const [isSOPPicked, setIsSOPPicked] = useState(false);
    const [selectedSopId, setSelectedSopId] = useState<string>("");
    const [paragonCaseId, setParagonCaseId] = useState<string | undefined>();
    const [paragonCaseSubject, setParagonCaseSubject] = useState<string | undefined>();
    const [showSOPList, setShowSOPList] = useState(true);
    const [selectedOutcome, setSelectedOutcome] = useState("");
    const [loadingActions, setLoadingActions] = useState(true);
    const [showActionsList, setShowActionsList] = useState(false);
    const [actions, setActions] = useState<any>();
    const [actionResponse, setActionResponse] = useState<any>();
    const [command, setCommand] = useState<string>("");
    const [showErrorBanner, setShowErrorBanner] = useState(false);
    const [noActionsListSupport, setNoActionsListSupport] = useState(false);
    const [showFinalBlurb, setShowFinalBlurb] = useState(false);
    const [sopListFeedback, setSopListFeedback] = useState('');
    const [isSteppingSupported, setIsSteppingSupported] = useState(false);
    const [closeFeedback, setCloseFeedback] = useState('');

    const domain: string = getParagonDomain(window.location.origin);

    const el = useParagonContextConsumerEl();
    const {
        value: { caseDetails },
    } = useParagonContext(el, paragonCoreDataContext);

    const { methods: widgetMethods } = useParagonContext(el, paragonWidgetDataContext);

    const { methods: clickStreamFunctions } = useParagonContext(el, paragonAnalyticsContext);

    function changeSOPId(value: string) {
        setSelectedSopId(value);
    }

    function getNextActivityStep() {
        setLoadingActions(true)
        getActivityStep(selectedSopId, widgetMethods, actionResponse, selectedOutcome).then((response) => {
            setShowErrorBanner(false);
            if (response.success) {
                const getNextSOPStepResponse: GetNextSOPStepResponseData = response.data;
                const action = getNextSOPStepResponse.actions;
                setActionResponse(action);
                setCommand(getNextSOPStepResponse.command);
                if (!command && action === "null") {
                    setNoActionsListSupport(true)
                }
                setActions(JSON.parse(action || "{}"));
            } else {
                setShowErrorBanner(true);
                logClickStreamData(clickStreamFunctions, "click", "action-plan-widget-task-error", { caseId: paragonCaseId });
                logger.info(`[action-plan-widget-task-error] For case: ${paragonCaseId}`);
            }
        }).catch(() => {
            console.log("Actions fetch failed");
            setShowErrorBanner(true);
        }).finally(() => {
            setLoadingActions(false)
            setSelectedOutcome("")
        })
    };

    function handleNextButton() {
        if (selectedSopId != "" && isSOPPicked) {
            logClickStreamData(clickStreamFunctions, "click", "action-plan-widget-next-with-SOP", {
                caseId: caseDetails?.id,
                sopId: selectedSopId,
                taskNo: actions?.length,
                selectedOutcome: selectedOutcome,
                command: command,
            });
            logger.info(`[action-plan-widget-next-with-SOP] For case: ${caseDetails?.id} is ${selectedSopId}`);
            getNextActivityStep();
            setShowSOPList(false);
            setShowActionsList(true);
        } else if (!isSOPPicked) {
            if (selectedSopId !== "none") {
                getNextActivityStep();
                setShowSOPList(false);
                setShowActionsList(true);
            } else {
                setShowFinalBlurb(true);
                setShowSOPList(false);
            }
            logClickStreamData(clickStreamFunctions, "click", "action-plan-widget-sop-not-listed", {
                caseId: caseDetails?.id,
                sopFeedback: sopListFeedback
            });
            logger.info(`[action-plan-widget-sop-not-listed] For case: ${caseDetails?.id}. Feedback: ${sopListFeedback}`);
        }
    }

    function handleFeedbackButton() {
        logClickStreamData(clickStreamFunctions, "click", "action-plan-widget-feedback", {
            caseId: caseDetails?.id,
            feedback: closeFeedback,
            taskNo: actions?.length,
            command: command,
        });
        logger.info(`[action-plan-widget-feedback] For case: ${caseDetails?.id}. Feedback: ${closeFeedback}`);
        handleReset();
    }

    function handleStartOver() {
        logClickStreamData(clickStreamFunctions, "click", "action-plan-widget-start-over", {
            caseId: caseDetails?.id,
            taskNo: actions?.length,
            command: command,
        });
        logger.info(`[action-plan-widget-start-over] For case: ${caseDetails?.id}`);
        handleReset();
    }

    function changeCancelReason(value: string) {
        setCloseFeedback(value);
    }

    function changeSopListFeedback(value: string) {
        setSopListFeedback(value);
    }

    function setIfSOPListed(value: boolean) {
        setIsSOPPicked(value);
    }

    const handleOutcomeSelection = (activity: string) => {
        setSelectedOutcome(activity);
    }

    function handleBackButton() {
        logClickStreamData(clickStreamFunctions, "click", "action-plan-widget-back-click", {
            caseId: caseDetails?.id,
            taskNo: actions?.length,
            command: command,
        });
        logger.info(`[action-plan-widget-back-click] For case: ${caseDetails?.id}`);
        if ((!actions || actions === null) && command !== "END") {
            handleReset();
        } else {
            actions.splice(actions.length - 1);
            if (actions.length === 0) {
                setShowSOPList(true)
                setNoActionsListSupport(false);
                setSelectedOutcome("")
                setSelectedSopId('')
                setIsSOPPicked(false);
                setIsSteppingSupported(false);
                setShowActionsList(false)
                setActionResponse(undefined)
                setActions(undefined)
            } else {
                setShowSOPList(false);
                setActions([...actions]);
                setActionResponse(JSON.stringify(actions));
            }
        }
    }
    function isNextButtonDisabled() {

        if (showFinalBlurb || command === "END") {
            return true
        } else if (showSOPList && (selectedSopId === '' || isSOPPicked)
            && !isSteppingSupported) {
            return true
        } else if (showActionsList && !selectedOutcome) {
            return true
        }
        return false;
    }

    function handleReset() {
        //Show SOP recommendations
        setShowSOPList(true);
        setSelectedSopId('');
        //Hide Tasks, Blurbs, Error messages
        setShowActionsList(false);
        setNoActionsListSupport(false);
        setShowFinalBlurb(false);

        setCommand('');
        setIsSOPPicked(false);
        setIsSteppingSupported(false);
        setActionResponse(undefined)
        setActions(undefined);
        setSelectedOutcome("");
        setSopListFeedback("");
    }

    useEffect(() => {
        if (!caseDetails?.id) {
            return;
        }
        setParagonCaseId(caseDetails?.id);
        setParagonCaseSubject(caseDetails?.subject);

        logger.info(`[action-plan-widget-load] For case: ${caseDetails?.id}`);
        logClickStreamData(clickStreamFunctions, "click", "action-plan-widget-load", { caseId: caseDetails?.id });

    }, [caseDetails?.id, caseDetails?.subject]);

    return (
        <PageSection
            title="Action Plan"
            widgetId="action-plan-widget"
        >
            <div className={$style.actionPlanWidget}>

                <HeaderComponent
                    tasksStep={showActionsList}
                    finalStep={command === "END"} />

                {showSOPList && <SOPDetails
                    paragonCaseId={paragonCaseId}
                    paragonCaseSubject={paragonCaseSubject}
                    widgetMethods={widgetMethods}
                    changeSOPId={changeSOPId}
                    changeSopListFeedback={changeSopListFeedback}
                    setIfSOPListed={setIfSOPListed}
                    isSOPPicked={isSOPPicked}
                    clickStream={clickStreamFunctions}
                    setIsSteppingSupported={setIsSteppingSupported}
                />}

                {showActionsList && !noActionsListSupport && <ActivityList
                    actions={actions}
                    loading={loadingActions}
                    onOutcomeSelection={handleOutcomeSelection}
                />}

                {noActionsListSupport &&
                    <KatBox variant="white" className={$style.mainBox}>
                        <KatAlert variant='info'
                            persistent
                            header='SOP tasks unavailable'
                        >
                            <label>Sorry, we cannot generate steps for the SOP at this time. Please follow standard procedures to address the case and try again later.</label>
                            <KatLink
                                href={domain + "/hz/codex?id=" + selectedSopId}
                                target="_blank"
                                label="SOP link"
                            />
                        </KatAlert>

                    </KatBox>
                }

                {showErrorBanner && <KatAlert variant='danger' className={$style.errorBanner}
                    description='Error loading, try again...'
                // dismissed={isSOPPicked}
                />}

                {(command === "END" || showFinalBlurb) && <FinalBlurb
                    command={command}
                />}
                <SelectionFooter
                    handleNextButton={handleNextButton}
                    handleSubmitButton={handleFeedbackButton}
                    handleBackButton={handleBackButton}
                    changeCancelReason={changeCancelReason}
                    handleResolveButton={handleStartOver}
                    command={command}
                    disablePopover={!isSOPPicked || showActionsList || isSteppingSupported}
                    disableNext={isNextButtonDisabled()}
                    disableBackCancel={showSOPList}
                />

            </div>
        </PageSection>
    );
};