import {KatBadge, KatDivider, KatSpinner, KatBox, KatButton} from '@amzn/katal-react';
import React, {useContext, useEffect, useState} from 'react';
import {localization} from 'src/helpers';


import {paragonMediator} from '@amzn/paragon-context/lib/ParagonMediatorContext/paragonMediatorContext';
import {useParagonContext, useParagonContextConsumerEl} from '@amzn/paragon-ui-react';
import {paragonAnalyticsContext} from "@amzn/paragon-context/lib/ParagonAnalyticsContext";

import Transcript from 'src/widgets/IssueSummaryWidget/components/widgets/ReplyCase/Transcript';
import ReviewPtrModal from 'src/widgets/IssueSummaryWidget/components/widgets/ReplyCase/ReviewPtrModal';

import {metricNames} from 'src/widgets/IssueSummaryWidget/components/widgets/ReplyCase/model/clickStreamMetric';
import {
    CaseAttributes,
    CaseStatusType,
    ErrorData,
    ReviewReplyInput,
    ReviewViewProps,
    EmailAttributes,
} from 'src/widgets/IssueSummaryWidget/components/widgets/ReplyCase/model/replyCaseSchemaElements';

import $style from './review-view.module.scss';
import {ReplyHandlerContext} from "src/widgets/IssueSummaryWidget/components/widgets/ReplyCase/providers/replyHandler.context";
import {ServicesAPIContext} from "src/service/service.context";
import {logClickStreamData} from "src/common/utils";

const defaults: ReviewViewProps = {
    params: {} as ReviewReplyInput,
    agentLogin: '',
    isTardisSCV: false,
    showComposer: () => {},
    showSubmitted: () => {},
    showError: () => {},
}

export default function ReviewView(props: ReviewViewProps = defaults) {
    const [tokenId, setTokenId] = useState('');
    const [emailContentBlob, setEmailContentBlob] = useState('');
    const [emailContentBlobPreview, setEmailContentBlobPreview] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [attachments, setAttachments] = useState<{ fileName: string, fileKey: string }[]>([]);
    const [caseAttributes, setCaseAttributes] = useState<CaseAttributes>({} as CaseAttributes);
    const [emailAttributes, setEmailAttributes] = useState<EmailAttributes>({} as EmailAttributes);
    const [metadata, setMetadata] = useState<Record<string, string>>({});
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [statusCode] = useState(0);
    const [showPtrModal, setShowPtrModal] = useState(false);
    const [params, setParams] = useState(props.params);

    const PTR_BACK_COUNT_LIMIT = 2;

    const serviceAPIContext = useContext(ServicesAPIContext);

    const replySubmitHandlerContext = useContext(ReplyHandlerContext);

    const { translate } = localization();

    const el = useParagonContextConsumerEl();
    const { methods: clickStreamFunctions } = useParagonContext(el, paragonAnalyticsContext);

    useEffect(() => {
        setParams(props.params);
    }, [props.params]);

    useEffect(() => {
        constructReplyToCase()
    }, [params]);

    function nextStep() {
        return allowDirectReply() ? 'reply' : 'transfer';
    }

    function shouldShowToList(): boolean {
        return !!emailAttributes.toEmailList && emailAttributes.toEmailList.length > 0
    }

    function shouldShowCcList(): boolean {
        return emailAttributes.ccEmailList && emailAttributes.ccEmailList.length > 0
    }

    function shouldShowBccList(): boolean {
        return emailAttributes.bccEmailList && emailAttributes.bccEmailList.length > 0
    }

    function emailContent(): string {
        // If email has been translated to seller's language of preference, show original version for associate to review in their LOP.
        return isTranslationEnabled() ? metadata?.originalBlob as string : emailContentBlobPreview;
    }

    function isTranslationEnabled(): boolean {
        const enabledInMetadata = metadata?.translationEnabled?.toLowerCase() === 'true';
        const enabledInConfig = params?.replyTranslationConfig?.translateReplyEnabled;
        return !!(enabledInMetadata || enabledInConfig);
    }

    function allowDirectReply(): boolean {
        if (!isTranslationEnabled()) { // TODO remove once next allowed action flag is set regardless of translationEnabled
            return true;
        }
        return metadata?.nextAllowedAction === 'REPLY';
    }

    function nextAllowedActionisTransfer(): boolean {
        return metadata?.nextAllowedAction === 'TRANSFER';
    }

    function constructReplyToCase(): void {
        setIsLoadingData(true);
        setShowPtrModal(false);

        serviceAPIContext.paragonService.reviewReply(params).then(response => {
            if (response && response.reviewReviewData) {
                const output = response.reviewReviewData
                setTokenId(output.tokenId);
                setAttachments(output.attachmentList);
                setMetadata(output.metadata);
                setCaseAttributes(output.caseAttributes as CaseAttributes);
                setEmailAttributes(output.emailAttributes as EmailAttributes);
                setEmailContentBlob(output.emailContentBlob);
                setEmailContentBlobPreview(output.emailContentBlob);
                // If HMD exists, replace HMD placeholder with HMD preview text so that the user can review the full text.
                if (output.emailContentBlob && output.metadata && output.metadata.hmdPreview) {
                    setEmailContentBlobPreview(output.emailContentBlob.replace('${print-hmd-poll}', output.metadata.hmdPreview));
                }
            }
            setIsLoadingData(false);
            setShowPtrModal(false);
            setErrorMessage('');
        }).catch(error => handleError(error));
    }

    function cancelReplyToCase(event): void {
        logClickStreamData(clickStreamFunctions, 'click', metricNames.reviewView.cancelReplyToCase);
        //analyticsMethods.reportEvent(event);
        props.showComposer();
    }

    function submitReplyToCase(event): void {
        setIsLoadingData(true);

        if (allowDirectReply()) {
            logClickStreamData(clickStreamFunctions, 'click', metricNames.reviewView.submitReplyToCaseDirectReply);
            //analyticsMethods.reportEvent(event);

            if (isTranslationEnabled()) {
                logClickStreamData(clickStreamFunctions, 'click', metricNames.reviewView.tardisPtrModal , getPtrMetricsMetaData());
                //analyticsMethods.logData(getPtrMetrics(metricNames.reviewView.tardisPtrModal))
            }

            const params = {
                uuid: tokenId
            }

            serviceAPIContext.paragonService.replyToCase(params).then(response => {
                if (response && response.replyToCaseData) {
                    props.showSubmitted(response.replyToCaseData);

                    if (props.params?.newCaseStatus === CaseStatusType.PENDING_MERCHANT_ACTION && props.params?.pmaReason !== '') {
                        logClickStreamData(clickStreamFunctions, 'click', `ParagonHorizonte:PMAFriction:${  props.params.pmaReason}`, {caseId: props.params.caseId});
                      //  analyticsMethods.logData(metricData);
                    }
                    clearCachedBodyContent();
                }

                replySubmitHandlerContext.handleReplySubmitted({ ekkoId: response.replyToCaseData?.commId as string });
                setIsLoadingData(false);
                setErrorMessage('');
                paragonMediator.notify('replyToCaseForm-submission-succeeded', {});
            }).catch(error => handleError(error));
        } else if (isTranslationEnabled() && nextAllowedActionisTransfer()) {
            toggleShowPtrModal()
        } else {
            console.log('Unexpected value ', metadata?.nextAllowedAction, ' for next allowed action with translationEnabled=', isTranslationEnabled())

            props.showError({ message: 'Paragon_TAM_CM_An_error_occurred_please_try_again_later'});
            props.showComposer();

            setIsLoadingData(false);
        }
    }

    function toggleShowPtrModal() {
        setIsLoadingData(false);

        if(getBackCount() >= PTR_BACK_COUNT_LIMIT) {
            logClickStreamData(clickStreamFunctions, 'click', metricNames.reviewView.tardisPtrModal , getPtrMetricsMetaData());

           // analyticsMethods.logData(getPtrMetrics(metricNames.reviewView.tardisPtrModal));
            transferCaseWithTranslatedReply();
        } else {
            logClickStreamData(clickStreamFunctions, 'click', metricNames.reviewView.tardisPtrModalShow , getPtrMetricsMetaData());

           // analyticsMethods.logData(getPtrMetrics(metricNames.reviewView.tardisPtrModalShow));
            setShowPtrModal(true);
        }
    }

    function getBackCount() {
        const storageKey = (caseAttributes?.caseId || metadata?.caseId || '').concat('backCount');
        if (sessionStorage.getItem(storageKey)) {
            return parseInt(sessionStorage.getItem(storageKey) || '0');
        }
        return 0;
    }

    function clearBackCount() {
        const storageKey = (caseAttributes?.caseId || metadata?.caseId || '').concat('backCount');
        if (sessionStorage.getItem(storageKey)) {
            sessionStorage.removeItem(storageKey);
        }
    }

    function transferCaseWithTranslatedReply() {
        clearBackCount();
        setShowPtrModal(false);
        setIsLoadingData(true);

        let localMetadata = metadata;

        if(props.isTardisSCV){
            localMetadata = localMetadata ? {...localMetadata, isTardisSCV: 'true'} : { isTardisSCV: 'true' }
        }

        const params = {
            caseId: (caseAttributes.caseId || metadata.caseId) as string,
            currentQueue: caseAttributes.caseQueue,
            metadata: localMetadata,
            emailContentBlob: emailContentBlob
        }

        serviceAPIContext.paragonService.transferTranslatedReply(params).then(response => {
            if(response && response.transferTranslatedReplyData) {
                const submittedData = {
                    transferSuccessMessage: response.transferTranslatedReplyData
                }
                props.showSubmitted(submittedData);
                clearCachedBodyContent()
            }
            setIsLoadingData(false);
            setErrorMessage('');
        }).catch(error => handleError(error))
    }

    function clearCachedBodyContent(): void {
        const storageKey = 'bodyContent'.concat(params?.caseId || '');
        if (localStorage.getItem(storageKey)) {
            localStorage.removeItem(storageKey);
        }
        clearBackCount();
    }

    function handleError(error) {
        const errorData: ErrorData = { message: ''};

        if (error && error.response) {
            errorData.statusCode = error.response.status;
            errorData.message = error.response.data;
        } else if (error) {
            errorData.message = error;
        } else {
            errorData.statusCode = 500;
            errorData.message =  'Paragon_TAM_CM_An_error_occurred_please_try_again_later';
        }

        props.showError(errorData);
        props.showComposer();
        setIsLoadingData(false);
    }

    function showComposer() {
        props.showComposer();
        setIsLoadingData(false);
    }

    function getPtrMetricsMetaData(): Object {
        return {
                Action: nextStep(),
                GoBackCounter: getBackCount(),
                UserId: props.agentLogin,
                IsTranslationEnabled: isTranslationEnabled()
        }
    }

    const toListBadges = emailAttributes.toEmailList?.map((item) => <KatBadge key={item} label={item} />);

    const ccBadges = emailAttributes.ccEmailList?.map((item) => <KatBadge key={item} label={item} />);

    const bccBadges = emailAttributes.bccEmailList?.map((item) => <KatBadge key={item} label={item} />);

    const attachmentsBadges = attachments.map(attachment => <h6 key={attachment.fileKey} className="m-0 p-0">
        <KatBadge type="info" className="attachment-item-review" label={attachment.fileName} />
    </h6>);

    return (
        <KatBox className={$style.overRideKatBox} variant="ltgrey">
            <div className="d-flex justify-content-center">
                {isLoadingData && <KatSpinner variant="default" size="small" />}
            </div>

            {!isLoadingData && !showPtrModal &&
            <div>
                <h3> { translate('Paragon_TAM_CM_Review_Your_Reply') } </h3>
                <KatDivider variant="eastern" />
                <div className={$style.recipientGroup}>
                    {shouldShowToList() &&
                    <span className={$style.infoRow}>
                        <span className={$style.prependText}>{ translate('Paragon_TAM_CM_To') }</span> { toListBadges }
                    </span>
                    }
                    {shouldShowCcList() &&
                    <span className={$style.infoRow}>
                        <span className={$style.prependText}>Cc</span> { ccBadges }
                    </span>
                    }
                    {shouldShowBccList() &&
                    <span className={$style.infoRow}>
                        <span className={$style.prependText}>Bcc</span> { bccBadges }
                    </span>
                    }
                    <span className={$style.infoRow}>
                        <span className={$style.prependText}>{ translate('Paragon_TAM_CM_From') }</span> { emailAttributes.fromEmail }
                    </span>
                    <span className={$style.infoRow}>
                        <span className={$style.prependText}>{ translate('Paragon_TAM_CM_Subject') }</span> { emailAttributes.subject }
                    </span>
                </div>
                <div className={$style.emailContentCard}>
                    <Transcript text={emailContent()} />
                </div>

                {attachments.length > 0 &&
                <div className={$style.attachmentReview}>
                    <div className={$style.componentHeader}>
                        <b> { translate('Paragon_TAM_CM_Attachments') } </b>
                    </div>
                    <div>
                        { attachmentsBadges }
                    </div>
                </div>
                }

                <div className={$style.transitionButtonGroup}>
                    <KatButton className={$style.transitionButton || 'transition-button'} onClick={(event) => submitReplyToCase(event)}
                                      data-testid="submit-review-katal-button"
                                      label={translate('Paragon_TAM_CM_Send')}
                                      variant="primary"
                                      data-csm-name="reply-composer-send-top"
                                      data-csm-meta-is-translation-enabled={isTranslationEnabled()} />
                    <KatButton className={$style.transitionButton} onClick={(event) => cancelReplyToCase(event)}
                                      data-testid="cancel-review-katal-button"
                                      label={translate('Paragon_TAM_CM_Back')}
                                      variant="secondary"
                                      data-csm-name="reply-composer-back-top" />
                </div>
            </div>
            }
            {errorMessage && statusCode &&
            <div>
                Error: { translate(errorMessage) } Status Code: { statusCode }
            </div>
            }
            {showPtrModal &&
            <ReviewPtrModal caseId={caseAttributes.caseId || metadata.caseId}
                            showPtrModal={showPtrModal}
                            userLogin={props.agentLogin}
                            isTardisSCV={props.isTardisSCV}
                            showComposer={showComposer}
                            transferCaseWithTranslatedReply={transferCaseWithTranslatedReply}/>
            }
        </KatBox>
    )
}