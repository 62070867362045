import { KatAlert, KatLabel } from '@amzn/katal-react';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { localization } from 'src/helpers';

import DropdownOption
    from 'src/widgets/IssueSummaryWidget/components/widgets/ReplyCase/components/composer/DropdownOption/DropdownOption';
import { ErrorData } from 'src/widgets/IssueSummaryWidget/components/widgets/ReplyCase/model/replyCaseSchemaElements';

import $style from './atlas-labelling-dropdown.module.scss';
import { ServicesAPIContext } from "src/service/service.context";

interface KatalOption {
    name: string;
    value: string;
    tooltip: string;
    whyContactedList: Issue[];
}

interface Issue {
    worldServerId: string;
    whyContactIssueId: string;
    whyContactLabel: string;
    whyContactLabelDesc: string;
}

interface AtlasLabellingDropdownProps {
    className?: string;
    caseId: string;
    newCaseStatus?: string;
    isAtlasFeedbackEnabled?: boolean;
    showError?: (errorData) => void;
}

export default function AtlasLabellingDropdown(props: AtlasLabellingDropdownProps) {
    const [shouldShowSelectAtlasTaskError, setShouldShowSelectAtlasTaskError] = useState(false);
    const [taskIssueId, setTaskIssueId] = useState<string>('');
    const [taskLabelList, setTaskLabelList] = useState<KatalOption[]>([]);
    const [taskWhyContactMap, setTaskWhyContactMap] = useState({});

    const serviceAPIContext = useContext(ServicesAPIContext);

    const { translate } = localization();

    const transformTaskLabelList = useCallback((taskList) => {
        const transformedList: KatalOption[] = []
        for (const task of taskList) {
            const transformedTask = { // Transform task into a usable format for Katal components
                name: task.worldServerId,
                value: task.issueId,
                tooltip: task.taskLabelDesc,
                whyContactedList: task.whyContactLabels
            }
            transformedList.push(transformedTask) // Transform issue into a usable format for Katal components

            if (transformedTask && transformedTask.whyContactedList) {
                const transformedTaskWhyContactMap = taskWhyContactMap;
                transformedTaskWhyContactMap[transformedTask.value] = transformedTask.whyContactedList.map(issue => (
                    {
                        name: issue.worldServerId,
                        value: issue.whyContactIssueId,
                        label: issue.whyContactLabel,
                        tooltip: issue.whyContactLabelDesc
                    }
                )
                )
            }
        }
        setTaskLabelList(transformedList);
    }, [])

    const getAtlasDropdownData = useCallback(() => {
        const params = {
            caseId: props.caseId
        }

        serviceAPIContext.paragonService.getWhyContactedDropdown(params).then(response => {
            if (response && response.whyContactedDropdownData) {
                const output = response.whyContactedDropdownData;
                setShouldShowSelectAtlasTaskError(output.showSelectAtlasTaskError);

                if (output.selectedTaskLabel) {
                    setTaskIssueId(output.selectedTaskLabel.issueId);
                } else if (output.taskLabelList && output.taskLabelList.length > 0) {
                    setTaskIssueId(output.taskLabelList[0].issueId);
                }

                if (output.taskLabelList) {
                    transformTaskLabelList(output.taskLabelList);
                }
            }
        }).catch(error => {
            const errorData: ErrorData = {};

            if (error && error.response) {
                errorData.statusCode = error.response.status;
                errorData.message = error.response.data;
                props.showError?.(errorData);
            } else {
                errorData.statusCode = 500;
                errorData.message = 'Paragon_TAM_CM_An_error_occurred_please_try_again_later';
                props.showError?.(errorData);
            }
        });

    }, []);

    useEffect(() => {
        getAtlasDropdownData();
    }, [getAtlasDropdownData, transformTaskLabelList]);

    const alertElement = <div className={$style.atlasLabelDiv}>
        <KatAlert variant="danger" header={translate('Paragon_TAM_Atlas_ReplyCase_Select_Task_Error')} />
    </div>

    return (
        <div>
            {taskLabelList.length > 0 && 
            <div className={$style.styledDiv}>
                {shouldShowSelectAtlasTaskError && props.newCaseStatus === 'Resolved' && alertElement}
                <div>
                    <section>
                        <div className={$style.dropdownHeader}>
                            <KatLabel text={translate('Paragon_TAM_CM_Select_Seller_Task_Label')} />
                        </div>
                        <DropdownOption
                            id={'taskLabelFormDropdown'}
                            updateValue={(value: string | undefined) => setTaskIssueId(value as string)}
                            options={taskLabelList.map(task => ({ name: task.name, value: task.value, tooltip: task.tooltip }))}
                            valueField="value"
                            textField="name"
                            descriptionField="tooltip"
                            value={taskIssueId}
                            state={!!taskIssueId} />
                    </section>
                </div>
            </div>}
        </div>
    )
}
