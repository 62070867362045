export interface EmailAttributes {
  subject: string;
  toEmailList: string[];
  bccEmailList: string[];
  fromEmail: string;
  ccEmailList: string[];
}

export interface CaseAttributes {
  caseProperties: CasePropertyItem[];
  caseId: string;
  creationDate: string;
  caseQueue: string;
  caseSubStatus: string;
  merchantId: string;
  marketplaceId: string;
  newCaseStatus: string;
  caseStatus: string;
}

export interface MetricData {
  name: string,
  meta?: Partial<Record<string, string>>,
  type: string,
}

export const CaseStatusType = {
  PENDING_AMAZON_ACTION: 'Pending Amazon Action',
  PENDING_MERCHANT_ACTION: 'Pending Merchant Action',
  RESOLVED: 'Resolved',
  WORK_IN_PROGRESS: 'Work-in-Progress'
} as const

export const ParagonEvents = {
  ATTACH_BLURB: 'attach-blurb',
  ATTACH_BLURB_FRICTION: 'blurb-friction-blurb-to-be-populated',
  SAVE_FOLLOW_UP_ON_TRANSFER: 'transfer-case-form-submission-succeeded',
  SAVE_FOLLOW_UP_ON_TRANSFER_TO_AGENT: 'transfer-case-to-agent-form-submission-succeeded',
  SAVE_FOLLOW_UP_ON_TRANSFER_TO_EXTERNAL_TEAM: 'transfer-case-to-external-team-form-submission-succeeded',
  TOGGLE_REPLY_TO_CASE: 'toggle-reply-to-case'
} as const

export interface ReviewPtrModalProps {
    caseId?: string;
    showPtrModal?: boolean;
    userLogin?: string;
    isTardisSCV?: boolean;
    transferCaseWithTranslatedReply?: () => void;
    showComposer?: () => void;
}

export const Stage = {
  COMPOSE: 'compose',
  SUGGESTER: 'suggester',
  REVIEW: 'review',
  SUBMITTED: 'submitted',
  BLANK: ''
} as const

export interface ReviewReplyInput {
  header: Content;
  body: Content;
  additionalInfo: Content;
  signature: Content;
  footer: Content;
  marketplaceId: string;
  caseStatus: string;
  creationDate: string;
  queueBlurbBaseName: string;
  previousEmailCorrespondence: string;
  caseProperties: CasePropertyItem[];
  locale: string;
  caseSubStatus: string;
  merchantId: string;
  caseResolutionCategoryId: number;
  caseResolutionReasonId: number;
  toEmail: string[];
  subject: string;
  attachments: Attachment[];
  metaData: Record<string, string>;
  newCaseStatus: string;
  pmaReason: string;
  caseId: string;
  headerContent?: string;
  headerContentIds?: string[];
  bodyContent?: string;
  bodyContentIds?: string[];
  additionalInfoContent?: string;
  additionalInfoContentIds?: string[];
  signatureContent?: string;
  signatureContentIds?: string[];
  footerContent?: string;
  footerContentIds?: string[];
  replyTranslationConfig: ReplyTranslationConfig;
}

export interface ReplyTranslationConfig {
  translationCriteria: {
    ptr: number;
    localization: number;
  };
  translateReplyEnabled: boolean;
  targetLocale: string;
  replyTransferQueue: string;
}

export interface ErrorData {
  statusCode?: number;
  message?: string;
}

export interface CasePropertyItem {
  id: number;
  historyRequired: boolean;
  name: string;
  type: string;
  booleanPropertyValue: boolean;
  stringPropertyValue: string;
  numericPropertyValue: number;
  listPropertyValue: string[];
}

export interface ReviewViewProps {
    params: ReviewReplyInput;
    agentLogin?: string;
    isTardisSCV?: boolean;
    showComposer: () => void;
    showSubmitted: (data) => void;
    showError: (errorData: ErrorData) => void;
}

export const PMA_FRICTION = {
  SOFT: 'SOFT',
  HARD: 'HARD',
  NONE: 'NONE'
}

export interface BlurbMetadata {
  PmTenantId: number;
  PmCaseId: string;
  PmBlurbId: string;
  PmBlurbLocale: string;
  PmGreyed: boolean;
  PmWorkflowId: string | undefined;
  PmWorkflowStepName: string | undefined;
  PmDiagRunId: string | undefined;
  PmReplyEdited?: boolean;
}

export interface Blurb {
  metadata: BlurbMetadata;
  originalContent: string;
}

interface Reason {
  categoryId: number;
  description: string;
  id: number;
  worldServerid: string;
  reason: string;
}

export interface ReasonCategory {
  category: string;
  id: number;
  worldServerid: string;
  reasons: Reason[];
}

export interface EmailBodyComponentData {
  caseId: string;
  emailContent: string;
  blurbFrictionData: {
    frictionBlurbs: Blurb[]
  },
  latestAttachBlurbEventId?: string;
  latestBlurbFrictionBlurbToBePopulatedId?: string;
}

export interface TextSuggesterData {
  replyId: string;
  suggestedText: string;
  suggestedTextEdit: string;
  modelLatency: number;
  feedbackData?: TextSuggesterFeedbackData;
}

export interface TextSuggesterFeedbackData {
  action: string;
  agentLogin: string;
  edit?: string;
  dismissedReason?: string;
  modelLatency: number;
}

export interface SubmissionMetadata {
  ekkoId: string;
}

export const emailBodyComponentDataActions = {
  setEmailBodyComponentData: 'set-blurb-friction-data',
  setFrictionBlurbs: 'set-friction-blurbs'
}

export interface WhyContactedDropdownSubmitInput {
  caseId: string;
  caseStatus: string;
  whyContactIssueId: string;
}

export interface SubmitExceptionInput {
  caseId: string;
  reason: string;
  isExceptionConfirmed: boolean;
  exceptionDescription: string;
}

export interface SaveFollowupWorkInput {
  caseId: string;
  followupWork: string;
}

export interface ViewCaseComposerInput {
  caseId: string;
  marketplaceId: string;
}

export interface ViewCaseComposerOutput {
  marketplaceId: string;
  output: ViewCaseComposerOutputData;
  caseId: string;
  tenantId: number;
  locale: string;
}

export interface ViewCaseComposerOutputData {
  ccList: string;
  customSignatureList: CustomSignature[];
  primaryEmail: string
  subject: string;
  caseStatus: string;
  queueName: string;
  defaultSignature: string;
  customComponentData: Record<string, string>;
  merchantId: string;
  fromAddress: string;
  reasonCategoryList: ReasonCategory[];
  replyTranslationConfig: ReplyTranslationConfig;
  creationDate: string;
  previousEmailCorrespondence?: string;
  caseProperties?: CasePropertyItem[];
  queueBlurbBaseName?: string;
}

export interface CustomSignature {
  gist: string;
  text: string;
  name: string;
}

export interface ReplyCaseStatusDropdownInput {
  caseId: string;
  locale: string;
}

export interface ReplyCaseStatusDropdownOutput {
  caseStatusList: CaseStatus[];
  currentCaseStatusItem: CaseStatus;
  mergedReasonIds: string;
  fieldMap: Record<string, boolean>,
  paaReasonsList: string[];
  caseSubStatusList: CaseSubStatus[];
  reasonCategoryList: ReasonCategory[];
  selectedCategory?: ReasonCategory;
  selectedReason?: Reason;
  caseQueueFeatureList: string[];
  caseSubStatus: string;
}

export interface CaseStatus {
  statusSlaMinutes: number;
  code: string;
  title: string;
}

export interface CaseSubStatus {
  caseSubStatusName: string;
}

interface Reason {
  categoryId: number;
  description: string;
  id: number;
  worldServerid: string;
  reason: string;
}

export interface ReasonCategory {
  category: string;
  id: number;
  worldServerid: string;
  reasons: Reason[];
}

export interface StoreSuggestionInput {
  caseId: string
  replyId: string
  originalText: string
  suggestedText: string
}

export interface StoreSuggestionOutput {
  predictionId: string
}

export interface StoreSuggestionFeedbackInput {
  caseId: string
  replyId: string
  feedbackData: string
}

export interface CheckPmaFrictionInput {
  caseId: string;
}

export interface FinalWordVerificationOutput {
  isEligible: boolean;
  blurbContent: string;
  validationMessage: string;
}

export interface ReplyToCaseInput {
  uuid: string;
}

export interface ReplyToCaseOutput {
  commId?: string;
  transferSuccessMessage?: string;
}

export interface ReviewReplyOutput {
  metadata: Record<string, string>;
  tokenId: string;
  attachmentList: Attachment[];
  caseAttributes: CaseAttributes;
  emailContentBlob: string;
  locale: string;
  emailAttributes: EmailAttributes;
}

export interface Attachment {
  fileName: string;
  fileKey: string;
  fileSize: number;
  fileType: string;
  bucketName: string;
  presignedUrl: string;
}

export interface SaveFollowupWorkOutput {
  message: string;
}

export interface SavePrimaryEmailListInput {
  caseId: string;
  emails: string[];
}

export interface SubmitExceptionOutput {
  isSuccess: boolean;
}

export interface TransferTranslatedReplyInput {
  caseId: string;
  currentQueue: string;
  metadata: Record<string, string>,
  emailContentBlob: string;
}

export interface UploadFileToS3Input {
  fileBuffer: ArrayBuffer;
  fileName: string;
  fileType: string;
  caseId: string;
  attachmentSource: string;
}

export interface UploadFileToS3Output {
  results: {
    data: {
      fileKey: string;
      fileType: string;
      message: string;
      success: boolean;
    }
  }
}

export interface WhyContactedDropdownInput {
  caseId: string;
}

export interface WhyContactedDropdownOutput {
  selectedTaskLabel: TaskLabel;
  taxonomyId: string;
  showSelectAtlasTaskError: boolean;
  showAtlasFeedbackClickLink: boolean;
  selectedWhyContact: WhyContactLabel;
  showWhyContactModal: boolean;
  feedbackType: string;
  taskLabelList: TaskLabel[];
}

export interface Content {
  content: string;
  contentIds: string[];
}

export interface WhyContactLabel {
  whyContactIssueId: string;
  whyContactLabel: string;
  worldServerId: string;
  whyContactLabelDesc?: string;
  reasonCodeId?: number;
}
export interface TaskLabel {
  issue: {
    issueId: string;
  },
  issueId: string;
  worldServerId: string;
  taskLabelDesc: string;
  whyContactLabels: WhyContactLabel[];
}

export interface FinalWordVerificationInput {
  caseId: string;
  tenantId: string;
}