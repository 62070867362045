import { KatalComponent, register } from '../../shared/base';

/**
 * @component {kat-list} KatalList Lists consist of related content grouped together.
 * @slot default Should be filled with `li` elements.
 * @example Ordered {"variant":"ordered","content":"<li>test<\/li> <li>test<\/li> <li>test<\/li>"}
 * @example Unordered {"variant":"unordered","content":"<li>test<\/li> <li>test<\/li> <li>test<\/li>"}
 * @example Bullet {"variant":"bullet","content":"<li>test<\/li> <li>test<\/li> <li>test<\/li>"}
 * @example Checkmark {"variant":"checkmark","content":"<li>test<\/li> <li>test<\/li> <li>test<\/li>"}
 * @status Production
 * @a11y {keyboard}
 * @a11y {sr}
 * @a11y {contrast}
 */
@register('kat-list')
export class KatList extends KatalComponent {
  static get observedAttributes() {
    return ['variant'];
  }

  constructor() {
    super(KatList.observedAttributes);
    this.loaded = false;
  }

  connectedCallback() {
    super.connectedCallback();

    this.loaded = true;
    this.role = 'list';
    this.render();

    this.mutationObserver = new MutationObserver(mutationList => {
      mutationList.forEach(mutation => {
        if (mutation.addedNodes?.length) {
          Array.from(mutation.addedNodes)
            .filter(addedNode => addedNode.tagName === 'LI' && !addedNode.role)
            .forEach(addedNode => {
              addedNode.setAttribute('role', 'listitem');
            });
        }
      });
    });

    this.mutationObserver.observe(this, {
      childList: true,
    });
  }

  disconnectedCallback() {
    if (this.mutationObserver) {
      this.mutationObserver.disconnect();
    }
  }

  /**
   * @required
   * @classprop {enum} variant This is the type of list - Default is unordered
   * @enum {value} ordered Ordered List
   * @enum {value} unordered Unordered List - Default
   * @enum {value} bullet Bulleted list
   * @enum {value} checkmark Checked list
   */
  get variant() {
    return this.getAttribute('variant') || 'unordered';
  }

  set variant(value) {
    this.setAttribute('variant', value);
  }

  render() {
    this.querySelectorAll('li:not([role])').forEach(element => {
      element.setAttribute('role', 'listitem');
    });
  }

  // Respond to attribute changes.
  attributeChangedCallback() {
    if (this.loaded) {
      this.render();
    }
  }
}
