import React, { FunctionComponent } from "react";
import { Spinner } from "./Spinner";
import $style from "./loading.module.scss";

interface LoadingProps extends React.HTMLProps<HTMLDivElement> {
    isLoading: boolean,
}

export const Loading: FunctionComponent<LoadingProps> = ({
    children,
    isLoading,
}) => {
    return (
        <>
            {isLoading && (
                <div className={$style.loading}>
                    <Spinner size="small" />
                </div>
            )}
            {children}
        </>
    );
};