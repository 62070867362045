import {KatCheckbox, KatSpinner, KatButton} from '@amzn/katal-react';
import React, {useContext, useEffect, useState} from 'react';
import {paragonMediator} from '@amzn/paragon-context/lib/ParagonMediatorContext/paragonMediatorContext';
import {localization} from 'src/helpers';
import {ErrorData} from 'src/widgets/IssueSummaryWidget/components/widgets/ReplyCase/model/replyCaseSchemaElements';

import $style from './final-word-verifier.module.scss';
import {ServicesAPIContext} from "src/service/service.context";

interface FinalWordVerifierProps {
    caseId: string;
    tenantId: number;
    updateCaseSubStatus: (caseSubStatus: string) => void;
    showError: (data) => void;
}

export default function FinalWordVerifier(props: FinalWordVerifierProps) {
    const [isLoading, setIsLoading] = useState(false);
    const [subStatusToggleState, setSubStatusToggleState] = useState(false);
    const [isEligible, setIsEligible] = useState<boolean | undefined>(undefined);

    const { translate } = localization();
    const serviceAPIContext = useContext(ServicesAPIContext);

    useEffect(() => {
        toggleFinalWordSubStatus();
    }, [subStatusToggleState]);

    function handleOnChange(event) {
        setSubStatusToggleState(event.detail.checked);
    }

    function checkFinalWordEligibility() {
        setIsLoading(true);

        const params = {
            caseId : props.caseId,
            tenantId: props.tenantId.toString()
        }

        serviceAPIContext.paragonService.finalWordVerification(params).then(response => {
            if (response && response.finalWordVerificationData) {
                const isEligible = response.finalWordVerificationData.isEligible;
                const blurbContent = response.finalWordVerificationData.blurbContent;

                if (blurbContent && isEligible) {
                    paragonMediator.notify('attach-blurb', {
                        'blurbContent': blurbContent,
                        'propertyValue': 'FinalWord'
                    });
                }
                paragonMediator.notify('case-review-resolve-succeeded', {});

                setIsEligible(isEligible);
            }
        }).catch(error => {
            const errorData: ErrorData = {};

            if (error && error.response) {
                errorData.statusCode = error.response.status;
                errorData.message = error.response.data;
            } else if (error) {
                errorData.message = error;
            } else {
                errorData.statusCode = 500;
                errorData.message =  'Paragon_TAM_CM_An_error_occurred_please_try_again_later';
            }

            props.showError(errorData);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    function toggleFinalWordSubStatus() {
        if (subStatusToggleState) {
            props.updateCaseSubStatus('Final Word');
        } else {
            props.updateCaseSubStatus('');
        }
    }

    const loadingElement = <KatSpinner size="small"/>

    const nonEligibleLabelElement = <b className={$style.nonEligibleLabel} data-testid="katal-non-eligible-textarea">
        {translate('Paragon_TAM_CM_Final_Word_Eligibility_Approved')}
    </b>

    const eligibleLabelElement = <b className={$style.eligibleLabel} data-testid="katal-eligible-label">
        {translate('Paragon_TAM_CM_Final_Word_Eligibility_Not_Approved')}
    </b>

    const finalWordCheckbox = <KatCheckbox onChange={handleOnChange}/>

    const finalWordVerifierElement = <div>
        <span className={$style.finalWordVerifierContainer}>
            <KatButton className={$style.checkFinalWordButton} data-testid="katal-button" onClick={checkFinalWordEligibility} variant="link">
                {translate('Paragon_TAM_CM_Final_Word_Eligibility')}
            </KatButton>
            {isEligible === false && nonEligibleLabelElement}
            {isEligible === true && eligibleLabelElement}
            {isEligible === true && finalWordCheckbox}
        </span>
    </div>

    return (
        <div>
            <div className="d-flex justify-content-center">
                {isLoading && loadingElement}
            </div>
            {!isLoading && finalWordVerifierElement}
        </div>
    )
}