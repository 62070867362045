import React, {useEffect, useState} from 'react';
import {linkify} from 'src/common/linkify';
import {useParagonContext, useParagonContextConsumerEl} from "@amzn/paragon-ui-react";
import {paragonCoreDataContext} from "@amzn/paragon-context/lib/ParagonCoreDataContext";
import {paragonUserContext} from "@amzn/paragon-context/lib/ParagonUserContext";
import {paragonPageContext} from "@amzn/paragon-context/lib/ParagonPageContext";
import {paragonReplyContext} from "@amzn/paragon-context/lib/ParagonReplyContext";

interface TranscriptProps {
    text: string;
}

import $style from './transcript.module.scss';

export default function Transcript(props: TranscriptProps) {

    const el = useParagonContextConsumerEl();
    const { value: coreData } = useParagonContext(el, paragonCoreDataContext);
    const { value: userDetails } = useParagonContext(el, paragonUserContext);
    const { value: pageData } = useParagonContext(el, paragonPageContext);
    const { value: replyData, on: replyEventOn } = useParagonContext(el, paragonReplyContext);

    const [loadingConfiguration, setLoadingConfiguration] = useState(true);

    useEffect(() => {
        if (coreData && userDetails && pageData && replyData) {
            setLoadingConfiguration(false);
        }
    }, [coreData, userDetails, pageData, replyData])

    return (
        <>
            {!loadingConfiguration &&
            <div className={$style.transcriptContainer} data-testid="transcript-container" dir="auto" dangerouslySetInnerHTML={{ __html: linkify(props.text, replyData)}} />
            }
        </>
    )
}
