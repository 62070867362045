import { html, property } from 'lit-element';
import { nothing } from 'lit-html';
import { KatLitElement, register } from '../../shared/base';
import baseStyles from '../../shared/base/base.lit.scss';
import styles from './option.lit.scss';

/**
 * @component {kat-option} KatalOption An option that can be selected inside a <kat-dropdown>.
 * @status Production
 * @theme flo
 * @slot default The inner HTML of the option in the option list of the dropdown.
 */
@register('kat-option')
export class KatOption extends KatLitElement {
  /** The value of the option. Will be the value of the dropdown when this option is selected. */
  @property()
  value?: string;

  /**
   * The search key used to override the search behavior of this option. When the dropdown
   * is searchable and this field is present it will be used instead of the textContent for searching.
   */
  @property()
  searchkey?: string;

  /** Whether the option is selected or not. */
  @property()
  selected?: boolean;

  /** A disabled option displays in dimmed text and can't be selected. */
  @property()
  disabled?: boolean;

  /** Indicates whether the dropdown should allow multiple selections. */
  @property()
  multiple?: boolean;

  /**
   * Internal property, indicates whether the checkbox in multiple mode shall be checked fully or partially.
   * Used for Select All option.
   */
  @property()
  indeterminate?: boolean;

  /**
   * Role attribute, used in place of HTML semantics.
   */
  @property()
  role = 'option';

  static get styles() {
    return [baseStyles, styles];
  }

  firstUpdated() {
    this.setAttribute('tabindex', '-1');
  }

  renderSelectBox() {
    if (!this.multiple) {
      return nothing;
    }

    return html`
      <kat-checkbox
        id="select-option"
        tabindex="-1"
        ?disabled=${this.disabled}
        ?checked=${this.selected && !this.indeterminate}
        ?indeterminate=${this.indeterminate}
      ></kat-checkbox>
    `;
  }

  render() {
    if (this.disabled) {
      this.removeAttribute('aria-selected');
    } else {
      this.setAttribute(
        'aria-selected',
        this.selected === true ? 'true' : 'false'
      );
    }

    return html`
      <div class="content-wrapper">
        ${this.renderSelectBox()}
        <div class="content">
          <slot></slot>
        </div>
        <span class="ring"></span>
      </div>
    `;
  }
}
