import {KatAlert, KatBox, KatDivider} from '@amzn/katal-react';
import React, {createRef, useEffect, useState} from 'react';
import {localization} from 'src/helpers';


import {getMostRecentSCE} from 'src/common/schemaCaseElementUtils';

import {useParagonContext, useParagonContextConsumerEl} from "@amzn/paragon-ui-react";
import {paragonCoreDataContext} from "@amzn/paragon-context/lib/ParagonCoreDataContext";
import {paragonReplyContext} from "@amzn/paragon-context/lib/ParagonReplyContext";
import {paragonUserContext} from "@amzn/paragon-context/lib/ParagonUserContext";
import {paragonPageContext} from "@amzn/paragon-context/lib/ParagonPageContext";


import ReviewView from 'src/widgets/IssueSummaryWidget/components/widgets/ReplyCase/ReviewView';
import SubmittedView from 'src/widgets/IssueSummaryWidget/components/widgets/ReplyCase/SubmittedView';
import ComposerView from 'src/widgets/IssueSummaryWidget/components/widgets/ReplyCase/ComposerView';

import {SchemaCaseElement} from 'src/widgets/IssueSummaryWidget/model/schemaElementsData';
import {ParagonEvents, ReviewReplyInput, Stage} from 'src/widgets/IssueSummaryWidget/components/widgets/ReplyCase/model/replyCaseSchemaElements';

import $style from './reply-case.module.scss';

interface ReplyCaseProps {
    onDiscardChanges?: () => void;
    onRequestFocus?: () => void;
    focus?: boolean;
    showdcpForm?: boolean;
}

export default function ReplyCase(props: ReplyCaseProps) {
    const [stage, setStage] = useState<string>(Stage.COMPOSE);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [constructReplyToCaseData, setConstructReplyToCaseData] = useState<ReviewReplyInput>({} as ReviewReplyInput);
    const [submittedViewData, setSubmittedViewData] = useState({});
    const [expanded, setExpanded] = useState<boolean>(false);

    //Currently these values are not important for our launch, but in the future we'll want to use widget state instead.
    //https://code.amazon.com/packages/ParagonWebsiteLibs/blobs/mainline/--/packages/ParagonUiReact/src/ParagonContext/useWidgetDataContextState.ts
    const [showExceptionForm] = useState(false);
    const [validateDevLinks] = useState(false);
    const [isTardisSCV] = useState(false);

    const viewCaseComposerAppRef = createRef<HTMLDivElement>();

    const { translate } = localization();
    const el = useParagonContextConsumerEl();
    const { value: coreData } = useParagonContext(el, paragonCoreDataContext);
    const { value: userDetails } = useParagonContext(el, paragonUserContext);
    const { value: pageData } = useParagonContext(el, paragonPageContext);
    const { value: replyData, on: replyEventOn } = useParagonContext(el, paragonReplyContext);


    const [loadingConfiguration, setLoadingConfiguration] = useState(true);

    useEffect(() => {
        if (coreData && userDetails && pageData && replyData) {
            setLoadingConfiguration(false);
        }
    }, [coreData, userDetails, pageData, replyData])

    useEffect(() => {
        const unsubAttachBlurb = replyEventOn(ParagonEvents.ATTACH_BLURB, () => {
            setExpanded(true);
        });

        const unsubAttachBlurbFriction = replyEventOn(ParagonEvents.ATTACH_BLURB_FRICTION, () => {
            setExpanded(true);
        });

        return () => {
            unsubAttachBlurb();
            unsubAttachBlurbFriction();
        }
    }, []);

    function constructReplyToCaseParams(): ReviewReplyInput {
        const params: ReviewReplyInput = constructReplyToCaseData;

        params.header = {
            content: params.headerContent || '',
            contentIds: params.headerContentIds || []
        }
        params.body = {
            content: params.bodyContent || '',
            contentIds: params.bodyContentIds || []
        }
        params.additionalInfo = {
            content: params.additionalInfoContent || '',
            contentIds: params.additionalInfoContentIds || []
        }
        params.signature = {
            content: params.signatureContent || '',
            contentIds: params.signatureContentIds || []
        }
        params.footer = {
            content: params.footerContent || '',
            contentIds: params.footerContentIds || []
        }

        return params;
    }

    function lastEscalationEventSCE(): Partial<SchemaCaseElement> {
        return getMostRecentSCE('CaseActivities.EscalationEvents', coreData.caseDetails?.schemaCaseElementMap)
    }

    function discardChanges() {
        //this.$emit('hide');
        props.onDiscardChanges?.();
    }

    function requestFocus() {
        //TODO: Check how to handle focus
        // const el = this.$refs['viewCaseComposerApp'];
        // if(el instanceof HTMLElement) {
        //   this.$nextTick(() => {
        //     scrollIntoView(el, {
        //       scrollMode: 'always',
        //       block: 'center',
        //       inline: 'center',
        //       behavior: 'smooth',
        //     });
        //   });
        // }

        props.onRequestFocus?.();
    }

    function showComposer() {
        setStage(Stage.COMPOSE);
    }

    function showReview(data) {
        setConstructReplyToCaseData(data);
        setStage(Stage.REVIEW);
    }

    function showSubmitted(data) {
        setSubmittedViewData(data);
        setStage(Stage.SUBMITTED);
    }

    function showError(data) {
        setError(true);

        const errorMessageBuilder: string[] = [];

        if (data.statusCode) {
            errorMessageBuilder.push('Error (Status ', data.statusCode, '): ')
        }

        if (data.message) {
            errorMessageBuilder.push(translate(data.message));
            setErrorMessage(errorMessageBuilder.join(''));
        }
    }

    return (
        <div className={$style.replyCaseWidget}>
            <KatBox variant="white">

                <div id="viewCaseComposerApp" className="reply-parent-div" ref={viewCaseComposerAppRef}>
                    <div className="alert-message">
                        {error && <KatAlert variant="danger" header="Error occurred." description={errorMessage}/>}
                    </div>

                    {stage === Stage.COMPOSE &&
                    <ComposerView isIssueSummaryEnabled={!!coreData.tenantFeatures.isIssueSummaryFeatureEnabled}
                                  caseId={coreData.caseDetails?.id || ''}
                                  marketplaceId={pageData.marketplaceId || ''}
                                  lastEscalationEventSce={lastEscalationEventSCE()}
                                  showExceptionForm={showExceptionForm}
                                  showdcpForm={props.showdcpForm}
                                  validateDevLinks={validateDevLinks}
                                  showError={showError}
                                  discardChanges={discardChanges}
                                  requestFocus={requestFocus}
                                  showNextStep={showReview}
                                  focus={props.focus && stage === Stage.COMPOSE}/>
                    }

                    {stage === Stage.REVIEW &&
                    <ReviewView params={constructReplyToCaseParams()}
                                agentLogin={userDetails.agentLogin}
                                isTardisSCV={isTardisSCV}
                                showComposer={showComposer}
                                showSubmitted={showSubmitted}
                                showError={showError}/>
                    }

                    {stage === Stage.SUBMITTED &&
                    <SubmittedView params={submittedViewData}
                                   showComposer={showComposer}
                                   cancelAndDiscard={discardChanges} />
                    }
                </div>
            </KatBox>
        </div>
    )
}