import { KatWorkflowtracker } from "@amzn/katal-react";
import React, { FunctionComponent, useState, useEffect } from "react";
import $style from './common-component.module.scss';

type Props = {
    tasksStep: boolean
    finalStep: boolean
}

export const HeaderComponent: FunctionComponent<Props> = ({ tasksStep, finalStep }) => {
    const getSteps = () => [
        {
            state: tasksStep || finalStep ? "complete" : "in-progress",
            label: "Select a recommended SOP",
            clickable: false,
            id: 0,
        },
        {
            state: finalStep ? "complete" : (tasksStep ? "in-progress" : "todo"),
            label: "Execute tasks and select outcome",
            clickable: false,
            id: 1,
        },
        {
            state: finalStep ? "in-progress" : "todo",
            label: "Complete",
            clickable: false,
            id: 2,
        },
    ];

    return (
        <div className={$style.displayHeader}>
            <label>Select the SOP that best aligns with seller's issue and click 'Next' to generate a series of
                tasks and outcomes to guide you in resolving the issue.
            </label>
            <KatWorkflowtracker
                steps={getSteps()}
                className={$style.headerProgressBar}
            />
        </div>
    );
};