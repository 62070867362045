import React, {FunctionComponent, useState} from "react";
import { ControlSpacing } from "@amzn/paragon-ui-react/";
import {logClickStreamData} from "src/common/utils";
import {logger} from "src/logger";
import $style from "src/widgets/IssueSummaryWidget/components/case_seller_info/case-and-seller-details.module.scss";
import {AnalyticsFunctions} from "@amzn/paragon-context/lib/ParagonAnalyticsContext/models/AnalyticsFunctions";

interface ExpandableSellerInfoRowProps {
    type: string,
    values: string[],
    caseId: string,
    agentLogin: string,
    clickStream: AnalyticsFunctions,
}

export const ExpandableSellerInfoRow: FunctionComponent<ExpandableSellerInfoRowProps> = ({
    type,
    values,
    caseId,
    agentLogin,
    clickStream
}) => {

    const [isExpanded, setIsExpanded] = useState(false);

    const { length: elementsLength } = values;

    const firstThreeValues = elementsLength > 3 ? values.slice(0, 3) : values;
    const remainingValues = elementsLength > 3 ? values.slice(3) : [];

    return (
        <ControlSpacing column size="xs" justify="start">
            {firstThreeValues.map((value, index) => (
                <div key={index}>{value}</div>
            ))}

            {remainingValues.length > 0 && isExpanded && remainingValues.map((value, index) => (
                <div key={index}>{value}</div>
            ))}

            {remainingValues.length > 0 &&
                <button
                    onClick={() => {
                        logClickStreamData(clickStream, 'click',
                            `llm-issue-summary-${type}-list-${isExpanded ? 'collapsed' : 'expanded'}`)
                        logger.info(`User: ${agentLogin} ${isExpanded ? 'collapsed' : 'expanded'} ${type} from Case Seller Details for Case: ${caseId}`);
                        setIsExpanded(!isExpanded)
                    }}
                    className={$style.expandableListTrigger}>
                    {isExpanded ? `Show less ${type}`  : `+ ${remainingValues.length} ${type}`}
                </button>
            }
        </ControlSpacing>
    )
}
