import {pottyWordPattern} from 'src/common/rules/pottyWords';

interface ValidationFunction {
    (bundle, value: string): string;
}

export const REQUIRED_RULE = (t) => ({
    required: {
        value: true,
        message: t('Paragon_TAM_CM_This_Field_Is_Required')
    }
})

export const devLink = new RegExp(/((.|\n)*)sellercentral\.amazon\.dev|((.|\n)*)www\.sellercentral\.amazon\.com/g);


export const POTTY_WORD_VALIDATION = (t, value) => {
    let errorMessage = '';

    if (pottyWordPattern.test(value)) {
        const pottyWords = value.match(pottyWordPattern);
        if (pottyWords && pottyWords[0]) {
            errorMessage = `${t('Paragon_TAM_CM_Correct_Potty_Word')} "${pottyWords[0]}"`;
        } else {
            errorMessage = t('Paragon_TAM_CM_This_Field_Cannot_Have_Bad_Words');
        }
    }

    return errorMessage;
}

export const DEV_LINK_VALIDATION = (t, value) => {
    let errorMessage = '';

    if (devLink.test(value)) {
        const devLinks = devLink.exec(value);
        if (devLinks && devLinks[0]) {
            errorMessage = `www.sellercentral.amazon.dev ${t('Paragon_TAM_CM_Correct_Dev_Link')} ` +
                'https://cim-os-public.s3.us-east-2.amazonaws.com/PeekNow/SellerCentralConverterHelper.htm'
        } else {
            errorMessage = `www.sellercentral.amazon.dev ${t('Paragon_TAM_CM_Dev_Link_Not_Accessible')}`;
        }
    }

    return errorMessage;
}

export function getValidation(t, validations: ValidationFunction[]) {
    return (value) => {
        let errorMessage = '';

        validations.forEach(validation => {
            const validationMessage = validation(t, value);

            if (validationMessage !== '') {
                errorMessage = validationMessage;
            }
        })

        return errorMessage || true;
    }
}