import {KatAlert, KatLabel, KatTooltip, KatButton} from '@amzn/katal-react';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import {localization} from 'src/helpers';

import { paragonMediator } from '@amzn/paragon-context/lib/ParagonMediatorContext/paragonMediatorContext';

import DropdownOption
    from 'src/widgets/IssueSummaryWidget/components/widgets/ReplyCase/components/composer/DropdownOption/DropdownOption';
import {ErrorData} from 'src/widgets/IssueSummaryWidget/components/widgets/ReplyCase/model/replyCaseSchemaElements';

import $style from './atlas-labelling-dropdown.module.scss';
import {ReplyHandlerContext} from "src/widgets/IssueSummaryWidget/components/widgets/ReplyCase/providers/replyHandler.context";
import {ServicesAPIContext} from "src/service/service.context";

interface KatalOption {
    name: string;
    value: string;
    tooltip: string;
    whyContactedList: Issue[];
}

interface Issue {
    worldServerId: string;
    whyContactIssueId: string;
    whyContactLabel: string;
    whyContactLabelDesc: string;
}

interface AtlasLabellingDropdownProps {
    className?: string;
    caseId: string;
    newCaseStatus?: string;
    showWhyContactModal: (value: boolean) => void;
    isWhyContactedIncomplete: (value: boolean) => void;
    isAtlasFeedbackEnabled?: boolean;
    showError?: (errorData) => void;
    shouldShowReasonCategories?: boolean; //Used only for styling in ComposerView
}

export default function AtlasLabellingDropdown(props: AtlasLabellingDropdownProps) {
    const [shouldShowWhyContact, setShouldShowWhyContact] = useState(false);
    const [shouldShowSelectAtlasTaskError, setShouldShowSelectAtlasTaskError] = useState(false);
    const [whyContactIssueId, setWhyContactIssueId] = useState<string | undefined>(undefined);
    const [taskIssueId, setTaskIssueId] = useState<string>('');
    const [feedbackType, setFeedbackType] = useState<string>('');
    const [taskLabelList, setTaskLabelList] = useState<KatalOption[]>([]);
    const [taskWhyContactMap, setTaskWhyContactMap] = useState({});

    const serviceAPIContext = useContext(ServicesAPIContext);
    const replySubmitHandlerContext = useContext(ReplyHandlerContext);

    const { translate } = localization();

    const transformTaskLabelList = useCallback((taskList) => {
        const transformedList: KatalOption[] = []
        for (const task of taskList) {
            const transformedTask = { // Transform task into a usable format for Katal components
                name: task.worldServerId,
                value: task.issueId ,
                tooltip: task.taskLabelDesc,
                whyContactedList: task.whyContactLabels
            }
            transformedList.push(transformedTask) // Transform issue into a usable format for Katal components

            if (transformedTask && transformedTask.whyContactedList) {
                const transformedTaskWhyContactMap = taskWhyContactMap;
                transformedTaskWhyContactMap[transformedTask.value] = transformedTask.whyContactedList.map(issue => (
                        {
                            name: issue.worldServerId,
                            value: issue.whyContactIssueId,
                            label: issue.whyContactLabel,
                            tooltip: issue.whyContactLabelDesc
                        }
                    )
                )
                setTaskWhyContactMap(transformedTaskWhyContactMap);
            }
        }
        setTaskLabelList(transformedList);
    }, [])

    const getWhyContactedDropdownData = useCallback(() => {
        const params = {
            caseId: props.caseId
        }

        serviceAPIContext.paragonService.getWhyContactedDropdown(params).then(response => {
            if (response && response.whyContactedDropdownData) {
                const output = response.whyContactedDropdownData;
                setShouldShowWhyContact(output.showWhyContactModal);
                props.showWhyContactModal?.(output.showWhyContactModal);
                setShouldShowSelectAtlasTaskError(output.showSelectAtlasTaskError);
                setFeedbackType(output.feedbackType);

                const localWhyContactIssueId = output.selectedWhyContact ? output.selectedWhyContact.whyContactIssueId : undefined;

                if (output.selectedTaskLabel) {
                    if (localWhyContactIssueId && taskIssueId !== output.selectedTaskLabel.issueId) {
                        setWhyContactIssueId(localWhyContactIssueId);
                    }
                    setTaskIssueId(output.selectedTaskLabel.issueId);
                } else if (output.taskLabelList && output.taskLabelList.length > 0) {
                    setTaskIssueId(output.taskLabelList[0].issueId);
                }

                if (output.taskLabelList) {
                    transformTaskLabelList(output.taskLabelList);
                }
            }
        }).catch(error => {
            const errorData: ErrorData = {};

            if (error && error.response) {
                errorData.statusCode = error.response.status;
                errorData.message = error.response.data;
                props.showError?.(errorData);
            } else {
                errorData.statusCode = 500;
                errorData.message =  'Paragon_TAM_CM_An_error_occurred_please_try_again_later';
                props.showError?.(errorData);
            }
        });

        props.isWhyContactedIncomplete(getWhyContactedIncomplete());
    }, []);

    useEffect(() => {
        getWhyContactedDropdownData();
    }, [getWhyContactedDropdownData, transformTaskLabelList]);

    useEffect(() => {
        props.isWhyContactedIncomplete(getWhyContactedIncomplete());
    }, [taskIssueId]);

    useEffect(() => {
        replySubmitHandlerContext.setWhyContactedFormData({
            caseId: props.caseId as string,
            caseStatus: props.newCaseStatus as string,
            whyContactIssueId: whyContactIssueId as string
        });
        props.isWhyContactedIncomplete(getWhyContactedIncomplete());
    }, [whyContactIssueId]);

    useEffect(() => {
        props.isWhyContactedIncomplete(getWhyContactedIncomplete());
    }, [props.newCaseStatus]);

    function getWhyContactedIncomplete() {
        return (shouldShowWhyContact
            && props.newCaseStatus === 'Resolved'
            && (!whyContactIssueId || !taskIssueId || shouldShowSelectAtlasTaskError)) as boolean
    }

    function showAtlasFeedbackModal() {
        paragonMediator.notify('atlas-feedback-enabled', {feedbackType: feedbackType, caseId: props.caseId as string});
    }

    const atlasFeedbackButtonElement = <KatButton className={$style.feedbackKatButton}
        id={'why-contact-feedback-aui-modal'}
        onClick={showAtlasFeedbackModal}
        variant="link">
        {translate('Paragon_TAM_CM_Atlas_Give_Feedback')}
    </KatButton>

    const alertElement = <div className={$style.atlasLabelDiv}>
        <KatAlert variant="danger" header={translate('Paragon_TAM_Atlas_ReplyCase_Select_Task_Error')}/>
    </div>

    const atlasLabellingContainer = <div>
        {shouldShowSelectAtlasTaskError && props.newCaseStatus === 'Resolved' && alertElement}
        <div className="kat-row containerDiv">
            <section className="kat-col-xs-6 dropDownSection">
                <div className={$style.styledDiv}>
                    <KatLabel text={translate('Paragon_TAM_CM_Select_Seller_Task_Label')} />
                </div>
                <DropdownOption
                    id={'taskLabelFormDropdown'}
                    updateValue={(value: string | undefined) => setTaskIssueId(value as string)}
                    options={taskLabelList.map(task => ({ name: task.name, value: task.value, tooltip: task.tooltip}))}
                    valueField="value"
                    textField="name"
                    descriptionField="tooltip"
                    value={taskIssueId}
                    state={!!taskIssueId}/>
            </section>
            <section className="kat-col-xs-6 issueDropDownSection">
                <div className={$style.styledDiv}>
                    <KatLabel text={translate('Paragon_TAM_CM_Select_Seller_Why_Contacted')} />
                    <KatTooltip label={translate('Paragon_TAM_CM_Why_label_popover_text')} trigger-text="?" position="right"/>
                </div>
                <DropdownOption
                    id={'whyContactLabelFormDropdown'}
                    updateValue={(value: string | undefined) => setWhyContactIssueId(value as string)}
                    options={taskWhyContactMap[taskIssueId]}
                    valueField="value"
                    textField="name"
                    descriptionField="tooltip"
                    value={whyContactIssueId}
                    state={!!whyContactIssueId || props.newCaseStatus !== 'Resolved'}/>
                <div className="float-left">
                    {props.isAtlasFeedbackEnabled && atlasFeedbackButtonElement}
                </div>
            </section>
        </div>
    </div>

    return (
        <div>
            {shouldShowWhyContact && taskLabelList.length > 0 && atlasLabellingContainer}
        </div>
    )
}
