import {KatDropdown, KatOption, KatTooltip} from '@amzn/katal-react';
import React, {useCallback, useEffect, useState} from 'react';

import $style from './dropdown-option.module.scss';

interface DropdownOptionProps {
    options?: Record<string, string>[];
    valueField?: string;
    textField?: string;
    descriptionField?: string
    state?: boolean;
    value?: string;
    id?: string;
    updateValue: (value: string | undefined) => void;
}

const defaults: DropdownOptionProps = {
    updateValue: () => {},
    state: false,
    id: ''
}

export default function DropdownOption(props: DropdownOptionProps = defaults) {
    const [selectedValue, setSelectedValue] = useState('');
    const [value] = useState(props.value);

    const state = props.state;

    const updateValue = props.updateValue;

    const clearOptionSelected = useCallback(() => {
        setSelectedValue('');
    }, [])

    const selectValue = useCallback(() => {
        const options = props.options ? props.options : [];

        for (let i = 0; i <  options.length; i += 1) {
            if (options[i][props.valueField ? props.valueField : ''] === props.value) {
                setSelectedValue(props.value ? props.value : '');
                break;
            }
        }
    }, []);

    useEffect(() => {
        if (value === undefined) {
            clearOptionSelected()
        } else {
            selectValue()
        }
    }, [value, clearOptionSelected, selectValue]);

    useEffect(() => {
        if (selectedValue !== '') {
            updateValue(selectedValue);
        }
    }, [selectedValue, updateValue]);

    function optionSelected(event) {
        setSelectedValue(event.detail.value);
    }

    function shouldShowTooltip(description) {
        // If the description tool tip is a string id, don't show the tooltip
        return !(description && (description.match(/[^_]+/g)).length > 3)
    }

    return (
        <div>
            <KatDropdown
                data-testid="katal-dropdown"
                value={value}
                state={state ? undefined : 'error'}
                onChange={optionSelected}>
                {props.options?.map((option) => (
                        <KatOption
                            data-testid={`katal-dropdown-option-${option[props.valueField ? props.valueField : '']}`}
                            key={option[props.valueField ? props.valueField : '']}
                            value={option[props.valueField ? props.valueField : '']}
                            selected={state && selectedValue === option[props.valueField ? props.valueField : '']}
                            className="noPad">
                            <div className={$style.optionContainer}>
                                <div>
                                    { option[props.textField ? props.textField : ''] }
                                </div>
                                <div>
                                    { shouldShowTooltip(option[props.descriptionField ? props.descriptionField : ''])
                                    && <KatTooltip className={$style.styledKatToolTip}
                                        data-testid={`katal-tooltip-${option[props.descriptionField ? props.descriptionField : '']}`}
                                        label={option[props.descriptionField ? props.descriptionField : '']}
                                        trigger-text="?"
                                        position="left"/> }
                                </div>
                            </div>
                        </KatOption>
                    )
                )}
            </KatDropdown>
        </div>
    )
}