import React, { FunctionComponent, useEffect, useState } from "react";
import { KatButton } from "@amzn/katal-react";
import { paragonCoreDataContext } from "@amzn/paragon-context/lib/ParagonCoreDataContext";
import { paragonPageContext } from "@amzn/paragon-context/lib/ParagonPageContext";
import { paragonWidgetDataContext } from "@amzn/paragon-context/lib/ParagonWidgetDataContext";
import "@amzn/paragon-dynamic-view-renderer/lib/styles.css";
import {
    ControlSpacing,
    PageSection,
    useParagonContextConsumerEl,
    useParagonContext, useWidgetDataContextStateSetter
} from "@amzn/paragon-ui-react";
import "@amzn/paragon-ui-react/lib/styles.css";
import { useKeyboardShortcut } from "src/context/useKeyboardShortcuts";
import { CaseAndSellerDetails } from "src/widgets/IssueSummaryWidget/components/case_seller_info/CaseAndSellerDetails";
import { LLMIssueSummary } from "src/widgets/IssueSummaryWidget/components/llm_issue_summary/LLMIssueSummary";
import $style from "./issue-summary-widget.module.scss";
import { TransferCase } from "../TransferCase";
import { issueSummaryContext } from "@amzn/paragon-context/lib/IssueSummaryContext";
import { paragonAnalyticsContext } from "@amzn/paragon-context/lib/ParagonAnalyticsContext";
import { logClickStreamData } from "src/common/utils";
import { GetExternalTeamDataRequest, GetExternalTeamDataResponse } from "@amzn/paragon-context/lib/IssueSummaryContext/models/TransferFunctions/GetExternalTeamData";
import { GetQueueDataRequest, GetQueueDataResponse } from "@amzn/paragon-context/lib/IssueSummaryContext/models/TransferFunctions/GetQueueData";
import ReplyCase from "src/widgets/IssueSummaryWidget/components/widgets/ReplyCase/ReplyCase";
import {localization} from "src/helpers";
import { logger } from "src/logger";
import initialMetricsPublisher from "src/metrics";


export const IssueSummaryWidget: FunctionComponent = () => {
    const el = useParagonContextConsumerEl();

    const {
        value: { caseDetails, userDetails, tenantFeatures },
    } = useParagonContext(el, paragonCoreDataContext);

    const [isCustomerInfoLoading, setIsCustomerInfoLoading] = useState(true);

    const [showTransferContent, setShowTransferContent] = useState(false);
    const [showReplyContent, setShowReplyContent] = useState(false);
    const [queueData, setQueueData] = useState<GetQueueDataResponse>();
    const [externalData, setExternalData] = useState<GetExternalTeamDataResponse>();
    const [isTransferButtonClicked, setIsTransferButtonClicked] = useState(false);
    const [isReplyButtonClicked, setIsReplyButtonClicked] = useState(false);
    const [isLoadingQueueExternalList, setIsLoadingQueueExternalList] = useState(false);

    const { value: pageData } = useParagonContext(el, paragonPageContext);

    const { value: { isWidgetEditModeEnabled }, methods: widgetMethods } = useParagonContext(el, paragonWidgetDataContext);

    const { paragonConsumerEl } = useKeyboardShortcut("katal-app-example-shortcut", [], "", () => {
        alert("hi from a katal app");
    });

    const { value: compositeCaseData, methods } = useParagonContext(el, issueSummaryContext);

    const { methods: clickStreamFunctions } = useParagonContext(el, paragonAnalyticsContext);

    const [showTransferButton, setShowTransferButton] = useWidgetDataContextStateSetter<boolean>('showTransferButton', false);
    const [showReplyButton, setShowReplyButton] = useWidgetDataContextStateSetter<boolean>('showReplyButton', false);
    const [showPeekNowButton, setShowPeekNowButton] = useWidgetDataContextStateSetter<boolean>('showPeekNowButton', false);

    const {
        location: { origin },
    } = window;

    const onTransferCancel = () => {
        setShowTransferContent(false)
    }

    const onReplyCancel = () => {
        setShowReplyContent(false)
    }

    const { translate } = localization();

    useEffect(() => {
        if (!caseDetails?.id || !userDetails?.agentLogin || !clickStreamFunctions) {
            return;
        }
        setIsCustomerInfoLoading(false);

        logger.info(`Issue Summary widget is loaded for case: ${caseDetails?.id} + " and user: ${userDetails?.agentLogin}`);
        logClickStreamData(clickStreamFunctions, "click", "llm-issue-summary-widget-load");
        try{
            initialMetricsPublisher.newChildPublisher({
                methodName: "IssueSummaryWidget"
            }).publishCounterMonitor("LLMIssueSummaryWidgetLoaded", 1);
            } catch(error) {
                logger.warn("Exception occurred while publishing llm issue summary metrics")
            }
        }, [caseDetails?.id, userDetails?.agentLogin, clickStreamFunctions]);

    useEffect(() => {
        if (!caseDetails?.id || !caseDetails?.queue || !isTransferButtonClicked) {
            return;
        }

        logger.info("Request initiated to fetch Transfer Queues", {compositeCaseData});

        async function fetchQueueList() {
            setIsLoadingQueueExternalList(true);

            try {
                const request: GetQueueDataRequest = {
                    caseId: caseDetails?.id || '',
                    fromQueueName: caseDetails?.queue || '',
                }

                const response = await methods.getQueueData(request);
                setQueueData(response);
            } catch (error) {
                console.error("getQueueData error: ", error);
                logger.info("getQueueData call failed ", {error});
            } finally {
                setIsLoadingQueueExternalList(false);
            }
        }

        async function fetchExternalList() {
            setIsLoadingQueueExternalList(true);
            try {
                const request: GetExternalTeamDataRequest = {
                    caseId: caseDetails?.id || '',
                    fromQueueName: caseDetails?.queue || '',
                }

                const response = await methods.getExternalTeamData(request);
                setExternalData(response);
            } catch (error) {
                console.error("getExternalTeamData error: ", error);
                logger.info("getExternalTeamData call failed ", {error});
            } finally {
                setIsLoadingQueueExternalList(false);
            }
        }

        fetchQueueList();
        fetchExternalList();

    }, [isTransferButtonClicked, caseDetails?.id, caseDetails?.queue]);

    return (
        <PageSection
            title={translate("Paragon_TAM_CM_Issue_Summary")}
            widgetId="llm-issue-summary-widget"
            pageSectionControls={
                <ControlSpacing size="sm" justify="end">
                    {isWidgetEditModeEnabled &&
                        <ControlSpacing size="sm" justify="start">
                            <KatButton size="small" label={showTransferButton ? translate("Paragon_TAM_CM_Hide_Transfer_Button") : translate("Paragon_TAM_CM_Show_Transfer_Button") } variant="tertiary"
                                onClick={() => setShowTransferButton(!showTransferButton)}
                            />
                            <KatButton size="small" label={showReplyButton ? translate("Paragon_TAM_CM_Hide_Reply_Button") : translate("Paragon_TAM_CM_Show_Reply_Button")} variant="tertiary"
                                onClick={() => setShowReplyButton(!showReplyButton)}
                            />
                            <KatButton size="small" label={showPeekNowButton ? translate("Paragon_TAM_CM_Hide_PeekNow_Button") : translate("Paragon_TAM_CM_Show_PeekNow_Button")} variant="tertiary"
                                onClick={() => setShowPeekNowButton(!showPeekNowButton)}
                            />
                        </ControlSpacing>

                    }
                    {showTransferButton &&
                        <KatButton
                            variant="tertiary"
                            label= {translate("Paragon_TAM_CM_Transfer")}
                            onClick={() => {
                                setShowTransferContent(!showTransferContent);
                                setIsTransferButtonClicked(true);
                            }}
                        />
                    }
                    {showReplyButton &&
                        <KatButton
                            variant="tertiary"
                            label={translate("Paragon_TAM_CM_Reply")}
                            onClick={() => {
                                setShowReplyContent(!showReplyContent);
                                setIsReplyButtonClicked(true);
                            }}
                        />
                    }
                </ControlSpacing>
            }
        >

            {paragonConsumerEl}

            {showTransferContent &&
                <TransferCase
                    onTransferCancel={onTransferCancel}
                    isloadingData={isLoadingQueueExternalList}
                    queueData={queueData}
                    externalData={externalData}
                />}

            {showReplyContent &&
                <ReplyCase
                    onDiscardChanges={onReplyCancel}
                />}

            <div className={$style.issueSummaryWidget}>
                <CaseAndSellerDetails
                    isCaseAndSellerInfoLoading={isCustomerInfoLoading}
                    compositeCaseDetails={compositeCaseData}
                    tenantFeatures={tenantFeatures}
                    issueSummaryFunctions={methods}
                    userDetails={userDetails}
                    clickStream={clickStreamFunctions}
                    showPeekNowButton={showPeekNowButton}
                />

                <LLMIssueSummary
                    widgetMethods={widgetMethods}
                    caseId={caseDetails?.id}
                    agentLogin={userDetails?.agentLogin}
                    clickStream={clickStreamFunctions}
                    issueSummaryFunctions={methods}
                />
            </div>

        </PageSection>
    );
};
