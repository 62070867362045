import { katTranslator } from '../../shared/i18n';

const strings = {
  "no_options": {
    "en-US": "No results found.",
    "ar-AE": "لم يتم العثور على نتائج.",
    "bn-IN": "কোনও ফলাফল পাওয়া যায়নি।",
    "de-DE": "Keine Treffer.",
    "es-ES": "No se han encontrado resultados.",
    "es-MX": "No se encontraron resultados.",
    "fr-BE": "Aucun résultat n’a été trouvé.",
    "fr-FR": "Aucun résultat n’a été trouvé.",
    "gu-IN": "કોઈ પરિણામ મળ્યું નથી.",
    "hi-IN": "कोई नतीजा नहीं मिला.",
    "it-IT": "Nessun risultato trovato.",
    "ja-JP": "結果はありません。",
    "kn-IN": "ಯಾವುದೇ ಫಲಿತಾಂಶಗಳು ಕಂಡುಬಂದಿಲ್ಲ.",
    "ko-KR": "결과를 찾을 수 없습니다.",
    "ml-IN": "ഫലങ്ങളൊന്നും കണ്ടെത്തിയില്ല.",
    "mr-IN": "कोणतेही परिणाम आढळले नाहीत.",
    "nl-BE": "Geen resultaten gevonden.",
    "nl-NL": "Geen resultaten gevonden.",
    "pl-PL": "Nie znaleziono żadnych wyników.",
    "pt-BR": "Nenhum resultado foi encontrado.",
    "pt-PT": "Nenhum resultado encontrado.",
    "sv-SE": "Inga resultat hittades.",
    "ta-IN": "முடிவுகள் எதுவும் காணப்படவில்லை.",
    "te-IN": "ఫలితాలు ఏవీ కనుగొనబడలేదు.",
    "th-TH": "ไม่พบผลลัพธ์",
    "tr-TR": "Sonuç bulunamadı.",
    "vi-VN": "Không tìm thấy kết quả.",
    "zh-CN": "未找到任何结果。",
    "zh-TW": "找不到結果。"
  },
  "select_all": {
    "en-US": "Select all",
    "ar-AE": "تحديد الكل",
    "bn-IN": "সব নির্বাচন করুন",
    "de-DE": "Alle auswählen",
    "es-ES": "Seleccionar todo",
    "es-MX": "Seleccionar todo",
    "fr-BE": "Tout sélectionner",
    "fr-FR": "Tout sélectionner",
    "gu-IN": "બધા પસંદ કરો",
    "hi-IN": "सभी चुनें",
    "it-IT": "Seleziona tutto",
    "ja-JP": "すべて選択",
    "kn-IN": "ಎಲ್ಲವನ್ನೂ ಆಯ್ಕೆಮಾಡಿ",
    "ko-KR": "모두 선택",
    "ml-IN": "എല്ലാം തിരഞ്ഞെടുക്കുക",
    "mr-IN": "सर्व निवडा",
    "nl-BE": "Alles selecteren",
    "nl-NL": "Alles selecteren",
    "pl-PL": "Wybierz wszystkie",
    "pt-BR": "Selecionar tudo",
    "pt-PT": "Selecionar tudo",
    "sv-SE": "Välj alla",
    "ta-IN": "அனைத்தையும் தேர்ந்தெடுக்கவும்",
    "te-IN": "అన్నీ ఎంచుకోండి",
    "th-TH": "เลือกทั้งหมด",
    "tr-TR": "Tümünü seç",
    "vi-VN": "Chọn tất cả",
    "zh-CN": "全选",
    "zh-TW": "全選"
  },
  "pending_cancel": {
    "en-US": "Cancel",
    "ar-AE": "إلغاء",
    "bn-IN": "বাতিল",
    "de-DE": "Abbrechen",
    "es-ES": "Cancelar",
    "es-MX": "Cancelar",
    "fr-BE": "Annuler",
    "fr-FR": "Annuler",
    "gu-IN": "કેન્સલ કરો",
    "hi-IN": "कैंसल करें",
    "it-IT": "Annulla",
    "ja-JP": "キャンセル",
    "kn-IN": "ಕ್ಯಾನ್ಸಲ್ ಮಾಡಿ",
    "ko-KR": "취소",
    "ml-IN": "റദ്ദാക്കുക",
    "mr-IN": "रद्द करा",
    "nl-BE": "Annuleren",
    "nl-NL": "Annuleren",
    "pl-PL": "Anuluj",
    "pt-BR": "Cancelar",
    "pt-PT": "Cancelar",
    "sv-SE": "Avbryt",
    "ta-IN": "கேன்சல் செய்யவும்",
    "te-IN": "రద్దు చేయి",
    "th-TH": "ยกเลิก",
    "tr-TR": "İptal et",
    "vi-VN": "Hủy",
    "zh-CN": "取消",
    "zh-TW": "取消"
  },
  "pending_apply": {
    "en-US": "Apply",
    "ar-AE": "تطبيق",
    "bn-IN": "প্রয়োগ করুন",
    "de-DE": "Anwenden",
    "es-ES": "Aplicar",
    "es-MX": "Aplicar",
    "fr-BE": "Appliquer",
    "fr-FR": "Appliquer",
    "gu-IN": "લાગુ કરો",
    "hi-IN": "लागू करें",
    "it-IT": "Applica",
    "ja-JP": "適用",
    "kn-IN": "ಅನ್ವಯಿಸಿ",
    "ko-KR": "적용",
    "ml-IN": "ബാധകമാക്കുക",
    "mr-IN": "लागू करा",
    "nl-BE": "Toepassen",
    "nl-NL": "Toepassen",
    "pl-PL": "Zastosuj",
    "pt-BR": "Aplicar",
    "pt-PT": "Aplicar",
    "sv-SE": "Tillämpa",
    "ta-IN": "பயன்படுத்து",
    "te-IN": "వర్తింపజేయండి",
    "th-TH": "ปรับใช้",
    "tr-TR": "Uygula",
    "vi-VN": "Áp dụng",
    "zh-CN": "应用",
    "zh-TW": "套用"
  },
  "search": {
    "en-US": "search",
    "ar-AE": "البحث",
    "bn-IN": "অনুসন্ধান করুন",
    "de-DE": "Suchen",
    "en-ZA": "Search",
    "es-ES": "buscar",
    "es-MX": "buscar",
    "fr-BE": "rechercher",
    "fr-FR": "rechercher",
    "gu-IN": "શોધો",
    "hi-IN": "सर्च करें",
    "it-IT": "cerca",
    "ja-JP": "検索",
    "kn-IN": "ಸರ್ಚ್ ಮಾಡಿ",
    "ko-KR": "검색",
    "ml-IN": "തിരയുക",
    "mr-IN": "शोधा",
    "nl-BE": "zoeken",
    "nl-NL": "zoeken",
    "pl-PL": "szukaj",
    "pt-BR": "pesquisar",
    "pt-PT": "pesquisar",
    "sv-SE": "sök",
    "ta-IN": "சர்ச்",
    "te-IN": "శోధించండి",
    "th-TH": "ค้นหา",
    "tr-TR": "arama",
    "vi-VN": "tìm kiếm",
    "zh-CN": "搜索",
    "zh-TW": "搜尋"
  },
  "clear": {
    "en-US": "clear",
    "ar-AE": "مسح",
    "bn-IN": "সরিয়ে দিন",
    "de-DE": "Löschen",
    "es-ES": "borrar",
    "es-MX": "borrar",
    "fr-BE": "effacer",
    "fr-FR": "effacer",
    "gu-IN": "ક્લિયર કરો",
    "hi-IN": "हटाएं",
    "it-IT": "cancella",
    "ja-JP": "取り消す",
    "kn-IN": "ಸ್ಪಷ್ಟವಾಗಿದೆ",
    "ko-KR": "지우기",
    "ml-IN": "മായ്ക്കുക",
    "mr-IN": "साफ करा",
    "nl-BE": "wissen",
    "nl-NL": "wissen",
    "pl-PL": "odznacz",
    "pt-BR": "limpar",
    "pt-PT": "limpar",
    "sv-SE": "rensa",
    "ta-IN": "அழி",
    "te-IN": "క్లియర్ చేయండి",
    "th-TH": "ล้าง",
    "vi-VN": "xóa",
    "zh-CN": "清除",
    "zh-TW": "清除"
  }
}; 

export default katTranslator(strings);
    