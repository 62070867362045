import { KatalComponent, Keys, register } from '../../shared/base';
import { KatList } from '../list';

const ACTIONABLE_KEY_CODES = [Keys.Enter, Keys.Space];

/**
 * @component {kat-tile} KatalTile Tiles display multiple pieces of data together in a standard format. They’re normally presented in a <a href="/components/tile-grid/">tile grid</a> with each tile representing a distinct item.
 * @slot default The contents of the `kat-tile`.
 * @guideline Do Adding a features block enables a hover-scroll effect
 * @status Production
 * @theme flo
 * @example SampleTile {"image": "https://images-na.ssl-images-amazon.com/images/G/01/rainier/nav/sc-unified._CB341165134_.png","content": "<a href=\"https://www.amazon.com\"><kat-tile-container> <kat-tile-titles> <li>Hello</li> <li>World</li> </kat-tile-titles> <kat-tile-primary-list> <li>March 1st, 2018</li> <li>Cool Dudes, Inc.</li> <li>$9.99</li> <li> <kat-star-rating rating=\"4.5\" reviews=\"205\" ></kat-star-rating> </li> </kat-tile-primary-list><kat-tile-secondary-list> <li>Inventory Management</li> <li>Shipping Solutions</li> <li>Advertising</li> <li>Product Research</li> <li>Buyer Seller Meetings</li> <li>Reporting / Pie Charts</li> </kat-tile-secondary-list> </kat-tile-container></a> "}
 * @example NoPrimaryList {"image": "https://images-na.ssl-images-amazon.com/images/G/01/rainier/nav/sc-unified._CB341165134_.png","content": "<a href=\"https://www.amazon.com\"><kat-tile-container><kat-tile-titles><li>Hello</li><li>World</li></kat-tile-titles><kat-tile-secondary-list><li>Inventory Management</li><li>Shipping Solutions</li><li>Advertising</li><li>Product Research</li><li>Buyer Seller Meetings</li><li>Reporting / Pie Charts</li></kat-tile-secondary-list></kat-tile-container></a>"}
 * @example ImageOnly {"image": "https://images-na.ssl-images-amazon.com/images/G/01/rainier/nav/sc-unified._CB341165134_.png","content": "<kat-tile-container></kat-tile-container>"}
 * @event click Click event dispatches when a user clicks on the primary or secondary list, event.detail.click === 'primary-list' : event.detail.click === 'secondary-list'.
 * @event selected Selected event dispatches when a user selects an item from the list.
 * @a11y {keyboard}
 */
@register('kat-tile')
export class KatTile extends KatalComponent {
  static get observedAttributes() {
    return ['image', 'image-height'];
  }

  constructor() {
    super(KatTile.observedAttributes);
    this._loaded = false;
  }

  connectedCallback() {
    super.connectedCallback();

    const componentIsReadyToRender = () =>
      this.innerHTML?.length && this.querySelector('kat-tile-container');

    if (componentIsReadyToRender()) {
      this._loaded = true;
      this.render();
    }

    this._observer = new MutationObserver(mutationsList => {
      // If the mutation detected was the addition of any tag that
      // started with the name "kat-tile" then request a re-render
      const shouldRender = mutationsList.some(
        mutation =>
          mutation.type === 'childList' &&
          mutation.addedNodes &&
          Array.from(mutation.addedNodes).some(addedNode =>
            addedNode.tagName?.includes('KAT-TILE')
          )
      );

      if (shouldRender && componentIsReadyToRender()) {
        this._loaded = true;
        this.render();
      }
    });
    this._observer.observe(this, { childList: true, subtree: true });
    this.addEventListeners();
  }

  disconnectedCallback() {
    this._observer.disconnect();
    this.removeEventListeners();
  }

  addEventListeners() {
    const secondaryListElement = this.querySelector('kat-tile-secondary-list');
    if (secondaryListElement) {
      secondaryListElement.addEventListener(
        'focusin',
        this.tileSlideDown.bind(this)
      );
      secondaryListElement.addEventListener(
        'focusout',
        this.tileSlideUp.bind(this)
      );
    }
  }

  removeEventListeners() {
    const tileContainer = this.querySelector('kat-tile-container');
    const primaryContainer = tileContainer
      ? tileContainer.querySelector('kat-tile-primary-list')
      : null;
    const secondaryListElement = this.querySelector('kat-tile-secondary-list');
    if (secondaryListElement) {
      secondaryListElement.removeEventListener(
        'focusin',
        this.tileSlideDown.bind(this)
      );
      secondaryListElement.removeEventListener(
        'focusout',
        this.tileSlideUp.bind(this)
      );
      this.removeEventListener('mouseenter', this.tileSlideDown);
      this.removeEventListener('mouseleave', this.tileSlideUp);
    }
    [primaryContainer, secondaryListElement].forEach(container => {
      if (container) {
        container.removeEventListener(
          'keydown',
          this.keyDownEventListener.bind(this)
        );
      }
    });
  }

  keyDownEventListener(e) {
    if (ACTIONABLE_KEY_CODES.includes(event.keyCode)) {
      e.preventDefault();
      e.target.click();
    }
  }

  /**
   * @classprop {string} image Image src url
   * @required
   */
  get image() {
    return this.getAttributeOrDefault('image', '');
  }

  set image(value) {
    this.setAttribute('image', value);
  }

  /**
   * @classprop {string} image-height Max height the image is allowed to be
   */
  get imageHeight() {
    return this.getAttributeOrDefault('image-height', '220px');
  }

  set imageHeight(value) {
    this.setAttribute('image-height', value);
  }

  render() {
    // All the contents need to be ready or we should not continue rendering
    if (!this._loaded) {
      return;
    }

    const tileContainer = this.querySelector('kat-tile-container');
    const primaryContainer = tileContainer.querySelector(
      'kat-tile-primary-list'
    );
    const imageHeight = this.imageHeight;
    const imageURL = this.image;

    // Build image container and image inside it
    const imageContainer = document.createDocumentFragment();
    const imageContainerElement = document.createElement('div');
    imageContainerElement.setAttribute('class', 'tile-image-container');

    const imageElement = document.createElement('img');
    imageElement.setAttribute('src', imageURL);

    if (imageHeight) {
      imageElement.setAttribute('style', `max-height: ${imageHeight}`);
      imageElement.setAttribute('tabindex', '0');
      imageContainerElement.setAttribute('style', `height: ${imageHeight}`);
    }

    imageContainerElement.appendChild(imageElement);
    imageContainer.appendChild(imageContainerElement);

    // See if an image container already exists and remove it if it does
    const existingImageContainer = tileContainer.querySelector(
      'div.tile-image-container'
    );

    if (existingImageContainer) {
      tileContainer.removeChild(existingImageContainer);
    }

    const titlesContainer = tileContainer.querySelector('kat-tile-titles');
    if (titlesContainer) {
      tileContainer.insertBefore(imageContainer, titlesContainer);
    }

    const secondaryContainer = tileContainer.querySelector(
      'kat-tile-secondary-list'
    );

    tileContainer.classList.add('complete');

    // apply scroll event if there are features to display
    if (secondaryContainer) {
      // TODO: add touch support, mousedown and up was commented out because of bad interaction with hover states
      //this.addEventListener('mousedown', this.tileSlideDown);
      this.addEventListener('mouseenter', this.tileSlideDown);

      //this.addEventListener('mouseup', this.tileSlideUp);
      this.addEventListener('mouseleave', this.tileSlideUp);
    }

    [primaryContainer, secondaryContainer].forEach(container => {
      if (container) {
        container.addEventListener('keydown', e => {
          if (ACTIONABLE_KEY_CODES.includes(event.keyCode)) {
            e.preventDefault();
            e.target.click();
          }
        });
      }
    });
  }

  // Respond to attribute changes.
  attributeChangedCallback() {
    if (this._loaded) {
      this.render();
    }
  }

  tileSlideDown() {
    const tileContainer = this.querySelector('kat-tile-container');
    const tileContainerHeight = tileContainer.offsetHeight;
    const imageContainerHeight = this.querySelector(
      '.tile-image-container'
    ).offsetHeight;
    const titlesContainerHeight =
      this.querySelector('kat-tile-titles').offsetHeight;
    const primaryContainer = this.querySelector('kat-tile-primary-list');
    const primaryContainerHeight = primaryContainer
      ? primaryContainer.offsetHeight
      : 0;

    const extraHeight =
      tileContainerHeight -
      (imageContainerHeight + titlesContainerHeight + primaryContainerHeight);

    tileContainer.setAttribute(
      'style',
      'height:' + tileContainerHeight + 'px;'
    );
    if (primaryContainer) {
      primaryContainer.setAttribute(
        'style',
        'height:' + (primaryContainerHeight + extraHeight) + 'px;'
      );
    }

    const secondaryContainer = this.querySelector('kat-tile-secondary-list');

    if (secondaryContainer) {
      secondaryContainer.setAttribute(
        'style',
        'height:' + this.offsetHeight + 'px'
      );
    }
    this._scrollTo(tileContainer, imageContainerHeight, 500);
  }

  tileSlideUp() {
    const tileContainer = this.querySelector('kat-tile-container');

    this._scrollTo(tileContainer, 0, 500);
  }

  private _scrollTo(element, to, duration) {
    const start = element.scrollTop;
    const change = to - start;
    let currentTime = 0;
    const increment = 20;

    const animateScroll = () => {
      element.classList.add('animate-wait');
      currentTime += increment;
      const val = easeInOut(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        window.requestAnimationFrame(animateScroll);
      } else {
        element.classList.remove('animate-wait');
      }
    };

    window.requestAnimationFrame(animateScroll);
  }
}

@register('kat-tile-titles')
export class KatTileTitles extends KatList {
  connectedCallback() {
    this.variant = 'unordered';
    super.connectedCallback();
  }
}

@register('kat-tile-primary-list')
export class KatTilePrimaryList extends KatList {
  connectedCallback() {
    this.variant = 'unordered';
    super.connectedCallback();
  }
}

@register('kat-tile-secondary-list')
export class KatTileSecondaryList extends KatList {
  connectedCallback() {
    this.variant = 'checkmark';
    super.connectedCallback();
  }
}

/**
 * @param t current time
 * @param b start value
 * @param c change in value
 * @param d duration
 */
function easeInOut(t, b, c, d) {
  t /= d / 2;
  if (t < 1) return (c / 2) * t * t + b;
  t--;
  return (-c / 2) * (t * (t - 2) - 1) + b;
}
