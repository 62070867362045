import {KatInputGroup, KatInput, KatIcon} from '@amzn/katal-react';
import React from 'react';

import $style from './input-group.module.scss';

interface InputGroupProps {
    input?: string;
    fieldNameDisplay?: string;
    rules?: string;
    placeholderText?: string
    isDisabled?: boolean;
    stateValidated?: boolean;
    updateInput: (input: string) => void;
}

const defaults: InputGroupProps = {
    fieldNameDisplay: '',
    placeholderText: '',
    isDisabled: false,
    updateInput: () => {}
}

export default function InputGroup(props: InputGroupProps = defaults) {
    return (
        <div className={$style.styledInputGroup}>
            <KatInputGroup data-testid="katal-input-group">
                {props.fieldNameDisplay && <span className={$style.spanLabel}> {props.fieldNameDisplay} </span>}
                <KatInput
                    className={$style.composerKatInput}
                    data-testid="katal-input"
                    id="createCaseInput"
                    onChange={(event) => props.updateInput(event.currentTarget.value)}
                    value={props.input}
                    placeholder={props.placeholderText}
                    disabled={props.isDisabled}
                    type="text"
                />
                {props.stateValidated && <KatIcon className={$style.checkKatIcon} data-testid="katal-check-icon" name="check" size="large" />}
                {!props.stateValidated && <KatIcon className={$style.crossKatIcon} data-testid="katal-cross-icon" name="x" size="large" />}
            </KatInputGroup>
        </div>
    )
}