import { html, property } from 'lit-element';
import { ifNotNull } from '../../utils/directives';
import {
  KatLitElement,
  register,
  event,
  EventEmitter,
  Keys,
} from '../../shared/base';

const VARIANT_TO_TEXT_COLOR_CLASS = {
  success: 'text-fiji',
  danger: 'text-crimson',
  warning: 'text-golden',
  info: 'text-nordic',
};

const VARIANT_TO_ICON_NAME = {
  success: 'success',
  danger: 'danger',
  warning: 'warning',
  info: 'info-circle',
};

/**
 * @component {kat-flashbar} KatalFlashbar A flashbar communicates the status or results of a user’s action at the page level.<br /> This component is deprecated. Use <a href="/components/alert/">Alert component</a> instead.
 * @guideline Do Convey the severity and urgency within the context of activity.
 * @guideline Do Provide a specific and informative title so that the customer understands what the issue is before they read any text below the title.
 * @guideline Dont Don't use multiple flashbars in one page. If your rare use case requires this, stack the flashbars ordered by the urgency with which the customers need to pay attention: error, warning, information, then success.
 * @status Production
 * @theme flo
 * @example Info {"header": "Informational Flashbar", "description": "Used to give users context.", "variant":"info","link-label":"link-as-button","link-href":"https://www.amazon.com"}
 * @example Success {"header": "Success Flashbar", "description": "Used to let users know an action was successful.", "variant":"success","link-label":"link-as-button","link-href":"https://www.amazon.com"}
 * @example Danger {"header": "Danger Flashbar", "description": "Can be used to let users know an error has occured.", "variant":"danger","link-label":"link-as-button","link-href":"https://www.amazon.com"}
 * @example Warning {"header": "Warning Flashbar", "description": "Used to let users know they should be careful with an action they are to perform", "variant":"warning","link-label":"link-as-button","link-href":"https://www.amazon.com"}
 * @a11y {keyboard}
 * @a11y {contrast}
 */
@register('kat-flashbar')
export class KatFlashbar extends KatLitElement {
  /** The main text that displays. */
  @property()
  header: string;

  /** The secondary text that displays. */
  @property()
  description?: string;

  /** Whether the flashbar has been dismissed - Default is false */
  @property()
  dismissed?: boolean;

  /**
   * The state of the flashbar - Default is info
   * @enum {value} info Info alert - Default
   * @enum {value} success Success alert
   * @enum {value} danger Danger alert
   * @enum {value} warning Warning alert
   */
  @property()
  variant = 'info';

  /** The text to display for the link. */
  @property({ attribute: 'link-label' })
  linkLabel?: string;

  /** The location to navigate to. */
  @property({ attribute: 'link-href' })
  linkHref = '#';

  /**
   * Specifies how to open the link
   * @enum {value} _blank Opens in a new window
   */
  @property({ attribute: 'link-target' })
  linkTarget = '_self';

  /**
   * Whether the default link click
   *     action should be prevented. Set this to true when using the "link-click"
   *     event to prevent page navigation.
   */
  @property({ attribute: 'link-prevent-default' })
  linkPreventDefault?: boolean;

  /** Fires when the flashbar is dismissed. */
  @event('dismissed')
  private _dismissed: EventEmitter<{ dismissed: boolean }>;

  /** Fires when the link button has been clicked. Set "link-prevent-default" on the component to prevent page navigation. */
  @event('link-click', true)
  private _linkclick: EventEmitter;

  // disable shadow-dom
  createRenderRoot() {
    return this;
  }

  dismissAlert() {
    this.dismissed = true;
    this._dismissed.emit({ dismissed: this.dismissed });
  }

  closeOnKey(e) {
    if (Keys.Confirm.includes(e.keyCode)) {
      this.dismissAlert();
    }
  }

  handleLinkClick(e) {
    if (this.linkPreventDefault) {
      e.stopImmediatePropagation();
      e.preventDefault();
    }
    this._linkclick.emit();
  }

  handleOnKey(e) {
    if (Keys.Confirm.includes(e.keyCode)) {
      this.handleLinkClick(e);
    }
  }

  render() {
    const variant = this.variant || 'info';
    const linkHref = this.linkHref || '#';
    const linkTarget = this.linkTarget || '_self';
    const iconColorClass = VARIANT_TO_TEXT_COLOR_CLASS[variant];
    const iconName = VARIANT_TO_ICON_NAME[variant];
    const dismissed = this.dismissed ? 'dismissed' : '';

    return html`
      <div class="alert-container small ${variant} ${dismissed}">
        <div class="alert-row">
          <div class="icon icon-small ${iconColorClass}">
            <kat-icon name=${iconName} size="small"></kat-icon>
          </div>
          <div class="content">
            <div class="header">
              <span>${ifNotNull(this.header)}</span>
            </div>
            <div class="description">
              <span>${ifNotNull(this.description)}</span>
            </div>
          </div>
          <div class="flashbar-cta">
            <kat-link
              role="button"
              tabindex="0"
              label=${ifNotNull(this.linkLabel)}
              target=${linkTarget}
              href=${linkHref}
              variant="button"
              @click=${this.handleLinkClick}
              @keydown=${this.handleOnKey}
            ></kat-link>
          </div>
          <div class="dismiss">
            <kat-icon
              role="button"
              tabindex="0"
              part="flashbar-dismiss-icon"
              name="exit"
              size="small"
              @click=${this.dismissAlert}
              @keydown=${this.closeOnKey}
            ></kat-icon>
          </div>
        </div>
      </div>
    `;
  }
}
