import { ClassElement, Decorator } from './decorators';

/**
 * A decorator that can be placed on a class method, causing it to be called
 * only after `timeout` ms of inactivity.
 *
 * Note that this *cannot* be used to debounce shadow dom event handlers. By the
 * time the debounced function is called, the event will have passed the shadow
 * root boundary and been retargeted to the host element. If you need access to
 * the inner element, it is best to debounce a new function, and pass whatever
 * you need from the original event into it.
 *
 * @decorator
 * @param wait The time in ms to wait before calling the function.
 */
export const debounce = (wait: number): Decorator => (proto: ClassElement) => {
  const fn = proto.descriptor.value;

  // we use a weak map here to tie the timeout to the specific class instance,
  // otherwise all instances of the class would share the timeout
  const timeout = new WeakMap();

  function debounced(...args) {
    clearTimeout(timeout.get(this));
    timeout.set(
      this,
      setTimeout(() => {
        timeout.delete(this);
        fn.apply(this, args);
      }, wait)
    );
  }

  proto.descriptor.value = debounced;
  return proto;
};
