import {KatDropdown} from '@amzn/katal-react';
import React, {useCallback, useEffect} from 'react';

interface DropdownProps {
    defaultValue?: string;
    dropdownOptions?: { name: string, value: string }[];
    labelText?: string;
    placeholder?: string
    disabled?: boolean;
    noMargin?: boolean;
    handleChange: (event) => void;
}

const defaults: DropdownProps = {
    dropdownOptions: [],
    labelText: '',
    placeholder: '',
    disabled: false,
    noMargin: false,
    handleChange: () => {}
}

export default function Dropdown(props: DropdownProps = defaults) {
    const handleChange = props.handleChange

    const dropdownHandleChange = useCallback((event) => {
        handleChange(event);
    }, [handleChange]);

    useEffect(() => {
        if (props.defaultValue) {
            dropdownHandleChange({ detail: { value: props.defaultValue }})
        }
    }, [dropdownHandleChange, props.defaultValue]);

    return (
        <KatDropdown
            data-testid="katal-dropdown"
            options={JSON.stringify(props.dropdownOptions)}
            value={props.defaultValue}
            label={props.labelText}
            placeholder={props.placeholder}
            disabled={props.disabled}
            size="small"
            onChange={dropdownHandleChange}
        />
    )
}