import { katTranslator } from '../../shared/i18n';

const strings = {
  'kat-tag-aria-label-dismiss': {
    'en-US': 'Dismiss',
    'ar-AE': 'تجاهل',
    'bn-IN': 'বরখাস্ত করুন',
    'de-DE': 'Verwerfen',
    'es-ES': 'Omitir',
    'es-MX': 'Descartar',
    'fr-BE': 'Ignorer',
    'fr-FR': 'Ignorer',
    'gu-IN': 'બરતરફ કરો',
    'hi-IN': 'खारिज करें',
    'it-IT': 'Ignora',
    'ja-JP': '解除',
    'kn-IN': 'ವಜಾಗೊಳಿಸಿ',
    'ko-KR': '무시',
    'ml-IN': 'തള്ളിക്കളയുക',
    'mr-IN': 'काढून टाका',
    'nl-BE': 'Negeren',
    'nl-NL': 'Negeren',
    'pl-PL': 'Odrzuć',
    'pt-BR': 'Ignorar',
    'pt-PT': 'Ignorar',
    'sv-SE': 'Avfärda',
    'ta-IN': 'நிராகரி',
    'te-IN': 'రద్దు చేయండి',
    'th-TH': 'ปิด',
    'tr-TR': 'Kapat',
    'vi-VN': 'Bỏ qua',
    'zh-CN': '忽略',
    'zh-TW': '關閉',
  },
  'kat-tag-aria-label-select': {
    'en-US': 'Select',
    'ar-AE': 'تحديد',
    'bn-IN': 'নির্বাচন করুন',
    'de-DE': 'Auswählen',
    'es-ES': 'Seleccionar',
    'es-MX': 'Seleccionar',
    'fr-BE': 'Sélectionner',
    'fr-FR': 'Sélectionner',
    'gu-IN': 'પસંદ કરો',
    'hi-IN': 'चुनें',
    'it-IT': 'Seleziona',
    'ja-JP': '選択',
    'kn-IN': 'ಆಯ್ಕೆಮಾಡಿ',
    'ko-KR': '선택',
    'ml-IN': 'തിരഞ്ഞെടുക്കുക',
    'mr-IN': 'निवडा',
    'nl-BE': 'Selecteren',
    'nl-NL': 'Selecteren',
    'pl-PL': 'Wybierz',
    'pt-BR': 'Selecionar',
    'pt-PT': 'Selecionar',
    'sv-SE': 'Välj',
    'ta-IN': 'தேர்ந்தெடு',
    'te-IN': 'ఎంచుకోండి',
    'th-TH': 'เลือก',
    'tr-TR': 'Seç',
    'vi-VN': 'Chọn',
    'zh-CN': '选择',
    'zh-TW': '選取',
  },
};

export default katTranslator(strings);
