const _strings = {
  'katal-tooltip-trigger-fallback': {
    'en-US': 'Info',
  },
  'katal-tooltip-aria-label': {
    'en-US': '{trigger}: {details}',
  },
};

export default _strings;
