import {KatAlert, KatCard, KatDivider, KatFlashbar, KatLabel, KatSpinner, KatButton,KatBox} from '@amzn/katal-react';
import React, {useEffect, useState, useContext} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {localization} from 'src/helpers';

import {paragonMediator} from '@amzn/paragon-context/lib/ParagonMediatorContext/paragonMediatorContext';
import { useParagonContext, useParagonContextConsumerEl } from '@amzn/paragon-ui-react';
import {paragonAnalyticsContext} from '@amzn/paragon-context/lib/ParagonAnalyticsContext';


import Dropdown from 'src/components/DropDown/DropDown';
import PromptDialog, {PromptOptions} from 'src/components/PromptDialog/PromptDialog';

import AttachmentModule
    from 'src/widgets/IssueSummaryWidget/components/widgets/ReplyCase/components/composer/AttachmentModule/AttachmentModule';
import CaseStatusDropdown
    from 'src/widgets/IssueSummaryWidget/components/widgets/ReplyCase/components/composer/CaseStatusDropdown/CaseStatusDropdown';
import EmailBodyComponent, {ContentType, Inputs}
    from 'src/widgets/IssueSummaryWidget/components/widgets/ReplyCase/components/composer/EmailBodyComponent/EmailBodyComponent';
import FinalWordVerifier
    from 'src/widgets/IssueSummaryWidget/components/widgets/ReplyCase/components/composer/FinalWordVerifier/FinalWordVerifier';
import InputGroupTags
    from 'src/widgets/IssueSummaryWidget/components/widgets/ReplyCase/components/composer/InputGroupTags/InputGroupTags';
import ReasonCategoryDropdown
    from 'src/widgets/IssueSummaryWidget/components/widgets/ReplyCase/components/composer/ReasonCategoryDropdown/ReasonCategoryDropdown';
import ExceptionConfirmationForm
    from 'src/widgets/IssueSummaryWidget/components/widgets/ReplyCase/components/composer/ExceptionConfirmationForm/ExceptionConfirmationForm';
import AtlasLabellingDropdown
    from "src/widgets/IssueSummaryWidget/components/widgets/ReplyCase/components/composer/AtlasLabellingDropdown/AtlasLabellingDropdown";
import InputGroup
    from "src/widgets/IssueSummaryWidget/components/widgets/ReplyCase/components/composer/InputGroup/InputGroup";

import {SchemaCaseElement} from 'src/widgets/IssueSummaryWidget/model/schemaElementsData'
import {Attachment} from 'src/widgets/IssueSummaryWidget/components/widgets/ReplyCase/model/attachment';
import {clickStreamType, metricNames} from 'src/widgets/IssueSummaryWidget/components/widgets/ReplyCase/model/clickStreamMetric';
import {ReplyTranslationConfig, CasePropertyItem, ErrorData, ReasonCategory} from 'src/widgets/IssueSummaryWidget/components/widgets/ReplyCase/model/replyCaseSchemaElements';

import {DEV_LINK_VALIDATION, getValidation, POTTY_WORD_VALIDATION, REQUIRED_RULE} from 'src/common/rules/rules';

import $style from './composer-view.module.scss';
import {ReplyHandlerContext} from "src/widgets/IssueSummaryWidget/components/widgets/ReplyCase/providers/replyHandler.context";
import {ServicesAPIContext} from "src/service/service.context";
import {logClickStreamData} from "src/common/utils";
import CaseStatusDropdownWithDCP from './components/composer/CaseStatusDropdown/CaseStatusDropdownWithDCP';


interface Params {
    signatureContentIds: string[];
    metadata: Record<string, string>;
    attachments: Attachment[];
    additionalInfoContent: string;
    caseStatus: string;
    subject: string;
    bodyContent: string;
    newCaseStatus: string;
    bodyContentIds: string[];
    locale: string;
    bccEmail: string[];
    caseProperties: CasePropertyItem[];
    merchantId: string;
    caseId: string;
    additionalInfoContentIds: string[];
    queueBlurbBaseName: string;
    toEmail: string[];
    previousEmailCorrespondence: string;
    pmaReason: string;
    caseSubStatus: string;
    headerContentIds: string[];
    ccEmail: string[];
    replyTranslationConfig?: ReplyTranslationConfig;
    creationDate: string;
    signatureContent: string;
    marketplaceId: string;
    headerContent: string;
    footerContentIds: string[];
    footerContent: string;
    caseResolutionCategoryId?: string;
    caseResolutionReasonId?: string;
}

interface ComposerViewProps {
    isIssueSummaryEnabled: boolean;
    caseId: string;
    marketplaceId: string;
    focus?: boolean;
    lastEscalationEventSce?: Partial<SchemaCaseElement>;
    showExceptionForm?: boolean;
    showdcpForm?: boolean;
    validateDevLinks?: boolean;
    requestFocus: () => void;
    showNextStep: (params: Params) => void;
    discardChanges: () => void;
    showError: (data) => void;
}

const defaults: ComposerViewProps = {
    isIssueSummaryEnabled: false,
    caseId: '',
    marketplaceId: '',
    showExceptionForm: false,
    showdcpForm: false,
    validateDevLinks: false,
    requestFocus: () => {},
    showNextStep: () => {},
    discardChanges: () => {},
    showError: () => {}
}

export default function ComposerView(props: ComposerViewProps = defaults) {
    const [locale, setLocale] = useState('');
    const [caseStatus, setCaseStatus] = useState('');
    const [newCaseStatus, setNewCaseStatus] = useState('');
    const [caseSubStatus, setCaseSubStatus] = useState('');
    const [ccList, setCcList] = useState<string[]>([]);
    const [bccList, setBccList] = useState<string[]>([]);
    const [toList, setToList] = useState<string[]>([]);
    const [emailSubject, setEmailSubject] = useState('');
    const [merchantId, setMerchantId] = useState('');
    const [composerMetadata, setComposerMetadata] = useState<Record<string, string>>({});
    const [caseStatusDropdownMetadata, setCaseStatusDropdownMetadata] = useState<Record<string, boolean>>({});
    const [headerContent] = useState('');
    const [bodyContent, setBodyContent] = useState(loadCachedBodyContent());
    const [additionalInfoContent] = useState('');
    const [signatureContent, setSignatureContent] = useState('');
    const [dcpFormCheck, setDcpFormCheck] = useState(true);

    // the original content that was loaded via ajax; necessary for when the user discards their custom signature.
    const [originalSignatureContent, setOriginalSignatureContent] = useState('');

    const [footerContent] = useState('');
    const [creationDate, setCreationDate] = useState('');
    const [queueBlurbBaseName, setQueueBlurbBaseName] = useState('');
    const [previousEmailCorrespondence, setPreviousEmailCorrespondence] = useState('');
    const [caseProperties, setCaseProperties] = useState<CasePropertyItem[]>([]);
    const [attachments, setAttachments] = useState<Attachment[]>([]);
    const [headerContentIds] = useState<string[]>([]);
    const [bodyContentIds] = useState([]);
    const [additionalInfoContentIds] = useState([]);
    const [signatureContentIds] = useState([]);
    const [footerContentIds] = useState([]);
    const [caseStatusList, setCaseStatusList] = useState<{ label: string, value: string }[]>([]);
    const [caseSubStatusList, setCaseSubStatusList] = useState<string[]>([]);
    const [reasonCategoryList, setReasonCategoryList] = useState<ReasonCategory[]>([]);
    const [whyContactedDisplayed, setWhyContactedDisplayed] = useState(false);
    const [isWhyContactedIncomplete, setIsWhyContactedIncomplete] = useState(false);
    const [isBodyContentValid, setIsBodyContentValid] = useState(false);
    // default isSignatureContentValid to true, since a) defaultSignature should be valid on page load, and b) getValidationContext
    // is only called when the signature textarea is mounted (thus, the user would have to toggle in order to validate).
    const [isSignatureContentValid, setIsSignatureContentValid] = useState(true);
    const [shouldShowCcList, setShouldShowCcList] = useState(false);
    const [shouldShowBccList, setShouldShowBccList] = useState(false);
    const [shouldShowSignatureComponent, setShouldShowSignatureComponent] = useState(false);
    const [shouldShowComposer, setShouldShowComposer] = useState(false);
    const [cannotReply, setCannotReply] = useState(false);
    const [shouldShowPreviousEmailCorrespondence, setShouldShowPreviousEmailCorrespondence] = useState(false);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [failedRuleValidationMessages] = useState<Record<string, string[]>>({});
    const [errorMessage, setErrorMessage] = useState('');
    const [caseResolutionCategoryId, setCaseResolutionCategoryId] = useState<string|undefined>(undefined);
    const [caseResolutionReasonId, setCaseResolutionReasonId] = useState<string|undefined>(undefined);
    const [tenantId, setTenantId] = useState(0);
    const [replyTranslationConfig, setReplyTranslationConfig] = useState<ReplyTranslationConfig | undefined>(undefined);
    const [pmaReason, setPmaReason] = useState('');
    const [showPmaReasons, setShowPmaReasons] = useState(false);
    const [checkingPmaFriction, setCheckingPmaFriction] = useState(false);
    const [pmaHardFriction, setPmaHardFriction] = useState(false);
    const [isExceptionFormDisabled, setIsExceptionFormDisabled] = useState(false);

    const [promptOptions, setPromptOptions] = useState<PromptOptions>({
        title: '',
        message: '',
        confirm: () => {},
        discard: () => {},
        visible: false
    })

    const methods = useForm<Inputs>({ mode: 'all' });


    const serviceAPIContext = useContext(ServicesAPIContext);
    const replyHandlerContext = useContext(ReplyHandlerContext);

    const { translate } = localization();
    const el = useParagonContextConsumerEl();
    const { methods: clickStreamFunctions } = useParagonContext(el, paragonAnalyticsContext);

    const requiredRuleName = 'required';

    const onSubmit = () => {
        transition()
    }

    useEffect(() => {
        if (props.caseId && props.marketplaceId) {
            getReplyToCaseData();
        }
    }, [props.caseId, props.marketplaceId]);

    useEffect(() => {
        if (locale) {
            getReplyToCaseStatusData();
        }
    }, [locale]);

    useEffect(() => {
        const storageKey = 'bodyContent'.concat(props.caseId)
        localStorage.setItem(storageKey, bodyContent)
    }, [bodyContent]);

    useEffect(() => {
        if (!shouldShowSignatureComponent && originalSignatureContent !== '') {
            setSignatureContent(originalSignatureContent);
        }
    }, [shouldShowSignatureComponent]);

    useEffect(() => {
        setCannotReply(caseStatus === 'Unassigned')
        setShouldShowComposer(!!caseStatus && caseStatus !== 'Unassigned')
    }, [caseStatus]);

    function shouldDisableReview(): boolean {
        return toList.length === 0 || !emailSubject || !isBodyContentValid || !isSignatureContentValid || !signatureContent
            || isWhyContactedIncomplete || (shouldShowReasonCategories() && newCaseStatus === 'Resolved'
                &&  (!caseResolutionReasonId || !caseResolutionCategoryId)) || (newCaseStatus === 'Pending Merchant Action' && checkingPmaFriction)
            || isExceptionFormDisabled
            || (newCaseStatus === 'Pending Merchant Action' && showPmaReasons && pmaReason === '') || (newCaseStatus === 'Pending Merchant Action' && pmaHardFriction)
    }

    function shouldShowFollowupWork(): boolean {
        return props.isIssueSummaryEnabled && (newCaseStatus === 'Pending Merchant Action' || newCaseStatus === 'Pending Amazon Action');
    }

    function customSignatureToggleMessage(): string {
        return shouldShowSignatureComponent ? 'Paragon_TAM_CM_Discard_Custom_Signature' : 'Paragon_TAM_CM_Custom_Signature'
    }

    function previousEmailCorrespondenceToggleMessage(): string {
        return shouldShowPreviousEmailCorrespondence
            ? 'Paragon_TAM_CM_Hide_Previous_Email_Correspondence_Signature'
            : 'Paragon_TAM_CM_Show_Previous_Email_Correspondence_Signature'
    }

    function shouldShowSubmitError(): boolean {
        return shouldDisableReview() && errorMessage === translate('Paragon_TAM_CM_Invalid_Input_Parameters');
    }

    function shouldShowReasonCategories(): boolean {
        return (caseStatus !== 'Resolved' || newCaseStatus !== 'Resolved') && reasonCategoryList && reasonCategoryList.length > 0
    }

    function shouldShowExceptionForm(): boolean {
        // Only show exception form if:
        // 1. The pageConfig has the exceptionForm in the widget state
        // 2. The case is an exception
        // 3. if the current case status is not resolved
        // 4. if the user is resolving the case.
        return (props.showExceptionForm && isException() && caseStatus !== 'Resolved' && newCaseStatus === 'Resolved') as boolean;
    }

    function isException(): boolean {
        const lastEscalationEvent = props.lastEscalationEventSce
        && !!props.lastEscalationEventSce.data
            ? JSON.parse(props.lastEscalationEventSce.data)
            : undefined
        return lastEscalationEvent?.escalationType == 'EXCEPTION'
    }

    function emailBodyComponentRules(): object {
        return props.validateDevLinks
            ? { ...REQUIRED_RULE(translate), validate: getValidation(translate, [POTTY_WORD_VALIDATION, DEV_LINK_VALIDATION]) }
            : { ...REQUIRED_RULE(translate), validate: getValidation(translate, [POTTY_WORD_VALIDATION]) }
    }

    function enableCaseSubStatus(): boolean {
        if (caseStatusDropdownMetadata && caseStatusDropdownMetadata['PaaSubStatusEnabled']
            && caseSubStatusList && caseSubStatusList.length > 0 && newCaseStatus === 'Pending Amazon Action') {
            return true
        }
        return false
    }

    function caseSubStatusOptions(): { name: string, value: string }[] {
        const options: { name: string, value: string }[] = [];
        if (caseSubStatusList && caseSubStatusList.length > 0) {
            caseSubStatusList.forEach(subStatus => {
                options.push({
                    name: subStatus,
                    value: subStatus
                })
            })
        }
        if (!isCurrentCaseSubStatusEmpty() && !isCurrentCaseSubStatusExistsInCaseStatusesList()) {
            options.push({
                name: caseSubStatus,
                value: caseSubStatus
            })
        }
        if (isCurrentCaseSubStatusEmpty() && caseSubStatusList && caseSubStatusList.length > 0) {
            setCaseSubStatus(options[0]?.name || '');
        }
        return options;
    }

    function isCurrentCaseSubStatusEmpty(): boolean {
        return !caseSubStatus || caseSubStatus.length === 0;
    }

    function isCurrentCaseSubStatusExistsInCaseStatusesList(): boolean {
        if (caseSubStatusList) {
            for (let i = 0; i < caseSubStatusList.length; i++) {
                if (caseSubStatusList[i] === caseSubStatus) {
                    return true;
                }
            }
        }
        return false;
    }

    function onRequestFocus() {
        props.requestFocus();
    }

    function getReplyToCaseData() {
        setIsLoadingData(true);

        const params = {
            caseId: props.caseId,
            marketplaceId: props.marketplaceId
        }

        serviceAPIContext.paragonService.viewCaseComposer(params).then(response => {
            if (response && response.viewCaseComposerData) {
                const data = response.viewCaseComposerData;

                if (data.output) {
                    const output = data.output;
                    if (output.ccList) {
                        setCcList(output.ccList.split(new RegExp(/[;,]/, 'gm')).map(item => item.trim()));
                        setShouldShowCcList(true);
                    }
                    if (output.defaultSignature) {
                        setOriginalSignatureContent(output.defaultSignature);
                        setSignatureContent(output.defaultSignature);
                    } else {
                        setShouldShowSignatureComponent(true);
                    }
                    if (output.primaryEmail) {
                        setToList(output.primaryEmail.split(new RegExp(/[;,]/, 'gm')).map(item => item.trim()));
                    }
                    setEmailSubject(output.subject);
                    setMerchantId(output.merchantId);
                    setComposerMetadata(output.customComponentData);
                    setCaseStatus(output.caseStatus);
                    setNewCaseStatus(output.caseStatus);
                    setCreationDate(output.creationDate.toString());
                    setQueueBlurbBaseName(output.queueBlurbBaseName as string);
                    setReplyTranslationConfig(output.replyTranslationConfig);
                    if (output.previousEmailCorrespondence) {
                        setPreviousEmailCorrespondence(output.previousEmailCorrespondence);
                    }
                    if (output.caseProperties) {
                        setCaseProperties(output.caseProperties);
                    }
                }
                if (data.locale) {
                    setLocale(data.locale);
                }
                if (data.tenantId) {
                    setTenantId(data.tenantId);
                }
            }
            setErrorMessage('');
        }).catch(error => {
            const errorData: ErrorData = {};

            if (error && error.response) {
                errorData.statusCode = error.response.status;
                errorData.message = error.response.data;
                props.showError?.(errorData);
            } else if (error) {
                errorData.message = error;
                props.showError?.(errorData);
            } else {
                errorData.statusCode = 500;
                errorData.message =  'Paragon_TAM_CM_An_error_occurred_please_try_again_later';
                props.showError?.(errorData);
            }
        }).finally(() => {
            setIsLoadingData(false);
        });
    }

    function getReplyToCaseStatusData(): void {
        setIsLoadingData(true);

        const params = {
            caseId: props.caseId,
            locale: locale
        };

        serviceAPIContext.paragonService.getReplyCaseStatusDropdown(params).then(response => {
            if (response && response.replyCaseStatusDropdownData) {
                const data = response.replyCaseStatusDropdownData;
                setCaseStatusDropdownMetadata(data.fieldMap);

                if (data.caseSubStatusList) {
                    setCaseSubStatusList(data.caseSubStatusList.map(subStatusItem => subStatusItem.caseSubStatusName));
                }

                if (data.caseSubStatus) {
                    setCaseStatus(data.caseSubStatus);
                }

                if (data.caseStatusList) {
                    setCaseStatusList(data.caseStatusList.map(statusItem => ({
                        label : statusItem.title,
                        value : statusItem.title
                    })))
                }

                if (data.currentCaseStatusItem) {
                    setCaseStatus(data.currentCaseStatusItem.title)
                }

                setReasonCategoryList(data.reasonCategoryList)
                if (data.selectedCategory && data.selectedCategory.id) {
                    setCaseResolutionCategoryId(data.selectedCategory.id.toString());
                } else if (data.reasonCategoryList && data.reasonCategoryList[0] && data.reasonCategoryList[0].id) {
                    setCaseResolutionCategoryId(data.reasonCategoryList[0].id.toString());
                }
                if (data.selectedReason && data.selectedReason.id) {
                    setCaseResolutionReasonId(data.selectedReason.id.toString());
                }
            }
            setErrorMessage('')
        }).catch(error => {
            const errorData: ErrorData = {};

            if (error && error.response) {
                errorData.statusCode = error.response.status;
                errorData.message = error.response.data;
                props.showError?.(errorData);
            } else {
                errorData.statusCode = 500;
                errorData.message =  'Unexpected Error Occurred. Please Try Again...';
                props.showError?.(errorData);
            }
        }).finally(() => {
            setIsLoadingData(false);
        });
    }
    function transitionToReview() {
        const params: Params = {
            caseId: props.caseId,
            marketplaceId: props.marketplaceId,
            headerContent: headerContent,
            bodyContent: bodyContent,
            additionalInfoContent: additionalInfoContent,
            signatureContent: signatureContent,
            footerContent: footerContent,
            headerContentIds: headerContentIds,
            bodyContentIds: bodyContentIds,
            additionalInfoContentIds: additionalInfoContentIds,
            signatureContentIds: signatureContentIds,
            footerContentIds: footerContentIds,
            caseStatus: caseStatus,
            newCaseStatus: newCaseStatus,
            queueBlurbBaseName: queueBlurbBaseName,
            creationDate: creationDate,
            locale: locale,
            caseSubStatus: caseSubStatus,
            merchantId: merchantId,
            toEmail: toList,
            ccEmail: ccList,
            bccEmail: bccList,
            subject: emailSubject,
            attachments: attachments,
            caseProperties: caseProperties,
            previousEmailCorrespondence: previousEmailCorrespondence,
            metadata: composerMetadata,
            replyTranslationConfig: replyTranslationConfig,
            pmaReason: pmaReason
        }
        if (shouldShowReasonCategories()) {
            params.caseResolutionCategoryId = caseResolutionCategoryId
            params.caseResolutionReasonId = caseResolutionReasonId
        }
        props.showNextStep(params);
    }

    function transition() {
        if (shouldDisableReview()) {
            setErrorMessage(translate('Paragon_TAM_CM_Invalid_Input_Parameters') as string);
            return;
        } else if (errorMessage === translate('Paragon_TAM_CM_Invalid_Input_Parameters')) {
            setErrorMessage('');
        }

        const shouldDisplayPrompt = Object.values(failedRuleValidationMessages).some(x => x.length > 0)
        if (shouldDisplayPrompt) { // if there are warnings to show, display them in a modal before transitioning.
            const popupMessage = buildPopupWarningMessage(failedRuleValidationMessages);

            const promptOptions = {
                title: translate('Paragon_TAM_CM_Advance_Reply_Popup_Title') as string,
                message: popupMessage,
                confirm: () => {
                    transitionToReview();
                    logCSMTransitionWithRuleViolations(failedRuleValidationMessages);
                },
                discard: () => {
                },
                visible: true
            }

            setPromptOptions(promptOptions);
        }  else { // else transition to review.
            transitionToReview();
        }
    }

    function handleSignatureComponentValidation(validationOutput) {
        const failedRules = validationOutput.failedRules;
        storeWarningMessages('Signature:', failedRules); // rules that display warning messages
        setIsSignatureContentValid(!failedRules?.['required']); // rules that disable reply
    }

    function handleBodyComponentValidation(validationOutput) {
        const failedRules = {};
        failedRules[validationOutput.failedRule] = validationOutput.failedRuleMessage;

        storeWarningMessages('Correspondence:', failedRules); // rules that display warning messages

        const hasFailedRuleIsRequire = Object.prototype.hasOwnProperty.call(failedRules, requiredRuleName);

        setIsBodyContentValid(!hasFailedRuleIsRequire); // rules that disable reply
    }

    function storeWarningMessages(componentName, failedRules: Record<string, string>) {
        const failedRuleMessages: string[] = [];
        if (failedRules) {
            for (const [, ruleMessage] of Object.entries(failedRules)) {
                if (ruleMessage) {
                    failedRuleMessages.push(ruleMessage)
                }
            }
        }
        failedRuleValidationMessages[componentName] = failedRuleMessages // only put stuff in if there are failed rules.
    }

    function buildPopupWarningMessage(failedValidationRules: Record<string, string[]>) {
        const popupMessage = [translate('Paragon_TAM_CM_Advance_Reply_Popup_Warning'), '\n'];
        for (const [, messages] of Object.entries(failedValidationRules)) {
            if (messages.length > 0) {
                messages.forEach((message) => popupMessage.push('\n', message));
            }
        }
        return popupMessage.join('');
    }

    function logCSMTransitionWithRuleViolations(failedRules) {
        logClickStreamData(clickStreamFunctions, 'click', metricNames.composerView.transitionedToReviewWithRuleViolationsClicked, {
            tenantId: tenantId,
            caseId: props.caseId,
            failedRules: failedRules
        });

        //analyticsMethods.logData(clickStreamData);
    }

    function toggleBccList() {
        setShouldShowBccList(!shouldShowBccList);
    }

    function toggleCcList() {
        setShouldShowCcList(!shouldShowCcList);
    }

    function toggleSignature() {
        setShouldShowSignatureComponent(!shouldShowSignatureComponent);
    }

    function toggleShouldShowPreviousEmailCorrespondence() {
        setShouldShowPreviousEmailCorrespondence(!shouldShowPreviousEmailCorrespondence);
    }

    function loadCachedBodyContent() {
        const storageKey = 'bodyContent'.concat(props.caseId)
        const cachedBodyContent = localStorage.getItem(storageKey);
        return cachedBodyContent ? cachedBodyContent : '';
    }

    function clearCachedBodyContent() {
        const storageKey = 'bodyContent'.concat(props.caseId)
        if (localStorage.getItem(storageKey)) {
            localStorage.removeItem(storageKey)
        }
    }

    function cancelAndDiscardWithPrompt() {
        const promptOptions = {
            title: translate('Paragon_TAM_CM_Do_You_Want_To_Discard') as string,
            message: translate('Paragon_TAM_CM_Confirm_Reply_Discard') as string,
            confirm: () => {
                cancelAndDiscard();
            },
            discard: () => {
            },
            visible: true
        }

        setPromptOptions(promptOptions);
    }

    function cancelAndDiscard() {
        paragonMediator.notify('paragon-reply:toggle-button-unselected', {});
        setBodyContent('');
        setAttachments([]);
        clearCachedBodyContent();
        replyHandlerContext.handleClearBodyContent();
        props.discardChanges();
    }

    function updatePmaReason(val) {
        setPmaReason(val);
    }

    function updateShowPmaReasons(val) {
        setShowPmaReasons(val);
    }

    function updateCheckingPmaFriction(val) {
        setCheckingPmaFriction(val);
    }

    function updatePmaHardFriction(val) {
        setPmaHardFriction(val);
    }

    function reviewDisableCheck(){
        return newCaseStatus === 'Resolved' && dcpFormCheck && props.showdcpForm;
    }

    const composerElement = <KatCard className={$style.noBorderKatCard} no-body-padding>
        <h3 className={$style.replyHeader}> {translate('Paragon_TAM_CM_Reply')}</h3>
        <KatDivider variant="eastern" />
        {!props.showdcpForm && 
        <CaseStatusDropdown
            caseStatusList={caseStatusList}
            caseStatus={caseStatus}
            shouldShowFollowupWork={shouldShowFollowupWork()}
            caseId={props.caseId}
            handleChangeNewCaseStatus={(value) => setNewCaseStatus(value)}
            handleChangePmaReason={(value) => updatePmaReason(value)}
            handleChangeShowPmaReasons={(value) => updateShowPmaReasons(value)}
            handleChangeCheckingPmaFriction={(value) => updateCheckingPmaFriction(value)}
            handleChangePmaHardFriction={(value) => updatePmaHardFriction(value)}
        />}

        {props.showdcpForm && 
        <CaseStatusDropdownWithDCP
            caseStatusList={caseStatusList}
            caseStatus={caseStatus}
            shouldShowFollowupWork={shouldShowFollowupWork()}
            caseId={props.caseId}
            dcpFormCheck= {dcpFormCheck}
            changeDmpFormCheck={(value) => setDcpFormCheck(value)}
            handleChangeNewCaseStatus={(value) => setNewCaseStatus(value)}
            handleChangePmaReason={(value) => updatePmaReason(value)}
            handleChangeShowPmaReasons={(value) => updateShowPmaReasons(value)}
            handleChangeCheckingPmaFriction={(value) => updateCheckingPmaFriction(value)}
            handleChangePmaHardFriction={(value) => updatePmaHardFriction(value)}
        />}

        {enableCaseSubStatus() &&
        <div id="case-substatus-dropdown">
            <KatDivider variant="eastern" />
            <Dropdown
                handleChange={(event) => setCaseSubStatus(event.detail.value)}
                defaultValue={caseSubStatus}
                dropdownOptions={caseSubStatusOptions()}/>
        </div>
        }

        {shouldShowExceptionForm() && <ExceptionConfirmationForm caseId={props.caseId}
                                                                 shouldDisableSubmit={(shouldDisableSubmit) => setIsExceptionFormDisabled(shouldDisableSubmit)}/>
        }

        {(whyContactedDisplayed || shouldShowReasonCategories()) &&
        <hr className={$style.styledHr}/>
        }

        {!shouldShowReasonCategories() &&
        <AtlasLabellingDropdown
            className={$style.atlasLabelDropdownMargin}
            caseId={props.caseId}
            newCaseStatus={newCaseStatus}
            isWhyContactedIncomplete={(value) => setIsWhyContactedIncomplete(value)}
            showWhyContactModal={(value) => setWhyContactedDisplayed(value)}
            isAtlasFeedbackEnabled={caseStatusDropdownMetadata['isAtlasFeedbackEnabled']}
            shouldShowReasonCategories={shouldShowReasonCategories()}/>
        }

        {shouldShowReasonCategories() &&
        <AtlasLabellingDropdown
            caseId={props.caseId}
            newCaseStatus={newCaseStatus}
            isWhyContactedIncomplete={(value) => setIsWhyContactedIncomplete(value)}
            showWhyContactModal={(value) => setWhyContactedDisplayed(value)}
            isAtlasFeedbackEnabled={caseStatusDropdownMetadata['isAtlasFeedbackEnabled']}
            shouldShowReasonCategories={shouldShowReasonCategories()}/>
        }

        {shouldShowReasonCategories() &&
        <ReasonCategoryDropdown
            reasonCategoryList={reasonCategoryList}
            caseResolutionCategoryId={caseResolutionCategoryId}
            caseResolutionReasonId={caseResolutionReasonId}
            updateCaseResolutionReasonId={(value) => setCaseResolutionReasonId(value)}
            updateCaseResolutionCategoryId={(value) => setCaseResolutionCategoryId(value)}/>
        }

        <div className={$style.outboundMessageContainer}>
            <div className={$style.outboundMessageHeader}>
                <span className={$style.outboundMessageHeaderText}> <KatLabel text={translate('Paragon_TAM_CM_Outbound_Message')} /> </span>
                {(composerMetadata['isFinalWordEnabled'] === 'true' && newCaseStatus === 'Resolved') &&
                <span className="float-right">
                    <FinalWordVerifier updateCaseSubStatus={(value) => setCaseSubStatus(value)}
                                 caseId={props.caseId}
                                 tenantId={tenantId}
                                 showError={(errorData) => props.showError(errorData)}/>
                 </span>
                }
            </div>

            {composerMetadata['isPrimaryEmailOrCcListInvalid'] === 'true' && <KatAlert header={translate('Paragon_TAM_CM_Invalid_Email_Warning')} variant="warning"/>}
            {composerMetadata['shouldDisplayAuxEmailFields'] === 'true' &&
            <div>
                {/*TODO: Katal doesn't offer any direct substitutes for input-group-tags, so just use input-group-tags for now.*/}
                <InputGroupTags metadata={composerMetadata}
                                caseId={props.caseId}
                                input={toList}
                                updateInput={(input) => setToList(input)}
                                fieldNameDisplay={'To'}
                                shouldShowCcList={shouldShowCcList}
                                shouldShowBccList={shouldShowBccList}
                                isToFormInput={true}
                                placeholderText={translate('Paragon_TAM_CM_Email_Entry_Placeholder')}
                                isDisabled={composerMetadata.shouldDisableToCCAndBCCFields === 'true'}
                                toggleCcList={toggleCcList}
                                toggleBccList={toggleBccList}/>
                {shouldShowCcList &&
                <InputGroupTags input={ccList}
                                updateInput={(input) => setCcList(input)}
                                fieldNameDisplay={'Cc'}
                                isDisabled={composerMetadata.shouldDisableToCCAndBCCFields === 'true'}
                                placeholderText={translate('Paragon_TAM_CM_Email_Entry_Placeholder')}/>
                }
                {shouldShowBccList &&
                <InputGroupTags input={bccList}
                                updateInput={(input) => setBccList(input)}
                                fieldNameDisplay={'Bcc'}
                                isDisabled={composerMetadata.shouldDisableToCCAndBCCFields === 'true'}
                                placeholderText={translate('Paragon_TAM_CM_Email_Entry_Placeholder')}/>
                }
                <InputGroup updateInput={(value) => setEmailSubject(value)}
                                  fieldNameDisplay={'Subject'}
                                  rules="required"
                                  placeholderText={translate('Paragon_TAM_CM_This_Field_Is_Required')} />
            </div>
            }

            <EmailBodyComponent isValid={handleBodyComponentValidation}
                                className={$style.mainEmailBodyComponent}
                                    caseId={props.caseId}
                                    content={bodyContent}
                                    updateContent={(content) => setBodyContent(content)}
                                    contentIds={bodyContentIds}
                                    placeholderText={translate('Paragon_TAM_CM_Email_Body_Component_Placeholder')}
                                    rules={emailBodyComponentRules()}
                                    type="body"
                                    tenantId={tenantId}
                                    requestFocus={onRequestFocus}
                                    name={ContentType.BODY_CONTENT}
                                    focus={props.focus}/>

            {shouldShowSignatureComponent &&
            <EmailBodyComponent isValid={handleSignatureComponentValidation}
                                caseId={props.caseId}
                                content={signatureContent}
                                updateContent={(content) => setSignatureContent(content)}
                                contentIds={signatureContentIds}
                                placeholderText={translate('Paragon_TAM_CM_Signature_Component_Placeholder')}
                                rules={{ ...REQUIRED_RULE, validate: getValidation(translate, [POTTY_WORD_VALIDATION]) }}
                                header="Signature"
                                tenantId={tenantId}
                                name={ContentType.SIGNATURE_CONTENT}/>
            }

            {shouldShowPreviousEmailCorrespondence &&
            <EmailBodyComponent caseId={props.caseId}
                                content={previousEmailCorrespondence}
                                updateContent={(content) => setPreviousEmailCorrespondence(content)}
                                header={translate('Paragon_TAM_CM_Show_Previous_Email_Correspondence_Signature')}
                                tenantId={tenantId}
                                name={ContentType.PREVIOUS_EMAIL_CONTENT}/>
            }

            <div>
                <KatButton className={$style.emailKatButton} label={translate(customSignatureToggleMessage())}
                                variant="secondary"
                                size="small"
                                onClick={toggleSignature} />
                {composerMetadata['isEditPreviousEmailCorrespondenceEnabled'] === 'true' &&
                <KatButton className={$style.emailKatButton} label={translate(previousEmailCorrespondenceToggleMessage())}
                                variant="secondary"
                                size="small"
                                onClick={toggleShouldShowPreviousEmailCorrespondence} />
                }
            </div>
        </div>

        <AttachmentModule caseId={props.caseId} updateAttachments={(value) => setAttachments(value)}/>
        {shouldShowSubmitError() &&
        <KatFlashbar description={errorMessage} variant={'danger'} onDismissed={() => setErrorMessage('')} header={''}/>
        }

        <PromptDialog options={promptOptions}/>

        <div className='transitionButtonGroup float-left'>
            <KatButton className={$style.transitionButton} data-testid="katal-review-button"
                              label={translate('Paragon_TAM_CM_Review')}
                              type="submit"
                              variant="primary"
                              disabled={reviewDisableCheck()}
                              data-csm-name="reply-composer-review"/>
            <KatButton className={$style.transitionButton} data-testid="katal-cancel-and-discard-button"
                              label={translate('Paragon_TAM_CM_Cancel_And_Discard')}
                              variant="secondary"
                              data-csm-name="reply-composer-discard"
                              onClick={cancelAndDiscardWithPrompt} />
        </div>
    </KatCard>;

    return (
        <KatBox className={$style.overrideKatBox} variant="ltgrey">
            <FormProvider {...methods} >
                <form onSubmit={(event) => { event.preventDefault(); onSubmit(); }}>
                    <div className="d-flex justify-content-center">
                        {isLoadingData && <KatSpinner variant="default" size="small" />}
                    </div>
                    {cannotReply && <KatCard className="border-0 reply-composer-view">{translate('Paragon_TAM_CM_reply_to_unassigned_case_warning')}</KatCard>}
                    {shouldShowComposer && composerElement}
                </form>
            </FormProvider>
        </KatBox>
    )
}