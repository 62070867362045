import { classMap } from 'lit-html/directives/class-map';
import { ifDefined } from 'lit-html/directives/if-defined';
import { nothing } from 'lit-html';
import { html, property } from 'lit-element';
import { KatLitElement, register } from '../../shared/base';
import { getElementByIdThroughShadow } from '../../shared/utils';
import {
  checkSlots,
  getSlotValues,
  cleanDefaultSlot,
} from '../../shared/slot-utils';

import './label.flo.theme.scss';
import baseStyles from '../../shared/base/base.lit.scss';
import styles from './label.lit.scss';

/**
 * @component {kat-label} KatalLabel A label gives the user additional context about another component, such as an input or form field. Many components have this label component built in to them already, but this component can also be used independently to add a label when one is not already included.
 * @status production
 * @slot default Contents will be used as the children of the kat-label element. Takes precedence over text and emphasis properties.
 * @example Basic {"text": "Password", "variant": "", "state":"", "emphasis":""}
 * @example Constraint {"text": "Passwords must contain one of each of the following: letter, number, symbol", "variant": "constraint", "state":""}
 * @example Constraint-Error {"text": "Passwords must contain one of each of the following: letter, number, symbol", "emphasis":"", "variant": "constraint", "state":"error"}
 * @example Constraint-With-Emphasis {"text": "Passwords must contain one of each of the following: letter, number, symbol", "variant": "constraint", "state":"", "emphasis": "Attention"}
 * @example Constraint-With-Emphasis-Error {"text": "Passwords must contain one of each of the following: letter, number, symbol", "emphasis":"Attention", "variant": "constraint", "state":"error"}
 * @a11y {keyboard}
 * @a11y {sr}
 * @a11y {contrast}
 */
@register('kat-label')
export class KatLabel extends KatLitElement {
  /**
   * This ID is given to the inner label used to implement label to component association.
   * Treat this like the id attribute of a regular HTML element.
   */
  @property({ attribute: 'unique-id' })
  uniqueId?: string;

  /** Text that shows before the main content of the label.  Gives addtional context to the message */
  @property()
  emphasis?: string;

  /** The text that will be displayed to the user */
  @property()
  text?: string;

  /**
   * The type of label displayed to a user
   * @enum {value} default Normal label used for things such as form inputs
   * @enum {value} constraint Used for providing additional context to a form input such as expected format
   */
  @property()
  variant = 'default';

  /**
   * The type of label displayed.
   * @enum {value} default This is a normal label with no state associated with it
   * @enum {value} error Used when the associated content is invalid and the User must take action to correct it.
   */
  @property()
  state?: string;

  /** The element to associate the label with */
  @property()
  for?: string;

  /**
   * The text to be shown inside the tooltip placed next to the label text. The tooltip will only appear if this is
   * set.
   */
  @property({ attribute: 'tooltip-text', reflect: false })
  tooltipText?: string;

  /** The position of the tooltip. Defaults to "top". */
  @property({ attribute: 'tooltip-position', reflect: false })
  tooltipPosition = 'top';

  /** The icon that triggers the tooltip next to the label. Defaults to "help_outline". */
  @property({ attribute: 'tooltip-trigger-icon', reflect: false })
  tooltipTriggerIcon = 'help_outline';

  private _slotChanged: boolean;

  static get styles() {
    return [baseStyles, styles];
  }

  constructor() {
    super();

    this._listeners = [
      [
        'click',
        () => {
          if (!this.for) return;

          // Focus the associated control
          const ele = getElementByIdThroughShadow(this, this.for);
          if (ele?.focus) {
            ele.focus();
          }
        },
      ],
    ];

    cleanDefaultSlot(this);

    this._slotChanged = false;
    this.observeChildren(() => {
      this._slotChanged = true;
      this.requestUpdate();
    });
  }

  updated(changedProperties) {
    if (
      changedProperties.has('text') ||
      changedProperties.has('emphasis') ||
      changedProperties.has('for') ||
      changedProperties.has('uniqueId') ||
      this._slotChanged
    ) {
      this._slotChanged = false;

      // The visually hidden label is placed in light DOM so other elements in this shadow root (or document) can associate to it.
      // Otherwise label association does not cross shadow DOM boundary.
      let slot = this.querySelector("span[slot='private-light-dom']");
      if (!slot) {
        slot = document.createElement('span');
        slot.setAttribute('slot', 'private-light-dom');
        this.appendChild(slot);
      }

      // We tested making the label the slot root but that doesn't work so it must be just inside the slot like this.
      let label = slot.getElementsByTagName('label')[0];
      if (!label) {
        label = document.createElement('label');
        slot.appendChild(label);
      }

      const slots = getSlotValues(this, ['default']) as any;
      if (slots.default) {
        const slot = slots.default;
        label.innerHTML =
          slot.nodeType === slot.TEXT_NODE ? slot.textContent : slot.innerHTML;
      } else {
        label.innerHTML =
          // this selector fails in browsers that don't support shadow dom, so
          // we fall back to constructing the inner html ourselves
          // https://i.amazon.com/issues/KAT-4478
          this._shadow('label slot')?.innerHTML ??
          `${this.emphasis || ''} ${this.text || ''}`;
      }
      if (this.uniqueId) {
        label.setAttribute('id', this.uniqueId);
      }
      if (this.for) {
        label.setAttribute('for', this.for);
      }

      // Also find the associated component and assign katAriaLabel on it.
      // This is to support label association crossing the shadow DOM boundary when both the label
      // and associated component are in separate shadow roots.
      const ele = getElementByIdThroughShadow(this, this.for) as any;
      if (ele && 'katAriaLabel' in ele) {
        ele.katAriaLabel = this.text ? this.text : label.innerText;
      }
    }
  }

  firstUpdated() {
    super.firstUpdated();
    const result = checkSlots(this);
    if (result.default || result.emphasis) {
      this._slotChanged = true;
    }
  }

  render() {
    const result = checkSlots(this);
    const emphasis = !isEmpty(this.emphasis)
      ? html`<b part="label-emphasis">${this.emphasis}</b>`
      : nothing;
    const text = !isEmpty(this.text)
      ? html`<span part="label-text">${this.text}</span>`
      : nothing;
    const classes = {
      hide: !result.default && text === nothing && emphasis === nothing,
    };

    const tooltip = this.tooltipText
      ? html`
          <kat-popover
            trigger-type="hover"
            variant="tooltip"
            position=${this.tooltipPosition}
          >
            <kat-icon
              slot="trigger"
              name=${this.tooltipTriggerIcon}
              size="tiny"
            ></kat-icon>
            ${this.tooltipText}
          </kat-popover>
        `
      : nothing;

    return html`
      <label class=${classMap(classes)} for=${ifDefined(this.for)}>
        <slot>${emphasis} ${text}</slot>${tooltip}
        <span class="private"> <slot name="private-light-dom"></slot></span>
      </label>
    `;
  }
}

/**
 * Returns true if the value is null, undefined, or empty string.
 */
function isEmpty(value) {
  return value == null || value === '';
}
