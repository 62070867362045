import { html, property } from 'lit-element';
import { KatLitElement, register } from '../../shared/base';
import getString from './get-string';

/**
 * @component {kat-tooltip} KatalTooltip Tooltips provide additional context or instructive information to the user on a hover action, usually to help them complete a task or to give them information about disabled UI elements.<br /> This component is deprecated, use <a href="/components/popover/">Popover component</a> instead.
 * @deprecated Use kat-popover with variant=tooltip and trigger-type=hover instead.
 * @guideline Do When defining where the tooltip should be displayed (top, bottom, right, left, from the trigger). Ensure it does not cover relevant information.
 * @guideline Do Use a tooltip for supplemental information, but not for essential or important information that a user needs to know to complete an action, such as the action of filling in a form correctly.
 * @guideline Do Keep tooltip text brief, ideally no longer than about 150 characters.
 * @guideline Dont Don’t use buttons in a tooltip or popover.
 * @example LeftonText {"label":"This is more information on the left","trigger-text":"More Info","position":"left", "trigger-icon":""}
 * @example RightonText {"label":"This is more information on the right","trigger-text":"More Info","position":"right", "trigger-icon":""}
 * @example RightonIcon {"label":"This is more information on the right","trigger-icon":"info","position":"right", "size":"large"}
 * @example SmallRightonIcon {"label":"This is more information on the right","trigger-icon":"info","position":"right", "size":"small"}
 * @status Production
 * @theme flo
 * @a11y {keyboard}
 * @a11y {sr}
 * @a11y {contrast}
 */
@register('kat-tooltip')
export class KatTooltip extends KatLitElement {
  /** The text to display in the tooltip */
  @property()
  label: string;

  /** The text label that triggers the popover being shown */
  @property({ attribute: 'trigger-text' })
  triggerText?: string;

  /** Alternative text to describe the tooltip to screen reader users. */
  @property({ attribute: 'kat-aria-trigger' })
  katAriaTrigger?: string;

  /** Alternative text for the label of the tooltip shown only to screen reader users. */
  @property({ attribute: 'kat-aria-label' })
  katAriaLabel?: string;

  /** Displays an icon instead of text to trigger popover.  This takes precedence if set */
  @property({ attribute: 'trigger-icon' })
  triggerIcon?: string;

  /**
   * Where to place the popover element. Default is top
   * @enum {value} top Places the hover text above the trigger
   * @enum {value} right Places the hover text to the right of the trigger
   * @enum {value} bottom Places the hover text below the trigger
   * @enum {value} left Places the hover text to the  left of the trigger
   */
  @property()
  position = 'top';

  /**
   * The size of icon to used when a trigger icon is specified.  Default is large
   * @enum {value} tiny Tiny
   * @enum {value} small Small
   * @enum {value} large Large
   */
  @property()
  size = 'large';

  // disable shadow-dom
  createRenderRoot() {
    return this;
  }

  render() {
    let trigger = null;
    const ariaDetails = this.katAriaLabel || this.label;
    if (!this.triggerIcon) {
      const ariaLabel = this.katAriaTrigger || this.triggerText;
      trigger = html`<kat-label
        role="note"
        aria-label=${ariaLabel ? `${ariaLabel}: ${ariaDetails}` : ariaDetails}
        .text=${this.triggerText}
        tabindex="0"
      ></kat-label>`;
    } else {
      const ariaTrigger = this.katAriaTrigger
        ? this.katAriaTrigger
        : getString('katal-tooltip-trigger-fallback');
      const ariaLabel = getString('katal-tooltip-aria-label', {
        trigger: ariaTrigger,
        details: ariaDetails,
      });
      trigger = html`<kat-icon
        role="note"
        name=${this.triggerIcon}
        size=${this.size}
        aria-label=${ariaLabel}
        tabindex="0"
      ></kat-icon>`;
    }
    return html`${trigger}
      <div class="kat-tooltip-arrow"></div>
      <div class="kat-tooltip-label">${this.label}</div>`;
  }
}
