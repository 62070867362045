import { html, property } from 'lit-element';
import { KatLitElement, register } from '../../shared/base';
import baseStyles from '../../shared/base/base.lit.scss';
import styles from './input-group.lit.scss';

/**
 * @component {kat-input-group} KatalInputgroup Input groups combine multiple input types, such as text inputs, static text, icons, and dropdowns, into one compound input.
 * @status Development
 * @guideline Do Use kat-input, kat-icon, and other elements inside the input group to build a control with currency, symbols, and a space for the user to type.
 * @guideline Do Be sure to set placeholder and type attributes on your kat-input providing hints for users and giving context for assistive devices.
 * @slot default Can accept a span, div, kat-input, kat-icon, kat-predictive-input, and kat-dropdown.
 * @example Currency {"content": "<kat-icon name=\"euro_symbol\"><\/kat-icon>\r\n<kat-input placeholder=\"123.45\" type=\"number\" kat-aria-label=\"Currency selection\"><\/kat-input>"}
 * @example PhoneNumber {"content": "<span id=\"inputGroupFlagSpan\"><kat-flag country=\"us\"><\/kat-flag><\/span>\r\n<span id=\"inputGroupCountryCodeSpan\">+1<\/span>\r\n<kat-input id=\"inputGroupTelephoneInput\" placeholder=\"2223334444\" type=\"tel\" kat-aria-label=\"Phone number\"><\/kat-input>\r\n<kat-icon id=\"inputGroupPhoneIcon\" name=\"phone\"><\/kat-icon>"}
 * @example Dropdown {"content": "<kat-input placeholder=\"name\" kat-aria-label=\"Name\"></kat-input><kat-dropdown kat-aria-label=\"Suffix\" options=\"[{ &quot;name&quot;: &quot;Jr&quot;,&quot;value&quot;: &quot;jrValue&quot;},{&quot;name&quot;: &quot;Sr&quot;,&quot;value&quot;: &quot;srValue&quot;},{&quot;name&quot;: &quot;II&quot;,&quot;value&quot;: &quot;IIValue&quot;}]\"></kat-dropdown>"}
 * @example Email {"content": "<kat-icon class=\"text-storm\" name=\"mail\"><\/kat-icon>\r\n<kat-input placeholder=\"username\" type=\"email\" kat-aria-label=\"username\"><\/kat-input>\r\n<span>@amazon.com<\/span>"}
 * @example Password {"content": "<kat-icon name=\"lock\"><\/kat-icon>\r\n<kat-input type=\"password\" kat-aria-label=\"Password\"><\/kat-input>"}
 * @a11y {keyboard}
 * @a11y {sr}
 * @a11y {contrast}
 */
@register('kat-input-group')
export class KatInputGroup extends KatLitElement {
  static get styles() {
    return [baseStyles, styles];
  }

  /**
   * The size of the selected option.
   * @enum {value} large Large input group box - Default
   * @enum {value} small Small input group box
   */
  @property()
  size?: 'large' | 'small' = 'large';

  setChildrenSizeAttr() {
    Array.from(this.children).forEach(child =>
      child.setAttribute('size', this.size)
    );
  }

  onSlotChange() {
    this.setChildrenSizeAttr();
  }

  render() {
    return html`<div class="inner">
      <slot @slotchange=${this.onSlotChange}></slot>
    </div>`;
  }
}
