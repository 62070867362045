import { WidgetFunctions } from '@amzn/paragon-context/lib/ParagonWidgetDataContext/models/WidgetFunctions';
import {
    HasPeekNowAcccessParams,
    HasPeekNowResponseData,
    InvokePeekNowParams,
    InvokePeekNowResponseData,
} from '../model/PeekNowTypes';

export async function invokePeekNow(
    params: InvokePeekNowParams,
    widgetMethods: WidgetFunctions,
) {
    return await widgetMethods.proxyPost<InvokePeekNowResponseData>(
        'invokePeekNow',
        params,
    );
}

export async function hasPeekNowAccess(
    params: HasPeekNowAcccessParams,
    widgetMethods: WidgetFunctions,
) {
    return await widgetMethods.proxyPost<HasPeekNowResponseData>(
        'hasPeekNowAccess',
        params,
    );
}
