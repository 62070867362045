import { KatButton, KatCard, KatIcon, KatPopover, KatTextarea } from "@amzn/katal-react";
import React, { useState, useRef } from 'react';
import $style from './response-generation.module.scss';
import { localization } from "src/helpers";
import { Loading } from "src/components/Loading/Loading";
import {useParagonContext, useParagonContextConsumerEl} from "@amzn/paragon-ui-react";
import {paragonWidgetDataContext} from "@amzn/paragon-context/lib/ParagonWidgetDataContext";
import {GenerateResponseInput, GenerateResponseOutput} from "src/widgets/ReplyWidget/replyWidgetModel";

type ResponseGenerationProps = {
    caseId: string
    updateContent: Function;
    responseError: (value: boolean) => void,
}

export default function ResponseGeneration(props: ResponseGenerationProps) {
    const [isLoading, setisLoading] = useState(false);
    const [summaryGenerated, setSummaryGenerated] = useState(false);
    const [content, setContent] = useState("");

    const el = useParagonContextConsumerEl();

    const { value: { isWidgetEditModeEnabled }, methods: widgetMethods } = useParagonContext(el, paragonWidgetDataContext);

    const { translate } = localization();
    let popoverRef: React.MutableRefObject<KatPopover.Element | null> = useRef(null);

    const getGeneratedResponse = () => {
        async function generateResponse() {
            setisLoading(true);
            const params: GenerateResponseInput = {
                caseId: props.caseId,
                actionsTaken: content,
                callingSystem: "LLM_REPLY_WIDGET"
            }

            widgetMethods.proxyPost<GenerateResponseOutput>('generateResponse', params)
                .then((response) => {
                    if (response.success) {
                        props.updateContent(response.data.generatedResponse);
                        setSummaryGenerated(true);
                        props.responseError(false);
                    } else {
                        props.responseError(true);
                    }
                })
                .catch((error) => {
                    props.responseError(true);
                })
                .finally(() => {
                    setisLoading(false);
                    popoverRef.current?.hide();
                });
        }
        generateResponse();
    }

    return (
        <KatPopover position="auto" ref={popoverRef} className={$style.styledPopover}>
            <a slot="trigger">
                <KatIcon name='lightbulb_outline' size="tiny" />
                {!summaryGenerated ?
                    translate('Paragon_LLM_ReplyCase_Insert_Reply_Placeholder_Text')
                    : translate('Paragon_LLM_ReplyCase_Replace_Reply_Placeholder_Text')}
            </a>

            <Loading isLoading={isLoading}/>

            <KatCard>
                <span slot="title" className={$style.alignRow}>
                    <div className={$style.styledIconContainer}>
                        <KatIcon name="lightbulb_outline" size="medium" />
                    </div>
                    {translate('Paragon_LLM_ReplyCase_Title')}
                </span>
                <KatTextarea className={$style.styledKatTextarea}
                    disabled={isLoading}
                    value={content}
                    onInput={(e) => setContent(e.currentTarget.value)}
                    placeholder={translate('Paragon_LLM_ReplyCase_Hint')}
                >
                </KatTextarea>
                <span> {translate('Paragon_LLM_ReplyCase_Description')} </span>
                <KatButton disabled={isLoading || !content} variant='secondary' className={$style.styledInsertButton}
                    onClick={getGeneratedResponse}>
                    {!summaryGenerated ? translate('Paragon_LLM_ReplyCase_Button_Action_Insert') : translate('Paragon_LLM_ReplyCase_Button_Action_Replace')}
                </KatButton>
            </KatCard>
        </KatPopover>
    )
}