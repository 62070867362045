import React, { FunctionComponent } from "react";
import { ControlSpacing } from "@amzn/paragon-ui-react/";
import { LLMIdentifiers, IdentifierMap } from "src/widgets/IssueSummaryWidget/model/schemaElementsData";
import { CopyLinkifyIdentifier } from "src/widgets/IssueSummaryWidget/components/common/LinkifiedIdentifier";
import { IssueSummaryAlert } from "src/widgets/IssueSummaryWidget/components/llm_issue_summary/IssueSummaryAlert";
import $style from "./llm-issue-summary.module.scss";
import { getParagonDomain } from "src/common/utils";
import { AnalyticsFunctions } from "@amzn/paragon-context/lib/ParagonAnalyticsContext/models/AnalyticsFunctions";
import {localization} from "src/helpers";

interface IssueSummaryIdentifiersProps {
    identifiers?: LLMIdentifiers,
    areIdentifiersEmpty: boolean,
    spotOrdersUrl?: string,
    spotASINUrl?: string,
    fbaShipmentsUrl?: string,
    clickStream: AnalyticsFunctions,
    agentLogin?: string,
    caseId?: string,
}

export const IssueSummaryIdentifiers: FunctionComponent<IssueSummaryIdentifiersProps> = ({
    identifiers,
    areIdentifiersEmpty,
    spotOrdersUrl,
    spotASINUrl,
    fbaShipmentsUrl,
    clickStream,
    agentLogin,
    caseId,
}) => {

    const domain: string = getParagonDomain(window.location.origin);

    const { translate } = localization();

    const getIdentifierUrl = (identifierType: string, value: string) => {
        const identifierUrlMap: { [key: string]: string | undefined } = {
            ASIN: spotASINUrl,
            ShipmentId: fbaShipmentsUrl,
            OrderId: spotOrdersUrl
        }
        if (!identifierUrlMap.hasOwnProperty(identifierType)) {
            return null;
        }
        const placeHolders: { [key: string]: string } = {
            ASIN: "asinPlaceholder",
            ShipmentId: "shipmentIdPlaceholder",
            OrderId: "orderIdPlaceholder"
        }
        const identifierUrl = identifierUrlMap[identifierType];
        const placeHolder = placeHolders[identifierType];
        return domain + identifierUrl?.replace(placeHolder, value);
    }

    return (
        <div>
            {areIdentifiersEmpty &&
                <IssueSummaryAlert variant="info"
                          headerValue={translate("Paragon_TAM_CM_No_Identifiers_Found_Header")}
                          descriptionValue={translate("Paragon_TAM_CM_No_Identifiers_Found_Message")}
                />
            }

            {!areIdentifiersEmpty &&
                <div>
                    {Object.entries(identifiers || {}).map(([key, values]) => (
                        <div key={key} className={$style.action}>
                            <div className={$style.identifierGrid}>

                                <strong>{IdentifierMap[key as keyof typeof IdentifierMap]}:</strong>

                                <CopyLinkifyIdentifier
                                    agentLogin={agentLogin} caseId={caseId}
                                    clickStreamEventBaseName={key}
                                    clickStream={clickStream}
                                    className={$style.copyAllIcon} label={translate('Paragon_TAM_CM_Copy_ALL')} elementId={values.join(" ")} isCopyAll={true} />

                                <div className={$style.valuesContainer}>
                                    {values.map((value: string, index: string) => (
                                        <CopyLinkifyIdentifier
                                            agentLogin={agentLogin} caseId={caseId}
                                            clickStreamEventBaseName={key + "-identifier"}
                                            clickStream={clickStream}
                                            elementId={value} hrefUrl={getIdentifierUrl(key, value) || undefined}  />
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            }
        </div>
    )
}