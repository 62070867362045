import {PostMessageSerializable} from "@amzn/dvr-event-bus-library/lib/ParagonContext/models";
import {ProxyPostRequestOptions, ProxyResponse} from "@amzn/paragon-context/lib/ParagonWidgetDataContext/models/Proxy";
import {StoreSuggestionFeedbackInput, StoreSuggestionInput, StoreSuggestionOutput}
    from "src/widgets/IssueSummaryWidget/components/widgets/ReplyCase/model/replyCaseSchemaElements";

export class TardisStorageService {

  private readonly proxyPost:  <T extends PostMessageSerializable>
    (actionName: string, params: Record<string, PostMessageSerializable>, options?: ProxyPostRequestOptions) => ProxyResponse<T>;

  constructor(widgetMethods) {
    this.proxyPost = widgetMethods.proxyPost;
  }

  async storeSuggestion(params: StoreSuggestionInput): Promise<{ error: boolean, storeSuggestionData?: StoreSuggestionOutput}> {

    const response =
      await this.proxyPost<StoreSuggestionOutput>(
        "StoreSuggestion",
        { ...params }
      );

    if (response.success) {
      return  { error: false, storeSuggestionData: response.data };
    } else {
      return  { error: true };
    }
  }

  async storeSuggestionFeedback(params: StoreSuggestionFeedbackInput): Promise<{ error: boolean, storeSuggestionFeedbackData?: StoreSuggestionOutput}> {

    const response =
      await this.proxyPost<StoreSuggestionOutput>(
        "StoreSuggestionFeedback",
        { ...params }
      );
    if (response.success) {
      return  { error: false, storeSuggestionFeedbackData: response.data };
    } else {
      return  { error: true };
    }
  }
}
