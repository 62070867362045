import { KatalComponent, register } from '../../shared/base';

@register('kat-table-body')
export class KatTableBody extends KatalComponent {
  static get observedAttributes() {
    return [];
  }

  constructor() {
    super(KatTableBody.observedAttributes);
  }

  connectedCallback() {
    super.connectedCallback();

    this.setAttribute('role', 'rowgroup');
  }
}
