import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import $style from './tardis-transfer.module.scss';
import {KatAlert, KatBox, KatDropdown, KatRadiobutton, KatSpinner} from "@amzn/katal-react";
import * as tardisConfig from 'src/configuration/tardis-config.json';
import {TransferType} from "src/widgets/TransferCase/TransferCase";
import {
  QueueTransferRequest,
  QueueTransferResponse
} from "@amzn/paragon-context/lib/IssueSummaryContext/models/TransferFunctions/QueueTransfer";
import {paragonMediator} from "@amzn/paragon-context/lib/ParagonMediatorContext/paragonMediatorContext";
import {useParagonContext, useParagonContextConsumerEl} from "@amzn/paragon-ui-react";
import {paragonCoreDataContext} from "@amzn/paragon-context/lib/ParagonCoreDataContext";
import {ServicesAPIContext} from 'src/service/service.context';
import {localization} from "src/helpers";
import KatalMetricsPublisher from '@amzn/katal-metrics/lib/KatalMetricsPublisher';
import {MetricsType} from "src/metrics";

export interface TardisQueue {
  queueName: string;
  chat: TardisQueueChatConfig;
}

interface TardisQueueChatConfig {
  enabled: boolean;
  transferChatQueue: string;
}

type TardisTransferProps = {
  selectedTransferOption: TransferType;
  selectTransferOption: (option: TransferType) => void;
  submitTransfer: boolean;
  onTransferCancel: () => void;
  updateAttributeTransferAlerts: (isTransferSuccess: string, isEmailTransferEnabled: boolean, header: string, message: string) => void;
  transferByAttribute: (workItemId) => void;
  currentOwner: boolean;
  workItemId: string | undefined;
  queueConfig: any;
  onSubmitFailed: () => void;
  metricsPublisher?: KatalMetricsPublisher;
}

export const TardisTransfer : FunctionComponent<TardisTransferProps> = ({ selectedTransferOption, workItemId,
                                                                          selectTransferOption, submitTransfer,
                                                                          onTransferCancel, queueConfig, currentOwner,
                                                                          transferByAttribute, onSubmitFailed, metricsPublisher}) => {
  const el = useParagonContextConsumerEl();

  const {
    value: { caseDetails, userDetails },
  } = useParagonContext(el, paragonCoreDataContext);

  const serviceAPIContext = useContext(ServicesAPIContext);
  const { translate } = localization();

  const transferReasons: {name: string, value: string}[] = tardisConfig.transferReasons;
  const [selectedTransferReason, setSelectedTransferReason] = useState<string|undefined>(undefined);
  const [tardisTransferSelected, setTardisTransferSelected] = useState(false);
  const [tardisTransferAlert, setTardisTransferAlert] = useState(false);
  const [loadingTransfer, setLoadingTransfer] = useState(false);

  useEffect(() => {
    setTardisTransferSelected(selectedTransferOption === TransferType.TARDIS_TRANSFER);
  }, [selectedTransferOption])

  useEffect(() => {
    if (submitTransfer && selectedTransferOption === TransferType.TARDIS_TRANSFER) {
      const targetQueue = queueConfig["chat"]["transferChatQueue"];
      transferTardisCase(targetQueue, workItemId);
    }
  }, [submitTransfer])

  const changeTransferReason = (selectedReason: string) => {
    setSelectedTransferReason(selectedReason);
    setTardisTransferAlert(false);
  }

  const changeTransferOption = () => {
    selectTransferOption(TransferType.TARDIS_TRANSFER);
    setTardisTransferAlert(false);
  }

  const composeTransferReason = (transferReason: string, workItemId?: string) => {
    return transferReason.toUpperCase() + (workItemId ? ('_' + workItemId?.toUpperCase().replaceAll('-', '_')) : '');
  }

  const transferTardisCase = (targetQueue: string, workItemId: string | undefined) => {
    if (!selectedTransferReason) {
      setTardisTransferAlert(true);
      onSubmitFailed();
    } else {
      // TODO: change any to QueueTransferRequest
      const queueTransferRequest: any = {
        caseId: caseDetails?.id || '',
        fromQueueName: caseDetails?.queue || '',
        queueName: targetQueue,
        keepCurrentOwner: currentOwner,
        transferReason: composeTransferReason(selectedTransferReason, workItemId),
        advancedTransferEnabled: false,
        checkTranslationConfig: true
      };

      const startTime = new Date();

      serviceAPIContext.transferService.queueTransfer(queueTransferRequest).then((response: QueueTransferResponse) => {
        metricsPublisher?.publishTimerMonitor(MetricsType.TRANSFER_BY_QUEUE_DURATION, new Date().getTime() - startTime.getTime());
        paragonMediator.notify('append-header-success-message', {
          'successMessage': response?.message
        });
        if (workItemId && targetQueue) {
          transferByAttribute(workItemId);
        }
      }).catch((error) => {
        metricsPublisher?.publishCounterMonitor(MetricsType.TRANSFER_BY_QUEUE_ERROR, 1);
        paragonMediator.notify('append-header-error-message', {'errorMessage': error?.message});
        onTransferCancel();
      });
    }
  }

  return (
    <div className={$style.tardisTransferBox}>
      <KatBox variant="white">
        {tardisTransferAlert && <><KatAlert
            variant="danger"
            description={translate("Paragon_TAM_CM_Select_Reason_for_Transfer")}
        >
        </KatAlert><br/></>}
        <KatRadiobutton
          name="tardis-transfer-radio-button"
          className={$style.manualAlert}
          value="transferRadioButtonManualValue"
          label={translate("Paragon_TAM_CM_Transfer_To_Specialist")}
          checked={tardisTransferSelected}
          onClick={changeTransferOption}
        ></KatRadiobutton>
        <p>{translate("Paragon_TAM_CM_Select_Reason_for_Transfer")}</p>
        {loadingTransfer ? <KatSpinner size={'small'}/> :
          <KatDropdown className={$style.transferReasonsDropDown}
                      options={transferReasons}
                      value={translate(selectedTransferReason || '')}
                      disabled={!tardisTransferSelected}
                      placeholder={""}
                      onChange={(e: any) => {
                        changeTransferReason(e?.target?.value);
                      }}
        ></KatDropdown>}
      </KatBox>
    </div>)
}
