import React, { FunctionComponent } from "react";
import { KatAlert } from "@amzn/katal-react";

interface IssueSummaryAlertProps extends React.HTMLProps<HTMLDivElement> {
    variant: "info" | "danger" | "success" | "warning",
    headerValue: string,
    descriptionValue: string
}

export const IssueSummaryAlert: FunctionComponent<IssueSummaryAlertProps> = ({
    variant,
    headerValue,
    descriptionValue
}) => {

    return (
        <KatAlert
            variant={variant}
            header={headerValue}
            description={descriptionValue}
            persistent={true}
        />
    )
}