import React, {FunctionComponent, useState} from "react";
import { ControlSpacing } from "@amzn/paragon-ui-react/";
import { KatIcon, KatPopover } from "@amzn/katal-react";
import $style from "./copy-linkfy-identifier.module.scss";
import classNames from "classnames";
import { copyToClipboard, logClickStreamData } from "src/common/utils";
import { AnalyticsFunctions } from "@amzn/paragon-context/lib/ParagonAnalyticsContext/models/AnalyticsFunctions";
import {localization} from "src/helpers";
import { logger } from "src/logger";

interface Props extends React.HTMLProps<HTMLDivElement> {
    elementId: string,
    label?: string,
    hrefUrl?: string,
    isCopyAll?: boolean,
    clickStreamEventBaseName: string,
    clickStream: AnalyticsFunctions,
    agentLogin?: string,
    caseId?: string,
}

export const CopyLinkifyIdentifier: FunctionComponent<Props> = ({
    elementId,
    hrefUrl,
    label,
    isCopyAll = false,
    className,
    clickStreamEventBaseName,
    clickStream,
    agentLogin,
    caseId,
}) => {

    const [ isCopied, setIsCopied] = useState(false);

    const [ showPopover, setShowPopover ] = useState(false);

    const clickStreamMetricName: string = `llm-issue-summary-${clickStreamEventBaseName}-${isCopyAll ? 'copied-all' : 'copied'}`

    const copy = () => {
        setIsCopied(true);
        setShowPopover(true);
        copyToClipboard(elementId);
        setTimeout(() => {
            setIsCopied(false);
            setShowPopover(false);
        }, 2000);
        logClickStreamData(clickStream, 'click', clickStreamMetricName);
        logger.info(`User: ${agentLogin} ${clickStreamEventBaseName}-${isCopyAll ? 'copied-all' : 'copied'} for case: ${caseId}`);
    }

    const { translate } = localization();

    return (
        <ControlSpacing row size="sm">
            <KatPopover className={$style.copyPopOver} katAriaBehavior="tooltip" position="top" triggerType="click" visible={showPopover} disabled={!showPopover}>
                <KatIcon slot="trigger" onClick={copy} size="small" name="content_copy" className={classNames($style.copyIcon, isCopied && $style.success)}/>
                <div>{translate('Paragon_TAM_CM_Copied')}</div>
            </KatPopover>
            {hrefUrl && <a onClick={() => logClickStreamData(clickStream, 'click', `llm-issue-summary-${clickStreamEventBaseName}-url-clicked`)}  href={hrefUrl} target="_blank"> {elementId} </a>}
            {!hrefUrl && <div className={className}>{isCopyAll ? label : elementId}</div>}
        </ControlSpacing>
    )
}