import {KatAlert, KatSpinner, KatStatusindicator} from '@amzn/katal-react';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import {localization} from 'src/helpers';


import {normalizeString} from 'src/common/utils';
import Dropdown from 'src/components/DropDown/DropDown';

import {clickStreamType, metricNames} from 'src/widgets/IssueSummaryWidget/components/widgets/ReplyCase/model/clickStreamMetric';
import {PMA_FRICTION} from 'src/widgets/IssueSummaryWidget/components/widgets/ReplyCase/model/replyCaseSchemaElements';
import {ServicesAPIContext} from "src/service/service.context";

import {useParagonContext, useParagonContextConsumerEl} from '@amzn/paragon-ui-react';
import {paragonAnalyticsContext} from '@amzn/paragon-context/lib/ParagonAnalyticsContext';

import $style from './pma-reasons-dropdown.module.scss';



interface PmaReasonsDropdownProps {
    caseId?: string;
    handleChangePmaReason: (value: string) => void;
    handleChangeCheckingPmaFriction: (event) => void;
    handleChangeShowPmaReasons: (event) => void;
    handleChangePmaHardFriction: (event) => void;
}

export default function PmaReasonsDropdown(props: PmaReasonsDropdownProps) {
    const [pmaReason,  setPmaReason] = useState('');
    const [checkingPmaFriction,  setCheckingPmaFriction] = useState(false);
    const [showPmaReasons, setShowPmaReasons] = useState(false);
    const [pmaHardFriction, setPmaHardFriction] = useState(false);

    const el = useParagonContextConsumerEl();
    const { methods: analyticsMethods } = useParagonContext(el, paragonAnalyticsContext);

    const { translate } = localization();

    const serviceAPIContext = useContext(ServicesAPIContext);

    const pmaReasonList: {name: string, value: string}[] = [
        {
            name: normalizeString(translate, 'Paragon_TAM_CM_PMA_Dropdown_Value_Insufficient_Answer', 'First PMA was not sufficiently answered'),
            value: 'Insufficient_Answer'
        },
        {
            name: normalizeString(translate, 'Paragon_TAM_CM_PMA_Dropdown_Value_More_Info', 'Workflow/SOP needs more information after first PMA'),
            value: 'Need_More_Info'
        },
        {
            name: normalizeString(translate, 'Paragon_TAM_CM_PMA_Dropdown_Value_Additional_Issues', 'SP has additional questions/issues'),
            value: 'Additional_Issues'
        },
        {
            name: normalizeString(translate, 'Paragon_TAM_CM_PMA_Dropdown_Value_Pending_Confirmation', 'Pending confirmation from SP that issue is resolved'),
            value: 'Pending_Confirmation'
        }
    ]

    const checkPmaFriction  = useCallback(() => {
        updateCheckingPmaFriction(true);

        const params = {
            caseId: props.caseId || ''
        };

        serviceAPIContext.paragonService.checkPmaFriction(params).then(response => {
            if (response && response.checkPmaFrictionData) {
                if (response.checkPmaFrictionData === PMA_FRICTION.SOFT) {
                    const metricData = {
                        name: metricNames.pmaReasonsDropdown.ruleMatch,
                        type: clickStreamType.click
                    }
                    analyticsMethods.logData(metricData);
                    updateShowPmaReasons(true);
                } else if (response.checkPmaFrictionData === PMA_FRICTION.HARD) {
                    const metricData = {
                        name: metricNames.pmaReasonsDropdown.ruleMatch,
                        type: clickStreamType.click
                    }
                    analyticsMethods.logData(metricData);
                    updatePmaHardFriction(true);
                }
            } else {
                updateShowPmaReasons(false);
            }
        }).catch(() => {
            console.error('Unexpected error while calling checkPmaFriction.');
            updateShowPmaReasons(false);
        }).finally(() => {
            updateCheckingPmaFriction(false);
        });
    }, [])

    useEffect(() => {
        checkPmaFriction();
    }, [checkPmaFriction]);

    function updatePmaReason(event) {
        setPmaReason(event.detail.value);
        props.handleChangePmaReason(event.detail.value);
    }

    function updateCheckingPmaFriction(newValue: boolean) {
        setCheckingPmaFriction(newValue);
        props.handleChangeCheckingPmaFriction(newValue);
    }

    function isPmaReasonEmpty() {
        return pmaReason === '';
    }

    function updateShowPmaReasons(newValue: boolean) {
        setShowPmaReasons(newValue);
        props.handleChangeShowPmaReasons(newValue);
    }

    function updatePmaHardFriction(newValue: boolean) {
        setPmaHardFriction(newValue);
        props.handleChangePmaHardFriction(newValue);
    }

    const loadingSpinner = <div className="text-center">
        <KatSpinner data-testid="katal-spinner" size="small"/>
    </div>;

    const requireStatusIndicator = <div className={$style.styledDiv}>
        <KatStatusindicator label="This field is required." variant="error" className="status-indicator"/>
    </div>;

    const pmaReasonElement = <div id="case-status-show-paa-reasons-dropdown">
        <Dropdown
            labelText={normalizeString(translate, 'Paragon_TAM_CM_PMA_Soft_Friction_Words', '*This case does not belong in PMA')}
            placeholder={normalizeString(translate, 'Paragon_TAM_CM_PMA_Dropdown_Prompt_words', 'Please select a reason to enter Pending Merchant Action')}
            dropdownOptions={pmaReasonList}
            handleChange={updatePmaReason}/>
        {isPmaReasonEmpty() && requireStatusIndicator}
    </div>;

    const alertMessage = <KatAlert data-testid="katal-alert-pma-reasons" variant="danger" persistent>This case does not belong in PMA.</KatAlert>;

    return (
        <div>
            {checkingPmaFriction && loadingSpinner}
            {showPmaReasons && pmaReasonElement}
            {pmaHardFriction && alertMessage}
        </div>
    )
}