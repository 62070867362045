import { KatCheckbox, KatLink } from '@amzn/katal-react';
import React, { useEffect, useState } from 'react';
import IssueSummaryFollowupWork from 'src/widgets/IssueSummaryWidget/components/IssueSummaryFollowupWork/IssueSummaryFollowupWork'
import PmaReasonsDropdown from 'src/widgets/IssueSummaryWidget/components/widgets/ReplyCase/components/composer/PmaReasonsDropdown/PmaReasonsDropdown';
import { localization } from 'src/helpers';

interface CaseStatusDropdownWithDCPProps {
    caseId?: string;
    caseStatusList?: { label: string, value: string }[];
    caseStatus?: string;
    shouldShowFollowupWork?: boolean;
    dcpFormCheck?: boolean;
    changeDmpFormCheck: (data: boolean) => void;
    handleChangeNewCaseStatus: (status: string) => void;
    handleChangePmaReason: (event) => void;
    handleChangeCheckingPmaFriction: (event) => void;
    handleChangeShowPmaReasons: (event) => void;
    handleChangePmaHardFriction: (event) => void;
}

const defaults: CaseStatusDropdownWithDCPProps = {
    caseStatusList: [],
    changeDmpFormCheck: () => { },
    handleChangeCheckingPmaFriction: () => { },
    handleChangeNewCaseStatus: () => { },
    handleChangePmaHardFriction: () => { },
    handleChangePmaReason: () => { },
    handleChangeShowPmaReasons: () => { }
}

import $style from './case-status-drop-down-with-dcp.module.scss';
import { getParagonDomain } from 'src/common/utils';


export default function CaseStatusDropdownWithDCP(props: CaseStatusDropdownWithDCPProps = defaults) {
    const [localNewCaseStatus, setLocalNewCaseStatus] = useState(props.caseStatus || '');
    const domain: string = getParagonDomain(window.location.origin);

    const { translate } = localization();

    useEffect(() => {
        if (localNewCaseStatus !== '' && !shouldShowPmaReasons()) {
            resetPmaVariables();
        }
    }, [localNewCaseStatus]);

    function onCaseStatusChange(event) {
        setLocalNewCaseStatus(event.target.value);
        props.handleChangeNewCaseStatus(event.target.value);
    }

    function resetPmaVariables() {
        props.handleChangePmaReason('');
        props.handleChangeCheckingPmaFriction(false);
        props.handleChangeShowPmaReasons(false);
    }

    function shouldShowPmaReasons() {
        return localNewCaseStatus === 'Pending Merchant Action';
    }

    const pmaReasonsDropdown = <PmaReasonsDropdown
        caseId={props.caseId}
        handleChangeCheckingPmaFriction={props.handleChangeCheckingPmaFriction}
        handleChangePmaHardFriction={props.handleChangePmaHardFriction}
        handleChangePmaReason={props.handleChangePmaReason}
        handleChangeShowPmaReasons={props.handleChangeShowPmaReasons} />;

    const issueSummaryFollowupWork = <IssueSummaryFollowupWork data-testid="issue-summary-followup-work" caseId={props.caseId} />

    return (
        <div>
            <div className={$style.styledDiv}>
                <h5>{translate('Paragon_TAM_CM_Case_Status')}</h5>

                <div>
                    {props.caseStatusList?.map(option => (
                        <div key={option.value} className={$style.radioGroup}>

                            <input
                                type="radio"
                                id={option.value}
                                value={option.value}
                                name="radio-group-case-status"
                                checked={localNewCaseStatus == option.value}
                                onChange={(e) => (onCaseStatusChange(e))}
                            />

                            <div className={$style.caseStatusOptions}>
                                <label htmlFor={option.value}>
                                    {option.label}
                                </label>
                                {option.label === 'Resolved' && localNewCaseStatus === 'Resolved' &&
                                    <div className={$style.dcpFormView}>
                                        <KatLink
                                            href={domain + '/hz/case-audit?caseId=' + props.caseId}
                                            className={$style.dcpLink}
                                            label={translate('Paragon_Submit_DMP_FORM_Button')}
                                            variant="button"
                                            target="_blank"
                                            onClick={() => {
                                                props.changeDmpFormCheck(false);
                                            }}
                                        />
                                        <KatCheckbox
                                            className={$style.dcpCheckbox}
                                            checked={props.dcpFormCheck}
                                            onChange={() => {
                                                props.changeDmpFormCheck(!(props.dcpFormCheck));
                                            }}
                                        />

                                    </div>
                                }

                            </div>
                        </div>
                    ))}
                </div>

            </div>
            {shouldShowPmaReasons() && pmaReasonsDropdown}
            {props.shouldShowFollowupWork && issueSummaryFollowupWork}
        </div>
    )
}