import { nothing } from 'lit-html';
import { html, property } from 'lit-element';
import { KatLitElement, register } from '../../shared/base';
import baseStyles from '../../shared/base/base.lit.scss';
import styles from './badge.lit.scss';

/**
 * @component {kat-badge} KatalBadge Badges display a number or short text string to indicate attributes such as quantity, measurement, or status.
 * @status Production
 * @theme flo
 * @guideline Do Badges can only contain alphanumeric values.
 * @guideline Do Badges are always color-coded and positioned next to the object they are informing
 * @guideline Dont Do not include icons or imagery within the badge.
 * @guideline Dont Do not use badges to indicate status.
 * @example Success {"label": "Success Badge", "type":"success"}
 * @example Info {"label": "Informational Badge", "type":"info"}
 * @example Default {"label": "Default Badge", "type":"default"}
 * @example Warning {"label": "Warning Badge", "type":"warning"}
 * @example Danger {"label": "Danger Badge", "type":"danger"}
 * @example Angled {"label": "Angled Badge", "type":"default", "variant": "angled"}
 * @slot default Contents will be used as the main badge content.
 * @a11y {keyboard}
 * @a11y {sr}
 * @a11y {contrast}
 */
@register('kat-badge')
export class KatBadge extends KatLitElement {
  /** The text that will be displayed to the user */
  @property()
  label: string;

  /**
   * The type of badge to display to the user
   * @enum {value} default Used for a generic status or state
   * @enum {value} success Items that have positive meaning
   * @enum {value} warning Items that have negative meaning
   * @enum {value} danger Items with problems or that have been removed
   * @enum {value} info Items that are informational
   * @enum {value} tag Tag-style badges are used when displaying neutral values or messaging.
   */
  @property()
  type = 'default';

  /**
   * The style of badge to display to users
   * @enum {value} default The default state for a badge
   * @enum {value} angled Badge that fits the corner of an element
   */
  @property()
  variant = 'default';

  static get styles() {
    return [baseStyles, styles];
  }

  render() {
    return html`
      <span class="label" part="label">
        <span class="label__inner"><slot>${this.label || nothing}</slot></span>
      </span>
    `;
  }
}
