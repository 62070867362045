import React, {FunctionComponent, useEffect, useState} from 'react';
import {
    KatAlert,
    KatBox,
    KatCheckbox,
    KatDropdown,
    KatIcon,
    KatRadiobutton,
    KatSpinner,
} from '@amzn/katal-react';

import $style from './attribute-transfer.module.scss';
import languages from './transferLanguages.json';
import { localization } from "src/helpers";
import {TransferType} from "src/widgets/TransferCase/TransferCase";


type Props = {
    attributeTransferAlert: boolean;
    advancedTransferEnabled: boolean;
    languageAlert: boolean;
    languageCheckBox: boolean;
    loadingAttribute: boolean;
    selectAttribute: () => void;
    changelanguage: (data: string) => void;
    checkLanguageBox: (data: boolean) => void;
    selectedTransferOption: TransferType;
}

export const AttributeTransfer: FunctionComponent<Props> = ({
    attributeTransferAlert,
    advancedTransferEnabled,
    languageAlert,
    languageCheckBox,
    loadingAttribute,
    selectAttribute,
    changelanguage,
    checkLanguageBox,
    selectedTransferOption
}) => {

    const { translate } = localization();
    const [attributeTransferSelected, setAttributeTransferSelected] = useState(false);

    useEffect(() => {
        setAttributeTransferSelected(selectedTransferOption === TransferType.ATTRIBUTE_TRANSFER);
    }, [selectedTransferOption])


    return (
        <div className={$style.transferBox}>
            <KatBox variant='white'>
                {attributeTransferAlert && <KatAlert
                    variant="danger"
                    description={translate('Paragon_TAM_CM_Select_One_Reason')}
                ></KatAlert>}
                <KatRadiobutton
                    name="transfer-radio-button"
                    className={$style.manualAlert}
                    value="transferRadioButtonAttributeValue"
                    label={translate('Paragon_TAM_CM_Attribute_Transfer')}
                    checked={advancedTransferEnabled && attributeTransferSelected}
                    onClick={() => selectAttribute()}
                ></KatRadiobutton>
                <div>
                    {loadingAttribute && (
                        <KatSpinner variant="default" size="small"></KatSpinner>
                    )}
                </div>
                {!loadingAttribute && (
                    <KatCheckbox
                        className={$style.languageCheckBox}
                        onChange={() => {
                            checkLanguageBox(!languageCheckBox);
                        }}
                        label={translate('Paragon_TAM_CM_Language_Translation')}
                        disabled={!attributeTransferSelected}
                    ></KatCheckbox>
                )}
                {!loadingAttribute && languageCheckBox && attributeTransferSelected && (
                    <KatBox variant='ltgrey' className={$style.languageDisplay}>
                        <div className={$style.languageNote}>
                            Use in-page translator to check the language
                            <KatIcon name="info-circle-fill" size="tiny"></KatIcon>
                        </div>
                        <KatDropdown
                            className={$style.languagesDropDown}
                            placeholder={translate('Paragon_TAM_CM_Select_Language')}
                            options={JSON.stringify(languages)}
                            onChange={(e: any) => {
                                changelanguage(e?.target?.value);
                            }}
                        ></KatDropdown>
                        {languageAlert &&
                            <div className={$style.errorAlert}>
                                {translate('Paragon_TAM_CM_Language_Change_Failed')}
                            </div>}
                    </KatBox>
                )}
            </KatBox>
        </div>
    )

}