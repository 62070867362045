import { KatalComponent, Keys, register } from '../../shared/base';
import {
  WEBLAB_IDS,
  VISUAL_REFRESH_CLASSNAME,
  onBodyWeblabClassChange,
  offBodyWeblabClassChange,
} from '../../utils/weblab-helpers';

/**
 * Subcomponent for the individual steps.
 * For now this is not exposed and devs should use the JSON attribute on the parent component to generate these.
 */
@register('kat-workflowtracker-step')
export class KatWorkflowtrackerStep extends KatalComponent {
  static get observedAttributes() {
    return ['state', 'label', 'secondary-label'];
  }

  constructor() {
    super(KatWorkflowtrackerStep.observedAttributes);
    this.connectedEvents();
  }

  connectedCallback() {
    super.connectedCallback();
    this.render();
    onBodyWeblabClassChange(() => this.render());
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    offBodyWeblabClassChange(() => this.render());
  }

  /**
   * @classprop {enum} state Show success message instead of form
   * @enum {value} todo Step that has not been completed yet (default)
   * @enum {value} in-progress Step currently being worked on or navigated to
   * @enum {value} complete Completed step
   * @enum {value} error Step that has resulted in an error
   * @required
   */
  get state() {
    return this.getAttribute('state') || 'todo';
  }

  set state(value) {
    this.removeOrSetAttribute('state', value);
  }

  /**
   * @classprop {string} label Label that describes what the workflow step represents
   */
  get label() {
    return this.getAttribute('label') || '';
  }

  set label(value) {
    this.setAttribute('label', value);
  }

  /**
   * @classprop {string} secondaryLabel Additional information about the workflow step
   */
  get secondaryLabel() {
    return this.getAttribute('secondary-label') || '';
  }

  set secondaryLabel(value) {
    this.setAttribute('secondary-label', value);
  }

  /**
   * @classprop {boolean} clickable Allows step to be clickable
   */
  get clickable() {
    return this.getBooleanAttribute('clickable');
  }

  set clickable(value) {
    this.setBooleanAttribute('clickable', value);
  }

  render() {
    const createAndAppendElement = (parent, elementTag) => {
      const element = document.createElement(elementTag);
      parent.appendChild(element);

      return element;
    };

    const getStepMarkerIconName = () => {
      if (this.state === 'todo') {
        return 'radio_button_off';
      }
      if (this.state === 'error') {
        return 'danger';
      }
      if (this.state === 'in-progress') {
        return 'radio_button_on';
      }
      if (this.state === 'complete') {
        return 'check_circle';
      }
    };

    const createAndAppendStepMarkerIcon = (parent: HTMLElement) => {
      // TODO: After 2023 Visual Refresh, this can be consolidated to only allow a kat-icon.
      const icon =
        document.body?.classList.contains(WEBLAB_IDS.group2) ||
        document.body?.classList.contains(VISUAL_REFRESH_CLASSNAME)
          ? 'kat-icon'
          : 'span';

      const stepMarkerElement = createAndAppendElement(parent, icon);
      if (icon === 'kat-icon') {
        stepMarkerElement.setAttribute('name', getStepMarkerIconName());
        stepMarkerElement.setAttribute('size', 'small');
      } else {
        stepMarkerElement.setAttribute('class', 'step-marker-circle');
      }
    };

    this.innerHTML = '';
    this.tabIndex = this.clickable ? 0 : -1;

    const stepMarker = createAndAppendElement(this, 'span');
    stepMarker.setAttribute('class', 'step-marker');

    const stepText = createAndAppendElement(this, 'span');
    stepText.setAttribute('class', 'step-text');

    createAndAppendStepMarkerIcon(stepMarker);

    const stepLabel = createAndAppendElement(stepText, 'span');
    stepLabel.setAttribute('class', 'step-label');
    stepLabel.innerHTML = this.sanitizeAndAddSoftHyphensToLabel(this.label, 5);

    if (this.secondaryLabel) {
      const secondaryStepLabel = createAndAppendElement(stepText, 'span');
      secondaryStepLabel.setAttribute('class', 'secondary-step-label');
      secondaryStepLabel.innerHTML = this.sanitizeAndAddSoftHyphensToLabel(
        this.secondaryLabel,
        5
      );
    }
  }

  // This will add a soft hyphen every 5 characters in words that are 5 characters or longer
  // https://css-tricks.com/almanac/properties/h/hyphenate/
  sanitizeAndAddSoftHyphensToLabel(label, num) {
    // sanitize label
    const cleanDiv = document.createElement('div');
    cleanDiv.innerText = label;
    const sanitizedLabel = cleanDiv.innerHTML;

    // add soft hyphens
    return sanitizedLabel.replace(
      RegExp('(\\w{' + num + '})(\\w)', 'g'),
      (all, text, char) => text + '&shy;' + char
    );
  }

  connectedEvents() {
    const click = e => {
      e.preventDefault();
      e.stopPropagation();

      if (!this.clickable) {
        return;
      }

      this.dispatchCustomEvent('step', { step: this.getAttribute('id') });
    };

    const keypress = e => {
      if (Keys.Confirm.includes(e.keyCode)) {
        click(e);
      }
    };

    this.addEventListener('click', click);
    this.addEventListener('keydown', keypress);
  }

  // Respond to attribute changes.
  attributeChangedCallback() {
    this.render();
  }
}
