import {ReplyFunctions} from "@amzn/paragon-context/lib/ParagonReplyContext/models";
import {TransferTranslatedReplyRequest} from "@amzn/paragon-context/lib/ParagonReplyContext/models/TransferTranslatedReply";
import {
  CheckPmaFrictionInput,
  FinalWordVerificationInput,
  FinalWordVerificationOutput,
  ReplyCaseStatusDropdownInput,
  ReplyCaseStatusDropdownOutput,
  ReplyToCaseInput,
  ReplyToCaseOutput,
  ReviewReplyInput,
  ReviewReplyOutput,
  SaveFollowupWorkInput,
  SaveFollowupWorkOutput,
  SavePrimaryEmailListInput,
  SubmitExceptionInput,
  SubmitExceptionOutput,
  TransferTranslatedReplyInput,
  UploadFileToS3Input, UploadFileToS3Output,
  ViewCaseComposerInput,
  ViewCaseComposerOutput,
  WhyContactedDropdownInput,
  WhyContactedDropdownOutput,
  WhyContactedDropdownSubmitInput
} from "src/widgets/IssueSummaryWidget/components/widgets/ReplyCase/model/replyCaseSchemaElements"

export class ParagonService {

  private readonly methods: ReplyFunctions;

  constructor(widgetMethods: ReplyFunctions) {
    this.methods = widgetMethods;
  }

  async viewCaseComposer(params: ViewCaseComposerInput): Promise<{ error: boolean, viewCaseComposerData?: ViewCaseComposerOutput}> {

    const response = await this.methods.viewCaseComposerV2({ ...params });

    if (response) {
      return  { error: false, viewCaseComposerData: {...response, tenantId: parseInt(response.tenantId)} };
    } else {
      return  { error: true };
    }
  }

  async getReplyCaseStatusDropdown(params: ReplyCaseStatusDropdownInput): Promise<{ error: boolean, replyCaseStatusDropdownData?: ReplyCaseStatusDropdownOutput}> {

    const response = await this.methods.replyCaseStatusDropdownV2({ ...params });

    if (response) {
      return  { error: false, replyCaseStatusDropdownData: response };
    } else {
      return  { error: true };
    }
  }

  async getWhyContactedDropdown(params: WhyContactedDropdownInput): Promise<{ error: boolean, whyContactedDropdownData?: WhyContactedDropdownOutput}> {

    const response = await this.methods.whyContactedDropdownGet(params);

    if (response) {
      return  { error: false, whyContactedDropdownData: response as unknown as WhyContactedDropdownOutput };
    } else {
      return  { error: true };
    }
  }

  async submitWhyContactedDropdown(params: WhyContactedDropdownSubmitInput): Promise<{ error: boolean, whyContactedDropdownSubmitData?: string}> {

    const response = await this.methods.whyContactedDropdownSubmit(params);

    if (response) {
      return  { error: false, whyContactedDropdownSubmitData: response };
    } else {
      return  { error: true };
    }
  }

  async reviewReply(params: ReviewReplyInput): Promise<{ error: boolean, reviewReviewData?: ReviewReplyOutput}> {

    const response = await this.methods.reviewReply(params);

    if (response) {
      return  { error: false, reviewReviewData: response };
    } else {
      return  { error: true };
    }
  }

  async replyToCase(params: ReplyToCaseInput): Promise<{ error: boolean, replyToCaseData?: ReplyToCaseOutput}> {

    const response = await this.methods.replyToCase(params);

    if (response) {
      return  { error: false, replyToCaseData: response };
    } else {
      return  { error: true };
    }
  }

  async transferTranslatedReply(params: TransferTranslatedReplyInput): Promise<{ error: boolean, transferTranslatedReplyData?: string}> {

    const response = await this.methods.transferTranslatedReply(params as TransferTranslatedReplyRequest);

    if (response) {
      return  { error: false, transferTranslatedReplyData: response };
    } else {
      return  { error: true };
    }
  }

  async uploadFileToS3(params: UploadFileToS3Input): Promise<{ error: boolean, uploadFileToS3Data?: UploadFileToS3Output }> {

    const response = await this.methods.uploadFileToS3(params);

    if (response) {
      return  { error: false, uploadFileToS3Data: response as unknown as UploadFileToS3Output};
    } else {
      return  { error: true };
    }
  }

  async finalWordVerification(params: FinalWordVerificationInput): Promise<{ error: boolean, finalWordVerificationData?: FinalWordVerificationOutput}> {

    const response = await this.methods.finalWordVerification(params);

    if (response) {
      return  { error: false, finalWordVerificationData: response };
    } else {
      return  { error: true };
    }
  }

  async savePrimaryEmailList(params: SavePrimaryEmailListInput): Promise<{ error: boolean, savePrimaryEmailListData?: string}> {

    const response = await this.methods.savePrimaryEmailList(params);

    if (response) {
      return  { error: false, savePrimaryEmailListData: response };
    } else {
      return  { error: true };
    }
  }

  async submitException(params: SubmitExceptionInput): Promise<{ error: boolean, submitExceptionData?: SubmitExceptionOutput}> {

    const response = await this.methods.submitException(params);

    if (response) {
      return  { error: false, submitExceptionData: response };
    } else {
      return  { error: true };
    }
  }

  async saveFollowupWork(params: SaveFollowupWorkInput): Promise<{ error: boolean, saveFollowupWorkData?: SaveFollowupWorkOutput}> {

    const response = await this.methods.saveFollowupWork(params);

    if (response) {
      return  { error: false, saveFollowupWorkData: response };
    } else {
      return  { error: true };
    }
  }

  async checkPmaFriction(params: CheckPmaFrictionInput): Promise<{ error: boolean, checkPmaFrictionData?: string}> {

    const response = await this.methods.checkPmaFriction(params);

    if (response) {
      return  { error: false, checkPmaFrictionData: response };
    } else {
      return  { error: true };
    }
  }
}