import { KatRadiobuttonGroup } from '@amzn/katal-react';
import React, { useEffect, useState } from 'react';
import IssueSummaryFollowupWork from 'src/widgets/IssueSummaryWidget/components/IssueSummaryFollowupWork/IssueSummaryFollowupWork'
import PmaReasonsDropdown from 'src/widgets/IssueSummaryWidget/components/widgets/ReplyCase/components/composer/PmaReasonsDropdown/PmaReasonsDropdown';
import { localization } from 'src/helpers';

interface CaseStatusDropdownProps {
    caseId?: string;
    caseStatusList?: { label: string, value: string }[];
    caseStatus?: string;
    shouldShowFollowupWork?: boolean;
    handleChangeNewCaseStatus: (status: string) => void;
    handleChangePmaReason: (event) => void;
    handleChangeCheckingPmaFriction: (event) => void;
    handleChangeShowPmaReasons: (event) => void;
    handleChangePmaHardFriction: (event) => void;
}

const defaults: CaseStatusDropdownProps = {
    caseStatusList: [],
    handleChangeCheckingPmaFriction: () => { },
    handleChangeNewCaseStatus: () => { },
    handleChangePmaHardFriction: () => { },
    handleChangePmaReason: () => { },
    handleChangeShowPmaReasons: () => { }
}

import $style from './case-status-drop-down.module.scss';

export default function CaseStatusDropdown(props: CaseStatusDropdownProps = defaults) {
    const [localNewCaseStatus, setLocalNewCaseStatus] = useState(props.caseStatus || '');

    const { translate } = localization();

    useEffect(() => {
        if (localNewCaseStatus !== '' && !shouldShowPmaReasons()) {
            resetPmaVariables();
        }
    }, [localNewCaseStatus]);

    function onCaseStatusChange(event) {
        setLocalNewCaseStatus(event.detail.value);
        props.handleChangeNewCaseStatus(event.detail.value);
    }

    function resetPmaVariables() {
        props.handleChangePmaReason('');
        props.handleChangeCheckingPmaFriction(false);
        props.handleChangeShowPmaReasons(false);
    }

    function shouldShowPmaReasons() {
        return localNewCaseStatus === 'Pending Merchant Action';
    }

    const pmaReasonsDropdown = <PmaReasonsDropdown
        caseId={props.caseId}
        handleChangeCheckingPmaFriction={props.handleChangeCheckingPmaFriction}
        handleChangePmaHardFriction={props.handleChangePmaHardFriction}
        handleChangePmaReason={props.handleChangePmaReason}
        handleChangeShowPmaReasons={props.handleChangeShowPmaReasons} />;

    const issueSummaryFollowupWork = <IssueSummaryFollowupWork data-testid="issue-summary-followup-work" caseId={props.caseId} />

    return (
        <div>
            <div className={$style.styledDiv}>
                <KatRadiobuttonGroup
                    data-testid="katal-radio-group"
                    name="katal-radio-group-case-status"
                    onChange={onCaseStatusChange}
                    id="case-status-dropdown-button-group"
                    value={localNewCaseStatus}
                    options={JSON.stringify(props.caseStatusList)}
                    label={translate('Paragon_TAM_CM_Case_Status')} />

            </div>
            {shouldShowPmaReasons() && pmaReasonsDropdown}
            {props.shouldShowFollowupWork && issueSummaryFollowupWork}
        </div>
    )
}