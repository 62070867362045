import React, { FunctionComponent, ReactNode, useState, useEffect } from "react";
import { CompositeCaseDetails } from "@amzn/paragon-context/lib/IssueSummaryContext/models/CompositeCaseDetails";
import $style from './case-and-seller-details.module.scss';
import { Loading } from "src/components/Loading/Loading";
import { ControlSpacing } from "@amzn/paragon-ui-react";
import { KatTable, KatPopover, KatLink, KatButton, KatIcon, KatTableBody } from '@amzn/katal-react';
import { CopyLinkifyIdentifier } from "src/widgets/IssueSummaryWidget/components/common/LinkifiedIdentifier";
import { CaseSellerInfoRow } from "src/widgets/IssueSummaryWidget/components/case_seller_info/CaseSellerInfoRow";
import {getParagonDomain, logClickStreamData} from "src/common/utils";
import { TenantFeatures } from "@amzn/paragon-core-data-context/lib/models/TenantFeatures";
import { IssueSummaryFunctions } from "@amzn/paragon-context/lib/IssueSummaryContext/models/IssueSummaryFunctions";
import { UserDetails } from "@amzn/paragon-core-data-context/lib/models/UserDetails";
import { LockCaseRequest, LockCaseResponse } from "@amzn/paragon-context/lib/IssueSummaryContext/models/CaseFunctions/LockCase";
import { paragonMediator } from "@amzn/paragon-context/lib/ParagonMediatorContext/paragonMediatorContext"
import { ReleaseCaseRequest, ReleaseCaseResponse } from "@amzn/paragon-context/lib/IssueSummaryContext/models/CaseFunctions/ReleaseCase";
import { PeekNowWidget } from "src/widgets/IssueSummaryWidget/components/widgets/PeekNowWidget";
import { formatDate } from "src/common/utils";
import { SellerInfo } from "src/widgets/IssueSummaryWidget/model/sellerInformation";
import { AnalyticsFunctions } from "@amzn/paragon-context/lib/ParagonAnalyticsContext/models/AnalyticsFunctions";
import {localization} from "src/helpers";
import { logger } from "src/logger";
import { ExpandableSellerInfoRow } from "src/widgets/IssueSummaryWidget/components/case_seller_info/ExpandableSellerInfoRow";

interface CaseAndSellerDetailsProps extends React.HTMLProps<HTMLDivElement> {
    compositeCaseDetails: CompositeCaseDetails,
    isCaseAndSellerInfoLoading: boolean,
    tenantFeatures: TenantFeatures,
    issueSummaryFunctions: IssueSummaryFunctions,
    userDetails: UserDetails,
    clickStream: AnalyticsFunctions,
    showPeekNowButton: boolean,
}

export const CaseAndSellerDetails: FunctionComponent<CaseAndSellerDetailsProps> = ({
    compositeCaseDetails,
    isCaseAndSellerInfoLoading,
    tenantFeatures,
    issueSummaryFunctions,
    userDetails,
    clickStream,
    showPeekNowButton
}) => {

    const [ isComponentExpanded, setIsComponentExpanded ] = useState(false);

    const [ isLockCaseLoading, setIsLockCaseLoading ] = useState(false);

    const [ isReleaseCaseLoading, setIsReleaseCaseLoading ] = useState(false);

    const domain: string = getParagonDomain(window.location.origin);

    const { translate } = localization();

    const defaultDataValue = translate('Paragon_TAM_CM_Data_Unavailable');

    const defaultSellerInfo: SellerInfo = {
        Nickname: defaultDataValue,
        Services: defaultDataValue,
        Type: defaultDataValue,
        Phone_Support: defaultDataValue,
        Account_Health: defaultDataValue,
        Account_Status: defaultDataValue,
        Marketplace_Status: defaultDataValue,
        Business_Phone: defaultDataValue,
        Staging: defaultDataValue,
        '2SV_Enabled': defaultDataValue,
        SIV_STATUS: defaultDataValue
    }

    const [ sellerInfo, setSellerInfo ] = useState<SellerInfo>(defaultSellerInfo);

    const [ brandsList, setBrandsList ] = useState<string[]>([]);

    useEffect(() => {
        if (!compositeCaseDetails?.sellerInfo) {
            return;
        }
        const sellerInfoValues = compositeCaseDetails?.sellerInfo

        const sellerAttributes: SellerInfo = {
            Nickname: sellerInfoValues.Nickname === 'Data_Unavailable' ? 'Data Unavailable' : sellerInfoValues.Nickname || defaultDataValue,
            Services: sellerInfoValues.Services === 'Data_Unavailable' ? 'Data Unavailable' : sellerInfoValues.Services || defaultDataValue,
            Type: sellerInfoValues.Type === 'Data_Unavailable' ? 'Data Unavailable' : sellerInfoValues.Type || defaultDataValue,
            Phone_Support: sellerInfoValues.Phone_Support === 'Data_Unavailable' ? 'Data Unavailable' : sellerInfoValues.Phone_Support || defaultDataValue,
            Account_Health: sellerInfoValues.Account_Health === 'Data_Unavailable' ? 'Data Unavailable' : sellerInfoValues.Account_Health || defaultDataValue,
            Account_Status: sellerInfoValues.Account_Status === 'Data_Unavailable' ? 'Data Unavailable' : sellerInfoValues.Account_Status || defaultDataValue,
            Marketplace_Status: sellerInfoValues.Marketplace_Status === 'Data_Unavailable' ? 'Data Unavailable' : sellerInfoValues.Marketplace_Status || defaultDataValue,
            Business_Phone: sellerInfoValues.Business_Phone === 'Data_Unavailable' ? 'Data Unavailable' : sellerInfoValues.Business_Phone || defaultDataValue,
            Staging: sellerInfoValues.Staging === 'Data_Unavailable' ? 'Data Unavailable' : sellerInfoValues.Staging || defaultDataValue,
            '2SV_Enabled': sellerInfoValues['2SV_Enabled'] === 'Data_Unavailable' ? 'Data Unavailable' : sellerInfoValues['2SV_Enabled'] || defaultDataValue,
            SIV_STATUS: sellerInfoValues.SIV_STATUS === 'Data_Unavailable' ? 'Data Unavailable' : sellerInfoValues.SIV_STATUS || defaultDataValue
        }
        setSellerInfo(sellerAttributes);

        const extendedCompositeDetails: CompositeCaseDetails & { brandList?: string[] } = compositeCaseDetails;
        setBrandsList(extendedCompositeDetails?.brandList || []);

    }, [compositeCaseDetails?.sellerInfo]);

    const canLockCase = (): boolean => {
        return (tenantFeatures.isOneClickLockCaseFeatureEnabled || false) && !compositeCaseDetails?.caseDetails?.owner;
    }

    const canReleaseCase = (): boolean => {
        return (tenantFeatures.isReleaseCaseFeatureEnabled || false) && compositeCaseDetails?.caseDetails?.status !== translate('Paragon_TAM_CM_Unassigned') &&
            compositeCaseDetails?.caseDetails?.status !== translate('Paragon_TAM_CM_Resolved') && compositeCaseDetails?.caseDetails?.owner === userDetails?.agentLogin;
    }

    const lockCaseToAgent = () => {
        setIsLockCaseLoading(true);

        const request: LockCaseRequest =  {
            caseId: compositeCaseDetails?.caseDetails?.caseId || "",
            agentLogin: userDetails?.agentLogin,
            validateCaseLock: true
        }
        logger.info(`User: ${userDetails?.agentLogin} initiated a request to lock case: ${compositeCaseDetails?.caseDetails?.caseId}`);

        issueSummaryFunctions.lockCase(request).then((response: LockCaseResponse) => {
            paragonMediator.notify("lock-case-from-agent-form-submission-succeeded", undefined);
            logger.info(`Lock case to Agent succeeded for User: ${userDetails?.agentLogin} and case: ${compositeCaseDetails?.caseDetails?.caseId}`);
        }).catch((error) => {
            paragonMediator.notify('lock-case-from-agent-form-submission-failed', undefined);
            logger.info(`Lock case to Agent failed for User: ${userDetails?.agentLogin} and case: ${compositeCaseDetails?.caseDetails?.caseId}`);
        }).finally(() => {
            setIsLockCaseLoading(false);
        })
    }

    const releaseCaseFromAgent = () => {
        setIsReleaseCaseLoading(true);

        const request: ReleaseCaseRequest = {
            caseId: compositeCaseDetails?.caseDetails?.caseId || ""
        }
        logger.info(`User: ${userDetails?.agentLogin} initiated a request to release case: ${compositeCaseDetails?.caseDetails?.caseId}`);

        issueSummaryFunctions.releaseCase(request).then((response: ReleaseCaseResponse) => {
            paragonMediator.notify('release-case-from-agent-form-submission-succeeded', undefined);
            logger.info(`Release case from Agent succeeded for User: ${userDetails?.agentLogin} and case: ${compositeCaseDetails?.caseDetails?.caseId}`);
        }).catch((error) => {
            paragonMediator.notify('release-case-from-agent-form-submission-failed', undefined);
            logger.info(`Release case from Agent failed for User: ${userDetails?.agentLogin} and case: ${compositeCaseDetails?.caseDetails?.caseId}`);
        }).finally(() => {
            setIsReleaseCaseLoading(false);
        })
    }

    const getStatusElement = (status: string): ReactNode   => {
        const statusMap: Record<string, { iconName: string, className: string }> = {
            Normal: { iconName: 'check', className: $style.normalStatus },
            'Normal (Good/Fair)': { iconName: 'check', className: $style.normalStatus },
            AtRisk: { iconName: 'alert-fill', className: $style.blockedStatus },
            PendingValidCC: { iconName: 'alert-fill', className: $style.blockedStatus },
            'At Risk (Bad)': { iconName: 'alert-fill', className: $style.blockedStatus },
            Blocked: { iconName: 'alert-fill', className: $style.blockedStatus },
            Pending: { iconName: 'alert-fill', className: $style.blockedStatus },
            Deactivated: { iconName: 'alert-circle-fill', className: $style.deactivatedStatus }
        };

        const { iconName = '', className = '' } = statusMap[status] || {}

        const isNormalOrDeactivated = ['Normal', 'Normal (Good/Fair)', 'Deactivated'].includes(status);

        return (
            <div className={isNormalOrDeactivated ? className : ''}>
                {iconName && <KatIcon name={iconName as KatIcon.Name} size="small" className={!isNormalOrDeactivated ? className : ''} />}
                <span className={$style.status}>{status}</span>
            </div>
        )
    }

    const toggleExpand = () => {
        logClickStreamData(clickStream, 'click', `llm-issue-summary-case-details-${isComponentExpanded ? 'collapsed' : 'expanded'}`);
        logger.info(`User: ${userDetails?.agentLogin} ${isComponentExpanded ? 'collapsed' : 'expanded'} Case and Seller Details for Case: ${compositeCaseDetails?.caseDetails?.caseId}`);
        setIsComponentExpanded(!isComponentExpanded);
    }

    return (
        <div className={$style.caseSellerInfo}>

            <h3>{translate('Paragon_TAM_CM_General_Case_Information')}</h3>

            {isCaseAndSellerInfoLoading &&
                <div className={$style.spinnerBox}>
                    <Loading isLoading={isCaseAndSellerInfoLoading}>&nbsp;</Loading>
                </div>
            }

            {!isCaseAndSellerInfoLoading &&
                <KatTable className={$style.caseInfoTable}>
                    <KatTableBody>
                        <CaseSellerInfoRow
                            cellLabel={translate('Paragon_TAM_CM_Case_ID') + ":"}
                            cellValueElement={<CopyLinkifyIdentifier clickStreamEventBaseName="case-id" clickStream={clickStream} elementId={compositeCaseDetails?.caseDetails?.caseId || ""}
                                agentLogin={userDetails?.agentLogin} caseId={compositeCaseDetails?.caseDetails?.caseId}
                            />}
                        />

                        <CaseSellerInfoRow
                            cellLabel={translate('Paragon_TAM_CM_Seller_ID') + ":"}
                            cellValueElement={<ControlSpacing row size="xs">
                                <CopyLinkifyIdentifier
                                    clickStreamEventBaseName="seller-id"
                                    clickStream={clickStream}
                                    elementId={compositeCaseDetails?.caseDetails?.merchantId || ""}
                                    hrefUrl={compositeCaseDetails?.isSellerLookupAllowed ? domain + compositeCaseDetails?.sellerLookupUrl : ""}
                                    agentLogin={userDetails?.agentLogin} caseId={compositeCaseDetails?.caseDetails?.caseId}
                                />
                                <a href={domain + compositeCaseDetails?.sellerCasesUrl} target="_blank">(Cases)</a>
                            </ControlSpacing>
                            }
                        />

                        <CaseSellerInfoRow
                            cellLabel={translate('Paragon_TAM_CM_Queue') + ":"}
                            cellValueElement={<div className={$style.emailQueue}>{compositeCaseDetails?.caseDetails?.queue}</div>}
                        />

                        <CaseSellerInfoRow
                            cellLabel={translate('Paragon_TAM_CM_Owner') + ":"}
                            cellValueElement={<div>{compositeCaseDetails?.caseDetails?.owner}</div>}
                        />

                        <CaseSellerInfoRow
                            cellLabel={translate('Paragon_TAM_CM_Nickname') + ":"}
                            cellValueElement={<div>{sellerInfo.Nickname}</div>}
                        />

                        <CaseSellerInfoRow
                            cellLabel={translate('Paragon_TAM_CM_Email') + ":"}
                            cellValueElement={<div className={$style.emailQueue}>{compositeCaseDetails?.caseDetails?.primaryEmail}</div>}
                        />

                        <CaseSellerInfoRow
                            cellLabel={translate('Paragon_TAM_CM_Status') + ":"}
                            cellValueElement={<ControlSpacing row size="xs">
                                <span>{compositeCaseDetails?.caseDetails?.status}</span>
                                {compositeCaseDetails?.caseDetails?.caseSubStatus && <span> - { compositeCaseDetails?.caseDetails?.caseSubStatus }</span>}
                                {canLockCase() &&
                                    <KatButton label={translate('Paragon_TAM_CM_Lock_Case')} variant="secondary" size="small" onClick={lockCaseToAgent} />
                                }
                                {canReleaseCase() &&
                                    <KatButton label={translate('Paragon_TAM_CM_Release_Case')} variant="secondary" size="small" onClick={releaseCaseFromAgent} />
                                }

                                {(isLockCaseLoading || isReleaseCaseLoading) &&
                                    <KatIcon name="spinner" size="small" />
                                }

                            </ControlSpacing>}
                        />

                        <CaseSellerInfoRow cellLabel={translate('Paragon_TAM_CM_SIV_STATUS') + ":"} cellValueElement={<span>{sellerInfo.SIV_STATUS}</span>} />

                        <CaseSellerInfoRow cellLabel={translate('Paragon_TAM_CM_Account_Health') + ":"} cellValueElement={getStatusElement(sellerInfo.Account_Health)} />

                        <CaseSellerInfoRow cellLabel={translate('Paragon_TAM_CM_Account_Status') + ":"} cellValueElement={getStatusElement(sellerInfo.Account_Status)} />

                        <CaseSellerInfoRow cellLabel={translate('Paragon_TAM_CM_Marketplace_Status') + ":"} cellValueElement={getStatusElement(sellerInfo.Marketplace_Status)} />

                        {isComponentExpanded &&
                            <div>
                                <CaseSellerInfoRow cellLabel={translate('Paragon_TAM_CM_Business_Phone') + ":"} cellValueElement={<span>{sellerInfo.Business_Phone}</span>} />
                                <CaseSellerInfoRow cellLabel={translate('Paragon_TAM_CM_Tenant_ID') + ":"} cellValueElement={<span>{compositeCaseDetails?.caseDetails?.tenantId}</span>} />
                                <CaseSellerInfoRow cellLabel={translate('Paragon_TAM_CM_Created_date') + ":"} cellValueElement={<span>{formatDate(compositeCaseDetails?.caseDetails?.creationDate || "", 'default')}</span>} />
                                <CaseSellerInfoRow cellLabel={translate('Paragon_TAM_CM_Action_By') + ":"} cellValueElement={<span>{formatDate(compositeCaseDetails?.caseDetails?.nextResponseExpirationDate || "", 'default')}</span>} />
                                <CaseSellerInfoRow cellLabel={translate('Paragon_TAM_CM_Severity') + ":"} cellValueElement={<span>{compositeCaseDetails?.caseDetails?.severity}</span>} />
                                <CaseSellerInfoRow cellLabel={translate('Paragon_TAM_CM_Type') + ":"} cellValueElement={<span>{sellerInfo.Type}</span>} />
                                <CaseSellerInfoRow cellLabel={translate('Paragon_TAM_CM_Phone_Support') + ":"} cellValueElement={<span>{sellerInfo.Phone_Support}</span>} />
                                <CaseSellerInfoRow cellLabel={translate('Paragon_TAM_CM_Staging') + ":"} cellValueElement={<span>{sellerInfo.Staging}</span>} />
                                <CaseSellerInfoRow cellLabel={translate('Paragon_TAM_CM_2SV_Enabled') + ":"} cellValueElement={<span>{sellerInfo["2SV_Enabled"]}</span>} />
                                <CaseSellerInfoRow cellLabel={translate('Paragon_TAM_CM_Reason_Code') + ":"} cellValueElement={<span className={$style.reasonCode}>{compositeCaseDetails?.caseDetails?.caseReason?.reason}</span>} />
                                {sellerInfo.Services && <CaseSellerInfoRow className={$style.brandsListRow}  cellLabel={translate('Paragon_TAM_CM_Services') + ":"} cellValueElement={
                                    <ExpandableSellerInfoRow caseId={compositeCaseDetails?.caseDetails?.caseId || ""} agentLogin={userDetails?.agentLogin} clickStream={clickStream}
                                                             type="services" values={sellerInfo?.Services.split(',')}/>} />}
                                {brandsList.length > 0 && <CaseSellerInfoRow className={$style.brandsListRow} cellLabel={translate('Paragon_TAM_CM_Brands') + ":"} cellValueElement={
                                    <ExpandableSellerInfoRow caseId={compositeCaseDetails?.caseDetails?.caseId || ""} agentLogin={userDetails?.agentLogin} clickStream={clickStream}
                                        type="brands" values={brandsList}/>} /> }
                                <CaseSellerInfoRow cellLabelElement={<KatLink
                                    onClick={() => {
                                        logClickStreamData(clickStream, 'click', "llm-issue-summary-case-history-link-clicked")
                                        logger.info(`User: ${userDetails?.agentLogin} clicked on Case History link for Case: ${compositeCaseDetails?.caseDetails?.caseId}`);
                                    }}
                                    label={translate('Paragon_TAM_CM_View_Case_History') + ":"}  target='_blank' variant='link'
                                    href={domain + '/hz/casehistory?caseId=' + compositeCaseDetails?.caseDetails?.caseId}
                                />} />
                            </div>
                        }
                        <div className={$style.expandLink} onClick={toggleExpand} >
                            <KatIcon name={isComponentExpanded ? 'chevron-up' : 'chevron-down'} size="small"/>
                            <span className={$style.expandLinkText}>{!isComponentExpanded ? translate('Paragon_TAM_CM_Show_More') + ":" : translate('Paragon_TAM_CM_Hide_More') + ":"}</span>
                        </div>
                    </KatTableBody>
                </KatTable>
            }
            {showPeekNowButton && <CaseSellerInfoRow className={$style.peeknowButton} cellValueElement={<PeekNowWidget compositeCaseDetails={compositeCaseDetails} userDetails={userDetails}/>} />}
        </div>
    )
}
