import { IssueSummaryFunctions } from "@amzn/paragon-context/lib/IssueSummaryContext/models/IssueSummaryFunctions";
import { AgentTransferRequest, AgentTransferResponse } from "@amzn/paragon-context/lib/IssueSummaryContext/models/TransferFunctions/AgentTransfer";
import { AttributeTransferRequest, AttributeTransferResponse } from "@amzn/paragon-context/lib/IssueSummaryContext/models/TransferFunctions/AttributeTransfer";
import { EmailTransferRequest, EmailTransferResponse } from "@amzn/paragon-context/lib/IssueSummaryContext/models/TransferFunctions/EmailTransfer";
import { ExternalTeamTransferRequest, ExternalTeamTransferResponse } from "@amzn/paragon-context/lib/IssueSummaryContext/models/TransferFunctions/ExternalTeamTransfer";
import { QueueTransferRequest, QueueTransferResponse } from "@amzn/paragon-context/lib/IssueSummaryContext/models/TransferFunctions/QueueTransfer";
import { ProxyErrorResponse, ProxySuccessResponse } from "@amzn/paragon-context/lib/ParagonWidgetDataContext/models/Proxy";

export class TransferService {

  private readonly methods: IssueSummaryFunctions;

  constructor(widgetMethods: IssueSummaryFunctions) {
    this.methods = widgetMethods;
  }

  async attributeTransfer(request: AttributeTransferRequest): Promise<AttributeTransferResponse> {
    return this.methods.attributeTransfer(request).then(response => this.interceptContextProviderResponse(response))
  }

  async emailTransfer(request: EmailTransferRequest): Promise<EmailTransferResponse> {
    return this.methods.emailTransfer(request).then(response => this.interceptContextProviderResponse(response))
  }

  async queueTransfer(request: QueueTransferRequest): Promise<QueueTransferResponse> {
    return this.methods.queueTransfer(request).then(response => this.interceptContextProviderResponse(response))
  }

  async externalTeamTransfer(request: ExternalTeamTransferRequest): Promise<ExternalTeamTransferResponse> {
    return this.methods.externalTeamTransfer(request).then(response => this.interceptContextProviderResponse(response))
  }

  async agentTransfer(request: AgentTransferRequest): Promise<AgentTransferResponse> {
    return this.methods.agentTransfer(request).then(response => this.interceptContextProviderResponse(response))
  }

  /**
   * This interceptor dewraps the decorated response that comes from provider, 
   * so the catch clauses can work as usual. 
   * TODO: Once the support for catch in provider is enabled this should be removed.
   * @param response 
   * @returns Response wrapped with ProxySuccessResponse
   */
  private interceptContextProviderResponse(response: ProxySuccessResponse<any> | ProxyErrorResponse) {
    if (!response.success) throw response as ProxyErrorResponse;
    return (response as ProxySuccessResponse<any>).data;
  }
}