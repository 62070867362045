import { nothing } from 'lit-html';
import { ifDefined } from 'lit-html/directives/if-defined';
import { html, property } from 'lit-element';
import {
  KatLitElement,
  register,
  event,
  EventEmitter,
  Keys,
} from '../../shared/base';
import baseStyles from '../../shared/base/base.lit.scss';
import styles from './tag.lit.scss';
import getString from './strings';

/**
 * @component {kat-tag} KatTag Tags provide an interactive element that triggers events, filters content, or makes a selection.
 * @guideline Do Keep tag text to 2 or fewer words whenever possible. Use numerals when appropriate.
 * @guideline Do Position tags in an appropriate context, close to the content they interact with.
 * @guideline Do Use Selectable tags in a group of two or more.
 * @guideline Dont Don’t use more than 1 or 2 words of text per tag.
 * @guideline Dont Don’t include icons or images.
 * @guideline Dont Don’t use tag to replace Button. Unlike buttons, which are persistent, tags present dynamic options relevant to the specific context.
 * @guideline Dont Don’t use tag to indicate static attributes such as quantity, measurement, or status. Use Badge instead.
 * @status Production
 * @theme flo
 * @example Default {"label": "Clickable", "type": "clickable"}
 * @example Dismissible {"label": "Dismissible", "type": "dismissible"}
 * @example Selectable {"label": "Selectable", "type": "selectable"}
 * @slot default Contents will be used as the main tag content.
 * @a11y {keyboard}
 * @a11y {sr}
 * @a11y {contrast}
 */
@register('kat-tag')
export class KatTag extends KatLitElement {
  static get styles() {
    return [baseStyles, styles];
  }

  /** The text that will be displayed to the user. */
  @property()
  label: string;

  /**
   * The type of tag to display to the user
   * @enum {value} clickable Used to trigger an event.
   * @enum {value} dismissible Used to trigger an event. Dismissible tags should be removed from the page on click.
   * @enum {value} selectable Used in a group of two or more to make selections and/or filter content.
   */
  @property()
  type: 'clickable' | 'dismissible' | 'selectable' = 'clickable';

  /** Whether or not a tag is selected. Only available on tags with type of 'selectable'. Default is false. */
  @property()
  selected?: boolean;

  /** Whether or not the tag is dismissed. Only available on tags with a type of 'dismissible'. Default is false. */
  @property()
  dismissed?: boolean;

  /** Whether or not the tag is disabled. Only available for tags with a type of 'selectable'. Cannot be mixed with 'selected'. Default is false. */
  @property()
  disabled?: boolean;

  /** Defines the label read to screen-reader users when they select the tag. On dismissible tags, kat-aria-label is placed on the dismiss button instead of the tag.*/
  @property({ attribute: 'kat-aria-label' })
  katAriaLabel?: string;

  /** Emits when tag is dismissed. To prevent tag from becoming hidden on click, call the preventDefault() method on the event. Only available for dismissible tags. */
  @event('dismiss', { cancelable: true })
  private _dismiss: EventEmitter<{ dismissed: boolean }>;

  shouldUpdate(changingProperties: Map<string, any>) {
    if (changingProperties.has('disabled')) {
      if (this.type !== 'selectable') {
        // only 'selectable' type KatTags can be disabled
        this.disabled = false;
      }
      // disabled KatTags cannot be selected, even if they are 'selectable'
      this.selected = false;
    }

    if (changingProperties.has('selected') && this.type !== 'selectable') {
      this.selected = false;
    }

    if (changingProperties.has('dismissed') && this.type !== 'dismissible') {
      this.dismissed = false;
    }

    return true;
  }

  private getAriaRole() {
    if (this.type === 'selectable') {
      return 'option';
    }

    return 'button';
  }

  private getDismissAriaLabel() {
    return this.katAriaLabel || getString('kat-tag-aria-label-dismiss');
  }

  private getSelectAriaLabel() {
    return this.katAriaLabel || getString('kat-tag-aria-label-select');
  }

  private getAriaSelected() {
    if (this.type === 'selectable') {
      return !!this.selected;
    }
    return undefined;
  }

  private getAriaDisabled() {
    if (this.type === 'selectable') {
      return !!this.disabled;
    }
    return undefined;
  }

  /**
   * Call to dismiss the tag. Emits the dismiss event.
   *
   * @katalmethod
   */
  async dismiss() {
    const canceled = !this._dismiss.emit({ dismissed: true });

    if (canceled) {
      return;
    }

    this.dismissed = true;
    await this.updateComplete;
  }

  private handleClick() {
    if (this.disabled) {
      return;
    }

    if (this.type === 'selectable') {
      this.selected = !this.selected;
    } else if (this.type === 'dismissible') {
      // Do nothing - click is handled on 'X' button
    } else {
      this.selected = false;
      this.dismissed = false;
    }
  }

  private handleKeydown(e) {
    if (e.keyCode === Keys.Enter || e.keyCode === Keys.Space) {
      e.preventDefault();
      e.target.click();
    }
  }

  private renderDismissIcon() {
    if (this.type !== 'dismissible') {
      return nothing;
    }

    return html`
      <div
        class="dismiss-icon-container"
        @click=${this.dismiss}
        @keydown=${this.handleKeydown}
        tabindex="0"
        part="dismiss"
        role="button"
        aria-label=${this.getDismissAriaLabel()}
      >
        <kat-icon name="exit" size="xsmall"></kat-icon>
      </div>
    `;
  }

  render() {
    return html`
      <span
        part="label"
        class="label"
        tabindex=${this.type === 'dismissible' || this.disabled ? '-1' : '0'}
        @click=${this.handleClick}
        @keydown=${this.handleKeydown}
        ?selected=${this.selected}
        role=${this.getAriaRole()}
        aria-selected=${ifDefined(this.getAriaSelected())}
        aria-disabled=${ifDefined(this.getAriaDisabled())}
        aria-label=${ifDefined(
          this.type !== 'dismissible' ? this.getSelectAriaLabel() : undefined
        )}
      >
        <span class="label__inner"><slot>${this.label || nothing}</slot></span>
        ${this.renderDismissIcon()}
      </span>
    `;
  }
}
