import { WidgetFunctions } from '@amzn/paragon-context/lib/ParagonWidgetDataContext/models/WidgetFunctions';
import {
    GetNextSOPStepResponseData,
    PredictSOPsFromCaseInfoResponseData
} from '../model/ActionPlanTypes';

export async function getPredictedSOPs(
    caseId: string | undefined,
    caseSubject: string | undefined,
    numSops: number,
    widgetMethods: WidgetFunctions,
) {
    const params = {
        numSops: numSops,
        caseDocument: {
            caseId: caseId,
            caseSubject: caseSubject,
            caseContactText: "",
        },
    }

    return await widgetMethods.proxyPost<PredictSOPsFromCaseInfoResponseData>(
        "predictSOPsFromCaseInfo",
        params
    );
}

export async function getActivityStep(
    sopId: string,
    widgetMethods: WidgetFunctions,
    actions?: string,
    selectedOutcome?: string,
) {
    const params = {
        sopId: sopId,
        actions: actions,
        selectedOutcome: selectedOutcome,
    }

    return await widgetMethods.proxyPost<GetNextSOPStepResponseData>(
        "getNextSOPStep",
        params
    );

}