import { nothing, render } from 'lit-html';
import { spreadProps } from '@open-wc/lit-helpers';
import { html, property } from 'lit-element';
import { ifNotNull } from '../../utils/directives';
import { cleanDefaultSlot } from '../../shared/slot-utils';
import { createElement } from '../../shared/utils';
import {
  KatLitElement,
  register,
  nextUniqueId,
  event,
  EventEmitter,
} from '../../shared/base';

import baseStyles from '../../shared/base/base.lit.scss';
import styles from './radiobutton-group.lit.scss';

/**
 * @component {kat-radiobutton-group} KatalRadioButtonGroup A radio button group combines multiple radio buttons, allowing users to select a single option from a list of several options.
 * @guideline Do Ensure all input fields have a label.
 * @status Production
 * @theme flo
 * @slot label Static slot. Content to be displayed on top of the component. Defaults to empty.
 * @example Basic {"name": "group1", "value": "one", "label":"Radio Button Group", "options": [{"value": "one", "label": "First option"}, {"value": "two", "label": "Second option"},{"value": "three", "label": "Third option"}]}
 * @example Disabled {"name": "group1", "disabled": true, "value": "one", "label":"Radio Button Group", "options": [{"value": "one", "label": "First option"}, {"value": "two", "label": "Second option"}, {"value": "three", "label": "Third option"}]}
 * @example Complex {"name": "group1", "value": "one", "label":"Radio Button Group", "constraint-label": "Longer description of the group.", "error-label": "Please select a option", "options": [{"value": "one", "label": "First option", "constraintLabel": "secondary label on first option"}, {"value": "two", "label": "Second option (disabled)", "disabled": true}, {"value": "three", "label": "Third option"}]}
 * @a11y {keyboard}
 * @a11y {sr}
 * @a11y {contrast}
 */
@register('kat-radiobutton-group')
export class KatRadiobuttonGroup extends KatLitElement {
  /** The name used to group radio buttons. */
  @property()
  name: string;

  /** If set then the child button with the same value will be selected. */
  @property()
  value?: string;

  /** An array of options used to populate the group with radio buttons. Each item in the array is a object and the properties are assigned to the radio buttons as properties. e.g. [{"label":"First","value":"1", "constraintLabel": "secondary label"}, {"label":"Second","value":"2"}] */
  @property()
  options: {
    name: string;
    disabled?: boolean;
    label: string;
    constraintLabel?: string;
    value: string;
  }[];

  /** The title of the whole group. */
  @property()
  label?: string;

  /** A message explaining the purpose of the group. */
  @property({ attribute: 'constraint-label' })
  constraintLabel?: string;

  /** The error message to display for the group. */
  @property({ attribute: 'error-label' })
  errorLabel?: string;

  /** If set will disable all radio buttons in the group. */
  @property()
  disabled?: boolean;

  _groupLabelId = nextUniqueId();
  _groupConstraintLabelId = nextUniqueId();

  /** Fires whenever the selected radio button changes. */
  @event('change', true)
  private _change: EventEmitter<{ value: string }>;

  /** Fires whenever a radio button in the group is focused and the last focused element was outside the group. */
  @event('focus', true)
  private _focus: EventEmitter;

  /** Fires whenever focus leaves any of the radio buttons in the group to an element outside of the group. */
  @event('blur', true)
  private _blur: EventEmitter;

  static get styles() {
    return [baseStyles, styles];
  }

  constructor() {
    super();

    this._lastFocusedRadio = null;
    this._lastBlurredRadio = null;

    this.handleRadioFocus = this.handleRadioFocus.bind(this);
    this.handleRadioBlur = this.handleRadioBlur.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);

    cleanDefaultSlot(this);
  }

  updated(changedProps) {
    // This code is necessary to put the options into the light DOM. If the options were placed
    // in the fallback content of the slot they would not be visible to forms.
    if (
      changedProps.has('options') ||
      changedProps.has('value') ||
      changedProps.has('disabled') ||
      changedProps.has('name')
    ) {
      if (!this.options || this.options.length === 0) return;
      let slot = this.getElementsByClassName('kat-options')[0];
      if (!slot) {
        slot = createElement('div', { class: 'kat-options kat-spacing-group' });
        this.appendChild(slot);
      }
      const radioButtons = this.options.map(option => {
        const { name, disabled = false, ...otherProps } = option;

        return html`
          <kat-radiobutton
            .name=${this.name}
            ?checked=${option.value === this.value}
            ?disabled=${this.disabled || disabled}
            @focus=${this.handleRadioFocus}
            @blur=${this.handleRadioBlur}
            @change=${this.handleRadioChange}
            ...=${spreadProps(otherProps)}
          ></kat-radiobutton>
        `;
      });
      render(html`${radioButtons}`, slot);
    }
  }

  handleRadioChange(e) {
    const radio = e.target;
    this.value = radio.value;

    this._change.emit({ value: this.value });
  }

  handleRadioFocus(e) {
    e.stopPropagation();
    this._lastFocusedRadio = e.target;

    if (this._lastBlurredRadio === null) {
      this._focus.emit();
    }
  }

  handleRadioBlur(e) {
    e.stopPropagation();
    this._lastBlurredRadio = e.target;
    this._lastFocusedRadio = null;

    // The focus handler above should be executed before this setTimeout callback
    setTimeout(() => {
      if (this._lastFocusedRadio === null) {
        this._blur.emit();
      }
      this._lastBlurredRadio = null;
    }, 0);
  }

  /**
   * Programmatically focus the group. Focuses the first radio button.
   * @katalmethod
   */
  focus() {
    const first = this.getElementsByTagName('kat-radiobutton')[0];
    if (first) {
      first.focus();
    }
  }

  /**
   * Programmatically blur any focused radio buttons.
   * @katalmethod
   */
  blur() {
    Array.from(this.getElementsByTagName('kat-radiobutton')).forEach(button => {
      button.blur();
    });
  }

  render() {
    const error = this.errorLabel
      ? html`
          <kat-statusindicator
            label=${this.errorLabel}
            variant="error"
            class="label error"
          ></kat-statusindicator>
        `
      : nothing;

    const constraintLabel = this.constraintLabel
      ? html`
          <div class="label constraint" id=${this._groupConstraintLabelId}>
            ${this.constraintLabel}
          </div>
        `
      : nothing;

    return html`
      <div class="labels">
        <div class="label default">
          <slot name="label" id=${this._groupLabelId}
            >${this.label || nothing}</slot
          >
        </div>
        ${constraintLabel} ${error}
      </div>
      <div class="items">
        <slot
          role="radiogroup"
          aria-disabled=${ifNotNull(this.disabled ? 'true' : undefined)}
          aria-labelledby=${this._groupLabelId}
          aria-describedby=${ifNotNull(
            this.constraintLabel ? this._groupConstraintLabelId : undefined
          )}
        >
        </slot>
      </div>
    `;
  }
}
