import { KatalComponent, register } from '../../shared/base';

/**
 * @component {kat-table} KatalTable Table is a simple, re-skinned HTML table with no additional functionality. This component has been superseded by the <a href="/components/data-table/">data table</a> for most use cases and should not be used unless your table is extremely small (<5 rows) and does not require anything beyond static data display.
 * @slot default Should contain `kat-table-*` elements.
 * @example SimpleTable {"content": "<kat-table-head><kat-table-row><kat-table-cell>Header 1</kat-table-cell><kat-table-cell>Header 2</kat-table-cell></kat-table-row></kat-table-head><kat-table-body><kat-table-row><kat-table-cell>Value 1</kat-table-cell><kat-table-cell>Value 2</kat-table-cell></kat-table-row></kat-table-body><kat-table-footer></kat-table-footer>"}
 * @status Production
 * @themes flo
 * @a11y {keyboard}
 * @a11y {sr}
 * @a11y {contrast}
 */
@register('kat-table')
export class KatTable extends KatalComponent {
  static get observedAttributes() {
    return [];
  }

  constructor() {
    super(KatTable.observedAttributes);
  }

  connectedCallback() {
    super.connectedCallback();

    // the 'role' attribute should be 'table'
    this.setAttribute('role', 'table');
  }
}
