import { html, property } from 'lit-element';
import { nothing } from 'lit-html';
import { KatLitElement, register } from '../../shared/base';
import baseStyles from '../../shared/base/base.lit.scss';
import styles from './optgroup.lit.scss';

/**
 * @component {kat-optgroup} KatalOptgroup A grouping of <kat-option>'s that can be selected inside a <kat-dropdown>.
 * @status Production
 * @theme flo
 * @slot label The inner HTML of a label representing the grouping of options.
 * @slot default A kat-option. See kat-option slots for more details.
 */
@register('kat-optgroup')
export class KatOptgroup extends KatLitElement {
  /** The label of the option group. If searched for, will reveal itself and all children. */
  @property()
  label: string;

  /** The icon of the option group. */
  @property()
  icon?: string;

  /**
   * The search key used to override the search behavior of this option. When the dropdown
   * is searchable and this field is present it will be used instead of the label for searching.
   */
  @property()
  searchkey?: string;

  /** A disabled option group displays in dimmed text and neither itself nor its children can be selected. */
  @property()
  disabled?: boolean;

  /** Indicates whether the dropdown should allow multiple selections. */
  @property()
  multiple?: boolean;

  /**
   * Internal property. Indicates whether the checkbox in multiple mode shall be checked fully or partially.
   * True when some, but not all, options within the group are selected.
   * Used for Select All option.
   */
  @property()
  indeterminate?: boolean;

  /**
   * Internal property. Indicates whether the checkbox in multiple mode is selected when all child options are selected.
   * Used for Select All option.
   */
  @property()
  selected?: boolean;

  static get styles() {
    return [baseStyles, styles];
  }

  firstUpdated() {
    this.setAttribute('tabindex', '-1');
  }

  renderSelectBox() {
    if (!this.multiple) {
      return nothing;
    }

    return html`
      <kat-checkbox
        id="select-option"
        tabindex="-1"
        ?disabled=${this.disabled}
        ?checked=${this.selected && !this.indeterminate}
        ?indeterminate=${this.indeterminate}
      ></kat-checkbox>
    `;
  }

  renderGroupLabel() {
    if (!this.icon && !this.label) {
      return nothing;
    }

    return html`
      <div class="standard-optgroup-label">
        ${this.label ? html`<span>${this.label}</span>` : nothing}
        ${this.icon
          ? html`
              <div class="standard-optgroup-icon">
                <kat-icon name="${this.icon}" size="tiny"></kat-icon>
              </div>
            `
          : nothing}
      </div>
    `;
  }

  render() {
    return html`
      <div class="group-wrapper" role="group">
        <div class="header-wrapper" tabindex="-1">
          ${this.renderSelectBox()}
          <slot name="label"> ${this.renderGroupLabel()} </slot>
        </div>
        <slot></slot>

        <span class="ring"></span>
      </div>
    `;
  }
}
