import React, { FunctionComponent, useEffect } from 'react';
import {localization} from 'src/helpers';

import { KatButton, KatTextarea } from '@amzn/katal-react';
import { paragonCoreDataContext } from '@amzn/paragon-context/lib/ParagonCoreDataContext';
import { paragonMediator } from '@amzn/paragon-context/lib/ParagonMediatorContext/paragonMediatorContext';
import { paragonWidgetDataContext } from '@amzn/paragon-context/lib/ParagonWidgetDataContext';
import '@amzn/paragon-dynamic-view-renderer/lib/styles.css';
import {
  ControlSpacing,
  PageSection,
  PageSectionPadding,
  useParagonContextConsumerEl,
  useParagonContext,
} from '@amzn/paragon-ui-react';
import '@amzn/paragon-ui-react/lib/styles.css';

import { useKeyboardShortcut } from 'src/context/useKeyboardShortcuts';

export const DemoWidget: FunctionComponent = () => {
  const el = useParagonContextConsumerEl();

  const {
    value: { userDetails, caseDetails },
  } = useParagonContext(el, paragonCoreDataContext);

  const { translate } = localization();

  const { methods: widgetMethods } = useParagonContext(
    el,
    paragonWidgetDataContext,
  );

  const { paragonConsumerEl } = useKeyboardShortcut(
    'katal-app-example-shortcut',
    [],
    '',
    () => {
      alert('hi from a katal app');
    },
  );

  const mediatorTest = () => {
    paragonMediator.notify('attach-blurb', {
      blurbContent: 'Mediator test blurb',
      propertyValue: 'FinalWord',
    });
  };

  return (
    <PageSection title="Demo Widget" widgetId="demo-widget">
      {paragonConsumerEl}

      <PageSectionPadding>
        <ControlSpacing column>
          {caseDetails && (
            <div>
              <h1>Case #{caseDetails.id}</h1>
            </div>
          )}

          {!caseDetails && (
            <h4>
              The widget needs to be rendered inside of Paragon to load the case
              and Agent details
            </h4>
          )}

          {userDetails && <h4> User Login: {userDetails.agentLogin}</h4>}

          <KatTextarea />

          <ControlSpacing row>
            <KatButton label="Submit Text" onClick={() => {}} />

            <KatButton label="Clear Text" onClick={() => {}} />
          </ControlSpacing>

          <ControlSpacing row>
            <KatButton onClick={mediatorTest}>MediatorTest</KatButton>

            <KatButton
              label="Invoke proxy Method"
              onClick={async () => {
                try {
                  const response = await widgetMethods.proxyPost(
                    'getCustomerInfo',
                    {
                      tenantId: '1',
                      agentLogin: 'kvvvarma',
                      customerId: '503348183702',
                      marketplaceId: '1',
                    },
                  );
                  if (response.success) {
                    console.log(response);
                  } else {
                    console.error(response.message); // paragon response error code: 400/500 etc
                  }
                } catch (e) {
                  console.error(e); // provider not found
                }
              }}
            />
          </ControlSpacing>
        </ControlSpacing>
      </PageSectionPadding>
    </PageSection>
  );
};
