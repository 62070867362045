
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import * as tardisConfig from 'src/configuration/tardis-config.json';

import {
  KatAlert,
  KatBox,
  KatButton,
  KatCheckbox,
  KatDivider,
} from '@amzn/katal-react';
import '@amzn/paragon-dynamic-view-renderer/lib/styles.css';
import { paragonCoreDataContext } from '@amzn/paragon-context/lib/ParagonCoreDataContext';
import { issueSummaryContext } from "@amzn/paragon-context/lib/IssueSummaryContext";
import { GetQueueDataResponse } from "@amzn/paragon-context/lib/IssueSummaryContext/models/TransferFunctions/GetQueueData";
import { GetExternalTeamDataResponse } from "@amzn/paragon-context/lib/IssueSummaryContext/models/TransferFunctions/GetExternalTeamData";
import { AgentSearchRequest, AgentSearchResponse } from "@amzn/paragon-context/lib/IssueSummaryContext/models/TransferFunctions/AgentSearch";
import { QueueTransferRequest, QueueTransferResponse } from "@amzn/paragon-context/lib/IssueSummaryContext/models/TransferFunctions/QueueTransfer";
import { ExternalTeamTransferRequest, ExternalTeamTransferResponse } from "@amzn/paragon-context/lib/IssueSummaryContext/models/TransferFunctions/ExternalTeamTransfer";
import { AgentTransferRequest, AgentTransferResponse } from "@amzn/paragon-context/lib/IssueSummaryContext/models/TransferFunctions/AgentTransfer";
import { EmailTransferRequest, EmailTransferResponse } from '@amzn/paragon-context/lib/IssueSummaryContext/models/TransferFunctions/EmailTransfer';
import { AttributeTransferRequest, AttributeTransferResponse } from '@amzn/paragon-context/lib/IssueSummaryContext/models/TransferFunctions/AttributeTransfer';
import {
  ControlSpacing,
  useParagonContextConsumerEl,
  useParagonContext,
} from '@amzn/paragon-ui-react';
import '@amzn/paragon-ui-react/lib/styles.css';

import $style from './transfer-case.module.scss';
import { paragonMediator } from '@amzn/paragon-context/lib/ParagonMediatorContext/paragonMediatorContext';
import { AttributeTransfer } from 'src/widgets/TransferCase/components/attribute-transfer/AttributeTransfer';
import { ManualTransfer } from 'src/widgets/TransferCase/components/manual-transfer/ManualTransfer';
import { localization } from "src/helpers";
import { ServicesAPIContext } from 'src/service/service.context';
import { TardisQueue, TardisTransfer } from "src/widgets/TransferCase/components/tardis-transfer/TardisTransfer";
import KatalMetricsPublisher from '@amzn/katal-metrics/lib/KatalMetricsPublisher';
import { MetricsType } from 'src/metrics';

type Props = {
  onTransferCancel: () => void;
  isloadingData: boolean;
  externalData?: GetExternalTeamDataResponse;
  queueData?: GetQueueDataResponse;
  metricsPublisher?: KatalMetricsPublisher
}

export enum TransferType {
  MANUAL_TRANSFER, TARDIS_TRANSFER, ATTRIBUTE_TRANSFER
}

export const TransferCase: FunctionComponent<Props> = ({ onTransferCancel, isloadingData, externalData, queueData, metricsPublisher }) => {
  const [loadSuccessContainer, setLoadSuccessContainer] = useState(false);
  const [alertDescription, setAlertDescription] = useState('');
  const [alertHeader, setAlertHeader] = useState('');
  const [loadEmailTransferContainer, setLoadEmailTransferContainer] = useState(false);
  const [showTransferError, setShowTransferError] = useState(false);

  const [selectedTransferType, setSelectedTransferType] = useState('QueueNew');
  const [selectedLanguage, setSelectedLanguage] = useState('');

  const [agentData, setAgentData] = useState<AgentSearchResponse[]>();
  const [loadingAttribute, setLoadingAttribute] = useState(false);
  const [submitTransfer, setSubmitTransfer] = useState(false);
  const [annotationText, setAnnotationText] = useState('');
  const [selectedQueueAgentTeam, setSelectedQueueAgentTeam] = useState('');
  const [currentOwner, setCurrentOwner] = useState(false);
  const [languageCheckBox, setLanguageCheckBox] = useState(false);
  const [manualTransferAlert, setManualTransferAlert] = useState(false);
  const [attributeTransferAlert, setAttributeTransferAlert] = useState(false);
  const [languageAlert, setLanguageAlert] = useState(false);
  const [advancedTransferEnabled, setAdvancedTransferEnabled] = useState(false);
  const [selectedTransferOption, setSelectedTransferOption] = useState(TransferType.MANUAL_TRANSFER);
  const [workItemId, setWorkItemId] = useState<string|undefined>(undefined);
  const [currentQueueConfig, setCurrentQueueConfig] = useState<TardisQueue|undefined>(undefined);

  const el = useParagonContextConsumerEl();

  const {
    value: { caseDetails, userDetails },
  } = useParagonContext(el, paragonCoreDataContext);


  const { methods: widgetMethods } = useParagonContext(
    el,
    issueSummaryContext,
  );

  const serviceAPIContext = useContext(ServicesAPIContext);

  const { translate } = localization();

  function onTransferClickHandler() {
    if (selectedTransferOption === TransferType.ATTRIBUTE_TRANSFER && !languageCheckBox) {
      setAttributeTransferAlert(true);
      return;
    } else if (selectedTransferOption === TransferType.ATTRIBUTE_TRANSFER && languageCheckBox && !selectedLanguage) {
      setLanguageAlert(true);
      return;
    } else if (selectedTransferOption === TransferType.MANUAL_TRANSFER && selectedQueueAgentTeam === '') {
      setManualTransferAlert(true);
      return;
    }
    
    metricsPublisher?.publishCounterMonitor(MetricsType.TRANSFER_CASE_TRIGGER, 1);

    getWorkitemId().then((response) => {
      const workItemId = response;
      setWorkItemId(workItemId);
      switch (selectedTransferOption) {
        case TransferType.MANUAL_TRANSFER:
          switch (selectedTransferType) {
            case 'QueueNew':
              workItemId ? queueTransferLiveChannel(workItemId) : queueTransferSubmit();
              break;
            case 'ExternalNew':
              externalTransferSubmit();
              break;
            case 'AgentNew':
              agentTransferSubmit();
              break;
          }
          break;
        case TransferType.ATTRIBUTE_TRANSFER:
          workItemId ? transferByAttribute(workItemId) : transferByEmail();
          break;
        case TransferType.TARDIS_TRANSFER:
          workItemId ? setSubmitTransfer(true) : paragonMediator.notify('append-header-error-message', { 'errorMessage': 'Paragon_TAM_CM_Missed_Chat' });
          break;
      }
    });
  }

  function getWorkitemId() {
    const request = caseDetails?.id || '';
    return widgetMethods.getTransferWorkItemId(request);
  }

  function addConnection() {
    const request = caseDetails?.id || '';
    return widgetMethods.addConnectConnection(request);
  }

  function getConnectState() {
    const request = caseDetails?.id || '';
    return widgetMethods.getConnectState(request);
  }

  function transferByAttribute(workitemId) {
    const request: AttributeTransferRequest = {
      workItemId: workitemId || ''
    }
    setLoadingAttribute(true);
    const startTime = new Date();
    serviceAPIContext.transferService.attributeTransfer(request).then((response: AttributeTransferResponse) => {
      updateAttributeTransferAlerts(response.isTransferCaseSuccess, response.isEmailTransfer, response.header, response.message);
      metricsPublisher?.publishTimerMonitor(MetricsType.TRANSFER_BY_ATTRIBUTE_DURATION, new Date().getTime() - startTime.getTime());
      addConnection().then((response) => {
        console.info('Adding connection to connect hybrid endpoint:', response);
      }).catch((error) => {
        metricsPublisher?.publishCounterMonitor(MetricsType.ADDING_CH_CONNECTION_ERROR, 1);
        paragonMediator.notify('append-header-error-message', { 'errorMessage': 'Paragon_TAM_CM_An_error_occurred_please_try_again_later' })
      })
    }).catch((error) => {
      metricsPublisher?.publishCounterMonitor(MetricsType.TRANSFER_BY_ATTRIBUTE_ERROR, 1);
      handleTransferByAttributeError(error);
    }).finally(() => {
      setLoadingAttribute(false);
    })
  }

  function handleTransferByAttributeError(error) {
    const isTransferCaseSuccess = error.success ?? '';
    const isEmailTransfer = error.message?.isEmailTransfer ?? false;
    const header = error.message?.header ?? '';
    const message = error.message?.message ?? '';
    setShowTransferError(true);
    console.error(`Error in transferring case using attribute based transfer routing ${error}`);
    updateAttributeTransferAlerts(isTransferCaseSuccess, isEmailTransfer, header, message);
  }

  function updateAttributeTransferAlerts(isTransferSuccess: string,
    isEmailTransferEnabled: boolean,
    header: string,
    message: string) {
    setLoadSuccessContainer(isTransferSuccess === 'true');
    setLoadEmailTransferContainer(isEmailTransferEnabled)
    setAlertHeader(header)
    setAlertDescription(message)
  }

  function transferByEmail() {
    let contactState: string | undefined = undefined;
    const startTime = new Date();
    getConnectState().then((response) => {
      contactState = response;
      if (contactState === "connected") {
        paragonMediator.notify('append-header-error-message', { 'errorMessage': 'Paragon_TAM_CM_CH_End_live_connection_before_transfer_as_email' })
      } else {
        const request: EmailTransferRequest = {
          caseId: caseDetails?.id || ''
        }
        setLoadingAttribute(true);
        serviceAPIContext.transferService.emailTransfer(request).then((response: EmailTransferResponse) => {
          paragonMediator.notify('append-header-success-message', {
            'successMessage': response?.message
          });
          metricsPublisher?.publishTimerMonitor(MetricsType.TRANSFER_BY_EMAIL_DURATION, new Date().getTime() - startTime.getTime());
          console.log("Inside emailTransfer response:", response);
        }).catch((error) => {
          paragonMediator.notify('append-header-error-message', { 'errorMessage': error?.message });
          metricsPublisher?.publishCounterMonitor(MetricsType.TRANSFER_BY_EMAIL_ERROR, 1)
          console.log("Inside queueTransfer error:", error);
        }).finally(() => {
          onTransferCancel();
          setLoadingAttribute(false);
        })
      }
    });
  }

  function queueTransferLiveChannel(workitemId) {
    const request: QueueTransferRequest = {
      caseId: caseDetails?.id || '',
      fromQueueName: caseDetails?.queue || '',
      queueName: selectedQueueAgentTeam,
      keepCurrentOwner: currentOwner,
      advancedTransferEnabled: queueData?.isShowAdvancedTransferCase || false
    }
    setSubmitTransfer(true);
    const startTime = new Date();
    serviceAPIContext.transferService.queueTransfer(request).then((response: QueueTransferResponse) => {
      paragonMediator.notify('append-header-success-message', {
        'successMessage': response?.message
      });
      metricsPublisher?.publishTimerMonitor(MetricsType.TRANSFER_BY_QUEUE_DURATION, new Date().getTime() - startTime.getTime())
      transferByAttribute(workitemId);
    }).catch((error) => {
      paragonMediator.notify('append-header-error-message', { 'errorMessage': error?.message });
      metricsPublisher?.publishCounterMonitor(MetricsType.TRANSFER_BY_QUEUE_ERROR, 1)
      console.log("Inside queueTransfer error:", error);
      onTransferCancel();
    }).finally(()=> {
      setSubmitTransfer(false);
    })
  }

  function queueTransferSubmit() {
    const request: QueueTransferRequest = {
      caseId: caseDetails?.id || '',
      fromQueueName: caseDetails?.queue || '',
      queueName: selectedQueueAgentTeam,
      keepCurrentOwner: currentOwner,
      advancedTransferEnabled: queueData?.isShowAdvancedTransferCase || false
    }
    setSubmitTransfer(true);
    const startTime = new Date();
    serviceAPIContext.transferService.queueTransfer(request).then((response: QueueTransferResponse) => {
      paragonMediator.notify('append-header-success-message', {
        'successMessage': response?.message
      });
      metricsPublisher?.publishTimerMonitor(MetricsType.TRANSFER_BY_QUEUE_DURATION, new Date().getTime() - startTime.getTime())
      console.log("Inside queueTransfer response:", response);
    }).catch((error) => {
      paragonMediator.notify('append-header-error-message', { 'errorMessage': error?.message });
      metricsPublisher?.publishCounterMonitor(MetricsType.TRANSFER_BY_QUEUE_ERROR, 1)
      console.log("Inside queueTransfer error:", error);
    }).finally(() => {
      onTransferCancel();
      setSubmitTransfer(false)
    })
  }
  function externalTransferSubmit() {
    const request: ExternalTeamTransferRequest = {
      caseId: caseDetails?.id || '',
      queueName: selectedQueueAgentTeam,
      annotationText: annotationText,
    }
    setSubmitTransfer(true);
    const startTime = new Date();
    serviceAPIContext.transferService.externalTeamTransfer(request).then((response: ExternalTeamTransferResponse) => {
      paragonMediator.notify('append-header-success-message', {
        'successMessage': response?.message
      });
      metricsPublisher?.publishTimerMonitor(MetricsType.TRANSFER_BY_EXTERNAL_QUEUE_DURATION, new Date().getTime() - startTime.getTime())
      console.log("Inside externalTeamTransfer response:", response);
    }).catch((error) => {
      paragonMediator.notify('append-header-error-message', { 'errorMessage': error?.message });
      metricsPublisher?.publishCounterMonitor(MetricsType.TRANSFER_BY_EXTERNAL_QUEUE_ERROR, 1)
      console.log("Inside externalTeamTransfer error:", error);
    }).finally(() => {
      onTransferCancel();
      setSubmitTransfer(false);
    })
  }
  function agentTransferSubmit() {
    const request: AgentTransferRequest = {
      caseId: caseDetails?.id || '',
      agentLogin: selectedQueueAgentTeam,
    }
    setSubmitTransfer(true);
    const startTime = new Date();
    serviceAPIContext.transferService.agentTransfer(request).then((response: AgentTransferResponse) => {
      paragonMediator.notify('append-header-success-message', {
        'successMessage': response?.message
      });
      metricsPublisher?.publishTimerMonitor(MetricsType.TRANSFER_BY_AGENT_DURATION, new Date().getTime() - startTime.getTime())
      console.log("Inside agentTransfer response:", response);
    }).catch((error) => {
      paragonMediator.notify('append-header-error-message', { 'errorMessage': error?.message });
      metricsPublisher?.publishCounterMonitor(MetricsType.TRANSFER_BY_AGENT_ERROR, 1);
      console.log("Inside agentTransfer error:", error);
    }).finally(() => {
      onTransferCancel();
      setSubmitTransfer(false);
    })
  }

  function fetchAgentData(searchTextVal: string) {
    if (selectedTransferType != "AgentNew") {
      return;
    }
    try {
      const request: AgentSearchRequest = {
        searchText: searchTextVal,
      }
      const promise = widgetMethods.agentSearch(request);
      promise.then((response) => {
        setAgentData(response);
      })
    } catch (error) {
      metricsPublisher?.publishCounterMonitor(MetricsType.AGENT_SEARCH_ERROR, 1);
      console.error(error);
    }
  }
  function selectAttribute() {
    selectTransferOption(TransferType.ATTRIBUTE_TRANSFER);
    setManualTransferAlert(false);
  }
  function selectManual() {
    setSelectedTransferType('QueueNew');
    selectTransferOption(TransferType.MANUAL_TRANSFER);
    setAttributeTransferAlert(false);
    setLanguageAlert(false);
  }
  function changeTransferType(value: string) {
    setSelectedTransferType(value);
  }
  function changeQueueAgentTeam(value: string) {
    setSelectedQueueAgentTeam(value);
  }
  function changeAnnotationText(value: string) {
    setAnnotationText(value);
  }
  function changelanguage(value: string) {
    setSelectedLanguage(value);
  }
  function checkLanguageBox(value: boolean) {
    setLanguageCheckBox(value);
  }

  function selectTransferOption(option: TransferType) {
    setSelectedTransferOption(option);
  }

  function successDismissMethod() {
    setLoadSuccessContainer(false);
    setShowTransferError(false);
    onTransferCancel();
  }
  function errorDismissMethod() {
    setLoadSuccessContainer(false);
    setShowTransferError(false);
  }
  useEffect(() => {
    if (queueData?.isShowAdvancedTransferCase) {
      setAdvancedTransferEnabled(true);
      selectTransferOption(TransferType.ATTRIBUTE_TRANSFER);
    }
  }, [queueData?.isShowAdvancedTransferCase]);

  useEffect(() => {
    const tardisQueues: TardisQueue[] = tardisConfig["tardisQueues"];
    if (tardisQueues && tardisQueues.length > 0) {
      const tardisQueue = tardisQueues.filter(tardisQueue => tardisQueue.queueName === caseDetails?.queue)[0];
      if (tardisQueue !== undefined) {
        getWorkitemId().then((response) => {
          if (response) {
            setWorkItemId(response);
            setCurrentQueueConfig(tardisQueue);
          }
        })
      }
    }
  }, [caseDetails]);

  return (
    <div>
      <KatBox variant="white">
        <h3>{translate('Paragon_TAM_CM_Transfer_Case')}</h3>
        <KatDivider variant="eastern"></KatDivider>
        {showTransferError && !loadSuccessContainer && (
          <KatAlert header={translate(alertHeader)}
            description={translate(alertDescription)}
            variant="danger"
            onDismiss={() => errorDismissMethod()}
          ></KatAlert>
        )}
        {loadSuccessContainer && (
          <KatAlert description={translate(alertDescription)}
            variant="success"
            onDismiss={() => successDismissMethod()}
          ></KatAlert>
        )}
        {advancedTransferEnabled &&
          <AttributeTransfer
            attributeTransferAlert={attributeTransferAlert}
            advancedTransferEnabled={advancedTransferEnabled}
            languageAlert={languageAlert}
            selectAttribute={selectAttribute}
            changelanguage={changelanguage}
            languageCheckBox={languageCheckBox}
            checkLanguageBox={checkLanguageBox}
            loadingAttribute={loadingAttribute && selectedTransferOption === TransferType.ATTRIBUTE_TRANSFER}
            selectedTransferOption={selectedTransferOption}
          />}
        {currentQueueConfig?.chat?.enabled && <TardisTransfer
              selectedTransferOption={selectedTransferOption}
              selectTransferOption={selectTransferOption}
              submitTransfer={submitTransfer}
              onTransferCancel={onTransferCancel}
              updateAttributeTransferAlerts={updateAttributeTransferAlerts}
              transferByAttribute={transferByAttribute}
              currentOwner={currentOwner}
              workItemId={workItemId}
              queueConfig={currentQueueConfig}
              onSubmitFailed={() => setSubmitTransfer(false)}
              metricsPublisher={metricsPublisher}
        />}
        <ManualTransfer
          manualTransferAlert={manualTransferAlert}
          queueData={queueData}
          externalData={externalData}
          agentData={agentData}
          selectedTransferType={selectedTransferType}
          selectManual={selectManual}
          fetchAgentData={fetchAgentData}
          changeQueueAgentTeam={changeQueueAgentTeam}
          changeTransferType={changeTransferType}
          changeAnnotationText={changeAnnotationText}
          isloadingData={isloadingData}
          submitTransfer={submitTransfer}
          selectedTransferOption={selectedTransferOption}
        />

        <KatDivider variant="athens"></KatDivider>
        <div className={$style.transferStaticNote}>{translate('Paragon_TAM_CM_Transfer_Static_Message')}</div>
        {selectedTransferType === 'QueueNew' && (
          <KatCheckbox
            className={$style.lockCaseToOwner}
            onChange={() => {
              setCurrentOwner(!currentOwner);
            }}
            label={translate('Paragon_TAM_CM_Keep_Case_Locked_to_Current_Owner')}
          />
        )}
        <div className={$style.transferButtons}>
          <ControlSpacing row>
            <KatButton
              label={translate('Paragon_TAM_CM_Transfer')}
              variant="primary"
              onClick={() => onTransferClickHandler()}
            ></KatButton>
            <KatButton label={translate('Paragon_TAM_CM_Cancel')} variant="secondary" onClick={onTransferCancel}></KatButton>
          </ControlSpacing>
        </div>
      </KatBox>
    </div >
  );
};

