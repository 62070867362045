import React, { FunctionComponent } from "react";
import { KatSpinner } from "@amzn/katal-react";

interface SpinnerProps {
    size?: 'large'|'small';
}

export const Spinner: FunctionComponent<SpinnerProps> = ({
    size,
}) => {
    return <KatSpinner size={size} />;
};