import {KatButton, KatInputGroup, KatLabel, KatTag} from '@amzn/katal-react';
import React, {useEffect, useState} from 'react';
import SavePrimaryEmailButton from
        'src/widgets/IssueSummaryWidget/components/widgets/ReplyCase/components/composer/SavePrimaryEmailButton/SavePrimaryEmailButton';

import $style from './input-group-tags.module.scss';

interface InputGroupTagsProps {
    caseId?: string;
    metadata?: Record<string, string>;
    isToFormInput?: boolean;
    shouldShowCcList?: boolean;
    shouldShowBccList?: boolean;
    isDisabled?: boolean;
    input?: string[];
    fieldNameDisplay?: string;
    placeholderText?: string;
    updateInput: (input: string[]) => void;
    toggleCcList?: () => void;
    toggleBccList?: () => void;
}

const defaults: InputGroupTagsProps = {
    isToFormInput: false,
    shouldShowCcList: false,
    shouldShowBccList: false,
    isDisabled: false,
    fieldNameDisplay: '',
    placeholderText: '',
    updateInput: () => {}
}

export default function InputGroupTags(props: InputGroupTagsProps = defaults) {
    const [localInput, setLocalInput] = useState<string[]>(props.input || []);

    const submissionKeys = ['Enter', ' ', ',', ';'];

    useEffect(() => {
        setLocalInput(props.input || []);
    }, [props.input]);

    useEffect(() => {
        props.updateInput(localInput);
    }, [localInput]);

    function handleTags(event) {
        if (submissionKeys.includes(event.key)) {
            if (event.target.value !== '') {
                setLocalInput([...localInput, event.target.value]);
                event.target.value = '';
            }
            event.preventDefault();
        }
        else if (event.key === 'Backspace' && localInput.length && event.target.value == 0){
            const tagsCopy = [...localInput];
            tagsCopy.pop();
            event.preventDefault();
            setLocalInput(tagsCopy);
        }
    }

    const removeTags = index => {
        setLocalInput([...localInput.filter(tag => localInput.indexOf(tag) !== index)]);
    };

    return (
        <KatInputGroup>
            {props.fieldNameDisplay &&
            <KatLabel>{props.fieldNameDisplay}</KatLabel>
            }

            <div className={$style.inputGroupTagsContainer}>
                <div className={$style.tagGroup}>
                    {localInput.map((tag, index) => (
                        <KatTag className={$style.tag} data-testid={`katal-tag-input-group-tag-${index}`}
                             label={tag} key={index}
                             type="dismissible"
                             onDismiss={() => removeTags(index)}/>
                    ))}

                    <input
                        data-testid="input-group-tags-input"
                        id="input-group-tags-input-element"
                        type="text"
                        onKeyDown={handleTags}
                        placeholder={props.isDisabled ? '' : props.placeholderText}
                        disabled={props.isDisabled}
                    />
                </div>
            </div>

            {!props.isDisabled && props.isToFormInput && (!props.shouldShowCcList || !props.shouldShowBccList) &&
            <div>
                {props.metadata?.isSaveToFieldEnabled === 'true' &&
                <SavePrimaryEmailButton caseId={props.caseId} toEmailList={localInput} />
                }
                {!props.shouldShowCcList &&
                <KatButton data-testid="katal-button-cc"
                           id="cc-button"
                           label="Cc"
                           variant="secondary"
                           onClick={props.toggleCcList} size="small"/>
                }
                {!props.shouldShowBccList &&
                <KatButton data-testid="katal-button-bcc"
                           id="bcc-button"
                           label="Bcc"
                           variant="secondary"
                           onClick={props.toggleBccList} size="small" />
                }
            </div>
            }
        </KatInputGroup>
    )
}