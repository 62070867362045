import React, { FunctionComponent, useEffect, useState } from 'react';

import { TransferCase } from './TransferCase';
import {
  PageSection,
  useParagonContextConsumerEl,
  useParagonContext
} from "@amzn/paragon-ui-react";
import { issueSummaryContext } from "@amzn/paragon-context/lib/IssueSummaryContext";
import { ParagonEvents } from './model/ParagonEvents';
import { GetQueueDataRequest, GetQueueDataResponse } from "@amzn/paragon-context/lib/IssueSummaryContext/models/TransferFunctions/GetQueueData";
import { GetExternalTeamDataRequest, GetExternalTeamDataResponse } from "@amzn/paragon-context/lib/IssueSummaryContext/models/TransferFunctions/GetExternalTeamData";
import { localization } from "src/helpers";
import { paragonCoreDataContext } from "@amzn/paragon-context/lib/ParagonCoreDataContext";
import initialMetricsPublisher from 'src/metrics';
import KatalMetricsPublisher from '@amzn/katal-metrics/lib/KatalMetricsPublisher';
import { Metric } from '@amzn/katal-metrics';

export const TransferWidget: FunctionComponent = () => {
  const [showTransferContent, setShowTransferContent] = useState(false);
  const el = useParagonContextConsumerEl();
  const { on: toggleTransferEventOn } = useParagonContext(el, issueSummaryContext);
  const {
      value: { caseDetails },
  } = useParagonContext(el, paragonCoreDataContext);
  const [queueData, setQueueData] = useState<GetQueueDataResponse>();
  const { methods } = useParagonContext(el, issueSummaryContext);

  const [externalData, setExternalData] = useState<GetExternalTeamDataResponse>();
  const [isLoadingQueueExternalList, setIsLoadingQueueExternalList] = useState(false);
  const [metricsPublisher, setMetricsPublisher] = useState<KatalMetricsPublisher>();

  const { translate } = localization();

  const toggleExpanded = () => {
    setShowTransferContent(!showTransferContent);
  };

  const onTransferCancel = () => {
        setShowTransferContent(false)
    }

  useEffect(() => {
    const unsubToggleTransfer = toggleTransferEventOn(ParagonEvents.TOGGLE_TRANSFER, () => {
      toggleExpanded();
    });

    return () => {
      unsubToggleTransfer();
    };
  });

  useEffect(() => {
    if (!caseDetails?.id || !caseDetails?.queue) {
        return;
    }

    async function fetchQueueList() {
        setIsLoadingQueueExternalList(true);

        try {
            const request: GetQueueDataRequest = {
                caseId: caseDetails?.id || '',
                fromQueueName: caseDetails?.queue || '',
            }

            const response = await methods.getQueueData(request);
            setQueueData(response);
        } catch (error) {
            console.error("getQueueData error: ", error);
        } finally {
            setIsLoadingQueueExternalList(false);
        }
    }

    async function fetchExternalList() {
        setIsLoadingQueueExternalList(true);
        try {
            const request: GetExternalTeamDataRequest = {
                caseId: caseDetails?.id || '',
                fromQueueName: caseDetails?.queue || '',
            }

            const response = await methods.getExternalTeamData(request);
            setExternalData(response);
        } catch (error) {
            console.error("getExternalTeamData error: ", error);
        } finally {
            setIsLoadingQueueExternalList(false);
        }
    }

    function setupMetricsPublisher(caseId: string, queueName: string) {
      const caseIdMetric = new Metric.String("caseId", caseId);
      const queueNameMetric = new Metric.String("queueName", queueName);

      const transferPublisher = initialMetricsPublisher.newChildPublisher({
        relatedMetrics: [caseIdMetric, queueNameMetric],
        methodName: "TransferWidget"
      })

      setMetricsPublisher(transferPublisher);
    }

    fetchQueueList();
    fetchExternalList();
    setupMetricsPublisher(caseDetails?.id, caseDetails?.queue)
    
}, [caseDetails?.id, caseDetails?.queue]);

  return (
  <PageSection
      title={translate("Paragon_TAM_CM_Transfer")}
      widgetId="transfer-widget"
      isExpanded={showTransferContent}
      onToggleExpand={()=> toggleExpanded()}
    >
      {showTransferContent && <TransferCase
          onTransferCancel={onTransferCancel}
          isloadingData={isLoadingQueueExternalList}
          queueData={queueData}
          externalData={externalData}
          metricsPublisher={metricsPublisher}
      />}
  </PageSection>);
};
