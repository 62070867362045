export interface SCEDataRequest {
    schemaCaseElementPrefix: SchemaCaseElementsPrefix;
    schemaPropertyAttribute: SchemaDataProperties;
    schemaPropertyValue: SchemaDataProperties;
}

export enum SchemaCaseElementsPrefix {
    LLM_SCHEMA_PREFIX = "LLMPredictions.Results",
}

export enum SchemaDataProperties {
    LLM_SCHEMA_USE_CASE = "LLMUseCase",
    LLM_SCHEMA_ISSUE_SUMMARY = "LLM_ISSUE_SUMMARY",
}

export interface SchemaCaseElementsDataResponse {
    schemaElementsData: Record<string, SchemaElementData>;
}

export interface SchemaElementData {
    createdAt: Date;
    schema: Schema;
    schemaData: string;
    schemaElementId: string;
    schemaElementType: string;
    schemaPrefix: SchemaCaseElementsPrefix;
}

export interface LLMSchemaData {
    RequestId?: string;
    RequestText?: string;
    LLMUseCase: SchemaDataProperties;
    LLMModelId?: string;
    PredictedText: string;
    Metadata: LLMMetaData;
}

export interface LLMMetaData {
    AttackDetected?: boolean,
    AttackReasoning?: string,
    ActionsTaken?: LLMActions[];
    Identifiers?: LLMIdentifiers;
    Exception?: LLMException;
}

export interface LLMActions {
    Action: string;
    Source: LLMSource[];
}

export interface LLMSource {
    Type: string,
    Id: string
}

export interface LLMIdentifiers {
    ASIN?: string[],
    ShipmentId?: string[],
    FNSKU?: string[],
    ReimbursementId?: string[],
    OrderId?: string[]
}

export interface LLMException {
    Type: string | null,
    Message: string | null
}

export const IdentifierMap = {
    "ASIN": "ASINs",
    "FNSKU": "FNSKUs",
    "OrderId": "Order IDs",
    "ShipmentId": "Shipment IDs",
    "ReimbursementId": "Reimbursement IDs"
}

export type SchemaCaseElementPage = {
    schemaCaseElements:  SchemaCaseElement[],
    nextPageToken?: string,
    schemaCaseElementPrefix: string,
}

export type SchemaCaseElement = {
    data: string,
    elementId: string,
    elementType: string,
    schema: Schema,
}

export type Schema = {
    name: string,
    version: string,
}

