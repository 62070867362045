import React, {useState} from 'react';
import {KatIcon} from '@amzn/katal-react';
import $style from './big-button-link.module.scss';

interface BigButtonLinkProps {
    className?: string;
    topText: string;
    bottomText1: string;
    bottomText2: string;
    hrefLink: string;
    linkifiedText: string;
    buttonClicked: (event) => void;
}

export default function BigButtonLink(props: BigButtonLinkProps) {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <div className={props.className || $style.bigButtonBorder}
                         onClick={(event) => props.buttonClicked(event)}
                         onMouseOver={() => setIsHovered(true)}
                         onMouseLeave={() => setIsHovered(false)}>
            <div>
                {isHovered &&
                    <div className={$style.hoveredDiv}>
                        { props.topText }
                    </div>
                }
                {!isHovered &&
                    <div className={$style.noHoveredDiv}>
                        { props.topText }
                    </div>
                }

                <div>
                    { props.bottomText1 }
                    <a onClick={(event) => {event.stopPropagation()}} href={props.hrefLink} target="_blank" rel="noreferrer"> { props.linkifiedText } </a>
                    { props.bottomText2 }
                </div>
            </div>
            {isHovered &&
                <KatIcon className={$style.hoveredKatIcon} data-testid="katal-icon" name="keyboard_arrow_right" size="large" />
            }

            {!isHovered &&
            <KatIcon className={$style.noHoveredKatIcon} data-testid="katal-icon" name="keyboard_arrow_right" size="large" />
            }
        </div>
    )
}