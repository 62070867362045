import React, { FunctionComponent, useEffect, useState } from 'react';
import {
    KatButton,
    KatIcon,
    KatLabel,
    KatLink,
    KatPopover,
} from '@amzn/katal-react';
import { CompositeCaseDetails } from '@amzn/paragon-context/lib/IssueSummaryContext/models/CompositeCaseDetails';
import { paragonMediator } from '@amzn/paragon-context/lib/ParagonMediatorContext/paragonMediatorContext';
import { paragonPageContext } from '@amzn/paragon-context/lib/ParagonPageContext';
import { paragonWidgetDataContext } from '@amzn/paragon-context/lib/ParagonWidgetDataContext';
import { UserDetails } from '@amzn/paragon-core-data-context/lib/models/UserDetails';
import '@amzn/paragon-dynamic-view-renderer/lib/styles.css';
import {
    useParagonContext,
    useParagonContextConsumerEl,
} from '@amzn/paragon-ui-react';
import '@amzn/paragon-ui-react/lib/styles.css';
import { Loading } from 'src/components/Loading/Loading';
import { hasPeekNowAccess, invokePeekNow } from '../../../service/peekNow';
import $style from './peek-now.module.scss';

export type PeekNowWidgetProps = {
    compositeCaseDetails: CompositeCaseDetails;
    userDetails: UserDetails;
};
export const PeekNowWidget: FunctionComponent<PeekNowWidgetProps> = ({
    compositeCaseDetails,
    userDetails,
}) => {
    const el = useParagonContextConsumerEl();

    const [canSeeButton, setCanSeeButton] = useState(false);
    const [disablePeekNowReason, setDisablePeekNowReason] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const { value: pageData } = useParagonContext(el, paragonPageContext);
    const PERMISSION_PROPAGATION_MESSAGE =
        'Permissions take up to 4 hours to propagate.';
    const PEEKNOW_WIKI_URL =
        'https://w.amazon.com/bin/view/Alpha-Prime/PeekNow/User_Guide/Common_Issues/';
    const GENERIC_PEEKNOW_ERROR = 'Generic PeekNow Permission Error';
    const PEEKNOW_BINDLE_PERMISSION_ERROR =
        "PeekNow button is locked because you don't have the required permissions.";
    const PEEKNOW_INVALID_CASE_ERROR =
        'PeekNow button is locked because Case Status is Unassigned/Resolved.';
    const { methods: widgetMethods } = useParagonContext(
        el,
        paragonWidgetDataContext,
    );

    useEffect(() => {
        if (!compositeCaseDetails?.caseDetails?.tenantId || !userDetails?.agentLogin || !compositeCaseDetails?.caseDetails?.status) {
            return;
        }
        const displayPeekNow = () => {
            hasPeekNowAccess(
                {
                    caseTenantId: compositeCaseDetails?.caseDetails?.tenantId,
                    agentLogin: userDetails?.agentLogin,
                    caseStatus: compositeCaseDetails?.caseDetails?.status,
                },
                widgetMethods,
            ).then((res) => {
                if (res.success) {
                    console.log(res);
                    setCanSeeButton(res.data.canAccessPeekNow);
                    if (res.data.errorMessage) {
                        setCanSeeButton(false);
                        if (res.data.errorMessage === 'NO_PERMISSION') {
                            setDisablePeekNowReason(
                                PEEKNOW_BINDLE_PERMISSION_ERROR,
                            );
                        } else if (res.data.errorMessage === 'INVALID_CASE') {
                            setDisablePeekNowReason(PEEKNOW_INVALID_CASE_ERROR);
                        } else {
                            setDisablePeekNowReason(GENERIC_PEEKNOW_ERROR);
                        }
                    } else setCanSeeButton(true);
                } else {
                    console.log(res); // this one is good to know because this is likely an unexpected client-side error
                    setDisablePeekNowReason(GENERIC_PEEKNOW_ERROR);
                }
                setIsLoading(false);
            });
        };
        displayPeekNow();
    }, [compositeCaseDetails?.caseDetails?.tenantId, userDetails?.agentLogin, compositeCaseDetails?.caseDetails?.status]);
    const handlePeekNowClick = () => {
        invokePeekNow(
            {
                customerId: '',
                caseId: Number(compositeCaseDetails?.caseDetails?.caseId),
                merchantId: compositeCaseDetails?.caseDetails?.merchantId,
                marketplaceId: pageData?.marketplaceId,
                agentLogin: userDetails?.agentLogin,
                reason: compositeCaseDetails?.caseReason,
                caseTenantId: compositeCaseDetails?.caseDetails?.tenantId,
                duration: 120,
            },
            widgetMethods,
        ).then((res) => {
            if (res.success && res.data.canPeek) {
                window.open(res.data.sellerCentralURL);
            } else {
                console.log(res); // also necessary to debug on client side
                paragonMediator.notify('append-header-error-message', {
                    errorMessage: 'Error in invoking PeekNow',
                });
            }
        });
    };
    return (
        <div>
            {isLoading && (
                <div className={$style.spinnerBox}>
                    <Loading isLoading={isLoading}>&nbsp;</Loading>
                </div>
            )}

            {canSeeButton && !isLoading && (
                <KatButton
                    className={$style.peekNowButton}
                    variant="secondary"
                    label="View seller account"
                    onClick={handlePeekNowClick}
                />
            )}
            {!canSeeButton && !isLoading && (
                <KatPopover
                    katAriaBehavior="tooltip"
                    position="bottom-end"
                    triggerType="hover"
                >
                    <KatIcon slot="trigger" size="medium" name="lock_outline" />
                    <div className={$style.popOverContent}>
                        <KatLabel
                            text={disablePeekNowReason}
                            variant="constraint"
                        />
                    </div>
                    <div className={$style.popOverContent}>
                        <KatLink
                            label="PeekNow Permissions Wiki 🔗"
                            href={PEEKNOW_WIKI_URL}
                            target="_blank"
                            variant="link"
                        />
                    </div>
                    <div className={$style.popOverContent}>
                        <KatLabel
                            emphasis="Note: "
                            text={PERMISSION_PROPAGATION_MESSAGE}
                            variant="constraint"
                        />
                    </div>
                </KatPopover>
            )}
        </div>
    );
};
