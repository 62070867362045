import React, { FunctionComponent, useEffect, useState } from 'react';
import {
    KatAlert,
    KatBox,
    KatDropdown,
    KatIcon,
    KatInput,
    KatInputGroup,
    KatRadiobutton,
    KatSpinner,
    KatTextarea,
} from '@amzn/katal-react';

import $style from './manual-transfer.module.scss';
import { GetQueueDataResponse } from '@amzn/paragon-context/lib/IssueSummaryContext/models/TransferFunctions/GetQueueData';
import { GetExternalTeamDataResponse } from '@amzn/paragon-context/lib/IssueSummaryContext/models/TransferFunctions/GetExternalTeamData';
import { AgentSearchResponse } from '@amzn/paragon-context/lib/IssueSummaryContext/models/TransferFunctions/AgentSearch';
import {localization} from "src/helpers";
import {TransferType} from "src/widgets/TransferCase/TransferCase";

type Props = {
    manualTransferAlert: boolean;
    queueData?: GetQueueDataResponse;
    externalData?: GetExternalTeamDataResponse;
    agentData?: AgentSearchResponse[];
    selectedTransferType: string;
    selectManual: () => void;
    fetchAgentData: (data: string) => void;
    changeQueueAgentTeam: (data: string) => void;
    changeTransferType: (data: string) => void;
    changeAnnotationText: (data: string) => void;
    isloadingData: boolean;
    submitTransfer: boolean;
    selectedTransferOption: TransferType;
}

export const ManualTransfer: FunctionComponent<Props> = ({
    manualTransferAlert,
    queueData,
    externalData,
    agentData,
    selectedTransferType,
    selectManual,
    fetchAgentData,
    changeQueueAgentTeam,
    changeTransferType,
    changeAnnotationText,
    isloadingData,
    submitTransfer,
    selectedTransferOption
}) => {

    const { translate } = localization();

    const transferTypes = [
        {
            value: 'QueueNew',
            name: 'Queue',
        },
        {
            value: 'ExternalNew',
            name: 'External',
        },
        {
            value: 'AgentNew',
            name: 'Agent',
        },
    ];
    const [searchText, setSearchText] = useState('');
    const [advancedTransferEnabled, setAdvancedTransferEnabled] = useState(false);
    const [manualTransferSelected, setManualTransferSelected] = useState(false);

    function loadingData() {
        return (isloadingData || submitTransfer) && manualTransferSelected;
    }

    useEffect(() => {
        if (queueData?.isShowAdvancedTransferCase) {
            setAdvancedTransferEnabled(true);
        }
    }, [queueData?.isShowAdvancedTransferCase]);

    useEffect(() => {
        setManualTransferSelected(selectedTransferOption === TransferType.MANUAL_TRANSFER);
    }, [selectedTransferOption])

    return (
        <div className={$style.manualAttributeBox}>
            <div className="manual-attribute-box">
                <KatBox variant="white">
                    {manualTransferAlert && <KatAlert
                        variant="danger"
                        description={translate('Paragon_TAM_CM_Select_Manual_Option')}
                    ></KatAlert>}
                    <KatRadiobutton
                        name="transfer-radio-button"
                        className={$style.manualAlert}
                        value="transferRadioButtonManualValue"
                        label={translate('Paragon_TAM_CM_Manual_Transfer')}
                        checked={manualTransferSelected}
                        onClick={() => selectManual()}
                    ></KatRadiobutton>
                    <div>
                        {loadingData() && (
                            <KatSpinner variant="default" size="small"></KatSpinner>
                        )}
                    </div>
                    <div>
                        {!loadingData() && (
                            <KatInputGroup className={$style.fixDropdownHeight}>
                                <KatDropdown className={$style.transferTypesDropDown}
                                    options={JSON.stringify(transferTypes)}
                                    value={selectedTransferType}
                                    disabled={!manualTransferSelected}
                                    onChange={(e: any) => {
                                        changeTransferType(e?.target?.value);
                                        setSearchText('');
                                        changeQueueAgentTeam('');
                                    }}
                                ></KatDropdown>
                                <KatInput
                                    type="text"
                                    className={$style.filterInput}
                                    disabled={!manualTransferSelected}
                                    placeholder={translate('Paragon_TAM_CM_Type_here_to_search')}
                                    value={searchText}
                                    onKeyup={(e: any) => {
                                        fetchAgentData(e.target.value);
                                        console.log('ee::', e.target.value);
                                        setSearchText(e.target.value);
                                    }}
                                ></KatInput>
                                <KatIcon name="search"></KatIcon>
                            </KatInputGroup>
                        )}
                    </div>

                    {(selectedTransferType === 'ExternalNew') && externalData && (
                        <select className={$style.multiSelectInput}
                            onChange={(e: any) => {
                                changeQueueAgentTeam(e.target.value);
                            }}
                            multiple>
                            {externalData.queueList.filter((option: { queueName: string; queueDisplayName: string; }) => (
                                option.queueDisplayName.toLowerCase().includes(searchText.toLowerCase()) ||
                                option.queueName.toLowerCase().includes(searchText.toLowerCase())
                            )).map((option: { queueName: string; queueDisplayName: string; }) => (
                                <option key={option.queueName} value={option.queueName}>{option.queueDisplayName}</option>
                            ))}
                        </select>
                    )}
                    {(selectedTransferType === 'QueueNew') && queueData && (
                        <select className={$style.multiSelectInput}
                            onChange={(e: any) => {
                                changeQueueAgentTeam(e.target.value);
                            }}
                            multiple>
                            {queueData?.transferQueueList.filter((option: { queueName: string; queueDisplayName: string; }) => (
                                option.queueDisplayName.toLowerCase().includes(searchText.toLowerCase()) ||
                                option.queueName.toLowerCase().includes(searchText.toLowerCase())
                            )).map((option: { queueName: string; queueDisplayName: string; }) => (
                                <option value={option.queueName}>{option.queueDisplayName}</option>
                            ))}
                        </select>
                    )}
                    {(selectedTransferType === 'AgentNew' && searchText.length > 0) && agentData && (
                        <select className={$style.multiSelectInput}
                            onChange={(e: any) => {
                                changeQueueAgentTeam(e.target.value);
                            }}
                            multiple>
                            {agentData.map((option: { userAlias: string; firstName: string; lastName: string; }) => (
                                <option value={option.userAlias}>{option.userAlias + " - " + option.firstName + " " + option.lastName}</option>
                            ))}
                        </select>
                    )}
                    {selectedTransferType === 'ExternalNew' && (
                        <>
                            <div>Add annotation</div>
                            <KatTextarea className={$style.textarea}
                                onKeyup={(e: any) => {
                                    changeAnnotationText(e.target.value);
                                }} />
                        </>
                    )}
                </KatBox>
            </div>
        </div>
    )

}