import {KatModal} from '@amzn/katal-react';
import React from 'react';

interface ModalProps {
    className?: string;
    noCloseIcon?: boolean;
    header?: () => JSX.Element;
    body?: () => JSX.Element;
    footer?: () => JSX.Element;
    visible: boolean;
    onDismiss: () => void;
}

import $style from 'src/components/Modal/modal.module.scss';

export default function Modal(props: ModalProps) {

    function handleDismiss(event) {
        event.preventDefault();
        props.onDismiss();
    }

    return (
        <div className={props.className}>
            <KatModal data-testid="katal-modal" onClose={handleDismiss} no-close-icon={props.noCloseIcon} visible={props.visible}>
                <span slot="title">{ props.header?.() }</span>
                <div className={$style.bodyContainer}>{ props.body?.() }</div>
                {props.footer && <div slot="footer">{ props.footer() }</div>}
            </KatModal>
        </div>
    )
}