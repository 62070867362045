
export const metricNames = {
  exceptionConfirmationForm: {
    caseExceptionSubmitted: 'case_exception_submitted'
  },
  pmaReasonsDropdown: {
    ruleMatch: 'ParagonHorizonte:PMAFriction:RuleMatch'
  },
  emailBodyComponent: {
    pmGreyedBlurbReplySend: 'pm_greyed_blurb_reply_sent'
  },
  composerView: {
    transitionedToReviewWithRuleViolationsClicked: 'transitioned-to-review-with-rule-violations-clicked'
  },
  reviewView: {
    tardisPtrModal: 'tardis-ptr-modal',
    tardisPtrModalShow: 'tardis-ptr-modal-show',
    cancelReplyToCase: 'cancel-reply-to-case',
    submitReplyToCaseDirectReply : 'submit-reply-to-case-direct-reply',
    submitPtrModal: 'submit-ptr-modal'
  }
}

export const clickStreamType = {
  click: 'click'
} as const
