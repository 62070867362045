import React, { FunctionComponent, useEffect, useState } from "react";
import ReplyCase from "./ReplyCase";
import { ControlSpacing, PageSection, useParagonContext, useParagonContextConsumerEl, useWidgetDataContextStateSetter } from "@amzn/paragon-ui-react";
import { localization } from "src/helpers";
import {paragonReplyContext} from "@amzn/paragon-context/lib/ParagonReplyContext";
import { paragonWidgetDataContext } from "@amzn/paragon-context/lib/ParagonWidgetDataContext";
import { ParagonEvents } from "./model/replyCaseSchemaElements";
import { KatButton } from "@amzn/katal-react";


export const ReplyCaseWidget: FunctionComponent = () => {
  const el = useParagonContextConsumerEl();
  const { translate } = localization();
  const [showReplyContent, setShowReplyContent] = useState(false);
  const { on: toggleReplyEventOn } = useParagonContext(el, paragonReplyContext);
  const { value: { isWidgetEditModeEnabled } } = useParagonContext(el, paragonWidgetDataContext);
  const [showdcpForm, setShowDcpForm] = useWidgetDataContextStateSetter<boolean>('showDCPForm',false);

  const onReplyCancel = () => {
    setShowReplyContent(false)
  }

  const toggleExpanded = () => {
    setShowReplyContent(!showReplyContent);
  };

  useEffect(() => {
    const unsubToggleTransfer = toggleReplyEventOn(ParagonEvents.TOGGLE_REPLY_TO_CASE, () => {
      toggleExpanded();
    });

    return () => {
      unsubToggleTransfer();
    };
  });
      
  return (
    <PageSection
      title={translate("Paragon_TAM_CM_Reply")}
      widgetId="reply-widget"
      isExpanded={showReplyContent}
      onToggleExpand={()=>toggleExpanded()}
      pageSectionControls={
        <ControlSpacing size="sm" justify="end">
          {isWidgetEditModeEnabled && 
                <ControlSpacing size="sm" justify="end">
                    <KatButton size="small"
                            label={showdcpForm ? "Hide DCPForm" : "Show DCPForm"}
                            variant="tertiary"
                            onClick={() => setShowDcpForm(!showdcpForm)}
                    />
                </ControlSpacing>
	        }
        </ControlSpacing>
      }
    >
      <ReplyCase
        onDiscardChanges={onReplyCancel}
        showdcpForm={showdcpForm}
      />
    </PageSection>
  );
};
