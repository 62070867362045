import { KatButton, KatIcon, KatPopover } from "@amzn/katal-react";
import React, { FunctionComponent, useState } from "react";
import $style from './common-component.module.scss';
import { FeedbackPopover } from "./FeedbackPopover";

type Props = {
    handleNextButton: () => void;
    handleSubmitButton: () => void;
    handleBackButton: () => void;
    handleResolveButton: () => void;
    changeCancelReason: (data: string) => void;
    command: string;
    disablePopover: boolean;
    disableNext: boolean;
    disableBackCancel: boolean;
}

export const SelectionFooter: FunctionComponent<Props> = ({
    handleNextButton,
    handleSubmitButton,
    handleBackButton,
    handleResolveButton,
    changeCancelReason,
    command,
    disablePopover,
    disableNext,
    disableBackCancel }) => {

    return (
        <div>
            <KatButton
                className={$style.buttonDisplay}
                variant="secondary"
                disabled={disableBackCancel}
                onClick={() => handleBackButton()}
            >
                <KatIcon name="arrow_back" size="xsmall" /> Back
            </KatButton>
            <KatButton
                className={$style.buttonDisplay}
                variant="tertiary"
                label="Select another SOP"
                disabled={disableBackCancel}
                onClick={() => handleResolveButton()}
            />
            {command !== "END" && <KatPopover
                position="top-start"
                triggerType="hover"
                disabled={disablePopover}
            >
                <KatButton
                    className={$style.buttonDisplay}
                    variant="primary"
                    label="Next"
                    slot="trigger"
                    disabled={disableNext}
                    onClick={() => handleNextButton()}
                />
                <label>Currently, we are unable to generate steps for this SOP.
                    Please click the link icon to open the SOP in a new tab.</label>
            </KatPopover>
            }
            <KatPopover
                className={$style.stylePopoverContent}
                position="auto"
                triggerType="click"
            >
                <KatButton
                    className={$style.buttonDisplay}
                    variant="link"
                    label="Help us improve"
                    slot="trigger"
                    disabled={disableBackCancel}
                />
                <FeedbackPopover
                    handleSubmitButton={handleSubmitButton}
                    setFeedback={changeCancelReason}
                />
            </KatPopover>
        </div>
    );
};