import { SchemaCaseElement, SchemaCaseElementPage } from "src/widgets/IssueSummaryWidget/model/schemaElementsData";

export function getMostRecentSCE(schemaPrefix: string, schemaCaseMap?: Record<string, SchemaCaseElementPage>): Partial<SchemaCaseElement> {
    if (schemaCaseMap && schemaCaseMap[schemaPrefix]) {
        const schemaCaseElements = schemaCaseMap[schemaPrefix].schemaCaseElements;
        if (schemaCaseElements && schemaCaseElements.length > 0) {
            return schemaCaseElements[0]
        }
    }
    return {}
}