import React, { useState } from 'react';
import { localization } from 'src/helpers';

import { PageSection } from "@amzn/paragon-ui-react";
import { ReviewReplyInput, Stage } from '../IssueSummaryWidget/components/widgets/ReplyCase/model/replyCaseSchemaElements';

import $style from './reply-widget.module.scss';
import ReviewView from '../IssueSummaryWidget/components/widgets/ReplyCase/ReviewView';
import SubmittedView, { Params } from '../IssueSummaryWidget/components/widgets/ReplyCase/SubmittedView';
import LLMComposerView, { ComposerViewInput } from './components/LLMComposerView/LLMComposerView';

export default function ReplyWidget() {
    const [stage, setStage] = useState<string>(Stage.COMPOSE);
    const [composeStageParams, setComposeStageParams] = useState<ComposerViewInput | undefined>(undefined);
    const [reviewStageParams, setReviewStageParams] = useState<ReviewReplyInput | undefined>(undefined);
    const [submitStageParams, setSubmitStageParams] = useState<Params | undefined>(undefined);

    const { translate } = localization();

    return (
        <PageSection
            title={translate('Paragon_TAM_CM_Reply')}
            widgetId="llm-issue-summary-widget">
            <div className={$style.replyWidget}>
                {stage === Stage.COMPOSE &&
                    <LLMComposerView
                        params={composeStageParams}
                        setComposeStageParams={setComposeStageParams}
                        showReview={(reviewData) => {
                            setReviewStageParams(reviewData);
                            setStage(Stage.REVIEW);
                        }}
                    />
                }
                {stage === Stage.REVIEW && 
                    <ReviewView 
                    params={reviewStageParams!}
                    showComposer={() => setStage(Stage.COMPOSE)}
                    showSubmitted={(submitData) => {
                        setSubmitStageParams(submitData);
                        setStage(Stage.SUBMITTED);
                    }}
                    showError={() => {}}
                />
                }
                {stage === Stage.SUBMITTED &&
                    <SubmittedView 
                        params={submitStageParams}
                        showComposer={() => {
                            setComposeStageParams(undefined);
                            setStage(Stage.COMPOSE)
                        }}
                    />
                }
            </div>        
        </PageSection>
    )
}