import { KatAlert, KatBox, KatButton, KatIcon, KatTextarea } from "@amzn/katal-react";
import ResponseGeneration from "../ResponseGeneration/ResponseGeneration";
import CaseStatusDropdown from "../CaseStatusDropdown/CaseStatusDropdown";
import AtlasLabellingDropdown from "../AtlasLabellingDropdown/AtlasLabellingDropdown";
import ReasonCategoryDropdown from "../ReasonCategoryDropdown/ReasonCategoryDropdown";
import { Loading } from "src/components/Loading/Loading";
import React, { useContext, useEffect, useState } from "react";
import { useParagonContext, useParagonContextConsumerEl } from "@amzn/paragon-ui-react";
import { paragonCoreDataContext } from "@amzn/paragon-context/lib/ParagonCoreDataContext";
import { paragonPageContext } from "@amzn/paragon-context/lib/ParagonPageContext";
import { localization } from "src/helpers";
import { ServicesAPIContext } from "src/service/service.context";
import { ReasonCategory, ReviewReplyInput, ViewCaseComposerOutput } from "src/widgets/IssueSummaryWidget/components/widgets/ReplyCase/model/replyCaseSchemaElements";
import $style from './llm-composer-view.module.scss';

export interface ComposerViewInput {
    caseStatus: string;
    caseResolutionCategoryId?: string;
    caseResolutionReasonId?: string;
    messageContent: string;
}

interface LLMComposerViewProps {
    showReview: Function;
    setComposeStageParams: Function;
    params?: ComposerViewInput;
}

export default function LLMComposerView(props: LLMComposerViewProps) {
    const [messageContent, setMessageContent] = useState(props.params?.messageContent ?? "");
    // Case Status
    const [caseStatus, setCaseStatus] = useState(props.params?.caseStatus ?? "");
    const [caseStatusList, setCaseStatusList] = useState<{ name: string, value: string }[]>([]);
    const [caseStatusDataLoaded, setCaseStatusDataLoaded] = useState(false);
    // Atlas Dropdown
    const [caseResolutionCategoryId, setCaseResolutionCategoryId] = useState<string | undefined>(props.params?.caseResolutionCategoryId);
    const [caseResolutionReasonId, setCaseResolutionReasonId] = useState<string | undefined>(props.params?.caseResolutionReasonId);
    const [reasonCategoryList, setReasonCategoryList] = useState<ReasonCategory[]>([]);
    // Reply Metadata
    const [viewCaseComposerData, setviewCaseComposerData] = useState<ViewCaseComposerOutput | undefined>(undefined);
    // Response Generation
    const [responseError, setResponseError] = useState(false);

    //Paragon Context
    const el = useParagonContextConsumerEl();
    const { value: { caseDetails } } = useParagonContext(el, paragonCoreDataContext);
    const { value: pageData } = useParagonContext(el, paragonPageContext);

    const { translate } = localization();
    const serviceAPIContext = useContext(ServicesAPIContext);

    const caseId = caseDetails?.id ?? '';
    const shouldShowDropdowns = !!caseId && caseStatusDataLoaded;
    const readyToReview = messageContent && viewCaseComposerData && caseResolutionCategoryId && caseResolutionReasonId;

    const getCaseDropdownData = () => {
        const params = {
            caseId: caseId,
            locale: ''
        };

        serviceAPIContext.paragonService.getReplyCaseStatusDropdown(params).then(response => {
            if (response && response.replyCaseStatusDropdownData) {
                const data = response.replyCaseStatusDropdownData;
                if (data.caseStatusList) {
                    setCaseStatusList(data.caseStatusList.map(statusItem => ({
                        name: statusItem.title,
                        value: statusItem.title
                    })))
                }
                if (!props.params?.caseStatus && data.currentCaseStatusItem) {
                    setCaseStatus(data.currentCaseStatusItem.title)
                }

                setReasonCategoryList(data.reasonCategoryList);
                if (!props.params?.caseResolutionCategoryId) {
                    if (data.selectedCategory && data.selectedCategory.id) {
                        setCaseResolutionCategoryId(data.selectedCategory.id.toString());
                    } else if (data.reasonCategoryList && data.reasonCategoryList[0] && data.reasonCategoryList[0].id) {
                        setCaseResolutionCategoryId(data.reasonCategoryList[0].id.toString());
                    }
                }

                if (!props.params?.caseResolutionReasonId && data.selectedReason && data.selectedReason.id) {
                    setCaseResolutionReasonId(data.selectedReason.id.toString());
                }
                setCaseStatusDataLoaded(true);
            }
        })
    }

    const getViewCaseComposerData = () => {
        const params = {
            caseId: caseId,
            marketplaceId: pageData.marketplaceId
        }

        serviceAPIContext.paragonService.viewCaseComposer(params).then(response => {
            if (response && response.viewCaseComposerData) {
                const data = response.viewCaseComposerData;
                setviewCaseComposerData(data);
            }
        });
    }
    const emailStringToList = (emailList: string): string[] => {
        return emailList.split(new RegExp(/[;,]/, 'gm')).map(item => item.trim());
    }

    const constructComposeViewParams = (): ComposerViewInput => {
        return {
            caseStatus: caseStatus,
            caseResolutionCategoryId: caseResolutionCategoryId,
            caseResolutionReasonId: caseResolutionReasonId,
            messageContent: messageContent
        }
    }

    function constructReplyToCaseParams(): ReviewReplyInput {
        // The button is not clickable unless this data exists, so we can assume it does
        const caseComposerOutput = viewCaseComposerData!.output;
        return {
            header: {
                content: '',
                contentIds: []
            },
            body: {
                content: messageContent,
                contentIds: []
            },
            additionalInfo: {
                content: '',
                contentIds: []
            },
            signature: {
                content: '',
                contentIds: []
            },
            footer: {
                content: '',
                contentIds: []
            },
            ...caseComposerOutput,
            ...pageData,
            caseStatus: caseDetails?.status ?? '',
            creationDate: (caseDetails?.creationDate ?? '').toString(),
            queueBlurbBaseName: caseComposerOutput.queueBlurbBaseName ?? '',
            previousEmailCorrespondence: caseComposerOutput.previousEmailCorrespondence ?? '',
            caseProperties: caseComposerOutput.caseProperties ?? [],
            locale: viewCaseComposerData!.locale ?? '',
            caseSubStatus: '',
            caseResolutionCategoryId: Number(caseResolutionCategoryId),
            caseResolutionReasonId: Number(caseResolutionReasonId),
            toEmail: emailStringToList(caseComposerOutput.primaryEmail),
            attachments: [],
            metaData: {},
            newCaseStatus: caseStatus,
            pmaReason: '',
            caseId: caseId,
        };
    }

    useEffect(() => {
        if (caseId) {
            getCaseDropdownData();
        }
        if (caseId && pageData.marketplaceId) {
            getViewCaseComposerData();
        }
    }, [caseId, pageData.marketplaceId]);


    const isGeneratedResponseError = (value: boolean) => {
        setResponseError(value);
    }

    return (
        <>
        {responseError &&
            <KatAlert variant="danger">
                {translate('Paragon_LLM_ReplyCase_Error_Generating_Response')}
            </KatAlert>
        }
        <KatBox variant='ltgrey' className={$style.dropdownBox}>
            {shouldShowDropdowns &&
                <>
                    <CaseStatusDropdown caseId={caseId} caseStatus={caseStatus} caseStatusList={caseStatusList} setCaseStatus={setCaseStatus} />
                    <AtlasLabellingDropdown caseId={caseId}
                        newCaseStatus={caseStatus}
                        isAtlasFeedbackEnabled={false} />
                    <ReasonCategoryDropdown
                        reasonCategoryList={reasonCategoryList}
                        caseResolutionCategoryId={caseResolutionCategoryId}
                        caseResolutionReasonId={caseResolutionReasonId}
                        updateCaseResolutionReasonId={(value) => setCaseResolutionReasonId(value)}
                        updateCaseResolutionCategoryId={(value) => setCaseResolutionCategoryId(value)} />
                </>
            }
            <Loading isLoading={!shouldShowDropdowns}/>

        </KatBox>
        <div className={$style.textAreaContainer}>
            <KatTextarea className={$style.styledKatTextarea}
                value={messageContent}
                onInput={(e) => setMessageContent(e.currentTarget.value)} />

            <div className={!messageContent ? $style.topFakePlaceholder : $style.bottomFakePlaceholder}>
                {translate('Paragon_LLM_ReplyCase_Type_Placeholder_Text')}
                <ResponseGeneration responseError={isGeneratedResponseError} caseId={caseId}
                                    updateContent={setMessageContent}/>
            </div>
        </div>

            <div className={$style.buttonContainer}>
                <KatButton disabled={!readyToReview} onClick={() => {
                    props.setComposeStageParams(constructComposeViewParams())
                    props.showReview(constructReplyToCaseParams());
                }}>
                    {translate('Paragon_TAM_CM_Review')}
                    </KatButton>
            <KatButton variant='link'> <KatIcon size="small" slot="icon" name='attachment'/></KatButton>
        </div>
        </>
    )
}