import { html, property } from 'lit-element';
import { KatLitElement, register } from '../../shared/base';
import baseStyles from '../../shared/base/base.lit.scss';
import styles from './box.lit.scss';

/**
 * @component {kat-box} Box Boxes visually separate and call attention to a group of related content within a page.
 * @slot default Contents will appear inside the box.
 * @guideline Do Boxes are best used as containers to hold content such as images and paragraphs.
 * @guideline Dont Don't use boxes to create spacing.
 * @example LtGreyBox {"variant":"ltgrey", "content":"Lorem ipsum"}
 * @example AzureBox {"variant":"azure", "content":"Lorem ipsum"}
 * @example WhiteBox {"variant":"white", "content":"Lorem ipsum"}
 * @example WhiteShadowBox {"variant":"white-shadow", "content":"Lorem ipsum"}
 * @example MojitoBox {"variant":"mojito", "content":"Lorem ipsum"}
 * @example ZirconBox {"variant":"zircon", "content":"Lorem ipsum"}
 * @example WhiteJunglemistBox {"variant":"white-junglemist", "content":"Lorem ipsum"}
 * @example NordicBox {"variant":"nordic", "content":"Lorem ipsum"}
 * @status Production
 * @theme flo
 * @a11y {keyboard}
 * @a11y {sr}
 * @a11y {contrast}
 */
@register('kat-box')
export class KatBox extends KatLitElement {
  /**
   * The Flow-style variant of the box
   * @enum {value} ltgrey Light grey box with no border
   * @enum {value} azure Azure box with no border
   * @enum {value} white white box with a light grey 1px solid border
   * @enum {value} white-shadow white box with a light grey 1px border and a drop shadow
   * @enum {value} mojito Mojito box with no border
   * @enum {value} zircon Zircon box with no border
   * @enum {value} white-junglemist White Junglemist box with no border
   * @enum {value} nordic Nordic box with no border and white font color
   */
  @property()
  variant?: string;

  static get styles() {
    return [baseStyles, styles];
  }

  render() {
    return html`<slot></slot>`;
  }
}
