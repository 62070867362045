import { html, property } from 'lit-element';
import {
  KatLitElement,
  register,
  nextUniqueId,
  event,
  EventEmitter,
} from '../../shared/base';
import { createElement } from '../../shared/utils';
import { cleanDefaultSlot } from '../../shared/slot-utils';

import baseStyles from '../../shared/base/base.lit.scss';
import styles from './radiobutton.lit.scss';

/**
 * @component {kat-radiobutton} KatalRadiobutton Radio buttons allow users to select a single option from a list of several options. They are collected into groups by the <a href="/components/radiobutton-group/">radio button group component</a>.
 * @guideline Do Group radio buttons by the name attribute. Radio buttons with the same name will function together as a group.
 * @guideline Do Accessibility requirements for Radio buttons require they should be inside an element with role="radiogroup".
 * @example Basic {"label": "Option1", "value":"value1", "checked":"true", "constraint-label":""}
 * @example WithConstraint {"label": "Option1", "value":"value1", "constraint-label":"This is the first option"}
 * @example Disabled {"label": "Option1", "value":"value1", "disabled":"true", "constraint-label":""}
 * @status Production
 * @theme flo
 * @a11y {keyboard}
 * @a11y {sr}
 * @a11y {contrast}
 */
@register('kat-radiobutton')
export class KatRadiobutton extends KatLitElement {
  /** The value of the radio button */
  @property()
  value: string;

  /** The label displayed to the user for */
  @property()
  label?: string;

  /** Provides users with more information about what they enter into the input */
  @property({ attribute: 'constraint-label' })
  constraintLabel?: string;

  /** Whether the radio button is selected or not */
  @property()
  get checked(): boolean {
    if (this._input) {
      // pass the `checked` value through from the underlying input
      return this._input.checked;
    }
    return this._checked;
  }

  // set `checked` on the underlying input if we have rendered, otherwise store
  // it on `_checked` for now.
  set checked(val: boolean) {
    val = !!val;
    const oldVal = this.checked;
    this._checked = val;
    if (this._input) {
      this._input.checked = val;
    }
    this.requestUpdate('checked', oldVal);
  }

  /** The id of the form element */
  @property({ attribute: 'unique-id' })
  get uniqueId(): string {
    return this._uniqueId;
  }

  set uniqueId(val: string) {
    const oldVal = this.uniqueId;
    if (!val) {
      val = this._thisUniqueId;
    }
    this._uniqueId = val;
    this.requestUpdate('uniqueId', oldVal);
  }

  /** The form name of the element */
  @property()
  name?: string;

  /**
   * The label used for screen readers. By
   * default, screen readers will announce the text from the `label`
   * property. Use `kat-aria-label` when you want screen readers to announce
   * text different from the `label` property.
   */
  @property({ attribute: 'kat-aria-label' })
  katAriaLabel?: string;

  /**
   * The ID of an element used as a
   * label for screen readers. Use this if you are using your own label
   * element as the label of this kat-radiobutton.
   */
  @property({ attribute: 'kat-aria-labelledby' })
  katAriaLabelledby?: string;

  /** Prevents the user from changing the value of an input and prevents events from being triggered */
  @property()
  disabled?: boolean;

  _thisUniqueId = nextUniqueId();
  _constraintId = nextUniqueId();
  _checked = false;

  /** Fires when a radio button has been selected. */
  @event('change', true)
  private _change: EventEmitter<{ checked: boolean; value: string }>;

  /** Fires when the user focuses into the radio button. */
  @event('focus', true)
  private _focus: EventEmitter;

  /** Fires when the user stops focusing the radio button. */
  @event('blur', true)
  private _blur: EventEmitter;

  static get styles() {
    return [baseStyles, styles];
  }

  constructor() {
    super();

    cleanDefaultSlot(this);

    this._uniqueId = this._thisUniqueId;
  }

  updated(changedProps) {
    super.updated(changedProps);

    this._input = this.querySelector('input[part="radiobutton-input"]');
    let iconSpan;
    if (!this._input) {
      this._input = createElement('input', {
        type: 'radio',
        part: 'radiobutton-input',
        class: 'kat-radio',
        slot: 'radio',
        role: 'radio',
      });
      this._input.addEventListener('focus', this.handleFocus.bind(this));
      this._input.addEventListener('blur', this.handleBlur.bind(this));
      this._input.addEventListener('change', this.handleChange.bind(this));

      iconSpan = createElement('span', {
        class: 'kat-radiobutton-icon',
        part: 'radiobutton-icon',
        slot: 'radio',
      });

      this.appendChild(this._input);
      this.appendChild(iconSpan);
      this._input.checked = this._checked;
    } else {
      iconSpan = this.querySelector('[slot="radio"].kat-radiobutton-icon');
      this._input.checked = this.checked;
    }

    if (this.checked) {
      iconSpan.setAttribute('checked', '');
    } else {
      iconSpan.removeAttribute('checked');
    }
    if (this.disabled) {
      iconSpan.setAttribute('disabled', '');
    } else {
      iconSpan.removeAttribute('disabled');
    }

    this._input.id = this._uniqueId;
    this._input.name = this.name;
    this._input.value = this.value;
    this._input.disabled = this.disabled;
    this._input.setAttribute(
      'aria-label',
      this.katAriaLabel ? this.katAriaLabel : this.label
    );
    this._input.setAttribute('aria-describedby', this._constraintId);
    this._input.setAttribute('aria-labelledby', this.katAriaLabelledby);
  }

  handleClick(e) {
    // don't click if the click event is coming from the inner radio, this
    // avoids infinite loops, https://sim.amazon.com/issues/KAT-2993
    if (!this.disabled && e.target !== this._input) {
      this.click();
      // doesn't focus the event if other action needs to be taken for item
      // inside of the radiobutton
      if (!this.contains(document.activeElement)) {
        this.focus();
      }
    }
  }

  handleFocus(e) {
    e.stopPropagation();
    this._focus.emit();
  }

  handleBlur(e) {
    e.stopPropagation();
    this._blur.emit();
  }

  handleChange(e) {
    e.stopPropagation();
    this._change.emit({
      checked: true,
      value: this.value,
    });
    this.requestUpdate();
  }

  /**
   * Call to programmatically focus the radio button.
   * @katalmethod
   */
  focus() {
    if (!this.disabled && this._input) {
      this._input.focus();
    }
  }

  /**
   * Call to programmatically blur the radio button.
   * @katalmethod
   */
  blur() {
    if (!this.disabled && this._input) {
      this._input.blur();
    }
  }

  /**
   * Call to programmatically click the radio button.
   * @katalmethod
   */
  click() {
    if (!this.disabled && this._input) {
      this._input.click();
    }
  }

  render() {
    return html`
      <div class="wrapper" @click=${this.handleClick}>
        <div class="indicator"><slot name="radio"></slot></div>
        <div class="text">
          <slot>
            <kat-label
              part="radiobutton-label"
              for="${this._uniqueId}"
              .text=${this.label}
            ></kat-label>
            <kat-label
              id="${this._constraintId}"
              part="radiobutton-constraint-label"
              for="${this._uniqueId}"
              variant="constraint"
              .text=${this.constraintLabel}
            ></kat-label>
          </slot>
        </div>
      </div>
    `;
  }
}
