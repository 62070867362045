import { katTranslator } from '../../shared/i18n';

const strings = {
  'katal-statusindicator-success': {
    'en-US': 'Success: {label}',
    'ar-AE': 'عملية ناجحة: {label}',
    'bn-IN': 'সফলতা: {label}',
    'de-DE': 'Erfolg: {label}',
    'es-ES': 'Correcto: {label}',
    'es-MX': 'Sin errores: {label}',
    'fr-BE': 'Opération réussie : {label}',
    'fr-FR': 'Succès : {label}',
    'gu-IN': 'સફળતા: {label}',
    'hi-IN': 'हो गया : {label}',
    'it-IT': 'Operazione riuscita: {label}',
    'ja-JP': '成功：{label}',
    'kn-IN': 'ಯಶಸ್ವಿಯಾಗಿದೆ: {label}',
    'ko-KR': '성공: {label}',
    'ml-IN': 'വിജയകരം: {label}',
    'mr-IN': 'यश: {label}',
    'nl-BE': 'Gelukt: {label}',
    'nl-NL': 'Voltooid: {label}',
    'pl-PL': 'Powodzenie: {label}',
    'pt-BR': 'Sucesso: {label}',
    'pt-PT': 'Sucesso: {label}',
    'sv-SE': 'Klart: {label}',
    'ta-IN': 'வெற்றி: {label}',
    'te-IN': 'విజయం: {label}',
    'th-TH': 'สำเร็จแล้ว {label}',
    'tr-TR': 'Başarılı: {label}',
    'vi-VN': 'Thành công: {label}',
    'zh-CN': '成功：{label}',
    'zh-TW': '成功：{label}',
  },
  'katal-statusindicator-error': {
    'en-US': 'Error: {label}',
    'ar-AE': 'خطأ: {label}',
    'bn-IN': 'ত্রুটি: {label}',
    'de-DE': 'Fehler: {label}',
    'fr-BE': 'Erreur : {label}',
    'fr-FR': 'Erreur : {label}',
    'gu-IN': 'એરર: {label}',
    'hi-IN': 'गड़बड़ी : {label}',
    'it-IT': 'Errore: {label}',
    'ja-JP': 'エラー：{label}',
    'kn-IN': 'ದೋಷ: {label}',
    'ko-KR': '오류: {label}',
    'ml-IN': 'പിശക്: {label}',
    'mr-IN': 'त्रुटी: {label}',
    'nl-BE': 'Fout: {label}',
    'nl-NL': 'Fout: {label}',
    'pl-PL': 'Błąd: {label}',
    'pt-BR': 'Erro: {label}',
    'pt-PT': 'Erro: {label}',
    'sv-SE': 'Fel: {label}',
    'ta-IN': 'பிழை: {label}',
    'te-IN': 'లోపం:{label}',
    'th-TH': 'ข้อผิดพลาด {label}',
    'tr-TR': 'Hata: {label}',
    'vi-VN': 'Lỗi: {label}',
    'zh-CN': '错误：{label}',
    'zh-TW': '錯誤：{label}',
  },
  'katal-statusindicator-alert': {
    'en-US': 'Warning: {label}',
    'ar-AE': 'تحذير: {label}',
    'bn-IN': 'সতর্কতা: {label}',
    'de-DE': 'Achtung: {label}',
    'es-ES': 'Advertencia: {label}',
    'es-MX': 'Advertencia: {label}',
    'fr-BE': 'Attention : {label}',
    'fr-FR': 'Attention : {label}',
    'gu-IN': 'ચેતવણી: {label}',
    'hi-IN': 'चेतावनी : {label}',
    'it-IT': 'Avviso: {label}',
    'ja-JP': '警告：{label}',
    'kn-IN': 'ಎಚ್ಚರಿಕೆ: {label}',
    'ko-KR': '경고: {label}',
    'ml-IN': '(മുന്നറിയിപ്പ്: {label}',
    'mr-IN': 'अलर्ट: {label}',
    'nl-BE': 'Waarschuwing: {label}',
    'nl-NL': 'Waarschuwing: {label}',
    'pl-PL': 'Ostrzeżenie: {label}',
    'pt-BR': 'Aviso: {label}',
    'pt-PT': 'Aviso: {label}',
    'sv-SE': 'Varning: {label}',
    'ta-IN': 'எச்சரிக்கை: {label}',
    'te-IN': 'హెచ్చరిక: {label}',
    'th-TH': 'คำเตือน {label}',
    'tr-TR': 'Uyarı: {label}',
    'vi-VN': 'Cảnh báo: {label}',
    'zh-CN': '警告：{label}',
    'zh-TW': '警告：{label}',
  },
  'katal-statusindicator-information': {
    'en-US': 'Information: {label}',
    'ar-AE': 'المعلومات: {label}',
    'bn-IN': 'তথ্য: {label}',
    'de-DE': 'Informationen: {label}',
    'es-ES': 'Información: {label}',
    'es-MX': 'Información: {label}',
    'fr-BE': 'Informations : {label}',
    'fr-FR': 'Informations : {label}',
    'gu-IN': 'માહિતી: {label}',
    'hi-IN': 'जानकारी : {label}',
    'it-IT': 'Informazioni: {label}',
    'ja-JP': '情報：{label}',
    'kn-IN': 'ಮಾಹಿತಿ: {label}',
    'ko-KR': '정보: {label}',
    'ml-IN': 'വിവരങ്ങൾ: {label}',
    'mr-IN': 'माहिती: {label}',
    'nl-BE': 'Informatie: {label}',
    'nl-NL': 'Informatie: {label}',
    'pl-PL': 'Informacja: {label}',
    'pt-BR': 'Informações: {label}',
    'pt-PT': 'Informação: {label}',
    'ta-IN': 'தகவல்: {label}',
    'te-IN': 'సమాచారం: {label}',
    'th-TH': 'ข้อมูล {label}',
    'tr-TR': 'Bilgi: {label}',
    'vi-VN': 'Thông tin: {label}',
    'zh-CN': '信息：{label}',
    'zh-TW': '資訊：{label}',
  },
  'katal-statusindicator-pending': {
    'en-US': 'Pending: {label}',
    'ar-AE': 'قيد الانتظار: {label}',
    'bn-IN': 'পেন্ডিং: {label}',
    'de-DE': 'Ausstehend: {label}',
    'es-ES': 'Pendiente: {label}',
    'es-MX': 'Pendiente: {label}',
    'fr-BE': 'En attente : {label}',
    'fr-FR': 'En attente : {label}',
    'gu-IN': 'પેન્ડીંગ: {label}',
    'hi-IN': 'बाकी : {label}',
    'it-IT': 'In sospeso: {label}',
    'ja-JP': '保留中：{label}',
    'kn-IN': 'ಬಾಕಿ ಇರುವ: {label}',
    'ko-KR': '보류 중: {label}',
    'ml-IN': 'തീർപ്പാക്കാത്തവ: {label}',
    'mr-IN': 'प्रलंबित: {label}',
    'nl-BE': 'In behandeling: {label}',
    'nl-NL': 'In behandeling: {label}',
    'pl-PL': 'Oczekiwanie: {label}',
    'pt-BR': 'Pendente: {label}',
    'pt-PT': 'Pendente: {label}',
    'sv-SE': 'Väntande: {label}',
    'ta-IN': 'நிலுவையில் உள்ளது: {label}',
    'te-IN': 'పెండింగ్: {label}',
    'th-TH': 'รอดำเนินการ {label}',
    'tr-TR': 'Beklemede: {label}',
    'vi-VN': 'Đang chờ xử lý: {label}',
    'zh-CN': '等待中：{label}',
    'zh-TW': '待處理：{label}',
  },
  'katal-statusindicator-loading': {
    'en-US': 'Loading: {label}',
    'ar-AE': 'جارٍ التحميل: {label}',
    'bn-IN': 'লোড হচ্ছে: {label}',
    'de-DE': 'Wird geladen: {label}',
    'es-ES': 'Cargando: {label}',
    'es-MX': 'Carga: {label}',
    'fr-BE': 'Chargement : {label}',
    'fr-FR': 'Chargement : {label}',
    'gu-IN': 'લોડ થઈ રહ્યું છે: {label}',
    'hi-IN': 'लोड करना : {label}',
    'it-IT': 'Caricamento in corso: {label}',
    'ja-JP': '読み込み中：{label}',
    'kn-IN': 'ಲೋಡ್ ಆಗುತ್ತಿದೆ: {label}',
    'ko-KR': '로드 중: {label}',
    'ml-IN': 'ലോഡ് ചെയ്യുന്നു: {label}',
    'mr-IN': 'लोड करत आहे: {label}',
    'nl-BE': 'Laden {label}',
    'nl-NL': 'Wordt geladen: {label}',
    'pl-PL': 'Wczytywanie: {label}',
    'pt-BR': 'Carregando: {label}',
    'pt-PT': 'A carregar: {label}',
    'sv-SE': 'Laddar: {label}',
    'ta-IN': 'ஏற்றுகிறது: {label}',
    'te-IN': 'లోడ్ అవుతోంది: {label}',
    'th-TH': 'กำลังโหลด {label}',
    'tr-TR': 'Yükleniyor: {label}',
    'vi-VN': 'Đang tải: {label}',
    'zh-CN': '正在加载：{label}',
    'zh-TW': '正在載入：{label}',
  },
};

export default katTranslator(strings);
