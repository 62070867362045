import React, { ReactNode, FunctionComponent } from "react";
import { KatTableRow, KatTableCell } from "@amzn/katal-react";
import classnames from "classnames";
import $style from "src/widgets/IssueSummaryWidget/components/case_seller_info/case-seller-info-row.module.scss";

interface CaseSellerInfoRowProps extends React.HTMLProps<HTMLDivElement> {
    cellLabel?: String,
    cellLabelElement?: ReactNode,
    cellValueElement?: ReactNode,
}

export const CaseSellerInfoRow: FunctionComponent<CaseSellerInfoRowProps> = ({
    cellLabel,
    cellLabelElement,
    cellValueElement,
    className,
}) => {

    return (
        <KatTableRow className={classnames(className, $style.katTableRow)}>
            <KatTableCell className={$style.katCellLabel}>
                {cellLabel && <strong > {cellLabel}</strong> }
                {cellLabelElement}
            </KatTableCell>
            <KatTableCell className={classnames($style.katCellValue)}>
                {cellValueElement}
            </KatTableCell>
        </KatTableRow>
    )
}