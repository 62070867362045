import { KatButton, KatRadiobutton, KatTextarea } from '@amzn/katal-react';
import React, { FunctionComponent, useEffect, useState } from 'react';
import $style from './common-component.module.scss';

type Props = {
    handleSubmitButton: () => void;
    setFeedback: (data: string) => void;
}

export const FeedbackPopover: FunctionComponent<Props> = ({ handleSubmitButton, setFeedback }) => {

    const feedbackOptions = [
        'Incorrect SOP',
        'Incorrect steps',
        'Insufficient information to proceed',
        'Process is already known, no need to view steps',
        'Only needed the SOP name',
        'Other',
    ];

    useEffect(() => {
    })

    return (
        <div>
            <label>Choose a reason for not using action plan,</label>
            {feedbackOptions.map((type) => (
                <KatRadiobutton
                    label={type}
                    value={type}
                    onChange={(e) => {
                        setFeedback((e.target as HTMLInputElement).value);
                    }}
                />
            ))}
            <div className={$style.cancelReason}>
                <label>Please share any additional comments or details</label>
                <KatTextarea
                    className={$style.cancelText}
                    placeholder='Enter comments'
                    onKeyup={(e: any) => {
                        setFeedback(e.target.value);
                    }}
                />
                <KatButton
                    variant='primary'
                    label='Submit'
                    onClick={() =>
                        handleSubmitButton()}
                />
            </div>
        </div>
    );
};