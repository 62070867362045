import React, {createContext, PropsWithChildren, useCallback, useContext, useEffect, useReducer, useState} from 'react';
import { paragonMediator } from '@amzn/paragon-context/lib/ParagonMediatorContext/paragonMediatorContext';
import {useParagonContext, useParagonContextConsumerEl} from "@amzn/paragon-ui-react";
import {paragonCoreDataContext} from "@amzn/paragon-context/lib/ParagonCoreDataContext";
import {paragonUserContext} from "@amzn/paragon-context/lib/ParagonUserContext";
import {paragonPageContext} from "@amzn/paragon-context/lib/ParagonPageContext";
import {paragonReplyContext} from "@amzn/paragon-context/lib/ParagonReplyContext";
import {paragonAnalyticsContext} from "@amzn/paragon-context/lib/ParagonAnalyticsContext";
import {clickStreamType, metricNames} from 'src/widgets/IssueSummaryWidget/components/widgets/ReplyCase/model/clickStreamMetric';
import {
    ParagonEvents,
    SaveFollowupWorkInput,
    SubmitExceptionInput,
    WhyContactedDropdownSubmitInput,
    emailBodyComponentDataActions,
    EmailBodyComponentData,
    TextSuggesterData,
    TextSuggesterFeedbackData, SubmissionMetadata
} from 'src/widgets/IssueSummaryWidget/components/widgets/ReplyCase/model/replyCaseSchemaElements';

import {ServicesAPIContext} from "src/service/service.context";

interface ReplyHandlerData {
    exceptionConfirmationData: SubmitExceptionInput,
    issueSummaryFollowupWorkData: SaveFollowupWorkInput,
    whyContactedHandlerData: WhyContactedDropdownSubmitInput,
    emailBodyComponentData: EmailBodyComponentData,
    suggesterData: TextSuggesterData
}

interface ReplyHandlerContextInterface {
    setExceptionConfirmationFormData: (exceptionData: SubmitExceptionInput) => void;
    setIssueSummaryFollowupWorFormData: (followupWorkData: SaveFollowupWorkInput) => void;
    setWhyContactedFormData: (whyContactedData: WhyContactedDropdownSubmitInput) => void;
    setEmailBodyComponentFormData: (emailBodyComponentData: EmailBodyComponentData) => void;
    setSuggesterFormData: (suggesterData: TextSuggesterData) => void;
    getSuggesterFormData: () => TextSuggesterData;
    setSuggesterFeedbackData: (feedbackData: TextSuggesterFeedbackData) => void;
    cleanSuggesterData: () => void;
    handleReplySubmitted: (submissionMetadata: SubmissionMetadata) => void;
    handleClearBodyContent: () => void;
}

const defaultReplyHandlerData: ReplyHandlerData = {
    exceptionConfirmationData: {
        caseId: '',
        isExceptionConfirmed: false,
        exceptionDescription: '',
        reason: ''
    },
    issueSummaryFollowupWorkData: {
        caseId: '',
        followupWork: ''
    },
    whyContactedHandlerData: {
        caseId: '',
        caseStatus: '',
        whyContactIssueId: ''
    },
    emailBodyComponentData: {
        caseId: '',
        emailContent: '',
        blurbFrictionData: {
            frictionBlurbs: []
        },
        latestAttachBlurbEventId: '',
        latestBlurbFrictionBlurbToBePopulatedId: ''
    },
    suggesterData: {
        replyId: '',
        suggestedText: '',
        suggestedTextEdit: '',
        modelLatency: -1,
        feedbackData: {
            action: '',
            agentLogin: '',
            edit: '',
            dismissedReason: '',
            modelLatency: -1
        }
    }
}

const defaultReplyHandlerContext: ReplyHandlerContextInterface = {
    setExceptionConfirmationFormData: () => {},
    setIssueSummaryFollowupWorFormData: () => {},
    setWhyContactedFormData: () => {},
    setEmailBodyComponentFormData: () => {},
    setSuggesterFormData: () => {},
    getSuggesterFormData: () => defaultReplyHandlerData.suggesterData,
    setSuggesterFeedbackData:  () => {},
    cleanSuggesterData: () => {},
    handleReplySubmitted: () => {},
    handleClearBodyContent: () => {}
}

export const ReplyHandlerContext = createContext<ReplyHandlerContextInterface>(defaultReplyHandlerContext);

function emailComponentDataReducer(state: EmailBodyComponentData, action): EmailBodyComponentData {
    switch (action.type) {
        case emailBodyComponentDataActions.setEmailBodyComponentData:
            return{
                ...state,
                ...action.payload
            };

        case emailBodyComponentDataActions.setFrictionBlurbs:
            return {
                ...state,
                blurbFrictionData: {
                    frictionBlurbs: action.payload
                }
            };

        default:
            return state;
    }
}

export const ReplyHandlerContextProvider = (props: PropsWithChildren) => {
    const [exceptionConfirmationData, setExceptionConfirmationData] = useState(defaultReplyHandlerData.exceptionConfirmationData);
    const [issueSummaryFollowupWorkData, setIssueSummaryFollowupWorkData] = useState(defaultReplyHandlerData.issueSummaryFollowupWorkData);
    const [whyContactedData, setWhyContactedData] = useState(defaultReplyHandlerData.whyContactedHandlerData);
    const [emailBodyComponentData, dispatchEmailBodyComponentData] = useReducer(emailComponentDataReducer, defaultReplyHandlerData.emailBodyComponentData);
    const [suggesterData, setSuggesterData] = useState(defaultReplyHandlerData.suggesterData);

    const serviceAPIContext = useContext(ServicesAPIContext);

    const el = useParagonContextConsumerEl();
    const { value: coreData } = useParagonContext(el, paragonCoreDataContext);
    const { value: userDetails } = useParagonContext(el, paragonUserContext);
    const { value: pageData } = useParagonContext(el, paragonPageContext);
    const { value: replyData, on: replyEventOn } = useParagonContext(el, paragonReplyContext);

    const { methods: analyticsMethods } = useParagonContext(el, paragonAnalyticsContext);

    const [loadingConfiguration, setLoadingConfiguration] = useState(true);

    useEffect(() => {
        if (coreData && userDetails && pageData && replyData) {
            setLoadingConfiguration(false);
        }
    }, [coreData, userDetails, pageData, replyData])


    const saveFollowupWork = useCallback(() => {
        if (!!issueSummaryFollowupWorkData.followupWork && issueSummaryFollowupWorkData.followupWork.trim().length > 0) {
            serviceAPIContext.paragonService.saveFollowupWork(issueSummaryFollowupWorkData).then(() => {
                setIssueSummaryFollowupWorkData(defaultReplyHandlerData.issueSummaryFollowupWorkData);
                paragonMediator.notify('edit-issue-summary-form-submission-succeeded', {}); // refresh issue summary
            }).catch(() => {
                console.log('Unexpected Error Occurred saving follow up work...')
            });
        }
    }, [issueSummaryFollowupWorkData]);

    useEffect(() => {
        const unsubTransferCase = replyEventOn(ParagonEvents.SAVE_FOLLOW_UP_ON_TRANSFER, () => saveFollowupWork());
        const unsubTransferToAgent = replyEventOn(ParagonEvents.SAVE_FOLLOW_UP_ON_TRANSFER_TO_AGENT, () => saveFollowupWork());
        const unsubTransferToExternalTeam = replyEventOn(ParagonEvents.SAVE_FOLLOW_UP_ON_TRANSFER_TO_EXTERNAL_TEAM, () => saveFollowupWork());

        return () => {
            unsubTransferCase();
            unsubTransferToAgent();
            unsubTransferToExternalTeam();
        }
    }, []);

    function setExceptionConfirmationFormData(exceptionData: SubmitExceptionInput) {
        setExceptionConfirmationData(exceptionData);
    }

    function setIssueSummaryFollowupWorFormData(followupWorkData: SaveFollowupWorkInput) {
        setIssueSummaryFollowupWorkData(followupWorkData);
    }

    function setWhyContactedFormData(whyContactedData: WhyContactedDropdownSubmitInput) {
        setWhyContactedData(whyContactedData);
    }

    function setEmailBodyComponentFormData(emailBodyComponentData: EmailBodyComponentData) {
        dispatchEmailBodyComponentData({ type: emailBodyComponentDataActions.setEmailBodyComponentData, payload: emailBodyComponentData });
    }

    function setSuggesterFormData(suggesterData: TextSuggesterData) {
        setSuggesterData(prevState => (
            {
                ...prevState,
                replyId: suggesterData.replyId,
                suggestedText: suggesterData.suggestedText,
                suggestedTextEdit: suggesterData.suggestedTextEdit,
                modelLatency: suggesterData.modelLatency
            })
        );
    }

    function getSuggesterFormData() {
        return suggesterData;
    }

    function setSuggesterFeedbackData(feedbackData: TextSuggesterFeedbackData) {
        setSuggesterData(prevState => ({ ...prevState, feedbackData: feedbackData }));
    }

    function cleanSuggesterData() {
        setSuggesterData(defaultReplyHandlerData.suggesterData);
    }

    function emitSubmitExceptionClickstream() {
        const metricData = {
            name: metricNames.exceptionConfirmationForm.caseExceptionSubmitted,
            meta: {
                associateGrantedException: exceptionConfirmationData.isExceptionConfirmed
            },
            type: clickStreamType.click
        }

        analyticsMethods.logData(metricData);
    }

    function submitException() {
        if (exceptionConfirmationData.reason) {
            serviceAPIContext.paragonService.submitException(exceptionConfirmationData).then(response => {
                if (response && response.submitExceptionData) {
                    emitSubmitExceptionClickstream();
                }
            }).catch(() => {
                console.error('Unexpected error while submitting exception.');
            });
        }
    }

    function submitWhyContactedDropdownData() {
        if (whyContactedData.whyContactIssueId) {
            serviceAPIContext.paragonService.submitWhyContactedDropdown(whyContactedData).catch(() => {
                console.error('Unexpected error while submitting why contacted dropdown data.');
            });
        }
    }

    function clearFrictionBlurbs() {
        dispatchEmailBodyComponentData({type: emailBodyComponentDataActions.setFrictionBlurbs, payload: []});

        const storageKey = 'bodyFrictionBlurbs'.concat(emailBodyComponentData.caseId);
        if (localStorage.getItem(storageKey)) {
            localStorage.removeItem(storageKey);
        }
    }

    function isContentEdited(content: string, blurbContent: string) {
        return !content?.includes(blurbContent);
    }

    function emitBlurbFrictionClickstream() {
        const content = emailBodyComponentData.emailContent;

        if (emailBodyComponentData.blurbFrictionData.frictionBlurbs) {
            emailBodyComponentData.blurbFrictionData.frictionBlurbs.forEach(function (blurb) {
                const metadata = blurb.metadata;
                metadata.PmReplyEdited = isContentEdited(content, blurb.originalContent);
                analyticsMethods.logData({
                    name: metricNames.emailBodyComponent.pmGreyedBlurbReplySend,
                    meta: metadata,
                    type: clickStreamType.click
                });
            })
        }

        clearFrictionBlurbs()
    }

    function submitSuggestionFeedback(ekkoId: string) {
        const caseId = coreData.caseDetails?.id;
        const merchantId = coreData.caseDetails?.merchantId;

        if (caseId && merchantId && suggesterData.replyId) {
            const params = {
                caseId: caseId,
                replyId: suggesterData.replyId,
                merchantId: merchantId,
                feedbackData: JSON.stringify({ ...suggesterData.feedbackData, ekkoId } )
            }

            serviceAPIContext.storageService.storeSuggestionFeedback(params).catch(() => {
                console.error('Unexpected error while submitting suggester feedback data.');
            });
        }
    }

    function handleReplySubmitted(submissionMetadata: SubmissionMetadata) {
        submitException();
        saveFollowupWork();
        submitWhyContactedDropdownData();
        emitBlurbFrictionClickstream();
        submitSuggestionFeedback(submissionMetadata.ekkoId);
        cleanSuggesterData();
    }

    function handleClearBodyContent() {
        clearFrictionBlurbs();
    }

    const replySubmitHandlerContext = {
        setExceptionConfirmationFormData,
        setIssueSummaryFollowupWorFormData,
        setWhyContactedFormData,
        setEmailBodyComponentFormData,
        setSuggesterFormData,
        getSuggesterFormData,
        setSuggesterFeedbackData,
        cleanSuggesterData,
        handleReplySubmitted,
        handleClearBodyContent
    };

    return <ReplyHandlerContext.Provider value={replySubmitHandlerContext}>
        {props.children}
    </ReplyHandlerContext.Provider>;
}