import {KatButton} from '@amzn/katal-react';
import React, {useEffect, useState} from 'react';
import {localization} from 'src/helpers';

import Modal from 'src/components/Modal/Modal';

import $style from './prompt-dialog.module.scss';

export interface PromptOptions {
    title: string;
    message: string;
    confirm: () => void;
    discard: () => void;
    visible: boolean;
}

interface PromptDialogProps {
    options: PromptOptions
}

export default function PromptDialog(props: PromptDialogProps) {
    const [visible, setVisible] = useState(props.options.visible);

    const { translate } = localization();

    useEffect(() => {
        setVisible(props.options.visible);
    }, [props.options]);

    function discard() {
        setVisible(false);
        props.options.discard();
    }

    function confirm() {
        setVisible(false);
        props.options.confirm();
    }

    const footerElement = <div>
        <KatButton data-testid="katal-discard-button"
                   variant="secondary"
                   label={translate('Paragon_TAM_CM_Cancel')}
                   onClick={discard}/>
        <KatButton className={$style.confirmButton} data-testid="katal-confirm-button"
                         variant="primary"
                         label={translate('Paragon_TAM_CM_OK')}
                         onClick={confirm}/>
    </div>

    return (
        <Modal visible={visible}
               onDismiss={discard}
               header={() => <p>{props.options.title}</p>}
               body={() => <p>{props.options.message}</p>}
               footer={() => footerElement}/>
    )
}