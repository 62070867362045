import {KatDropdown, KatInput} from '@amzn/katal-react';
import React, {useContext, useEffect, useState} from 'react';
import {localization} from 'src/helpers';

import $style from './exception-confirmation-form.module.scss';
import {ReplyHandlerContext} from "src/widgets/IssueSummaryWidget/components/widgets/ReplyCase/providers/replyHandler.context";

interface ExceptionConfirmationFormProps {
    caseId: string;
    shouldDisableSubmit: (shouldDisable: boolean) => void;
}

export default function ExceptionConfirmationForm(props: ExceptionConfirmationFormProps) {
    const [isException, setIsException] = useState<string | undefined>(undefined);
    const [exceptionDescription, setExceptionDescription] = useState<string>('');
    const [exceptionReason, setExceptionReason] = useState<string>('');
    const dropdownOptions = [
        { name: 'Yes', value: 'true'},
        { name: 'No', value: 'false'}
    ];

    const shouldDisableSubmit = props.shouldDisableSubmit;

    const { translate } = localization();

    const replySubmitHandlerContext = useContext(ReplyHandlerContext);

    useEffect(() => {
        replySubmitHandlerContext.setExceptionConfirmationFormData({
            caseId: props.caseId,
            isExceptionConfirmed: isException === 'true',
            exceptionDescription: exceptionDescription,
            reason: exceptionReason
        });
    }, [isException, exceptionDescription, exceptionReason]);

    useEffect(() => {
        setExceptionDescription('');
        setExceptionReason('');
        shouldDisableSubmit(getShouldDisableSubmit());
    }, [isException, shouldDisableSubmit]);

    useEffect(() => {
        shouldDisableSubmit(getShouldDisableSubmit());
    }, [exceptionDescription, shouldDisableSubmit]);

    useEffect(() => {
        shouldDisableSubmit(getShouldDisableSubmit());
    }, [exceptionReason, shouldDisableSubmit]);

    function getShouldDisableSubmit() {
        if (
            // not selected
            (isException === undefined)
            // is an exception and one of the text fields aren't validated
            || (isException === 'true' && (!exceptionDescription || exceptionDescription.length === 0 || !exceptionReason || exceptionReason.length === 0))
            // is not an exception and the exception reason isn't validated
            || (isException === 'false' && (!exceptionReason || exceptionReason.length === 0))) {
            return true;
        }

        return false;
    }

    const rejectReasonElement = <section className="kat-col-xs-6">
        <KatInput
            className={$style.exceptionKatInput}
            data-testid="katal-input-exception-reason-rejected"
            label={translate('Paragon_TAM_CM_Why_Exception_Reason_Rejected')}
            value={exceptionReason}
            onChange={(event) => setExceptionReason(event.currentTarget.value)}/>
    </section>

    const confirmReasonElement = <div className="kat-row">
        <section className="kat-col-xs-6">
            <KatInput
                className={$style.exceptionKatInput}
                data-testid="katal-input-exception-description"
                label={translate('Paragon_TAM_CM_Exception_Description')}
                value={exceptionDescription}
                onChange={(event) => setExceptionDescription(event.currentTarget.value)}/>
        </section>
        <section className="kat-col-xs-6">
            <KatInput
                className={$style.exceptionKatInput}
                data-testid="katal-input-exception-reason-confirmed"
                label={translate('Paragon_TAM_CM_Why_Exception_Reason_Confirmed')}
                value={exceptionReason}
                onChange={(event) => setExceptionReason(event.currentTarget.value)}/>
        </section>
    </div>

    return (
        <div>
            <div className="kat-row">
                <section className="kat-col-xs-6">
                    <KatDropdown
                        data-testid="katal-dropdown"
                        label={translate('Paragon_TAM_CM_Is_Exception_Question')}
                        value={isException}
                        options={JSON.stringify(dropdownOptions)}
                        onChange={(event) => setIsException(event.detail.value)}
                        state={(isException === undefined ? 'error' : undefined)} />
                </section>
                {isException === 'false' && rejectReasonElement}
            </div>
            {isException === 'true' && confirmReasonElement}
        </div>
    )
}