
import { useBundle } from '@amzn/react-arb-tools'

import { DEFAULT_LOCALE } from './constants';
import {STRINGS_MESSAGE_BUNDLE_NAME} from "src/constants";
import React, {useCallback} from 'react'


interface UseLocalizedBundle {
  isBundleLoading: boolean
  translate: (stringKey: string) => string
}

interface UseLocalizedBundle {
  isBundleLoading: boolean
  translate: (stringKey: string) => string
}

/**
 * @returns `isBundleLoading` boolean and typed `t` utility function
 */
export const localization = (): UseLocalizedBundle => {
  const [bundle, isBundleLoading] = useBundle(STRINGS_MESSAGE_BUNDLE_NAME)

  const getMessage = (stringKey: string) => {
    if (isBundleLoading) {
      return '';
    } else {
      try {
        return bundle.getMessage(stringKey as string)
      } catch (e) {
        console.error(`Can't find stringId: ${stringKey} in bundle.`);
        return stringKey
      }
    }
  }
  const translate = useCallback((stringKey: string) => { return getMessage(stringKey); },
      [isBundleLoading, bundle],
      )

  return { isBundleLoading, translate }
}
