import {KatLabel, KatFileUpload} from '@amzn/katal-react';
import React, {useContext, useEffect, useState} from 'react';
import {localization} from 'src/helpers';

import {Attachment} from 'src/widgets/IssueSummaryWidget/components/widgets/ReplyCase/model/attachment';

import {ServicesAPIContext} from 'src/service/service.context';

import $style from './attachment-module.module.scss';
import {
    UploadFileToS3Output
} from "src/widgets/IssueSummaryWidget/components/widgets/ReplyCase/model/replyCaseSchemaElements";

interface AttachmentModuleProps {
    caseId: string,
    updateAttachments: (attachments: Attachment[]) => void,
}

export default function AttachmentModule(props: AttachmentModuleProps) {
    const [localAttachments, setLocalAttachments] = useState<Attachment[]>([]);

    const updateAttachments = props.updateAttachments;

    const { translate } = localization();

    const serviceAPIContext = useContext(ServicesAPIContext);

    useEffect(() => {
        updateAttachments(localAttachments);
    }, [localAttachments, updateAttachments]);

    function isFileValid(file: File) {
        const base64Size = ((4 * file.size / 3) + 3) & ~3;
        const sizeLimitInByte = 10485760;

        if (base64Size > sizeLimitInByte || file?.name?.length > 255) {
            return false;
        }

        return true;
    }

    function uploadFileToS3(file: File) {
        // check if file is > 40MB
        if (!isFileValid(file)) {
            return;
        }

        file.arrayBuffer().then(fileBuffer => {
            const params = {
                attachmentSource: 'contact',
                caseId: props.caseId || '',
                fileBuffer: fileBuffer,
                fileName: file.name,
                fileType: file.type
            }

            serviceAPIContext.paragonService.uploadFileToS3(params).then(response => {
                if (response && response.uploadFileToS3Data) {
                    const responseData: UploadFileToS3Output = response.uploadFileToS3Data;
                    const attachment = {
                        fileSize: file.size,
                        fileName: file.name,
                        fileKey: responseData.results.data.fileKey,
                        fileType: responseData.results.data.fileType
                    }
                    setLocalAttachments(oldLocalAttachments => [...oldLocalAttachments, attachment] );
                }
            }).catch(() => {
                console.error(`Unexpected error while uploading file '${file.name}' to s3.`)
            })
        });
    }

    function uploadFiles(eventFiles: File[]) {
        for (const file of eventFiles) {
            uploadFileToS3(file)
        }
    }

    function onFileChange(event) {
        const eventFiles = event.detail.files;
        if (!eventFiles.length) {
            return;
        }

        uploadFiles(eventFiles);
    }

    function removeFile(event) {
        const eventFiles = event.detail.files[0];
        setLocalAttachments(localAttachments.filter((file) => file.fileName !== eventFiles.name));
    }

    return (
        <div className={$style.attachmentModuleDiv}>
            <div className={$style.componentHeader}>
                <KatLabel data-testid="katal-label" text={translate('Paragon_TAM_CM_Attachments')}/>
            </div>
            <div>
                <KatFileUpload
                    className={$style.attachmentFileUpload}
                    data-testid="katal-file-upload"
                    file-view="list"
                    multiple
                    variant="small"
                    max-size="10485760"
                    onFilesAttached={onFileChange}
                    onFilesRemoved={removeFile}>
                    <div slot="hint">{translate('Paragon_TAM_CM_Attachment_Module_Placeholder')}</div>
                </KatFileUpload>
            </div>
        </div>
    )
}