import {KatAlert, KatBox} from '@amzn/katal-react';
import React, {useEffect} from 'react';
import {localization} from 'src/helpers';

export interface Params {
    commId?: string;
    transferSuccessMessage?: string;
}
interface SubmittedViewProps {
    params?: Params;
    showComposer: () => void;
    cancelAndDiscard?: () => void;
}

import $style from './review-view.module.scss';

export default function SubmittedView(props: SubmittedViewProps) {
    const { translate } = localization();

    useEffect(() => {
        setTimeout(() => {
            props.showComposer();
            props.cancelAndDiscard?.();
        }, 10000)
    });

    function showReplySuccess() {
        return props.params?.commId !== undefined
    }

    function transferMessage() {
        return props.params?.transferSuccessMessage || ''
    }

    return (
        <KatBox className={$style.overRideKatBox} variant="ltgrey">
            {showReplySuccess()
                ? <KatAlert data-testid="katal-alert-email-success"
                            variant="success" header={translate('Paragon_TAM_CM_Email_Success')}
                            description={`ID: ${  props.params?.commId}`}
                            onDismiss={props.cancelAndDiscard}/>
                : <KatAlert data-testid="katal-alert-success"
                            variant="success"
                            description={transferMessage()}/>
            }
        </KatBox>
    )
}