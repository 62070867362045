import { KatalComponent, register } from '../../shared/base';

@register('kat-table-cell')
export class KatTableCell extends KatalComponent {
  static get observedAttributes() {
    return [];
  }

  constructor() {
    super(KatTableCell.observedAttributes);
  }

  connectedCallback() {
    super.connectedCallback();

    const parentRow = this.parentNode;
    if (!parentRow) return;

    const parentHead = parentRow.parentNode;

    const isHeader = parentHead && parentHead.tagName === 'KAT-TABLE-HEAD';
    this.setAttribute('role', isHeader ? 'columnheader' : 'cell');
  }
}
