import { KatalComponent, register } from '../../shared/base';

/**
 * @component {kat-workflowtracker} KatalWorkflowtracker The workflow tracker shows a user the number of steps, status, and their current location in a single workflow in which all the steps are required.
 * @guideline Do Use mode="linear" for step-by-step workflows
 * @guideline Do Use mode="custom" to define your own workflow. Custom is intended for workflows that don't have step dependencies.
 * @event click Click event fires when a user clicks on a clickable step. See event.detail.stepId (returns the id of the step clicked) and event.detail.steps for current steps state.
 * @example Simple {"mode":"linear", "steps": [{ "state":"complete", "label":"First Step" },{ "state":"complete", "label":"Second Step" },{ "state":"in-progress", "label":"Third Step" },{ "state":"todo", "label":"Fourth Step" }] }
 * @example Linear {"mode":"linear","steps": [{ "state":"complete", "label":"First Step","clickable":true,"id":"wt-first-step" },{ "state":"complete", "label":"Second Step","clickable":true,"id":"wt-second-step" },{ "state":"error", "label":"Third Step","clickable":false,"id":"wt-third-step" },{ "state":"todo", "label":"Fourth Step","clickable":false,"id":"wt-fourth-step" }] }
 * @example Vertical {"mode": "linear", "layout": "vertical", "steps": [{ "state":"complete", "label":"First Step", "secondaryLabel": "Additional details", "clickable":true,"id":"wt-first-step" },{ "state":"complete", "label":"Second Step","clickable":true,"id":"wt-second-step" },{ "state":"error", "label":"Third Step","clickable":false,"id":"wt-third-step" },{ "state":"todo", "label":"Fourth Step","clickable":false,"id":"wt-fourth-step" }] }
 * @example Responsive {"mode": "linear", "responsive": "true", "steps": [{ "state":"complete", "label":"First Step", "secondaryLabel": "Additional details", "clickable":true,"id":"wt-first-step" },{ "state":"complete", "label":"Second Step","clickable":true,"id":"wt-second-step" },{ "state":"error", "label":"Third Step","clickable":false,"id":"wt-third-step" },{ "state":"todo", "label":"Fourth Step","clickable":false,"id":"wt-fourth-step" }] }
 * @status Production
 * @a11y {keyboard}
 * @a11y {sr}
 * @a11y {contrast}
 */
@register('kat-workflowtracker')
export class KatWorkflowtracker extends KatalComponent {
  static get observedAttributes() {
    return ['steps', 'layout', 'responsive'];
  }

  constructor() {
    super(KatWorkflowtracker.observedAttributes);
    this._steps = [];
    this.connectedEvents();
  }

  initialize() {
    const stepsAttr = this.getAttribute('steps');
    if (stepsAttr) {
      this.steps = stepsAttr;
    }
  }

  connectedCallback() {
    super.connectedCallback();
    this.initialize();
    this.render();
  }

  /**
   * @classprop {enum} mode Set the mode of the workflow
   * @enum {value} linear The workflowtracker will only move forward, going back to a completed step will require the user to revisit previously completed steps
   * @enum {value} custom The workflowtracker will allow custom logic to control the progress
   */
  get mode() {
    return this.getAttribute('mode') || 'linear';
  }

  set mode(value) {
    ['linear', 'custom'].includes(value)
      ? this.setAttribute('mode', value)
      : this.removeAttribute('mode');
  }

  /**
   * @classprop {enum} layout Set the orientation of the workflow
   * @enum {value} horizontal (default)
   * @enum {value} vertical
   */
  get layout() {
    return this.getAttribute('layout') || 'horizontal';
  }

  set layout(value: string) {
    ['horizontal', 'vertical'].includes(value)
      ? this.setAttribute('layout', value)
      : this.removeAttribute('layout');
  }

  /**
   * @classprop {boolean} responsive Setting to true will cause the workflow tracker to collapse on small width screens (720px)
   */
  get responsive(): boolean {
    return this.getBooleanAttribute('responsive');
  }

  set responsive(value: boolean) {
    this.setBooleanAttribute('responsive', value);
  }

  /**
   * @classprop {json} steps An array of states to display the linear steps, in desired order. Valid states are "todo", "in-progress", "complete", or "error". Defaults to "todo"
   * @required
   */
  get steps() {
    return this._steps;
  }

  set steps(value) {
    if (Array.isArray(value)) {
      this._steps = value;
      this.render();
    } else if (typeof value === 'string') {
      try {
        this.steps = value === '' ? [] : JSON.parse(value);
      } catch (err) {
        throw new Error(
          'KatalWorkflowtracker - Items must be set as valid JSON - ' + err
        );
      }
    } else if (!value) {
      //null or empty
      this._steps = [];
    } else {
      throw new Error(
        'KatalWorkflowtracker - unrecognized steps format provided.'
      );
    }
  }

  render() {
    const steps = this.steps;

    const createAndAppendElement = (parent, elementTag) => {
      const element = document.createElement(elementTag);
      parent.appendChild(element);

      return element;
    };

    this.innerHTML = '';

    const stepFragment = document.createDocumentFragment();

    for (let i = 0; i < steps.length; i++) {
      const step = steps[i];
      const element = createAndAppendElement(
        stepFragment,
        'kat-workflowtracker-step'
      );
      element.setAttribute('state', step.state);
      element.setAttribute('label', step.label);
      if (step.secondaryLabel) {
        element.setAttribute('secondary-label', step.secondaryLabel);
      }
      if (step.clickable) {
        element.setAttribute('clickable', step.clickable);
      }
      if (step.id) {
        element.setAttribute('id', step.id);
      }
    }

    this.appendChild(stepFragment);
  }

  connectedEvents() {
    this.addEventListener('step', function (e: any) {
      e.preventDefault();
      e.stopPropagation();

      if (this.mode === 'linear') {
        const steps = this.steps;

        const step = steps.filter(item => item.id === e.detail.step)[0];
        const stepIndex = steps.indexOf(step);

        step.clickable = false;
        step.state = 'in-progress';

        for (let i = stepIndex + 1; i < steps.length; i++) {
          steps[i].clickable = false;
          steps[i].state = 'todo';
        }

        this.steps = steps;
      }

      this.dispatchCustomEvent(
        'click',
        {
          stepId: e.detail.step,
          steps: this.steps,
        },
        false
      );
    });
  }

  // Respond to attribute changes.
  attributeChangedCallback() {
    this.render();
  }
}
