import React, { FunctionComponent, useEffect, useState, ReactNode } from "react";
import $style from "./llm-issue-summary.module.scss";
import { ControlSpacing } from "@amzn/paragon-ui-react/";
import { Loading } from "src/components/Loading/Loading";
import { WidgetFunctions } from "@amzn/paragon-context/lib/ParagonWidgetDataContext/models/WidgetFunctions";
import { useSchemaCaseElements } from "src/widgets/IssueSummaryWidget/service/useSchemaCaseElements";
import { IssueSummaryIdentifiers } from "src/widgets/IssueSummaryWidget/components/llm_issue_summary/IssueSummaryIdentifiers";
import { IssueSummaryAlert } from "src/widgets/IssueSummaryWidget/components/llm_issue_summary/IssueSummaryAlert";
import { LLMSource } from "src/widgets/IssueSummaryWidget/model/schemaElementsData";
import { AnalyticsFunctions } from "@amzn/paragon-context/lib/ParagonAnalyticsContext/models/AnalyticsFunctions";
import { getParagonDomain, logClickStreamData } from "src/common/utils";
import { useParagonContextConsumerEl, useParagonContext } from '@amzn/paragon-ui-react';
import { paragonReplyContext } from "@amzn/paragon-context/lib/ParagonReplyContext";
import {localization} from "src/helpers";
import { CopyLinkifyIdentifier } from "src/widgets/IssueSummaryWidget/components/common/LinkifiedIdentifier";
import { IssueSummaryFunctions } from "@amzn/paragon-context/lib/IssueSummaryContext/models/IssueSummaryFunctions";
import { CorrespondenceType, ExpandCorrespondenceRequest } from "@amzn/paragon-context/lib/IssueSummaryContext/models/CaseFunctions/ExpandCorrespondence";
import { logger } from "src/logger";

interface LLMIssueSummaryProps extends React.HTMLProps<HTMLDivElement>  {
    caseId?: string,
    agentLogin?: string,
    widgetMethods: WidgetFunctions;
    clickStream: AnalyticsFunctions
    issueSummaryFunctions: IssueSummaryFunctions,
}

export const LLMIssueSummary: FunctionComponent<LLMIssueSummaryProps> = ({caseId, agentLogin, widgetMethods, clickStream, issueSummaryFunctions}) => {

    const {
        isSchemaDataLoading,
        predictedText,
        llmIdentifiers,
        llmActionsTaken,
        llmException,
        isLLMAttackDetected
    } = useSchemaCaseElements(caseId, widgetMethods, agentLogin);

    const domain: string = getParagonDomain(window.location.origin);

    const [ hasSellerIssueException, setHasSellerIssueException] = useState(false);

    const [ errorMessage, setErrorMessage] = useState('Paragon_TAM_CM_Seller_Issue_ERROR_Header');

    const [ isSellerIssueEmpty, setIsSellerIssueEmpty] = useState(false);

    const [ areActionsEmpty, setAreActionsEmpty ] = useState(false);

    const [ areIdentifiersEmpty, setAreIdentifiersEmpty ] = useState(false);

    const el = useParagonContextConsumerEl();

    const { value: replyData } = useParagonContext(el, paragonReplyContext,);

    const [ shouldShowActionsTaken, setShouldShowActionsTaken ] = useState(true);

    const { translate } = localization();

    const linkify = (identifier: string, identifierUrl: string, identifierPlaceHolder: RegExp, type: string): ReactNode => {
        const url = domain + identifierUrl.replace(identifierPlaceHolder, identifier);
        return <CopyLinkifyIdentifier className={$style.issue} clickStream={clickStream} clickStreamEventBaseName={type + "-identifier"} elementId={identifier} hrefUrl={url}
            agentLogin={agentLogin} caseId={caseId}
        />
    }

    const getLinkifiedLLMText = (text: string, sources?: LLMSource[]): ReactNode => {
        logger.info(`Generating links for ${caseId} and user: ${agentLogin}`);
        const asinRegexPattern = /(<a[^>]*)?(B0\w{8})([^>]*<\/a>)?/;
        const orderIdRegexPattern =  /(<a[^>]*)?(?!S0)(\w{3}-\d{7}-\d{7})([^>]*<\/a>)?/;
        const shipmentIdRegexPattern = /(<a[^>]*)?(FBA\w{5,9})([^>]*<\/a>)?/;

        const regexPatterns: RegExp[] = [asinRegexPattern, orderIdRegexPattern, shipmentIdRegexPattern];

        const combinedRegex = new RegExp(regexPatterns.map(regex => regex.source).join('|'), 'g');

        const parts: string[] = text.split(combinedRegex).filter(part => part);

        return (
            <div>
                {parts.map((part, index) => {
                    const patterns = [
                        {pattern: asinRegexPattern, url: replyData.spotAsinToolURL || "", placeholder: /asinPlaceholder/, type: "ASIN"},
                        {pattern: orderIdRegexPattern, url: replyData.spotOrderToolURL || "", placeholder: /orderIdPlaceholder/, type: "OrderId"},
                        {pattern: shipmentIdRegexPattern, url: replyData.spotFbaPurchaseOrderToolURL || "", placeholder: /shipmentIdPlaceholder/, type: "ShipmentId"}
                    ]
                    for (const {pattern, url, placeholder, type} of patterns) {
                        if (pattern.test(part)) {
                            return <div className={$style.LLMTextLink}>{linkify(part, url, placeholder, type)}</div>
                        }
                    }
                    return <span>{part}</span>
                })}
                {sources && getSourcesElement(sources)}
            </div>
        )
    }

    const getSourcesElement = (sources: LLMSource[]): ReactNode => {
        if (sources?.length === 1) {
             return <button onClick={() => onSourceClick(sources[0])} type="button" className={$style.sourceButton}>[See Source]</button>
        }
        return (
            <span>
                {sources.map((source, index) => (
                    <button key={index} onClick={() => onSourceClick(source)} type="button" className={$style.sourceButton}>
                        {index === 0 ? '[' : ''}See Source {index + 1}{index < sources.length - 1 ? ', ' : ']'}
                    </button>
                ))}
            </span>
        )
    }

    useEffect(() => {
        if (isSchemaDataLoading) {
            return;
        }

        if (Object.keys(llmIdentifiers || {}).length === 0) {
            logClickStreamData(clickStream, 'click', 'llm-issue-summary-empty-identifiers');
            logger.info(`Issue Summary generated has empty identifiers for case: ${caseId}`, {agentLogin});
            setAreIdentifiersEmpty(true);
        }

        if (llmException.Message) {
            logClickStreamData(clickStream, 'click', 'llm-issue-summary-llm-exception');
            setHasSellerIssueException(true);
            logger.info(`Issue Summary generated has an exception for ${caseId}`, {agentLogin});

            const message = llmException.Message.toLowerCase();

            if (message.includes('unsupported language')) {
                setErrorMessage('Paragon_TAM_CM_Seller_Issue_LANGUAGE_ERROR_Header');
            }
            else if (message.includes('no valid seller contacts')) {
                setErrorMessage('Paragon_TAM_CM_Seller_Issue_CONTACT_ERROR_Header');
            }
            else if(message.includes('token size exceeds')) {
                logClickStreamData(clickStream, 'click', 'llm-issue-summary-large-token-exception');
                setErrorMessage('Paragon_TAM_CM_Seller_Issue_TOKEN_ERROR_Header')
            }
            return;
        }

        if (isLLMAttackDetected) {
            logClickStreamData(clickStream, 'click', 'llm-issue-summary-llm-attack-detected');
            logger.info(`Issue Summary detected an LLM Attack while generating summary for ${caseId}`, {agentLogin});
            setHasSellerIssueException(true);
            setAreIdentifiersEmpty(true);
            return;
        }

        if (predictedText === null || predictedText === '') {
            logClickStreamData(clickStream, 'click', 'llm-issue-summary-empty-prediction-text');
            logger.info(`Issue Summary generated an empty summary for caseId: ${caseId}`, {agentLogin});
            setIsSellerIssueEmpty(true);
        }

        if (llmActionsTaken === null || llmActionsTaken.length === 0 || llmActionsTaken[0].Action === null || llmActionsTaken[0].Action === '') {
            logClickStreamData(clickStream, 'click', 'llm-issue-summary-empty-actions');
            logger.info(`Issue Summary generated has empty actions for: ${caseId}`, {agentLogin});
            setAreActionsEmpty(true);
        }

        if (predictedText) {
            logClickStreamData(clickStream, 'click', 'llm-issue-summary-prediction-text-loaded');
            logger.info(`Issue Summary generated has prediction text for caseId: ${caseId}`, {agentLogin});
        }
        
    }, [isSchemaDataLoading, predictedText, llmIdentifiers, llmException, llmActionsTaken]);

    useEffect(() => {
        if (hasSellerIssueException || (isSellerIssueEmpty && areActionsEmpty)) {
            setShouldShowActionsTaken(false);
        }
    }, [hasSellerIssueException, isSellerIssueEmpty, areActionsEmpty])

    const onSourceClick = (source: LLMSource) => {
        logClickStreamData(clickStream, 'click', 'llm-issue-summary-see-source-clicked');
        const request: ExpandCorrespondenceRequest = {
            correspondenceType: source.Type as CorrespondenceType,
            sourceId: source.Id
        }
        logger.info(`See Source button is clicked on CaseId: ${caseId} by user: ${agentLogin}`);
        issueSummaryFunctions.expandCorrespondence(request);
    }

    return (
        <div className={$style.issueSummary}>
            <ControlSpacing column size="sm">

                <h3 className={$style.sellerIssue}>{translate('Paragon_TAM_CM_Seller_Issue')}</h3>

                {isSchemaDataLoading &&
                    <div className={$style.spinnerBox}>
                        <Loading isLoading={isSchemaDataLoading}>&nbsp;</Loading>
                    </div>
                }

                {!isSchemaDataLoading &&
                    <div>
                        <div>
                            {(!hasSellerIssueException && !isSellerIssueEmpty) &&
                                <h4 className={$style.issueSummaryHeadings}>{translate('Paragon_TAM_CM_Issue_Summary')}</h4>
                            }

                            {(hasSellerIssueException || isSellerIssueEmpty) &&
                                <IssueSummaryAlert
                                    className={$style.issueSummaryAlert}
                                    variant="info"
                                    headerValue={translate(errorMessage)}
                                    descriptionValue={translate('Paragon_TAM_CM_Seller_Issue_ERROR_Message')}
                                />
                            }

                            {(!hasSellerIssueException && !isSellerIssueEmpty) &&
                                <div className={$style.predictedText}>{getLinkifiedLLMText(predictedText)}</div>
                            }
                        </div>

                        {shouldShowActionsTaken && <div>
                            {(!hasSellerIssueException) &&
                                <h4 className={$style.issueSummaryHeadings}>{translate('Paragon_TAM_CM_Key_Actions_Taken')}</h4>
                            }

                            {areActionsEmpty &&
                                <IssueSummaryAlert
                                    className={$style.issueSummaryAlert}
                                    variant="info"
                                    headerValue={translate('Paragon_TAM_CM_No_Actions_Found_Header')}
                                    descriptionValue={translate('Paragon_TAM_CM_No_Actions_Found_Message')}
                                />
                            }
                            <ul className={$style.actionsList}>
                                {llmActionsTaken.map((action, index) => (
                                    <li key={index} className={$style.action}>
                                        {getLinkifiedLLMText(action.Action, action.Source)}
                                    </li>
                                ))}
                            </ul>
                        </div>}

                        <div>
                            <h4 className={$style.issueSummaryHeadings}>{translate('Paragon_TAM_CM_Key_Identifiers')}</h4>

                            <IssueSummaryIdentifiers identifiers={llmIdentifiers} areIdentifiersEmpty={areIdentifiersEmpty}
                                spotASINUrl={replyData.spotAsinToolURL} spotOrdersUrl={replyData.spotOrderToolURL}
                                                     fbaShipmentsUrl={replyData.spotFbaPurchaseOrderToolURL} clickStream={clickStream}
                                                     agentLogin={agentLogin} caseId={caseId}
                            />
                        </div>
                    </div>
                }
            </ControlSpacing>
        </div>
    );
};
