import { AnalyticsFunctions } from "@amzn/paragon-context/lib/ParagonAnalyticsContext/models/AnalyticsFunctions";

export function copyToClipboard(value: string) {
    const textareaEl = document.createElement("textarea");
    textareaEl.style.position = "fixed";
    textareaEl.style.left = "-9999px";
    textareaEl.value = value;
    textareaEl.setAttribute("readonly", "");
    document.body.appendChild(textareaEl);
    textareaEl.select();
    document.execCommand("copy");
    document.body.removeChild(textareaEl);
    return;
}

export function logClickStreamData(clickStreamFunctions: AnalyticsFunctions, metricType: string, metricName: string, metaData?: object) {
    const clickStreamData = {
        type: metricType,
        name: metricName,
        ...(metaData && {meta: metaData})
    }

    clickStreamFunctions.logData(clickStreamData);
}

export function formatDate(date: string|number|Date, dateFormat: "default"|"iso", timeZone?: "utc", locale?: string) {
    const dateObj = new Date(date);
    if (isNaN(dateObj.getTime())) {
        return "";
    }
    if (dateFormat === "default") {
        if (timeZone === "utc") {
            return dateObj.toLocaleString(locale, { timeZone: "UTC" });
        }
        return dateObj.toLocaleString(locale);
    } else if (dateFormat === "iso") {
        return dateObj.toISOString();
    }
    return dateObj.toUTCString();
}

export function getParagonDomain(iframeOriginUrl: string): string {
    return ParagonRootDomainMapping[iframeOriginUrl];
}

export const ParagonRootDomainMapping: { [key: string]: string } = {
    "https://0.0.0.0:4321": "https://paragon-na-preprod.amazon.com",
    "https://beta.pgn-associate-features.spse.amazon.dev" : "https://paragon-na.integ.amazon.com",
    "https://na-gamma.pgn-associate-features.spse.amazon.dev" : "https://paragon-na-preprod.amazon.com",
    "https://eu-gamma.pgn-associate-features.spse.amazon.dev" : "https://paragon-eu-preprod.amazon.com",
    "https://fe-gamma.pgn-associate-features.spse.amazon.dev" : "https://paragon-fe-preprod.amazon.com",
    "https://na-prod.pgn-associate-features.spse.amazon.dev" : "https://paragon-na.amazon.com",
    "https://eu-prod.pgn-associate-features.spse.amazon.dev" : "https://paragon-eu.amazon.com",
    "https://fe-prod.pgn-associate-features.spse.amazon.dev" : "https://paragon-fe.amazon.com"
}

export function normalizeString(t, stringId, stringAltError: string | null = null) {
  const translatedLabel = t(stringId);
  return translatedLabel === stringId && stringAltError !== null ? stringAltError : translatedLabel;
}

export function encodeHTML(html: string) {
    const el = document.createElement("div");
    el.textContent = html;
    return el.innerHTML;
}