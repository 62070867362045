import { useEffect, useState } from "react";
import { WidgetFunctions } from "@amzn/paragon-context/lib/ParagonWidgetDataContext/models/WidgetFunctions";
import { getSchemaCaseData } from "src/widgets/IssueSummaryWidget/service/issueSummary";
import {
    SCEDataRequest, SchemaCaseElementsDataResponse, LLMSchemaData, LLMActions,
    LLMIdentifiers, LLMException, SchemaCaseElementsPrefix, SchemaDataProperties
} from "src/widgets/IssueSummaryWidget/model/schemaElementsData";
import { logger } from "src/logger";

export function useSchemaCaseElements(caseId: string | undefined, widgetMethods: WidgetFunctions, agentLogin: string | undefined) {

    const [ isSchemaDataLoading, setSchemaDataLoading] = useState(true);

    const [ isLLMAttackDetected, setIsLLMAttackDetected ] = useState(false);

    const [ predictedText, setPredictedText ] = useState('');

    const [ llmIdentifiers, setLLMIdentifiers ] = useState<LLMIdentifiers>();

    const [ llmActionsTaken, setLLMActionsTaken ] = useState<LLMActions[]>([]);

    const [ llmException, setLLMException ] = useState<LLMException>({
        Type: null, Message: null
    });

    const parseSchemaData = (data: LLMSchemaData) => {
        if (data.Metadata.Exception) {
            setLLMException(data.Metadata.Exception);
            return;
        }
        setPredictedText(data.PredictedText);
        setLLMIdentifiers(data.Metadata.Identifiers);
        setLLMActionsTaken(data.Metadata.ActionsTaken || []);
        setIsLLMAttackDetected(data.Metadata.AttackDetected || false);
    };

    useEffect(() => {
        if (!caseId) {
            return;
        }

        const sceRequest: SCEDataRequest = {
            schemaCaseElementPrefix: SchemaCaseElementsPrefix.LLM_SCHEMA_PREFIX,
            schemaPropertyAttribute: SchemaDataProperties.LLM_SCHEMA_USE_CASE,
            schemaPropertyValue: SchemaDataProperties.LLM_SCHEMA_ISSUE_SUMMARY,
        };

        setSchemaDataLoading(true);

        logger.info(`Calling Satellite Service to fetch LLM Issue Summary for case: ${caseId} and user: ${agentLogin}`);

        getSchemaCaseData(caseId, [sceRequest], SchemaDataProperties.LLM_SCHEMA_ISSUE_SUMMARY, widgetMethods)
            .then((response) => {
                if (response.success) {
                    const schemaCaseResponse: SchemaCaseElementsDataResponse = response.data;
                    const schemaCaseData: string = schemaCaseResponse.schemaElementsData[SchemaCaseElementsPrefix.LLM_SCHEMA_PREFIX].schemaData;
                    if (schemaCaseData) {
                        try {
                            const parsedSchemaData: LLMSchemaData = JSON.parse(schemaCaseData);
                            parseSchemaData(parsedSchemaData);
                        } catch (error) {
                            console.error("Error parsing JSON:", error);
                            setLLMException({Type: "Internal Error", Message: "An Error Occurred, Please Try again"});
                        }
                    }
                }
            }).catch(() => {
                logger.info(`Exception occurred while fetching llm summary for case: ${caseId} and user: ${agentLogin}`);
                setLLMException({Type: "Internal Error", Message: "An Error Occurred, Please Try again"});
            }).finally(() => {
            logger.info(`Finished calling Satellite Service to fetch LLM Issue Summary for case: ${caseId} and user: ${agentLogin}`);
                setSchemaDataLoading(false);
            })

    }, [caseId]);

    return {
        isSchemaDataLoading,
        predictedText,
        llmIdentifiers,
        llmActionsTaken,
        llmException,
        isLLMAttackDetected
    };
}