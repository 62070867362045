import {KatButton, KatSpinner} from '@amzn/katal-react';
import React, {useContext, useState} from 'react';
import {localization} from 'src/helpers';

import $style from './save-primary-email-button.module.scss';
import {ServicesAPIContext} from "src/service/service.context";

interface SavePrimaryEmailButtonProps {
    caseId?: string;
    toEmailList?: string[];
}

export default function SavePrimaryEmailButton(props: SavePrimaryEmailButtonProps) {
    const [isLoading,  setIsLoading] = useState(false);
    const [shouldShowOutput, setShouldShowOutput] = useState(false);
    const [outputMessage,  setOutputMessage] = useState('');
    const [error, setError] = useState(false);

    const serviceAPIContext = useContext(ServicesAPIContext);

    const { translate } = localization();


    function saveToEmailList() {
        setIsLoading(true);

        const params = {
            caseId: props.caseId || '',
            emails: props.toEmailList || []
        }

        serviceAPIContext.paragonService.savePrimaryEmailList(params).then(response => {
            if (response && response.savePrimaryEmailListData) {
                setOutputMessage(response.savePrimaryEmailListData);
            }
        }).catch(error => {
            if (error && error.response) {
                setOutputMessage(error.response.data);
            } else {
                setOutputMessage('Paragon_TAM_CM_An_error_occurred_please_try_again_later');
            }

            setError(true);
        }).finally(() => {
            setShouldShowOutput(true);
            setIsLoading(false);
        });
    }

    return (
        <span>
            {shouldShowOutput && error && <span className={$style.failureSpan}>{translate(outputMessage)}</span>}
            {shouldShowOutput && !error && <span className={$style.successSpan}>{translate(outputMessage)}</span>}
            {isLoading && <KatSpinner data-testid="katal-spinner"
                                      data-csm-name="save-primary-email-button-spinner"
                                      variant="default"
                                      size="small" />}
            {!isLoading && <KatButton data-testid="katal-button" label={translate('Paragon_TAM_CM_Save_Primary_Email')}
                                      size="small"
                                      variant="primary"
                                      data-csm-name="save-primary-email-button-click"
                                      onClick={saveToEmailList}/>}
    </span>
    )
}