import DOMPurify from "dompurify";
import { encodeHTML } from "src/common/utils";

const AWS_TENANT_ID = 2;

interface Config {
    placeholderArray: string[],
    placeholderText: string,
}

export function linkify(text: string, configData) {
    if (!text) {
        return "";
    }

    const globalLinkifyDisable = window.location.search.indexOf("linkify=0") > -1;
    if (globalLinkifyDisable) {
        return encodeHTML(text);
    }

    let linkifiedText = santizeHTMLTags(text);
    const fieldMap = configData.fieldMap;
    const initialContactFieldMap = configData.InitialContactFieldMap;

    if (fieldMap?.tenantId === AWS_TENANT_ID) {
        const config: Config = {
            placeholderArray : [],
            placeholderText : "###LINK_Placeholder_"
        };
        linkifiedText = removeLinkDoms(linkifiedText, config);
        linkifiedText = parseHTMLforURLs(linkifiedText);
        linkifiedText = addLinkDoms(linkifiedText, config);
    } else {
        linkifiedText = linkifyHyperLinks(linkifiedText);
        linkifiedText = linkifyAsins(linkifiedText, configData);
        if (fieldMap?.shouldLinkifyOrderIds || initialContactFieldMap?.shouldLinkifyOrderIds) {
            linkifiedText = linkifyOrderIds(linkifiedText, configData);
        }
        linkifiedText = linkifyFbaShipmentIds(linkifiedText, configData);
    }

    linkifiedText = DOMPurify.sanitize(linkifiedText);
    return linkifiedText;
}

// This is to sanitize any HTML that comes with '<' example: <test or <test> or or </test> or <xyz@amazon.com> since v-html interprets this as an html tag.
// Verified with vue-sanitize and sanitize-html libraries. These libraries remove the HTML tags like <test> so we can't use that.
function santizeHTMLTags(text: string) {
    const HTML_TAG_MATCHER_REGEX = /(?:<a[^>]+>[^<]+<\/a>)|[<>]/gim; // matches well formed anchor tags and falls back to any < or > character
    const linkifiedText = text.replace(
      HTML_TAG_MATCHER_REGEX,
      function(entireCapture) {
          // // if it is hyperlink, ignore since we need that desanitized.
          if (entireCapture.startsWith("<a ")) {
              const template = document.createElement("template");
              template.innerHTML = entireCapture;

              const rootAnchor = template.content.querySelector("a");
              if(!rootAnchor) {
                  return "";
              }

              rootAnchor.innerHTML = encodeHTML(rootAnchor.innerHTML) || "";
              return rootAnchor.outerHTML;
          }

          if(entireCapture === "<") {
              return "&lt;";
          }

          return "&gt;";
      }
    );
    return linkifiedText;
}

/* Hyperlinks: http://www.amazon.com or https://www.amazon.com/test?xyz=123 */
function linkifyHyperLinks(text: string) {
    const A_HREF_OPEN_CAPTURING_GROUP = "(<a [^>]*)?";
    const A_HREF_CLOSE_CAPTURING_GROUP = "([^>]*<\\/a>)?";

    // '?:' designates non-capturing group for js
    const URL_PROTOCOL = "(?:(?:https?):\\/\\/|www\\d{0,3}[.])";
    const URL_DOMAIN = "(?:\\([A-Z0-9+&@#\\/%=~_|$?!;:,.\\-]*\\)|[^\\s()<>])*";
    const URL_PATH = "(?:\\([A-Z0-9+&@#\\/%=~_|$?!;:,.\\-]*\\)|[^\\s()<>])";

    // Additional parentheses create a capturing group to return entire url body
    const URL_BODY = `(${  URL_PROTOCOL  }${URL_DOMAIN  }${URL_PATH  })`;

    const URL_MATCHER = A_HREF_OPEN_CAPTURING_GROUP + URL_BODY + A_HREF_CLOSE_CAPTURING_GROUP;

    // gim - flags for the regex.
    // g - global (regex will search for every match and not just the first one)
    // i - case insenstive
    // m - multi-line (regex will match for text that covers multiple lines)
    const URL_MATCHER_REGEX = new RegExp(URL_MATCHER, "gim");
    const linkifiedText = text.replace(
      URL_MATCHER_REGEX,
      function($0, $1, $2, $3) {
          const entireCapture = $0;
          const aHrefOpenCapture = $1;
          const urlBodyCapture = $2;
          const aHrefCloseCapture = $3;

          const linkWithoutClosingBrackets = (urlBodyCapture.length >= 4 ? 
            (urlBodyCapture.substr(urlBodyCapture.length - 4) === "&gt;" ? urlBodyCapture.slice(0, -4) : urlBodyCapture) : urlBodyCapture);
          const addBracketNotInLink = (urlBodyCapture.length >= 4 ? (urlBodyCapture.substr(urlBodyCapture.length - 4) === "&gt;" ? ">" : "") : "");

          const retVal = "<a href=\"";

          // If plaintext already has an html link tag, do nothing
          // Otherwise check to see if the 'url' text has 'http' or 'https' protocol. If not, add protocol header to the link - will not appear in the text
          // Ensure that closing '>' brackets are not included in the url
          if (aHrefOpenCapture || aHrefCloseCapture) {
              return entireCapture;
          } else if (urlBodyCapture.match("^https?://")) {
              return `${retVal + linkWithoutClosingBrackets  }">${  linkWithoutClosingBrackets  }</a>${  addBracketNotInLink}`;
          } else {
              return `${retVal  }http://${  linkWithoutClosingBrackets  }" rel="nofollow noopener noreferrer" >${  linkWithoutClosingBrackets  }</a>${  addBracketNotInLink}`;
          }
      });
    return linkifiedText;
}

/* ASIN pattern: B0xxxxxxxx */
function linkifyAsins(linkifiedContactContent: string, configData) {
    const spotAsinToolURL = configData.spotAsinToolURL || ""
    const asinsLinkifiedContactText = linkifiedContactContent.replace( /(<a[^>]*)?(B0\w{8})([^>]*<\/a>)?/g,
      function ($0, $1, $2,$3) {
          if ($1 || $3) { //it's already inside a link
              return $0;
          }
          const spotAsinToolURLAfterReplacement = spotAsinToolURL.replace(/asinPlaceholder/, $2);
          return `<a href=${  spotAsinToolURLAfterReplacement   } target="_blank" rel="nofollow noopener noreferrer">${  $2  }</a>` ;
      });
    return asinsLinkifiedContactText;
}

/* orderID pattern : xxx-xxxxxxx-xxxxxxx except patterns beginning with S0 */
function linkifyOrderIds(linkifiedContactContent: string, configData) {
    const spotOrderToolURL = configData.spotOrderToolURL || ""
    const orderIdsLinkifiedContactContent = linkifiedContactContent.replace( /(<a[^>]*)?(?!S0)(\w{3}-\d{7}-\d{7})([^>]*<\/a>)?/g,
      function ($0, $1, $2,$3) {
          if ($1 || $3) { //it's already inside a link
              return $0;
          }
          const spotOrderToolURLAfterReplacement = spotOrderToolURL.replace(/orderIdPlaceholder/, $2);
          return `<a href=${  spotOrderToolURLAfterReplacement  } target="_blank" rel="nofollow noopener noreferrer">${  $2  }</a>` ;
      });
    return orderIdsLinkifiedContactContent;
}

/* FBA ShipmentID pattern: FBAx* with 5-9 occurrences of x. */
function linkifyFbaShipmentIds(linkifiedContactContent: string, configData) {
    const fbaPurchaseOrderToolURL = configData.spotFbaPurchaseOrderToolURL || ""
    const fbaShipmentIdsLinkifiedContactContent = linkifiedContactContent.replace( /(<a[^>]*)?(FBA\w{5,9})([^>]*<\/a>)?/g,
      function ($0, $1, $2,$3) {
          if ($1 || $3) { //it's already inside a link
              return $0;
          }
          const fbaPurchaseOrderToolURLAfterReplacement = fbaPurchaseOrderToolURL.replace(/shipmentIdPlaceholder/, $2);
          return `<a href=${  fbaPurchaseOrderToolURLAfterReplacement   } target="_blank" rel="nofollow noopener noreferrer">${  $2  }</a>` ;
      });
    return fbaShipmentIdsLinkifiedContactContent;
}

function getLink(href: string, text: string, span: string) {
    return `<a href=${ 
      href 
      } target="_blank" rel="nofollow noopener noreferrer">${
      text 
      }</a>${
      span}`;
}

function parseHTMLforURLs(html: string) {
    const regex = new RegExp(/(https*\:\/\/[\w\-.~:\/?#\[\]@%!$&'()*+,;=`.]*)/gi);//eslint-disable-line
    const placeHolderRegex = new RegExp(/(https*\:\/\/[\w \-.~:\/?#\[\]@%!$&'()*+,;=`.]*)/gi);//eslint-disable-line
    const htmlArray = html.split(regex);

    for (let i = 0; i < htmlArray.length; i++) {
        if(regex.test(htmlArray[i])){
            //Remove Resourcelinker embedded inside an URL string
            htmlArray[i] = htmlArray[i].replace(/(###LINK_Placeholder_\d*###)/,"");

            let endSPAN = "";
            if(htmlArray[i].match(/.*\/<\/span>/gi)) {
                htmlArray[i] = htmlArray[i].replace("</span>","");
                endSPAN = "</span>";
            }
            htmlArray[i] = htmlArray[i].replace(/(###LINK_Placeholder_\d*###)/,"");
            //construct template script

            htmlArray[i] = getLink(htmlArray[i], htmlArray[i], endSPAN);
        }
    }

    html = htmlArray.join("");
    return html;
}

function removeLinkDoms(html: string, config){
    const regex = new RegExp(/(<a[\s\S]*?<\/a>?)/gi);
    const htmlArray = html.split(regex);
    const placeholderArray = config.placeholderArray;
    const placeholderText = config.placeholderText;
    const len  = htmlArray.length;
    for (let i = 0; i < len; i++) {
        if(regex.test(htmlArray[i])) {
            const linkCode = htmlArray[i];
            htmlArray[i] = `${placeholderText + placeholderArray.length  }###`;
            placeholderArray.push(linkCode);
        }
    }

    html = htmlArray.join("");
    return html;
}

function addLinkDoms(html: string, config: Config){
    const placeholderArray = config.placeholderArray;
    const placeholderText = config.placeholderText;
    const len = placeholderArray.length;
    for (let j = 0; j < len; ++j) {
        html = html.replace(`${placeholderText + j  }###`, placeholderArray[j]);
    }
    return html;
}